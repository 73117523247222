import React from "react";
import { Link } from "react-router-dom";
import { forerun, ninoslogo, raquel, sergio } from "../../assets/img";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Carousel from "react-bootstrap/Carousel";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DOMPurify from "dompurify";

const FeedBackHome = ({ testimonial }) => {
  return (
    <div className="home-feed-back " style={{ margin: "70px auto" }}>
      <h4 className="sect-title text-center my-4 mb-md-4">
        Our partner perspectives.
      </h4>
      {/* <div className="contain d-flex flex-wrap w-90"> */}
      {/* <div className="box w-50">
          <div className="box1 mt-3">
            <div className="d-sm-flex">
              <div className="left-box m-auto m-sm-0">
                <img  
                  src={sergio}
                  alt=""
                />
              </div>
              <div className="rit-box">
                <p className="parag text-justify">
                “Our company-wide slack channels flooded with thank yous, and people were posting pictures of their kids just saying, it made my day, it made my week.”
                </p>
              </div>
            </div>
            <div className="txt-btm mt-2 d-flex justify-content-between">
              <div className="left-btm">
                <h5 className="d-blue">Sergio Marquina</h5>
                <p>People Operations Specialist at ninos</p>
                <img
                  src={ninoslogo}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className="box w-50">
          <div className="box1 mt-3">
            <div className="d-sm-flex">
              <div className="left-box m-auto m-sm-0">
                <img  
                  src={sergio}
                  alt=""
                />
              </div>
              <div className="rit-box">
                <p className="parag text-justify">
                “Our company-wide slack channels flooded with thank yous, and people were posting pictures of their kids just saying, it made my day, it made my week.”
                </p>
              </div>
            </div>
            <div className="txt-btm mt-2 d-flex justify-content-between">
              <div className="left-btm">
                <h5 className="d-blue">Sergio Marquina</h5>
                <p>People Operations Specialist at ninos</p>
                <img
                  src={ninoslogo}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
      {/* <div className="box   w-50">
          <div className="box1 mt-3">
            <div className="d-sm-flex">
              <div className="left-box m-auto m-sm-0">
                <img  
                  src={sergio}
                  alt=""
                />
              </div>
              <div className="rit-box">
                <p className="parag text-justify">
                “Our company-wide slack channels flooded with thank yous, and people were posting pictures of their kids just saying, it made my day, it made my week.”
                </p>
              </div>
            </div>
            <div className="txt-btm mt-2 d-flex justify-content-between">
              <div className="left-btm">
                <h5 className="d-blue">Sergio Marquina</h5>
                <p>People Operations Specialist at ninos</p>
                <img
                  src={ninoslogo}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div> */}
      <Container>
        {/* <Col md={12} lg={6}> */}

        {/* <OwlCarousel className='owl-theme' loop  nav> */}
        {/* <Carousel> */}

        <div className="slider">
          <div className="slide-track">
            {testimonial?.map((item, ind) => {
              return (
                <div
                  className="slide px-3"
                  style={{ perspective: "200px", padding: "10px" }}
                >
                  {/* <Carousel.Item interval={1000} className="w-100"> */}
                  <div
                    className="box w-100"
                    style={{ transition: "transform 1s", padding: "10px" }}
                  >
                    <div className="box1 mt-3" style={{ width: "500px" }}>
                      <div className="d-sm-flex">
                        <div className="left-box m-auto m-sm-0">
                          <img src={item?.image_url} alt="" />
                        </div>
                        <div className="rit-box">
                          <p
                            className="parag text-justify"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                // list?.description
                                item?.description?.substring(0, 150)
                              ),
                            }}
                          >
                            {/* {item?.description.substring(0, 150)} */}
                          </p>
                        </div>
                      </div>
                      <div className="txt-btm mt-2 d-flex justify-content-between">
                        <div className="left-btm">
                          <h5 className="d-blue">
                            {item?.name.substring(0, 50)}
                          </h5>

                          <p>{item?.title}</p>
                          <img
                            src={item?.icon_url}
                            alt=""
                            style={{
                              width: "100px",
                              height: "25px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </Carousel.Item> */}
                </div>
              );
            })}
          </div>
        </div>
        {/* </Carousel> */}
        {/* </OwlCarousel> */}
        {/* </Col> */}
      </Container>
    </div>
    // </div>
  );
};

export default FeedBackHome;

{
  /* {testimonial.map((item,ind)=>{
          return(

        <div className="box w-50">
          <div className="box1 mt-3">
            <div className="d-sm-flex">
              <div className="left-box m-auto m-sm-0">
                <img
                  src={item?.image_url}
                  alt=""
                />
              </div>
              <div className="rit-box">
                <p className="parag text-justify">
                {item?.description}
                </p>
              </div>
            </div>
            <div className="txt-btm mt-2 d-flex justify-content-between">
              <div className="left-btm">
                <h5 className="d-blue">{item?.name}</h5>
                 
               <p>{item?.title}</p>
                <img
                  src={item?.icon_url }
                  alt=""
              />
              </div>
            </div>
          </div>
        </div>
          )
        })
        }  */
}
