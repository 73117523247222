import React from 'react'
import { redeem_animated } from '../../assets/img'
import { Col } from 'react-bootstrap'

const FormRightComp1 = () => {
    return (
        <div className='w-100 ac-jc d-flex'
            style={{
                height: '100vh',
                background: '#E2ECFF',
                overflow: 'hidden',
            }}>
            <div className="mb-lg-5 d-flex flex-column flex-md-row form_cont ps-4 py-4 pe-4 ac-jb w-70 form-box-cont">
                <div className=''>
                    <p className='f5 text-dark fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 my-0 box-text-form text-sx-center'>Welcome</p>
                    <p class=" primary1 fs-xl-12 fs-xs-13 fs-md-10 fs-sm-8 fs-lg-12 f2 my-0 box-text-form text-sx-center">Lorem ipsum of the test used in website design </p>
                </div>
                <img src={redeem_animated} className='custom-img-form rounded-4' />
            </div>
        </div>
    )
}

export default FormRightComp1