import React from "react";

const FaqBanner = () => {
  return (
    <div>
      <div className="d-flex flex-column ac-jc faq-banner">
        <div className="w-80 w-lg-75 w-xl-65 w-xxl-60 text-center mx-auto">
          {/* <h4 className="sect-title">Frequently Asked Questions </h4> */}
          {/* <p className="parag">
          MerchHq builds on redeem page release by adding unique URLs to the
          list of capabilities on their swag platform.
        </p> */}
        </div>
      </div>
      <h4 className="sect-title text-center mt-4">Frequently Asked Questions </h4>
    </div>
  );
};

export default FaqBanner;
