import React from 'react'
import { closeIcon, commentIcon, discountIcon, happyEmoj, product } from '../../../assets/img'
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { productDetailImg } from '../../../redux/api/constants';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useEffect } from 'react';

const AvailableInventorySlide = ({ btnhand }) => {
    const navigate = useNavigate()
    // const [btn, setBtn] = useState(false)
    const [check1, setCheck1] = useState(false)
    const [check2, setCheck2] = useState(false)

    const [selectall, setSelectAll] = useState(false)
    const CheckBox1 = () => {
        setCheck1(!check1)
    }
    const CheckBox2 = () => {
        setCheck2(!check2)
    }
    const CheckBoxall = () => {
        // setSelectAll(true)
        setCheck1(true)
        setCheck2(true)
        setSelectAll(true)

    }
    useEffect(() => {
        if (check1 == true && check2 == true) {
            setSelectAll(true)
        } else {
            setSelectAll(false)
        }
    })

    return (
        <div className="tl-shipment-qty">
            <div
                className="back-update-qty"
                style={{ display: btnhand ? "block" : "none" }}></div>
            <div
                className="update-qty"
                style={{ right: btnhand ? "0%" : "-100%", }}>
                <div className="d-flex ac-jb mt-2 mt-lg-4">
                    <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
                        Available Inventory
                    </h6>
                    <button
                        onClick={btnhand}
                        className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2">
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div className='d-flex ac-jb mt-5' style={{ width: '554px', overflow: 'scroll', scrollBehavior: 'smooth' }}>
                    <div className='w-100 d-flex ac-js gap-4'>
                        {!selectall && <CheckBoxOutlineBlankIcon className='f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' onClick={CheckBoxall} style={{ cursor: 'pointer' }} />}
                        {selectall && <CheckBoxIcon className='f4 primary ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' onClick={CheckBoxall} style={{ cursor: 'pointer' }} />}
                        <p className='mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-14 fs-xxl-16 text-center text-dark '>Inventory</p>
                    </div>
                    <p className='mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-14 fs-xxl-16 text-center text-dark '>Inventory</p>
                </div>
                <div className='d-flex align-items-center mt-4 ac-jb' style={{ width: '681px', overflow: 'scroll', scrollBehavior: 'smooth' }}>
                    <div className='d-flex ac-jc'>
                        {!check1 && <CheckBoxOutlineBlankIcon className='f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' onClick={CheckBox1} style={{ cursor: 'pointer' }} />}
                        {check1 && <CheckBoxIcon className='f4 primary ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' onClick={CheckBox1} style={{ cursor: 'pointer' }} />}
                        <img src={product} className='img-box-tee2 object-fit-contain ms-3' />
                        <div className='d-flex'>
                            <div className=''>
                                <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0  ">The Triblend Unisex Tee</p>
                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start">Quantity : 100</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex text-start flex-wrap w-40'>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">XS: 98</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">S:197</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">M:288</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">L:0</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">XL: 416</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">2XL: 597</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">3XL: 689</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">4XL: 0</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">XXS: 0</p>
                    </div>
                </div>
                <div className='d-flex align-items-center mt-4 ac-jb ' style={{ width: '681px', overflow: 'scroll', scrollBehavior: 'smooth' }}>
                    <div className='d-flex ac-jc'>
                        {!check2 && <CheckBoxOutlineBlankIcon className='f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' onClick={CheckBox2} style={{ cursor: 'pointer' }} />}
                        {check2 && <CheckBoxIcon className='f4 primary ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' onClick={CheckBox2} style={{ cursor: 'pointer' }} />}
                        <img src={product} className='img-box-tee2 object-fit-contain ms-3' />
                        <div className='d-flex'>
                            <div className=''>
                                <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0  ">The Triblend Unisex Tee</p>
                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start">Quantity : 100</p>
                            </div>
                        </div>
                    </div>
                    <div className='d-flex text-start flex-wrap w-40'>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">XS: 98</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">S:197</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">M:288</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">L:0</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">XL: 416</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">2XL: 597</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">3XL: 689</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">4XL: 0</p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start px-2 py-1">XXS: 0</p>
                    </div>
                </div>
                <div className='d-flex flex-column w-100 mt-5'>
                    <div className='d-flex ac-jb w-100'>
                        <button className={check1 == true || check2 == true ? 'get-start-btn border-0 mt-0' : 'get-start-btn border-0 mt-0 opacity-50'} onClick={() => navigate('/redeem-user-account')} disabled={check1 == true || check2 == true ? false : true}>Reorder</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AvailableInventorySlide