// @ts-nocheck
import React from "react";
import { Carousel } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import { product } from "../../../../assets/img";
import { dashboardBanner, productDetailImg } from "../../../../redux/api/constants";
import { useNavigate } from "react-router-dom";

const DashboardBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="dash-board-banner mt-3 my-sm-3">
      <div className="box">
        <Carousel indicators={true} className="position-relative w-100 h-100">
          <Carousel.Item
            className=" justify-content-between"
            indicators={true}
            controls={false}
            interval={3000}>
            <div className="caption-banner d-flex flex-row">
              <div className="left-banner p-3">
                <p className="bg-lt-design design fs-11 fs-md-12 f2 mb-2 px-2 py-1 rounded rounded-2 mt-4">
                  Design Ready
                </p>
                <h3 className="text-dark f4 fs-16 fs-lg-30 fs-xl-40">
                  asdad Allmsade unisex Organize unisex Organize Allmade unisex
                  Organize unisex Organize
                </h3>
                <h6 className="primary fs-14 fs-md-16 fs-lg-18 fs-xl-19 f4 mt-2 mt-lg-3 p-0 rounded rounded-2">
                  $90.09
                </h6>
                <div className="btn-back" />
                <button
                  onClick={() => navigate("/proof-details")}
                  className="get-start-btn border-0">
                  View Proof
                </button>
              </div>
              <div className="rit-banner">
                <OwlCarousel className="owl-theme" loop {...dashboardBanner}>
                  <div className="item">
                    <img src={product} alt="" />
                  </div>
                  <div className="item">
                    <img src={product} alt="" />
                  </div>
                  <div className="item">
                    <img src={product} alt="" />
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};

export default DashboardBanner;
