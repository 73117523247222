// @ts-nocheck
import React, { useState } from "react";
import LeftProduct from "../component/Product/Build/LeftProduct";
import ProductPopupComponent from "../component/Product/Preset/ProductPopupComponent";
import RightProducts from "../component/Product/Preset/RightProducts";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";

const PresetProductScreen = () => {
  const [showPopup, setShowPopup] = useState(false);
  const showPopupHander = () => {
    setShowPopup(!showPopup);
    // console.log("099");
  };
  return (
    <div className="product-screen align-items-center d-flex flex-column flex-md-row">
      {/* <LeftProduct /> */}
      <RightProducts showPopupHander={showPopupHander} />
      {/* {showPopup && <ProductPopupComponent showPopupHander={showPopupHander} />} */}
    </div>
  );
};

export default PresetProductScreen;
