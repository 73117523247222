import React from 'react'
import { closeIcon, commentIcon, discountIcon, happyEmoj, product } from '../../../assets/img'
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { productDetailImg } from '../../../redux/api/constants';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const CreditBalanceSlide = ({ showUpdatehand, showUpdate }) => {
    const navigate = useNavigate()
    const [option, setOption] = useState(false)
    const showOption = () => {
        setOption(!option)
    }

    return (
        <div className="tl-shipment-qty">
            <div
                className="back-update-qty"
                style={{ display: showUpdate ? "block" : "none" }}></div>
            <div
                className="update-qty"
                style={{ right: showUpdate ? "0%" : "-100%", }}>
                <div className="d-flex ac-jb mt-2 mt-lg-4">
                    <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
                        Reload Credit Balance
                    </h6>
                    <button
                        onClick={showUpdatehand}
                        className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2">
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div className="btm-scroll w-100 w-md-100 pt-3 d-flex flex-md-row ac-jb">
                    <div className="prod-pack">
                        <div className="ac-jb mt-2 mt-lg-4">
                            <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">Current Credit Balance</p>
                        </div>
                    </div>
                    <div className="ms-3">
                        <div className="ac-jb mt-2 mt-lg-4">
                            <p class="f4 dark-green fs-xl-20 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 text-end">$ 5,007.46</p>                        </div>
                        {/* Btn */}
                    </div>
                </div>
                <p class="f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 mt-4">Add Credits to your Account</p>
                <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-16 primary1 mt-3 mb-0">Set budgets for your shipping costs and make future shipments seamless with credits. Tired of managing credits? Sign up for Topup here</p>
                <div className='box1'>
                    <p class="f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 mt-4">Amount to add</p>
                    <AttachMoneyIcon className='icon1 f3 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11' />
                    <input type='number' placeholder='Floor, Suite, Unit (optional)' className='mt-3 ps-5 f2 fs-11 fs-sm-12 fs-md-13 fs-lg-16' />
                </div>
                <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-3 mb-0">Recommended credits based on selected inventory : $18,021,222.00</p>
                <p class="f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 mt-4">Payment Method</p>
                <div className='box3'>
                    <p class="f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 mt-4">Amount to add</p>
                    <AttachMoneyIcon className='icon1 f3 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11' />
                    <input type='number' placeholder='**** 0015' className='mt-3 ps-5 f2 fs-11 fs-sm-12 fs-md-13 fs-lg-16' />
                    <ExpandMoreIcon className='icon2 f3 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11' style={{ cursor: 'pointer' }} onClick={showOption} />
                    {option && <div className='w-60 h-20 bg-light  rounded-2 text-center float-end' style={{
                        borderStyle: 'solid',
                        borderColor: '#e2ecff',
                        borderWidth: '2px',
                        position: 'absolute',
                        marginRight: '40px',
                        right: 0
                    }}>
                        <p class="f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 py-2" style={{ cursor: 'pointer' }}>Amount to add</p>
                        <p class="f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 py-2" style={{ cursor: 'pointer' }}>Amount to add</p>
                        <p class="f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 py-2" style={{ cursor: 'pointer' }}>Amount to add</p>
                    </div>}
                </div>

                <div style={{ height: '50vh', overflow: 'scroll' }} className='d-flex w-85 align-items-end pb-4'>
                    <div className='d-flex flex-column w-100'>
                        <div className='d-flex ac-jb w-100'>
                            <button className='get-start-btn border-0 mt-0' onClick={() => navigate('/autoship-name')}>Add Credits</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreditBalanceSlide