import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AlertPopUp from "./component/Popup/AlertPopUp";
import { saveAlertMessage } from "./redux/slice/alertMessage";
import ReactRoutes from "./routes";
import { Spinner } from "react-bootstrap";
import { saveLoader } from "./redux/slice/loaderNoti";
import { Toaster } from "react-hot-toast";

const App = () => {
  const dispatch = useDispatch();
  const alertMessage = useSelector((state) => state.alertMessage);

  const togglePopup = () => {
    dispatch(saveAlertMessage(null));
  };
  const [loading, setLoading] = useState(false);

  // For Loader
  const isSomeQueryPending = useSelector((state) =>
    Object.values(state.api.queries).some((query) => query.status === "pending")
  );

  // For Custom Loader
  const customLoader = useSelector((state) => state.loaderNoti);

  useEffect(() => {
    if (isSomeQueryPending) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  }, [isSomeQueryPending]);

  useEffect(() => {
    if (customLoader == false) {
      setLoading(false);
    } else if (customLoader == true) {
      setLoading(true);
    }
  }, [customLoader]);



  return (
    <div>
      
      <Toaster position="top-right" reverseOrder={false} />
      {loading && (
        <div
          style={{ zIndex: 1500, position: "fixed", top: "45%", left: "49%" }}
        >
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {alertMessage && (
        <AlertPopUp alertMessage={alertMessage} togglePopup={togglePopup} />
      )}
      <ReactRoutes />
    </div>
  );
};

export default App;
