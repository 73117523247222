// @ts-nocheck
import React, { useState } from "react";
import { bg1, logo } from "../assets/img";
import ChangePassword from "../component/Auth/ChangePassword";
import ForgotPassword from "../component/Auth/ForgotPassword";
import { useNavigate } from "react-router-dom";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const [showSuccess, setShowSuccess] = useState(false);
  const [emailID, setEmailID] = useState("");
  const onCLickSuccessHandler = () => {
    setShowSuccess(true);
  };
  return (
    <div className="d-flex flex-column flex-md-row sign-up position-relative align-items-center justify-content-center ">
      <h5
        onClick={() => navigate("/")}
        className="sect-title mb-0 primary log-logo"
      >
        {/* Merchhq */}
        <img src={logo} className="logo" alt="" style={{ width: "210px" }} />
      </h5>

      <img src={bg1} alt="" className="bg1" />
      {!showSuccess ? (
        <ForgotPassword
          onCLickSuccessHandler={onCLickSuccessHandler}
          setEmailID={setEmailID}
        />
      ) : (
        <ChangePassword emailID={emailID} />
      )}
      <img src={bg1} alt="" className="bg2" />
    </div>
  );
};

export default ForgetPassword;
