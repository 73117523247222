import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../Header/Header'

const LayoutTwo = () => {
    return (
        <div className="flex-wrap">
            <Header />
            <div className="out-left col ">
                <Outlet />
            </div>
        </div>
    )
}

export default LayoutTwo