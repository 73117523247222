// @ts-nocheck
import React from "react";
import { shippmentIcon } from "../../assets/img";

const InventoryShipment = () => {
  return (
    <div className="shipment-inventory w-100 h-100 d-flex ac-jc">
      <img className="w-45" src={shippmentIcon} alt="" />
    </div>
  );
};

export default InventoryShipment;
