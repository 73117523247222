import React from 'react'
import { autoship, closeIcon, commentIcon, discountIcon, happyEmoj, product, redeem_animated, redeem_collection } from '../../assets/img'
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { productDetailImg } from '../../redux/api/constants';
import { useNavigate } from 'react-router-dom';

const CreateRedeem = ({ showClickHandler, showredeem }) => {
    const navigation = useNavigate()
    return (
        <div className="tl-update-qty">
            <div
                className="back-update-qty"
                style={{ display: showredeem ? "block" : "none" }}
            ></div>
            <div
                className="update-qty"
                style={{ right: showredeem ? "0%" : "-100%" }}>
                <div className="d-flex ac-jb mt-2 mt-lg-4">
                    <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
                        Select a redeem page
                    </h6>
                    <button
                        onClick={showClickHandler}
                        className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2 align-selfe-center">
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div className="d-flex flex-sm-row ac-jb p-2 my-2 product-list-detail mt-4 margin-res "
                // onClick={() => navigation('')}
                >
                    <div className="select_box_slide w-100 w-xs-50 w-sm-60 w-md-100 px-3 pb-3 pt-3 ">
                        <div className="btm-scroll d-flex ">
                            <div className=" d-flex flex-column flex-sm-row my-2 product-list-detail ">
                                <div className='d-flex w-100 flex-column flex-md-row ac-jc'>
                                    <img src={redeem_collection} className='slide-box-img object-fit-contain' />
                                    <div className='ms-3'>
                                        <p className="f4 fs-16 fs-sm-17 fs-xs-18 fs-md-19 fs-lg-20 text-dark mt-0 mb-0 text-res">Collection</p>
                                        <p className="f2 fs-11 fs-sm-12 fs-xs-13 fs-md-14 fs-lg-15 primary1 mt-0 mb-0 mt-3 text-res">Gather Contact and Sizing information about your recipients. Often used before placing your order.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex flex-sm-row ac-jb p-2 my-2 product-list-detail mt-4 margin-res "
                    onClick={() => navigation('/autoship')}>
                    <div className="select_box_slide w-100 w-xs-50 w-sm-60 w-md-100 px-3 pb-3 pt-3 ">
                        <div className="btm-scroll d-flex ">
                            <div className="w-100 d-flex flex-column flex-sm-row my-2 product-list-detail ">
                                <div className='d-flex w-100 flex-column flex-md-row ac-jc'>
                                    <img src={autoship} className='slide-box-img object-fit-contain' />
                                    <div className='ms-3'>
                                        <p className="f4 fs-16 fs-sm-17 fs-xs-18 fs-md-19 fs-lg-20 text-dark mt-0 mb-0 text-res">Autoship</p>
                                        <p className="f2 fs-11 fs-sm-12 fs-xs-13 fs-md-14 fs-lg-15 primary1 mt-0 mb-0 mt-3 text-res">Automatically ship products from your inventory to recipients once Technologyfill out their information. Often used you have placed an order.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0 ms-5">*Must have product(s) in your inventory in order to launch autoship.</p>
            </div>
        </div>
    )
}

export default CreateRedeem


// w-xs-50
// w-sm-60
// w-90
// ms-3

// right-said-box
// ac-jc