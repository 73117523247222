import React from "react";
import FlatRate from "../component/pricing/FlatRate";
import ServiceBanner from "../component/pricing/ServiceBanner";
import ShippingPrice from "../component/pricing/ShippingPrice";
import TransactionPrice from "../component/pricing/TransactionPrice";
import MemberShipPlan from "../component/Services/Membership/MemberShipPlan";
import Footer from "../navbar/Footer";

const PricingScreen = () => {
  return (
    <div className="tl-pricing">
      <ServiceBanner />
      <TransactionPrice />
      {/* <MemberShipPlan /> */}
      <FlatRate />
      <ShippingPrice />
      <Footer />
    </div>
  );
};

export default PricingScreen;
