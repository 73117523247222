// @ts-nocheck
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuItem from "./MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  build_Pack,
  bulkPack,
  logo,
  presetPack,
  userIcon,
} from "../assets/img";
import { useDispatch, useSelector } from "react-redux";
import LoginMenuItem from "./LoginMenuItem";
import { saveLoginHeader } from "../redux/slice/loginHeader";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Token = localStorage.getItem("token");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));

  const [showMenu, setShowMenu] = useState(false);
  const [showSigndropList, setSignShowdropList] = useState(false);
  const [showdropList, setShowdropList] = useState(false);
  const [companyDropdown, setCompanyDropdown] = useState(false);
  const [plotformMenu, setPlotformMenu] = useState(false);

  const loginHeaderData = useSelector((state) => state.loginHeader);

  // console.log("loginHeaderData :", loginHeaderData);

  const onCLickDropdownShowHandler = () => {
    setShowdropList(false);
    setShowMenu(false);
    setCompanyDropdown(false);
    setPlotformMenu(false);
    // navigate("build-pack");
  };
  const onCLickSignDropdownShowHandler = (e) => {
    setCompanyDropdown(false);
    setSignShowdropList(false);
    setPlotformMenu(false);
    if (e === "signup") {
      navigate("/signup");
    } else if (e === "login") {
      navigate("/login");
    } else if (e === "account") {
      navigate("/account/profile");
    } else if (e === "/logout") {
      navigate("/");

      dispatch(saveLoginHeader(""));
    }
  };

  const logOutFun = () => {
    navigate("/");
    localStorage.clear();
    window.location.reload();
  };
  const onClickDashboard = () => {
    navigate("/dashboard");
    setCompanyDropdown(false);
    setSignShowdropList(false);
    setPlotformMenu(false);
  };
  // console.log("userDetails", userDetails?.email);

  return (
    <div className="top-header d-flex">
      <div className="d-flex justify-content-center align-items-center">
        <button
          onClick={() => {
            navigate("/");
            setCompanyDropdown(false);
            setSignShowdropList(false);
            setShowdropList(false);
            setPlotformMenu(false);
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start logo me-5"
        >
          {/* <h3 className="f4 fs-25 primary mb-0">merchhq</h3> */}

          <img src={logo} className="logo" alt="" />
        </button>
        {Token ? (
          <LoginMenuItem
            setSignShowdropList={setSignShowdropList}
            onCLickDropdownShowHandler={onCLickDropdownShowHandler}
            showdropList={showdropList}
            setShowdropList={setShowdropList}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            companyDropdown={companyDropdown}
            setCompanyDropdown={setCompanyDropdown}
            plotformMenu={plotformMenu}
            setPlotformMenu={setPlotformMenu}
          />
        ) : (
          <MenuItem
            setSignShowdropList={setSignShowdropList}
            onCLickDropdownShowHandler={onCLickDropdownShowHandler}
            showdropList={showdropList}
            setShowdropList={setShowdropList}
            showMenu={showMenu}
            setShowMenu={setShowMenu}
            companyDropdown={companyDropdown}
            setCompanyDropdown={setCompanyDropdown}
            plotformMenu={plotformMenu}
            setPlotformMenu={setPlotformMenu}
          />
        )}
      </div>
      <div className="d-flex ac-je position-relative">
        <button
          onClick={() => {
            setSignShowdropList(!showSigndropList);
            setShowMenu(false);
            setShowdropList(false);
            setCompanyDropdown(false);
            setPlotformMenu(false);
          }}
          className="sign-btn border-0"
        >
          <MenuIcon className="d-none d-md-flex" />
          <img
            src={userDetails?.image_url ? userDetails?.image_url : userIcon}
            className="user-icon"
          />
        </button>
        {userDetails?.email ? (
          <div
            className={`${
              showSigndropList ? "d-block" : "d-none"
            } sign-drop-list`}
          >
            <button
              onClick={() => onClickDashboard()}
              className="border-0 bg-transparent d-flex align-items-center py-2 "
            >
              <h5 className="mb-0">Dashboard</h5>
            </button>
            <button
              onClick={() => onCLickSignDropdownShowHandler("account")}
              className="border-0 bg-transparent d-flex align-items-center py-2 "
            >
              <h5 className="mb-0">Account Settings</h5>
            </button>
            {/* <button
                  onClick={() => onCLickSignDropdownShowHandler("refer")}
                  className="border-0 bg-transparent d-flex align-items-center py-2 ">
                  <h5 className="mb-0">Refer A Friend</h5>
                </button> */}
            <button
              onClick={() => onCLickSignDropdownShowHandler("help")}
              className="border-0 bg-transparent d-flex align-items-center py-2 "
            >
              <h5 className="mb-0">Help Center</h5>
            </button>
            <button
              onClick={() => logOutFun()}
              className="border-0 bg-transparent d-flex align-items-center py-2 "
            >
              <h5 className="mb-0">Logout</h5>
            </button>
          </div>
        ) : (
          <div
            className={`${
              showSigndropList ? "d-block" : "d-none"
            } sign-drop-list`}
          >
            {userDetails?.email && (
              <button
                onClick={() => onClickDashboard()}
                className="border-0 bg-transparent d-flex align-items-center py-2 "
              >
                <h5 className="mb-0">Dashboard</h5>
              </button>
            )}
            <button
              onClick={() => onCLickSignDropdownShowHandler("signup")}
              className="border-0 bg-transparent d-flex align-items-center py-2 "
            >
              <h5 className="mb-0">Sign Up</h5>
            </button>
            <button
              onClick={() => onCLickSignDropdownShowHandler("login")}
              className="border-0 bg-transparent d-flex align-items-center py-2 "
            >
              <h5 className="mb-0">Login</h5>
            </button>
            <button
              onClick={() => onCLickSignDropdownShowHandler("help")}
              className="border-0 bg-transparent d-flex align-items-center py-2 "
            >
              <h5 className="mb-0">Help Center</h5>
            </button>
          </div>
        )}
        <button
          onClick={() => {
            setShowMenu(!showMenu);
            setSignShowdropList(false);
            setCompanyDropdown(false);
          }}
          className="d-flex d-md-none menu-icon ms-2 "
        >
          {!showMenu ? <MenuIcon /> : <CloseIcon />}
        </button>
      </div>
    </div>
  );
};

export default Header;
