import React, { useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { productDetailImg } from "../../../redux/api/constants";
import DoneIcon from "@mui/icons-material/Done";
import { closeIcon } from "../../../assets/img";
// import CloseIcon from "@mui/icons-material/Close";

const ProductPopupComponent = ({
  popData,
  setShowPopup,
  showPopupHander,
  viewList,
  selectedIds,
  setSelectedIds,
  setCount,
  count,
  productPrice,
  imagesid,
  setTick,
  packsPrice,
  getAddToCart,
  colorcount,
  setColorCount,
  setBigImg,
  bigImg,
  defaultimg,
  material,
  setMaterial,
  setSize,
  size,
  setStyle,
  style,
  selectedColorId,
  setSelectedColorId,
  selectColorId,
  setSelectColorId,
  varients,
  pack,
  calculations,
  setCartData,
  cartData,
  userCartID,
  deleteCart,
  cartBtn,
  deleteBtn,
  customQty,
  varientsNew,
}) => {
  // console.log("popData", popData);
  const [colorInde, setColorInde] = useState(0);
  const [colorQnt, setColorQnt] = useState(1);
  const [qntyCount, setQntyCount] = useState(25);
  const incrementColor = () => {
    if (colorcount < 3) {
      setColorCount(colorcount + 1);
    }
  };

  // NO OF COLOR HANDLER
  const decrementColor = (id, type) => {
    let obj = { ...cartData };

    let final = {
      ...obj[id],
    };

    let noOfColors = +obj?.[id]?.no_of_colors;

    if (type == "decrement") {
      if (noOfColors > 1) {
        if (cartData?.[id]?.cart_id) {
          varientsNew(
            id,
            "",
            cartData?.[id]?.discount_value,
            selectColorId,
            noOfColors - 1,
            "logo"
          );
        } else {
          final.no_of_colors = noOfColors - 1;
        }
      }
    } else {
      if (noOfColors < 10) {
        if (cartData?.[id]?.cart_id) {
          varientsNew(
            id,
            "",
            cartData?.[id]?.discount_value,
            selectColorId,
            noOfColors + 1,
            "logo"
          );
        } else {
          final.no_of_colors = noOfColors + 1;
        }
      }
    }
    setCartData((val) => ({ ...val, [id]: final }));
  };

  const handleColorClick = (colorname, imageUrl, ind, id) => {
    setSelectedColorId(colorname?.color?.name);
    setSelectColorId(colorname?.color?.id);
    // setSelectedImage(imageUrl);
    setBigImg(ind);
    if (cartData?.[id]?.cart_id) {
      varientsNew(id, "", "", colorname?.color?.id, "", "color");
    }
  };

  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      {viewList?.map((list, ind) => {
        return (
          <div
            className="center-content d-flex flex-column flex-lg-row"
            key={ind}
          >
            <button
              onClick={showPopupHander}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="" />
            </button>

            <div className="left-popup">
              <div
                className="position-relative"
                style={{ width: "200px" }}
              ></div>
              <div>
                <div className="imges">
                  <img
                    // src={p1}
                    src={list?.images?.[bigImg]?.image_url}
                    style={{
                      // height: "200px",
                      // width: "200px",
                      objectFit: "cover",
                      padding: "10px",
                      borderRadius: "15px",
                    }}
                  />
                </div>
                <div className="d-flex ac-jc">
                  <div
                    className="d-flex mt-4 mob-carous-pop"
                    style={{ width: "400px", overflow: "scroll" }}
                  >
                    {list?.images?.map((img, imgInd) => {
                      return (
                        <div className="down_imgs">
                          <img
                            key={imgInd}
                            id={img?.id}
                            onClick={() => {
                              handleColorClick(img, "", imgInd, list?.id);
                              setBigImg(imgInd);
                            }}
                            src={img?.image_url}
                            // src={p3}
                            style={{
                              padding: "10px",
                              height: "100px",
                              width: "100px",
                              objectFit: "cover",
                              borderRadius: "15px",
                            }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
            <div className="rit-pop">
              <h2 className="pe-lg-5 text-center text-lg-start">
                {list?.name}
              </h2>
              {/* <p className="parag">{popData?.subCat}</p> */}
              {list?.images?.length > 0 && (
                <h3 className="mt-3">Select Color</h3>
              )}
              {list?.images?.length > 0 && (
                <div className="d-flex ac-js">
                  {list?.images?.map((col, colInd) => (
                    <button
                      key={colInd}
                      style={{ background: col?.color?.hex_code }}
                      className="cust-btn color-box d-flex ac-jc"
                      onClick={() =>
                        handleColorClick(col, "", colInd, list?.id)
                      }
                    >
                      {selectedColorId === col?.color?.name ? (
                        <DoneIcon className="text-white fs-25" />
                      ) : (
                        ""
                      )}
                    </button>
                  ))}
                </div>
              )}

              <div className="d-flex ac-jb pe-lg-5 mt-2 mt-lg-3">
                <div className="me-3 me-lg-0 d-flex ac-js mb-3">
                  <h3 className="mb-0">
                    Quantity
                    <span className="primary fs-17 me-4">
                      {" "}
                      (min {popData?.minQua})
                    </span>
                  </h3>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3 ms-5">
                    <select
                      className="cust-btn parag f4 fs-20"
                      value={+cartData?.[list?.id]?.count}
                      onChange={(e) =>
                        customQty(
                          e.target.value,
                          list?.id,
                          list?.product_varient_skus?.[0]?.id,
                          colorcount,
                          list?.[`for_${e.target.value}`],
                          "test"
                        )
                      }
                    >
                      <option value={25}>{25}</option>
                      <option value={50}>{50}</option>
                      <option value={100}>{100}</option>
                      <option value={150}>{150}</option>
                      <option value={250}>{250}</option>
                      <option value={500}>{500}</option>
                      <option value={1000}>{1000}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="">
                <h3>No. of colors in logo</h3>
                <div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3">
                  <button
                    onClick={() => decrementColor(list?.id, "decrement")}
                    className="qunt-btn fs-33 f3 primary1 cust-btn mb-0 me-2"
                  >
                    -
                  </button>
                  <h5
                    style={{ width: "50px", textAlign: "center" }}
                    className=" fs-25 f5 px-2 mb-0"
                  >
                    {cartData?.[list?.id]?.no_of_colors}
                  </h5>
                  <button
                    className="qunt-btn fs-33 f3 primary1 cust-btn mb-0 mx-2"
                    onClick={() => decrementColor(list?.id, "increment")}
                  >
                    +
                  </button>
                </div>
              </div>
              <div className="d-flex ac-js">
                <h2 className="op-80">
                  ₹
                  {cartData?.[list?.id]?.packs_price
                    ? +cartData?.[list?.id]?.packs_price
                    : +cartData?.[list?.id]?.for_price}
                  /item{" "}
                  <span>
                    {+cartData?.[list?.id]?.pack} Packs = ₹
                    {cartData?.[list?.id]?.packs_price
                      ? +cartData?.[list?.id]?.packs_price *
                      +cartData?.[list?.id]?.pack
                      : +cartData?.[list?.id]?.for_price *
                      +cartData?.[list?.id]?.pack}
                  </span>
                </h2>
              </div>
              <button
                className={`get-start-btn border-0 mb-2 ${cartBtn ? "opacity-50" : deleteBtn ? "opacity-50" : ""
                  }`}
                onClick={() => {
                  if (cartData?.[list?.id]?.cart_id) {
                    deleteCart(cartData?.[list?.id]?.cart_id, list?.id);
                  } else {
                    varients(
                      list?.id,
                      +cartData?.[popData?.id]?.count
                        ? +cartData?.[popData?.id]?.count
                        : 25
                    );
                  }
                }}
                disabled={cartBtn ? true : deleteBtn ? true : false}
              >
                {cartData?.[list?.id]?.cart_id ? "Remove Pack" : "Add to Pack"}
              </button>
              <h3 className="mt-3 mb-1">Description</h3>
              <p className="parag mb-4 px-3 ps-lg-0 text-center text-lg-start">
                {list?.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ProductPopupComponent;
