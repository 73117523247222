import React, { useState } from "react";
import ProfileLeftTab from "../component/Profile/ProfileLeftTab";
import { uploadIcon } from "../assets/img";

const CompanyScreen = () => {
  const [logo, setLogo] = useState({});
  const [secLogo, setSecLogo] = useState({});
  return (
    <div className="product-screen d-flex flex-column flex-md-row">
      <ProfileLeftTab />
      <div className="profile-screen">
        <h4 className="sect-title mb-2 mb-lg-1">Company</h4>

        <div className="d-flex align-items-center upload-box">
          <div className="">
            <p
              className="parag1 my-2"
              style={{
                fontFamily: "f4",
                fontSize: "18px",
              }}>
              Logo File
            </p>
            <p>Formats : pdf, .png, .ai, .eps, .jpg, .svg</p>
            <div className="file-upload">
              <div className="file-icon">
                <img
                  className={logo?.name ? "" : "placeholder_icon"}
                  src={
                    logo?.name ? window.URL.createObjectURL(logo) : uploadIcon
                  }
                  alt="logo"
                />
              </div>
              <label className="upload-btn">
                Upload
                <input
                  onChange={(e) => setLogo(e.target.files[0])}
                  className="d-none"
                  type="file"
                />
              </label>
            </div>
          </div>
          <div className="">
            <p
              className="parag1 my-2"
              style={{
                fontFamily: "f4",
                fontSize: "18px",
              }}>
              Second Logo File
            </p>
            <p>Formats : pdf, .png, .ai, .eps, .jpg, .svg</p>
            <div className="file-upload">
              <div className="file-icon">
                <img
                  className={secLogo?.name ? "" : "placeholder_icon"}
                  src={
                    secLogo?.name
                      ? window.URL.createObjectURL(secLogo)
                      : uploadIcon
                  }
                  alt="logo"
                />
              </div>
              <label className="upload-btn">
                Upload
                <input
                  onChange={(e) => setSecLogo(e.target.files[0])}
                  className="d-none"
                  type="file"
                />
              </label>
            </div>
          </div>
        </div>

        <p
          className="parag1 my-2"
          style={{
            fontFamily: "f4",
            fontSize: "18px",
          }}>
          Company Information
        </p>

        <div className="profile-input">
          <div className="box">
            <input className="" placeholder="Company Name" />
          </div>
          <div className="box">
            <select
              className=" f2 fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 px-3 py-2 rounded rounded-3 w-100"
              name=""
              id="">
              <option
                style={{
                  color: "#d1def9",
                }}
                hidden
                selected
                value="">
                Country
              </option>
              <option value="">United States</option>
              <option value="">India</option>
              <option value="">Germany</option>
            </select>
          </div>
        </div>
        <div className="profile-input">
          <div className="box">
            <input className="" placeholder="Company Address" />
          </div>
          <div className="box">
            <input className="" placeholder="Floor, Suite, Unit (optional)" />
          </div>
        </div>
        <div className="profile-input">
          <div className="box">
            <select
              className=" f2 fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 px-3 py-2 rounded rounded-3 w-100"
              name=""
              id="">
              <option
                style={{
                  color: "#d1def9",
                }}
                hidden
                selected
                value="">
                City
              </option>
              <option value="">Karnadaka</option>
              <option value="">Tamilnadu</option>
              <option value="">Kerala</option>
            </select>
          </div>
          <div className="box">
            <input className="" placeholder="State" />
          </div>
        </div>
        <div className="profile-input">
          <div className="box">
            <input className="" placeholder="Zip" />
          </div>
          <div className="box"></div>
        </div>

        <div className="d-flex profile-action mt-4">
          <button className="bg-primar rounded rounded-2 text-white mb-2 border-0">
            Save
          </button>
          <button
            style={{ backgroundColor: "#E2ECFF", color: "#839DD1" }}
            className=" rounded rounded-3 mb-2 border-0">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompanyScreen;
