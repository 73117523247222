import React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

const AddCard = ({ showCartCloseHandler, showCart }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`add-cart w-xs-96 w-sm-90 w-lg-100 mx-auto position-fixed ${
        showCart ? "show-btn-cart" : "hid-btn-cart"
      }`}
    >
      <div className="d-flex flex-column flex-md-row ac-jb bg-white inner-box px-2 px-sm-3 px-lg-3 px-xl-4 py-3">
        <h5 className="f4 fs-15 fs-md-15 text-dark mb-3 mb-md-0 px-3">
          1 Items Selected
        </h5>
        <div className="d-flex align-items-center">
          <div className="btn-top">
            <button
              onClick={() => navigate("/my-shipment")}
              className="cust-btn d-flex flex-column ac-jc f4 fs-14 fs-md-15 fs-lg-17primary1"
            >
              <ShoppingCartIcon />
              <h5 className={`f3 fs-14 fs-md-15 fs-lg-17 primary1 mb-0 mt-2`}>
                Create Order
              </h5>
            </button>
          </div>
          <div className="btn-top">
            <button
              disabled
              onClick={() => navigate("/my-shipment")}
              className="op-30 cust-btn d-flex flex-column ac-jc f4 fs-14 fs-md-15 fs-lg-17 primary1"
            >
              <ShoppingCartIcon />
              <h5
                className={`f3 fs-14 fs-md-15 fs-lg-17 text-primar1 mb-0 mt-2`}
              >
                Build Pack
              </h5>
            </button>
          </div>
          <div className="btn-top1 px-2 px-lg-4">
            <button className=" cust-btn d-flex flex-column ac-jc f4 fs-14 fs-md-15 fs-lg-17 primary1">
              <DeleteIcon />
              <h5 className={`f3 fs-14 fs-md-15 fs-lg-17 primary1 mb-0 mt-2`}>
                Delete
              </h5>
            </button>
          </div>
          <div className="btn-top1 px-2 px-lg-4">
            <button
              onClick={showCartCloseHandler}
              className=" cust-btn d-flex flex-column ac-jc f4 fs-14 fs-md-15 fs-lg-17 close-btn"
            >
              <CloseIcon />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCard;
