import React from "react";
import DashboardBanner from "./DashboardBanner";
import WhatsNew from "./WhatsNew";

const DashboardDetail = () => {
  return (
    <div className="d-flex flex-column flex-md-row">
      <DashboardBanner />
      <WhatsNew />
    </div>
  );
};

export default DashboardDetail;
