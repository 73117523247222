import React, { useEffect, useState } from "react";

import { Bottle, head, logos, tshirt1, tshirt2 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  useCreateorderMutation,
  useLazyProjectViewQuery,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const PresentationGalleryPage2A = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // RTK QUERY
  const [projectViewApi] = useLazyProjectViewQuery();
  const [approveViewApi] = useCreateorderMutation();

  const location = useLocation();
  const [productlist, setProductList] = useState([]);
  const [serachParams, setSearchParams] = useSearchParams();
  const [orders, setOrders] = useState(false);
  const [btn, setBtn] = useState(false);
  const [projectData, setProjectData] = useState({});

  const user = location?.state?.data?.project_request?.user;
  // console.log(user, "user");
  const getView = () => {
    projectViewApi(location?.state?.data?.id || serachParams?.get("project_id"))
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          const projectData = res?.project || {};
          setProductList(projectData.projectDetail || []);
          setProjectData(res?.project);
          console.log(projectData.projectDetail, "projectData.projectDetail");

          const allItemsHaveArtworkStatus3 = res?.project?.projectDetail.every(
            (item) => item?.artwork_status === 3
          );

          if (allItemsHaveArtworkStatus3) {
            setOrders(true);
          } else {
            setOrders(false);
          }
        }
      })
      .catch((err) => {
        console.log("Error fetching project details:", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id || serachParams?.get("project_id")) {
      getView();
    }
  }, []);

  const createOrder = () => {
    const formdata = new FormData();
    // if (type) {
    formdata.append("project_id", projectData?.id);
    dispatch(saveLoader(true));
    approveViewApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(saveLoader(false));
        getView();
      })
      .catch((err) => {
        console.log(err);
        dispatch(saveLoader(false));
      });
  };

  return (
    <div className=" " style={{ backgroundColor: "#fff" }}>
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="px-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                MerchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #110208 for AAHIKA
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project Name
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          {projectData?.status == 4 && (
            <button
              style={{
                backgroundColor: "#67a84a",
                borderRadius: "10px",
                padding: "10px",
                border: "none",
                marginLeft: "5px",
              }}
              className={`${
                !orders ? "opacity-50" : btn ? "opacity-50" : "opacity-100"
              }`}
              onClick={() => {
                createOrder();
              }}
              disabled={orders ? false : btn ? true : true}
            >
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                Create Order
              </p>
            </button>
          )}
        </div>
        {/* <div className="my-5">
          
          <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center">
            This Pen Product is not Available in MHQ
          </p>
        </div> */}

        <div className="d-flex flex-wrap ac-jb  my-3 ">
          {productlist?.map((item, ind) => {
            if (item?.status === 2 || item?.status === 1) {
              // if (item?.price_status !== 0) {
              const price = item?.projectPresentationPrice;
              const finalAmounts = price?.map((p) => p.total) || [];
              const minAmount =
                finalAmounts.length > 0 ? Math.min(...finalAmounts) : null;
              const maxAmount =
                finalAmounts.length > 0 ? Math.max(...finalAmounts) : null;
              let amountRange;
              if (minAmount !== null && maxAmount !== null) {
                amountRange = `Rs.${minAmount} - Rs.${maxAmount}`;
              } else {
                amountRange = "";
              }

              return (
                <div
                  className="hove"
                  style={{ position: "relative" }}
                  key={ind}
                >
                  <div className="list">
                    <div
                      onClick={() => {
                        navigate("/presentation-gallery-page-3a", {
                          state: { data: item, user: user },
                        });
                      }}
                      className="image rounded"
                      style={{
                        backgroundImage: `url(${item?.product?.defaultImage})`,
                        width: "300px",
                        height: "300px",
                        objectFit: "cover",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="hidden">
                        <div
                          className="primary"
                          style={{
                            margin: "0px 0px 0px 20px",
                            paddingTop: "20px",
                          }}
                        >
                          <ChatBubbleIcon />
                        </div>
                        <div style={{ marginTop: "100px" }}>
                          {/* <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 text-center">
                  Price {item.final_amount}
                </p> */}
                          <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 primary text-center">
                            {amountRange ? amountRange : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p className="f2 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 font-blue1 text-center my-3">
                    {item?.product?.name}
                  </p>
                </div>
              );
            }
          })}
        </div>

        {/* <div className="d-flex justify-content-end">
                      <div>
                        <button
                          className=""
                          style={{
                            backgroundColor: "#f82a2a",
                            borderRadius: "10px",
                            padding: "10px",
                            border: "none",
                          }}
                          onClick={() => {
                            navigate("/poproduct-supplier-approved-a");
                          }}
                        >
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                            Request Change
                          </p>
                        </button>
                      </div>
                      <div>
                        <button
                          style={{
                            backgroundColor: "#0082ca",
                            borderRadius: "10px",
                            padding: "10px",
                            border: "none",
                            marginLeft: "5px",
                          }}
                          onClick={() => {
                            navigate("/poproduct-supplier-approved-a");
                          }}
                        >
                          <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white ">
                            Approve Order
                          </p>
                        </button>
                      </div>
                    </div> */}
      </div>

      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="w-90 m-auto d-flex flex-wrap ac-jb">
            <div className="">
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <button
                class=" d-flex ac-jc px-3 mt-4"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>
            <div className="col-md-4">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <div class="d-flex white mt-sm-2 ">
                <FacebookRoundedIcon className="me-2" />

                <TwitterIcon className="mx-2" />

                <LinkedInIcon className="mx-2" />

                <InstagramIcon className="mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationGalleryPage2A;
