import React, { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import { closeIcon } from "../../../assets/img";
// import CloseIcon from "@mui/icons-material/Clo
const RequestView = ({
  varientsNew,
  showPopupHander,
  setSelectedIds,
  viewList,
  material,
  setMaterial,
  setSize,
  size,
  setStyle,
  style,
  selectedColorId,
  setSelectedColorId,
  selectColorId,
  setSelectColorId,
  setCartData,
  cartData,
  imagesid,
  setBigImg,
  bigImg,
  setCount,
  count,
  varients,
  calculations,
  deleteCart,
  cartBtn,
  deleteBtn,
  qtyBtn,
}) => {
  // QTY HANDLER
  const increment = (id, type) => {
    let count_value = cartData?.[id]?.count
      ? +cartData?.[id]?.count
      : +cartData?.[id]?.count_value;

    let pack_value = cartData?.[id]?.pack;

    if (type == "decrement") {
      if (+count_value > 1) {
        calculations(+count_value, id, pack_value, type);
      }
    } else {
      if (+count_value < 10) {
        calculations(+count_value, id, pack_value, type);
      }
    }
  };

  const setid = () => {
    const temp = [];
    for (let i = 0; i < count; i++) {
      temp.push(imagesid?.id);
    }
    setSelectedIds(temp);
  };

  useEffect(() => {
    setid();
  }, [count]);
  const handleColorClick = (colorname, imageUrl, ind, id) => {
    setSelectedColorId(colorname?.name);
    setSelectColorId(colorname?.id);
    // setSelectedImage(imageUrl);
    setBigImg(ind);
    if (cartData?.[id]?.cart_id) {
      varientsNew(
        id,
        "",
        cartData?.[id]?.discount_value,
        colorname?.id,
        "",
        "color"
      );
    }
  };

  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      {viewList?.map((list, ind) => {
        return (
          <div
            className="center-content d-flex flex-column flex-lg-row"
            key={ind}
          >
            <button
              onClick={() => {
                showPopupHander();
                setCount(1);
                // setColorCount(1);
              }}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="Close" />
            </button>

            <div className="left-popup">
              <div className="position-relative" style={{ width: "200px" }}>
                {/* <img
                    className="img_up_cont"
                    src={list?.colorImages?.[bigImg]?.image_url}
                  /> */}
              </div>
              <div>
                <div className="imges">
                  <img
                    style={{
                      // height: "200px",
                      // width: "200px",
                      objectFit: "cover",
                      padding: "10px",
                      borderRadius: "15px",
                    }}
                    // src={p1}
                    src={list?.colorImages?.[bigImg]?.image_url}
                  />
                  {/* {list?.colorImages?.map((img, imgInd) => (
                    <div className="gap-3 d-flex ac-j w-100">
                      <div
                        key={imgInd}
                        id={img?.id}
                        class="add_img d-flex ac-jc"
                        onClick={() => setBigImg(imgInd)}
                      >
                        <img
                          src={img?.image_url}
                          class="cp add_img object-fit-fill"
                        />
                      </div>
                    </div>
                  ))} */}
                </div>
                <div
                  className="d-flex  mt-4"
                  style={{ width: "400px", overflow: "scroll" }}
                >
                  {list?.colorImages?.map((img, imgInd) => {
                    return (
                      <div className="down_imgs">
                        <img
                          key={imgInd}
                          id={img?.id}
                          onClick={() => setBigImg(imgInd)}
                          src={img?.image_url}
                          // src={p3}
                          style={{
                            padding: "10px",
                            height: "100px",
                            width: "100px",
                            objectFit: "cover",
                            borderRadius: "15px",
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="rit-pop">
              <h2 className="pe-lg-5 text-center text-lg-start">
                {list?.name}
              </h2>
              <p className="parag">Designer</p>

              {list?.colors?.length > 0 && (
                <h3 className="mt-3">Select Color</h3>
              )}

              {list?.colors?.length > 0 && (
                <div className="d-flex ac-js">
                  {list?.colors?.map((col, colInd) => (
                    <button
                      key={colInd}
                      style={{ background: col?.hex_code }}
                      className="cust-btn color-box d-flex ac-jc"
                      onClick={() =>
                        handleColorClick(col, "", colInd, list?.id)
                      }
                    >
                      {selectedColorId === col?.name ? (
                        <DoneIcon className="text-white fs-25" />
                      ) : (
                        ""
                      )}
                    </button>
                  ))}
                </div>
              )}

              <div>
                {list?.sizes?.length > 0 && (
                  <div>
                    <div>
                      <label>sizes</label>
                    </div>
                    <select
                      onChange={(e) => {
                        setSize(e.target.value);
                      }}
                      value={size}
                    >
                      <option value={""}>select Size</option>
                      {list?.sizes?.map((size, ind) => {
                        return (
                          <option value={size?.value}>{size?.value}</option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {list?.materials?.length > 0 && (
                  <div>
                    <div>
                      <label>materials</label>
                    </div>
                    <select
                      onChange={(e) => {
                        setMaterial(e.target.value);
                      }}
                      value={material}
                    >
                      <option value={""}>select Materials</option>
                      {list?.materials?.map((material, ind) => {
                        return (
                          <option value={material?.value}>
                            {material?.value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}
                {list?.styles?.length > 0 && (
                  <div>
                    <div>
                      <label>Style</label>
                    </div>

                    <select
                      onChange={(e) => {
                        setStyle(e.target.value);
                      }}
                      value={style}
                    >
                      <option value={""}>select Style</option>
                      {list?.styles?.map((style, ind) => {
                        return (
                          <option value={style?.value}>{style?.value}</option>
                        );
                      })}
                    </select>
                  </div>
                )}
              </div>
              <div className="d-flex ac-jb pe-lg-5 mt-2 mt-lg-3">
                <div className="me-3 me-lg-0">
                  <h3>
                    Quantity
                    <span className="primary fs-19 me-4"> (max 10)</span>
                  </h3>
                  <div className="d-flex align-items-center justify-content-center justify-content-lg-start mt-3">
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn px-2 mb-0 me-2"
                      onClick={() => {
                        // decrement(list?.id);
                        increment(list?.id, "decrement");
                      }}
                      disabled={qtyBtn}
                    >
                      -
                    </button>
                    <h5 className=" fs-25 f5 px-2 mb-0">
                      {cartData?.[list?.id]?.count
                        ? cartData?.[list?.id]?.count
                        : cartData?.[list?.id]?.count_value}
                    </h5>
                    <button
                      className="qunt-btn fs-33 f3 primary1 cust-btn px-2 mb-0 mx-2"
                      onClick={() => {
                        increment(list?.id, "increment");
                      }}
                      disabled={qtyBtn}
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div className="d-flex ac-js">
                <h2 className="op-80">
                  ₹
                  {cartData?.[list?.id]?.packs_price
                    ? +cartData?.[list?.id]?.packs_price
                    : +cartData?.[list?.id]?.for_price}
                  /item{" "}
                </h2>
              </div>
              {/* {method == "final" ? (
                  <div className="d-flex gap-2">
                    {" "}
                    <button
                      className="get-start-btn border-0 mb-2"
                      onClick={() => {
                        // getAddToCart(list?.id);
                        varients(list?.id);
                      }}
                    >
                      Update Pack
                    </button>
                    <button
                      className="get-start-btn border-0 mb-2"
                      onClick={() => {
                        // getAddToCart(list?.id);
                        deleteCart(userCartID, list?.id);
                      }}
                    >
                      Remove Pack
                    </button>
                  </div>
                ) : ( */}
              <button
                className={`get-start-btn border-0 mb-2 ${
                  cartBtn ? "opacity-50" : deleteBtn ? "opacity-50" : ""
                }`}
                onClick={() => {
                  // getAddToCart(list?.id);
                  if (cartData?.[list?.id]?.cart_id) {
                    deleteCart(cartData?.[list?.id]?.cart_id, list?.id);
                  } else {
                    varients(list?.id);
                  }
                }}
                disabled={cartBtn ? true : deleteBtn ? true : false}
              >
                {cartData?.[list?.id]?.cart_id ? "Remove Pack" : "Add to Pack"}
              </button>
              {/* )} */}
              <h3 className="mt-3 mb-1">Description</h3>
              <p className="parag mb-4 px-3 ps-lg-0 text-center text-lg-start">
                {list?.description}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default RequestView;
