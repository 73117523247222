// @ts-nocheck
import React, { useState } from "react";
import {
  commentIcon,
  closeIcon,
  happyEmoj,
  discountIcon,
  quickIcon,
} from "../../assets/img";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

const UpdateQuantity = ({
  showClickHandler,
  showUpdate,
  showUpdateClick,
  breakdownList,
}) => {
  const navi = useNavigate();
  const [number, setNumber] = useState("");

  return (
    <div className="tl-update-qty">
      <div
        className="back-update-qty"
        style={{ display: showUpdate ? "block" : "none" }}
      ></div>
      <div
        className="update-qty update-qty-custom"
        style={{ right: showUpdate ? "-30%" : "-100%" }}
      >
        <button
          onClick={showClickHandler}
          className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="d-flex ac-jb mt-2 mt-lg-4">
          <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
            Product Breakdown
          </h6>
        </div>

        {breakdownList?.storing_type == 3 ? (
          <div className="d-flex gap-5 mt-2 mt-lg-4 w-100">
            <p className="mb-0 mt-3 f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14">
              Start Date:{" "}
              {
                breakdownList?.shipping_or_storage_details?.[0]
                  ?.storage_start_date
              }
            </p>
            <p className="mb-0 mt-3 f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14">
              Close Date :{" "}
              {
                breakdownList?.shipping_or_storage_details?.[0]
                  ?.storage_end_date
              }
            </p>
            <p className="mb-0 mt-3 f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14">
              No of Days :{" "}
              {
                breakdownList?.shipping_or_storage_details?.[0]
                  ?.storage_no_of_days
              }
            </p>
          </div>
        ) : (
          <div className="d-flex gap-5 mt-2 mt-lg-4 w-100">
            <p className="mb-0 mt-3 f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14">
              Type:{" "}
              {
                breakdownList?.shipping_or_storage_details?.[0]?.shipping_charge
                  ?.shipping_type?.name
              }
            </p>
            <p className="mb-0 mt-3 f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14">
              Location:{" "}
              {
                breakdownList?.shipping_or_storage_details?.[0]?.shipping_charge
                  ?.shipping_location?.name
              }
            </p>
          </div>
        )}
        <div className="btm-scroll pt-3 d-flex flex-column flex-md-row">
          <div className="prod-pack">
            {breakdownList?.shipping_or_storage_details?.map((item, ind) => {
              return (
                <div
                  className="d-flex flex-column flex-sm-row ac-jb p-2 my-2 product-list-detail"
                  key={ind}
                >
                  <div className="box-shadow-product box-shadow-product-res">
                    <div className="d-flex w-100 ac-jb mt-0">
                      <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-2 mb-2">
                        Box Name:
                      </p>
                      <p className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-2 mb-2 text-dark">
                        {`${item?.box?.name}-${item?.box?.model}-${item?.box?.dimension}`}
                      </p>
                    </div>
                    <div className="d-flex w-100 ac-jb mt-0">
                      <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                        Qty:
                      </p>
                      <p className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                        {item?.qty}
                      </p>
                    </div>
                    <div className="d-flex w-100 ac-jb mt-0">
                      <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                        Price:
                      </p>
                      <p className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                        {"₹" + item?.price ? item?.price : "-"}
                      </p>
                    </div>
                    <div className="d-flex"> </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateQuantity;
