// @ts-nocheck
import React, { useEffect, useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useRoutes } from "react-router-dom";
import { blog_bg, blog_user2, joinTeam } from "../../../assets/img";
import { Link, useNavigate } from "react-router-dom";
import {
  useBlogListMutation,
  useBlogTagsMutation,
} from "../../../redux/api/api";

const BlogTab = ({ type }) => {
  const navigate = useNavigate();
  const [taglist, setTagList] = useState([]);
  const [tagOnclick, setTagOnClick] = useState("");

  const [list, setList] = useState([]);

  //RTK
  const [blogListApi] = useBlogListMutation();

  //BlogList
  const getBlogList = () => {
    const formData = new URLSearchParams();

    blogListApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res)
        setList(res?.lists);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBlogList();
  }, []);

  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const [tab3, setTab3] = useState(false);
  const [tab4, setTab4] = useState(false);
  const [tab5, setTab5] = useState(false);
  console.log(type);

  //RTK
  const [blogsTags] = useBlogTagsMutation();

  const getTagsList = () => {
    const formData = new URLSearchParams();
    console.log("taglistApi", tagOnclick);
    if (tagOnclick?.length > 0) {
      formData.append("tag", tagOnclick);
    }
    blogsTags(formData)
      .unwrap()
      .then((res) => {
        console.log(res, "blogs");
        setTagList(res?.lists);
        setList(res?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTagsList();
  }, [tagOnclick]);

  const tabHand1 = () => {
    setTab1(true);
    setTab2(false);
    setTab3(false);
    setTab4(false);
    setTab5(false);
  };
  const tabHand2 = () => {
    setTab1(false);
    setTab2(true);
    setTab3(false);
    setTab4(false);
    setTab5(false);
  };
  const tabHand3 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(true);
    setTab4(false);
    setTab5(false);
  };
  const tabHand4 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(false);
    setTab4(true);
    setTab5(false);
  };
  const tabHand5 = () => {
    setTab1(false);
    setTab2(false);
    setTab3(false);
    setTab4(false);
    setTab5(true);
  };
  console.log("taglist", tagOnclick);
  return (
    <div className="home-tab mt-lg-4">
      <h4 className="sect-title text-center mb-2 mb-lg-3 blog-Text">
        {type == "blog" ? "Our blogs" : " Related Blog Posts"}
      </h4>
      {type == "blog" && (
        <div className="d-flex align-items-center justify-content-start justify-content-md-center w-95 mx-auto tab-tl">
          {taglist.map((item, ind) => {
            return (
              <div className="px-2">
                <button
                  onClick={() => setTagOnClick(item)}
                  className={
                    tagOnclick == item
                      ? "get-start-btn text-light f4 mt-0 border-0 mb-md-3"
                      : "get-start-btn text-black bg-transparent f4 mt-0 border-0 mb-md-3"
                  }
                >
                  {item}
                </button>
              </div>
            );
          })}
          {/* <div className="px-2 swag-wild-btn">
            <button
              onClick={tabHand2}
              className={
                tab2
                  ? "get-start-btn text-light f4 mt-0 border-0 mb-md-3"
                  : "get-start-btn text-black bg-transparent f4 mt-0 border-0 mb-md-3"
              }>
              Swag in the wild
            </button>
          </div>
          <div className="px-2">
            <button
              onClick={tabHand3}
              className={
                tab3
                  ? "get-start-btn text-light f4 mt-0 border-0 mb-md-3"
                  : "get-start-btn text-black bg-transparent f4 mt-0 border-0 mb-md-3"
              }>
              Culture
            </button>
          </div>
          <div className="px-2">
            <button
              onClick={tabHand4}
              className={
                tab4
                  ? "get-start-btn text-light f4 mt-0 border-0 mb-md-3"
                  : "get-start-btn text-black bg-transparent f4 mt-0 border-0 mb-md-3"
              }>
              Tips
            </button>
          </div>
          <div className="px-2">
            <button
              onClick={tabHand5}
              className={
                tab5
                  ? "get-start-btn text-light f4 mt-0 border-0 mb-md-3"
                  : "get-start-btn text-black bg-transparent f4 mt-0 border-0 mb-md-3"
              }>
              Press
            </button>
          </div> */}
        </div>
      )}
      <div className="w-90 w-md-80 w-lg-70 w-xl-65 mx-auto mt-2 mt-lg-4">
        <div className="d-flex flex-wrap flex-column flex-md-row blog-boxes">
          {list.map((item, ind) => {
            return (
              <div
                onClick={() => {
                  navigate("/blog-tip", {
                    state: {
                      id: item?.id,
                    },
                  });
                }}
                className="blog-box w-95 w-md-45 position-relative"
              >
                <button className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 text-white title-blog-cat">
                  {item?.tag}
                </button>
                <div className="bloh-img">
                  {/* <img src={blog_bg} alt="" /> */}
                  <img src={item?.image_url} alt="" />
                </div>
                <div className="blog-cont p-2 px-lg-4">
                  <div className="d-flex ac-js mt-2">
                    <AccessTimeIcon className="primary f3 fs-14 fs-md-15 fs-lg-16 fs-xl-19" />
                    <p className=" text-dark f3 fs-14 fs-md-15 fs-lg-16 fs-xl-18 mb-0 ms-2">
                      5 mins read
                    </p>
                  </div>
                  <h4 className="text-black f4 fs-16 fs-md-17 fs-lg-19 fs-xl-20 fs-xxl-22 my-2">
                    {item?.title?.substring(0, 30)}
                  </h4>
                  <p className="primary1 f1 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18  mb-0 ">
                    {/* MerchHQ is offering an opportunity to get more from your swag
                experience with their brand new membership program. */}
                    {item?.short_description?.substring(0, 70)}...
                  </p>
                </div>
              </div>
            );
          })}

          {/* <div onClick={()=>
          {
            navigate("/blog-tip");
          }} className="blog-box w-95 w-md-45 position-relative">
            <button className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 text-white title-blog-cat">
              Tip
            </button>
            <div className="bloh-img">
              <img src={blog_bg} alt="" />
            </div>
            <div className="blog-cont p-2 px-lg-4">
              <div className="d-flex ac-js mt-2">
                <AccessTimeIcon className="primary f3 fs-14 fs-md-15 fs-lg-16 fs-xl-19" />
                <p className=" text-dark f3 fs-14 fs-md-15 fs-lg-16 fs-xl-18 mb-0 ms-2">
                  5 mins read
                </p>
              </div>                                    
              <h4 className="text-black f4 fs-16 fs-md-17 fs-lg-19 fs-xl-20 fs-xxl-22 my-2">
                MerchHQ Launches Membership Plans
              </h4>
              <p className="primary1 f1 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18  mb-0 ">
                MerchHQ is offering an opportunity to get more from your swag
                experience with their brand new membership program.
              </p>
            </div>
          </div> */}
          {/* <div onClick={()=>
          {
            navigate("/blog-swag");
          }} className="blog-box w-95 w-md-45 position-relative">
            <button className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 text-white title-blog-cat">
              Swag in the wild
            </button>
            <div className="bloh-img">
              <img alt="" src={blog_user2} />
            </div>
            <div className="blog-cont p-2 px-lg-4 mt-2">
              <div className="d-flex ac-js">
                <AccessTimeIcon className="primary f3 fs-14 fs-md-15 fs-lg-16 fs-xl-19" />
                <p className=" text-dark f3 fs-14 fs-md-15 fs-lg-16 fs-xl-18 mb-0 ms-2">
                  5 mins read
                </p>
              </div>
              <h4 className="text-black f4 fs-16 fs-md-17 fs-lg-19 fs-xl-20 fs-xxl-22 my-2">
                Conference work for All
              </h4>
              <p className="primary1 f1 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18  mb-0 ">
                We’ve pulled together a list of the best swag for your
                conference giveaways across all categories!
              </p>
            </div>
          </div> */}
        </div>
        {/* <div className="d-flex flex-wrap flex-column flex-md-row blog-boxes">
          <div className="blog-box w-95 w-md-45 position-relative">
            <button className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 text-white title-blog-cat">
              Tip
            </button>
            <div className="bloh-img">
              <img src={blog_bg} alt="" />
            </div>
            <div className="blog-cont p-2 px-lg-4">
              <div className="d-flex ac-js mt-2">
                <AccessTimeIcon className="primary f3 fs-14 fs-md-15 fs-lg-16 fs-xl-19" />
                <p className=" text-dark f3 fs-14 fs-md-15 fs-lg-16 fs-xl-18 mb-0 ms-2">
                  5 mins read
                </p>
              </div>
              <h4 className="text-black f4 fs-16 fs-md-17 fs-lg-19 fs-xl-20 fs-xxl-22 my-2">
                MerchHQ Launches Membership Plans
              </h4>
              <p className="primary1 f1 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18  mb-0 ">
                MerchHQ is offering an opportunity to get more from your swag
                experience with their brand new membership program.
              </p>
            </div>
          </div>
          <div className="blog-box w-95 w-md-45 position-relative">
            <button className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 text-white title-blog-cat">
              Swag in the wild
            </button>
            <div className="bloh-img">
              <img alt="" src={blog_user2} />
            </div>
            <div className="blog-cont p-2 px-lg-4 mt-2">
              <div className="d-flex ac-js">
                <AccessTimeIcon className="primary f3 fs-14 fs-md-15 fs-lg-16 fs-xl-19" />
                <p className=" text-dark f3 fs-14 fs-md-15 fs-lg-16 fs-xl-18 mb-0 ms-2">
                  5 mins read
                </p>
              </div>
              <h4 className="text-black f4 fs-16 fs-md-17 fs-lg-19 fs-xl-20 fs-xxl-22 my-2">
                Conference work for All
              </h4>
              <p className="primary1 f1 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18  mb-0 ">
                We’ve pulled together a list of the best swag for your
                conference giveaways across all categories!
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default BlogTab;
