import React from 'react'
import { shippmentIcon } from '../../assets/img'

const EmtyShipment = () => {
    return (
        <div className="shipment-inventory w-100 h-100 d-flex flex-column d-flex ac-jc my-5">
            <img className="" src={shippmentIcon} alt="" style={{ width: '148px' }} />
            <h2 className="sect-title mb-4">No Shipments</h2>
            <p class="parag">You have not sent any Swag yet.</p>
            <button class="f4 get-start-btn bg-lt-blue border-0 primary my-0 mx-2 border-primary">Send</button>
        </div>
    )
}

export default EmtyShipment