import React, { useEffect } from "react";
import ProfileLeftTab from "../component/Profile/ProfileLeftTab";
import { useState } from "react";
import { uploadIcon } from "../assets/img";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveLoader } from "../redux/slice/loaderNoti";
import { useUserEditMutation } from "../redux/api/api";

const ProfileScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_details"));

  const [logo, setLogo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [btn, setBtn] = useState(false);

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setSetPasswordErr] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [organizationErr, setOrganizationErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);

  useEffect(() => {
    if (userData) {
      setFirstName(userData?.first_name);
      setLastName(userData?.last_name);
      setEmail(userData?.email);
      setPhone(userData?.mobile);
      setOrganization(userData?.organisation);
      setLogo(userData?.image_url);
    }
  }, []);

  // RTK QUERY
  const [profileEditAPi] = useUserEditMutation();

  const submitHandle = () => {
    if (
      email?.length == 0 ||
      firstName?.length == 0 ||
      lastName?.length == 0 ||
      organization?.length == 0 ||
      phone?.length < 10
    ) {
      setEmailErr(true);
      setFirstNameErr(true);
      setLastNameErr(true);
      setOrganizationErr(true);
      setPhoneErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("first_name", firstName);
      formdata.append("email", email);
      formdata.append("mobile", phone);
      formdata.append("organisation", organization);
      formdata.append("last_name", lastName);
      if (logo?.name) {
        formdata.append("image", logo);
      }
      dispatch(saveLoader(true));
      setBtn(true);
      profileEditAPi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("res", res);
          localStorage.setItem("user_details", JSON.stringify(res?.user));
          navigate(-1);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };
  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandle();
    }
  };

  // console.log("logo", logo);

  return (
    <div className="product-screen d-flex flex-column flex-md-row">
      <ProfileLeftTab />
      <div className="profile-screen">
        <h4 className="sect-title mb-2 mb-lg-1">Profile</h4>
        <p
          className="parag1 my-2"
          style={{
            fontFamily: "f4",
            fontSize: "18px",
          }}
        >
          Basic Information
        </p>

        <div className="profile-input">
          <div className="box">
            <p className="p_box">First Name</p>
            <input
              className=""
              placeholder="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
              onKeyDown={keydownHandler}
            />
            {firstName?.length == 0 && firstNameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter First Name</p>
              </div>
            )}
          </div>
          <div className="box">
            <p className="p_box">Last Name</p>
            <input
              className=""
              placeholder="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
              onKeyDown={keydownHandler}
            />
            {lastName?.length == 0 && lastNameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Last Name</p>
              </div>
            )}
          </div>
        </div>
        <div className="profile-input">
          <div className="box">
            <p className="p_box">Phone</p>
            <input
              className=""
              type="number"
              placeholder="Phone"
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 10) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 10) {
                  e.target.setCustomValidity("");

                  setPhone(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              maxLength={10}
              name="mobile"
              onKeyDown={keydownHandler}
              onChange={(e) => {
                setPhone(e.target.value);
              }}
              value={phone}
            />
            {phone?.length < 10 && phoneErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Phone No.</p>
              </div>
            )}
          </div>
          <div className="box">
            <p className="p_box">Email ID</p>
            <input
              className=""
              placeholder="Email ID"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              disabled
            />
            {validEmail.test(email) == false && emailErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Valid Email ID</p>
              </div>
            )}
          </div>
        </div>
        <div className="profile-input">
          <div className="box">
            <p className="p_box">Organization</p>
            <input
              className=""
              placeholder="Organization"
              onChange={(e) => setOrganization(e.target.value)}
              value={organization}
              onKeyDown={keydownHandler}
            />
            {organization?.length == 0 && organizationErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Organization</p>
              </div>
            )}
          </div>
          {/* <div className="box">
            <input className="" placeholder="Email ID" />
          </div> */}
        </div>

        <div className="">
          <p
            className="parag1 my-2"
            style={{
              fontFamily: "f4",
              fontSize: "18px",
            }}
          >
            Profile Image
          </p>
          <div style={{ marginLeft: "10px" }} className="file-upload">
            <div className="file-icon">
              <img
                className={logo?.name ? "" : logo ? "" : "placeholder_icon"}
                src={
                  logo?.name
                    ? window.URL.createObjectURL(logo)
                    : logo
                    ? logo
                    : uploadIcon
                }
                alt="logo"
              />
            </div>
            <label className="upload-btn">
              Upload
              <input
                onChange={(e) => setLogo(e.target.files[0])}
                className="d-none"
                type="file"
                accept="image/png, image/gif, image/jpeg"
              />
            </label>
          </div>
        </div>

        <div className="d-flex profile-action mt-4">
          <button
            className="bg-primar rounded rounded-2 text-white mb-2 border-0"
            onClick={submitHandle}
            disabled={btn}
          >
            Save
          </button>
          <button
            style={{ backgroundColor: "#E2ECFF", color: "#839DD1" }}
            className=" rounded rounded-3 mb-2 border-0"
            onClick={() => navigate(-1)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileScreen;
