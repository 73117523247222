import React, { useEffect, useRef, useState } from "react";
import { img1, logist, noLogo, packke } from "../../../assets/img";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OwlCarousel from "react-owl-carousel";
import { row2, row3 } from "../../../redux/api/constants";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ProductPopupComponent from "./ProductPopupComponent";
import { usePresetPackViewMutation } from "../../../redux/api/api";
import Inventory2Icon from "@mui/icons-material/Inventory2";

const PresetListTabcomp = ({
  type,
  onCLickhandler,
  eventList,
  containerRef,
}) => {
  // console.log(type);
  const navigate = useNavigate();
  const carouselRef = useRef([]);
  const [showPopup, setShowPopup] = useState(false);
  const [itemId, setItemId] = useState("");

  //RKT
  const [presetPackApi] = usePresetPackViewMutation();
  const [packlist, setPackList] = useState({});

  const getPresetPackView = (id) => {
    const formData = new FormData();
    formData.append("preset_pack_id ", id);
    presetPackApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        const temp = [];
        temp?.push(res?.pack);
        const image = [];
        temp?.map((item) => {
          image.push(item?.event_category?.image_url);
          item?.preset_product?.map((img) => {
            image.push(img?.product?.defaultImage);
          });
          // list?.push(()
        });

        const list = {
          ...res?.pack,
          images: image,
        };

        // list?.push({
        //   ...item,
        //   images: image,
        // });

        setPackList(list);
        setShowPopup(true);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showPopupHander = () => {
    setShowPopup(!showPopup);
    // console.log("099");
  };

  return (
    <>
      {eventList?.map((packs, ind) => {
        return (
          packs?.presetPack?.length > 0 && (
            <div className="container" key={ind}>
              <div
                style={{ margin: "50px 0" }}
                ref={(el) => (containerRef.current[ind] = el)}
              >
                <button className="d-flex f3 bg-white black border-0 w-80 m-auto mt-2">
                  <p className="sect-title mb-2 mt-2 mb-lg-1">{packs?.name}</p>
                </button>

                <div className="d-flex w-80 ac-jc m-auto ">
                  <button
                    className="iconbg me-2"
                    onClick={() => {
                      carouselRef?.current?.[ind]?.prev();
                      console.log("test");
                    }}
                  >
                    <ArrowBackIosIcon className="mb-3 mb-md-0 primary f1 fs-15 fs-md-16 fs-lg-17 fs-xl-18" />
                  </button>

                  <OwlCarousel
                    ref={(el) => (carouselRef.current[ind] = el)}
                    {...row3}
                    className="OwlCarousel_cust"
                  >
                    {packs?.presetPack?.map((item, indx) => {
                      return (
                        // <div className="false pac-box3 px-1 ">
                        <div className="preset-kit-box px-3">
                          <button
                            className="cust-btn"
                            style={{ width: "230px" }}
                          >
                            <div className="align-items-center align-items-md-start text-center text-md-start">
                              <div
                                // onClick={handleClick}
                                className="pe-3 text-dark"
                                key={indx}
                                onClick={() => {
                                  if (type == "home") {
                                    navigate("/products/preset-pack");
                                  } else {
                                    getPresetPackView(item?.id);
                                  }
                                }}
                              >
                                <button className="bg-transparent border-0 slisd topImg">
                                  <img
                                    src={
                                      item?.preset_image_url?.image_url
                                        ? item?.preset_image_url?.image_url
                                        : noLogo
                                    }
                                    className=""
                                    alt="icon"
                                  />
                                </button>
                                <div className="text-center">
                                  <h5 className="mb-0">{item?.title}</h5>
                                </div>
                                <div
                                  className=" mt-2 text-nowrap d-flex  ac-jb"
                                  style={{
                                    width: "250px",
                                    flexWrap: " wrap",
                                    padding: "4px 25px 0px 0px",
                                  }}
                                >
                                  <p className="mb-0 parag f3">
                                    ₹ {item?.total_price}/Pack
                                  </p>
                                  <p className="parag  mb-0 f3">
                                    <Inventory2Icon /> Min 25 packs
                                  </p>
                                </div>

                                <button
                                  className="get-start-btn1 border-0 mb-3 mt-3 mx-auto"
                                  style={{ width: "160px" }}
                                >
                                  Select Pack
                                </button>

                                <div></div>
                              </div>
                            </div>
                          </button>
                        </div>
                        // </div>
                      );
                    })}
                  </OwlCarousel>

                  <button
                    className="iconbg ms-2"
                    onClick={() => {
                      carouselRef?.current?.[ind]?.next();
                    }}
                  >
                    <ArrowForwardIosIcon className="mb-3 mb-md-0 primary f1 fs-15 fs-md-16 fs-lg-17 fs-xl-18" />
                  </button>
                </div>
              </div>
            </div>
          )
        );
      })}
      {showPopup && (
        <ProductPopupComponent
          showPopupHander={showPopupHander}
          itemId={itemId}
          packlist={packlist}
        />
      )}
    </>
  );
};

export default PresetListTabcomp;
