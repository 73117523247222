import React from "react";

const InventoryTab = ({ title, btnText, onClick, shipment }) => {
  console.log(shipment);
  return (
    <div className="top-product d-flex flex-column justify-content-between mt-3">
      <div className="d-flex ac-jb w-100">
        <h6 className="sect-title px-2 px-lg-3">{title}</h6>
        <button
          onClick={onClick}
          className={`cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3`}
        >
          {btnText}
        </button>
      </div>
      <div className="d-flex ac-js mt-3">
        <div className="position-relative">
          <button
            className={`cust-btn f3 fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  px-2 px-lg-3 mb-2 ${
              shipment ? "text-dark" : "primary1"
            }`}
          >
            Shipments
          </button>
          {shipment && <div className="active position-absolute" />}
        </div>
        <div className="position-relative">
          <button
            className={`cust-btn f3 fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20  px-2 px-lg-3 mb-2 ${
              shipment ? "primary1" : "text-dark"
            }`}
          >
            Contacts
          </button>
          {!shipment && <div className="active position-absolute" />}
        </div>
      </div>
    </div>
  );
};

export default InventoryTab;
