import {
  art1,
  art2,
  closeIcon,
  cloudUpload,
  head,
  logos,
  noLogo,
  profilePic,
  tshirt1,
  tshirt2,
  tshirt3,
  // viewIcon,
} from "../assets/img";
import React, { useEffect, useState, useRef } from "react";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import ImageGallery from "react-image-gallery";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import { presentation_list } from "../redux/api/DummyJson";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import {
  useLazyProjectViewQuery,
  useApproveandrejectMutation,
} from "../redux/api/api";
import { ErrorIcon } from "react-hot-toast";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import { saveLoader } from "../redux/slice/loaderNoti";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const PresentationListA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [projectViewApi] = useLazyProjectViewQuery();
  const [approveApi] = useApproveandrejectMutation();
  const location = useLocation();
  const { item } = location.state || {};
  const [serachParams, setSearchParams] = useSearchParams();
  const [presentionlist, setPresentationList] = useState([]);
  const [images, setImages] = useState([]);
  const [productqty, setProductQty] = useState();
  const [fixedcharges, setFixedCharges] = useState();
  const [userPopup, setUserPopup] = useState(false);
  const [address, setAddress] = useState("");
  const [dimension, setDimension] = useState("");
  const [user_comment, setUser_comment] = useState("");
  const [selectedItem, setSelectedItem] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [closing_date, setclosing_date] = useState([]);
  const [productPrice, setproductPrice] = useState({});
  const [numbers, setNumbers] = useState([]);
  const proImgRef = useRef();
  const [runCharge, setRunCharge] = useState([]);
  const [user, setUser] = useState();
  const [includeLogo, setIncludeLogo] = useState(false);
  const [artworkInd, setArtworkInd] = useState(1);
  const [artwrk, setArtWork] = useState({});
  const [artwork, setArtwork] = useState("");
  const [selectedArtwork, setSelectedArtwork] = useState(null);

  const handleUserPopup = () => {
    setUserPopup(!userPopup);
  };
  //   const handleUserPopup = () => {
  //     setUserPopup((prev) => {
  //         const newState = !prev;
  //         console.log(newState, "userpopup"); // Logs the updated state
  //         return newState;
  //     });
  // };
  const [notes, setNotes] = useState("");
  const [notesErr, setNotesErr] = useState(false);
  const [uploadErr, setUploadImageErr] = useState(false);
  const [qty, setQty] = useState({
    25: 25,
    50: 50,
    100: 100,
    150: 150,
    250: 250,
    500: 500,
    1000: 1000,
  });

  const calculation = (value, for_price) => {
    let percen = (+value / 100) * +for_price;
    let sum = Number(+percen + +for_price).toFixed();
    return +sum;
  };
  const submitHandler = (status, projectId) => {
    let formdata = new FormData();
    formdata.append("project_detail_id", projectId);
    formdata.append("artwork_status", status);
    formdata.append("project_artwork_id", artwork);

    if (notes) {
      formdata.append("user_comment", notes);
    }
    if (uploadImage?.name) {
      formdata.append("user_logo", uploadImage);
    }

    dispatch(saveLoader(true));
    approveApi(formdata)
      .unwrap()
      .then((res) => {
        toast.success(res?.message);
        dispatch(saveLoader(false));
        // setBtns(false);

        getView();
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        console.log("err", err);
      });
    // }
  };

  const getView = () => {
    projectViewApi(location?.state?.data?.id || serachParams?.get("project_id"))
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          const projectData = res?.project || {};
          setUser(projectData);
          setPresentationList(projectData.projectDetail || []);
          const requestimage = res?.project?.project_request?.images;
          const product = res?.project?.projectDetail;
          const data = product.map((item) => item?.productPrice);

          const projectPresentationPricess = res?.project?.projectDetail;
          const products = projectPresentationPricess
            .map((item) => item.projectPresentationPrice)
            .flat();
          setclosing_date(requestimage);
          const allArtworks = projectData.projectDetail
            ?.map((detail) => detail.artworks || [])
            ?.flat();
          const imageGalleryItems = Array.isArray(allArtworks)
            ? allArtworks.map((artwork) => ({
                original: artwork?.image_url || "",
                thumbnail: artwork?.image_url || "",
              }))
            : [];

          setImages(imageGalleryItems);
        }
      })
      .catch((err) => {
        console.log("Error fetching project details:", err);
      });
  };
  const fileChange = (e) => {
    setUploadImage(e.target.files[0]);
  };
  const imageSelector = (item) => {
    setUploadImage(item);
  };
  const handleArtworkChange = (artworkId) => {
    const selectedArtwork = presentionlist
      .map((item) => item.projectArtwork)
      .find((item) => item.id === artworkId);
    setSelectedArtwork(selectedArtwork);
  };

  useEffect(() => {
    if (location?.state?.data?.id || serachParams?.get("project_id")) {
      getView();
    }
  }, []);
  useEffect(() => {
    if (selectedItem) {
    }
  }, [selectedItem]);
  useEffect(() => {
    if (selectedItem?.artworks?.length > 0) {
      const firstArtwork = selectedItem.artworks[0];
      setArtwork(firstArtwork.id);
      setArtWork(firstArtwork);
      setUploadImage(firstArtwork);

      setArtworkInd(1);
    }
  }, [selectedItem]);

  const artworkSelector = (ids) => {
    const finder = selectedItem?.artworks?.find((item) => item.id === ids);
    if (finder) {
      setArtwork(ids);
      setArtWork(finder);
      setUploadImage(finder); // Optional, if you want to set upload image from selected artwork
      const findIndex = selectedItem.artworks.findIndex(
        (item) => item.id === ids
      );
      setArtworkInd(findIndex + 1);
    }
  };

  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      {userPopup && (
        <div className="modal-popup">
          <div className="back-close" />
          {/* {selectedItem && (() => {
    const selectedArtworkId = selectedItem?.artworks; 
    console.log(selectedArtworkId.location,"selectedArtworkId")
    return ( */}
          <div
            style={{ width: "60%" }}
            className="center-content d-flex flex-column flex-lg-row p-0 m-0"
          >
            <button
              onClick={handleUserPopup}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="Close" />
            </button>
            <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mt-4">
              <h6 className="mb-2 f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
                Artwork {artworkInd}
              </h6>

              <div className="w-100 d-flex ac-jc flex-column flex-md-row">
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-lg-2">
                    <p className="parag mb-2">
                      Artwork Allmade unisex Organic cotton Tee
                    </p>
                    <label className="w-100 position-relative">
                      <input
                        ref={proImgRef}
                        className="d-none"
                        type="file"
                        onChange={(e) => setUploadImage(e.target.files[0])}
                      />
                      <div>
                        {uploadImage ? (
                          <div
                            className="prev-img"
                            // onClick={() => proImgRef?.current?.click()}
                            role="button"
                          >
                            <img
                              src={
                                uploadImage?.name
                                  ? URL.createObjectURL(uploadImage)
                                  : uploadImage?.image_url
                              }
                              alt="Uploaded preview"
                            />
                          </div>
                        ) : (
                          <div
                            className="log-upload-box position-relative d-flex ac-jc mt-3 mb-3"
                            onClick={() => proImgRef?.current?.click()}
                            role="button"
                          >
                            <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
                              Primary Logo
                            </p>
                            <img src={cloudUpload} alt="Upload" />
                            <p className="text-dark text-center fs-13 fs-md-14">
                              Drag and drop to upload or Click <br />
                              here to upload
                            </p>
                          </div>
                        )}
                      </div>
                    </label>
                  </div>
                  <div className="d-flex ac-jc gap-3 w-100">
                    <div
                      className="d-flex ac  mt-4 mob-carous-pop"
                      style={{ width: "300px", overflow: "scroll" }}
                    >
                      {selectedItem?.artworks?.map((item, ind) => {
                        return (
                          <div
                            className="down_imgs m-1"
                            key={ind}
                            role={"button"}
                            onClick={() => artworkSelector(item?.id)}
                            style={
                              artwrk?.id == item?.id
                                ? { border: "1px solid black" }
                                : { border: "none" }
                            }
                          >
                            <img
                              src={item.image_url ? item.image_url : noLogo}
                              style={{
                                padding: "10px",
                                height: "100px",
                                width: "100px",
                                objectFit: "cover",
                                borderRadius: "15px",
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {/* {selectedArtworkId?.map((item, ind) => (
                 */}
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f3 primary1 fs-16 fs-md-18 fs-lg-19 mb-0 mt-3">
                      Artworks
                    </p>
                    <select
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      onChange={(e) => artworkSelector(e.target.value)}
                      value={artwork}
                    >
                      {selectedItem?.artworks?.length > 0 ? (
                        selectedItem?.artworks?.map((item, ind) => {
                          return (
                            <option
                              style={{ textAlign: "center" }}
                              value={item?.id}
                            >{`Artwork ${ind + 1}`}</option>
                          );
                        })
                      ) : (
                        <option style={{ textAlign: "center" }} value={""}>
                          Select Artwork
                        </option>
                      )}
                    </select>
                  </div>

                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Decorative Method
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={`${
                        artwrk?.logo_length ? artwrk?.logo_length : ""
                      }*${artwrk?.logo_breadth ? artwrk?.logo_breadth : "-"}`}
                    />
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                      Location Name
                    </p>
                    <input
                      className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                      value={artwrk?.logo_name ? artwrk?.logo_name : "-"}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-between mt-3 mt-lg-4 pop-input">
                <div>
                  <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                    Dimensions
                  </p>
                  <input
                    className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                    placeholder="L*B"
                    value={`${artwrk?.logo_length ? artwrk?.logo_length : ""}*${
                      artwrk?.logo_breadth ? artwrk?.logo_breadth : "-"
                    }`}
                  />
                </div>
                <div>
                  <p className="f2 text-dark cust-btn fs-13 fs-md-14 fs-lg-15 mb-2 mt-2">
                    Logo Colour
                  </p>
                  <input
                    className="text-dark fs-13 fs-md-14 fs-lg-15 mb-3 text-area-product p-2"
                    value={artwrk?.logo_color ? artwrk?.logo_color : "-"}
                    readOnly
                  />
                </div>
              </div>

              <div className="ac-jb mt-2 pop-input w-100">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                  Why am I Rejecting?
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => (
                      <Tooltip {...props} id="button-tooltip">
                        <p className="text-capitalize text-start f4 fs-15">
                          I would like to change the logo. I want the logo to be
                          centered. Can you show me a different option?
                        </p>
                      </Tooltip>
                    )}
                  >
                    <span className="d-flex align-items-center">
                      <img
                        // src={viewIcon}
                        className="viewDes"
                        alt="View Details"
                      />
                      <ErrorIcon />
                    </span>
                  </OverlayTrigger>
                </p>
                <input
                  type="text"
                  placeholder="Notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 mt-1"
                />
              </div>

              <button
                className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
                onClick={() => {
                  handleUserPopup();
                  submitHandler(4, selectedItem?.id);
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}

      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2 banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                merchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                PRESENTATION #{user?.presentation_code} for{" "}
                {user?.project_request?.user?.first_name}
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project {user?.project_code}
              </p>
            </div>
          </div>

          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        {presentionlist.map((item, index) => {
          if (item?.status === 2 || item?.status === 1) {
            const detail = item?.projectArtwork;
            const products = item?.productPrice;
            const prices = item?.projectPresentationPrice || [];

            const images = item?.artworks || [];
            const imageItems = images.map((image) => ({
              original: image.image_url || noLogo,
              thumbnail: image.image_url || noLogo,
            }));
            const qtyList = prices.map((p) => p.qty);
            const rsList = prices.map((p) => p.total);
            const fixedCharges = {};

            prices.forEach((presentationPrice) => {
              if (Array.isArray(presentationPrice?.run_charge)) {
                presentationPrice.run_charge.forEach((charge) => {
                  if (!fixedCharges[charge.name]) {
                    fixedCharges[charge.name] = [];
                  }
                  fixedCharges[charge.name].push(charge.value);
                });
              }
            });
            const fixedChargesArray = Object.entries(fixedCharges);

            let priceRs = [];

            prices?.map((item) => {
              let calculatedPrice;

              if (item?.qty >= 1 && item?.qty <= 25) {
                calculatedPrice = calculation(
                  item?.margin,
                  products?.productDistributorPrice?.for_25
                );
              } else if (item?.qty >= 26 && item?.qty <= 50) {
                calculatedPrice = calculation(
                  item?.margin,
                  products?.productDistributorPrice?.for_50
                );
              } else if (item?.qty >= 51 && item?.qty <= 100) {
                calculatedPrice = calculation(
                  item?.margin,
                  products?.productDistributorPrice?.for_100
                );
              } else if (item?.qty >= 101 && item?.qty <= 150) {
                calculatedPrice = calculation(
                  item?.margin,
                  products?.productDistributorPrice?.for_150
                );
              } else if (item?.qty >= 151 && item?.qty <= 250) {
                calculatedPrice = calculation(
                  item?.margin,
                  products?.productDistributorPrice?.for_250
                );
              } else if (item?.qty >= 251 && item?.qty <= 500) {
                calculatedPrice = calculation(
                  item?.margin,
                  products?.productDistributorPrice?.for_500
                );
              } else if (item?.qty >= 501 && item?.qty <= 1000) {
                calculatedPrice = calculation(
                  item?.margin,
                  products?.productDistributorPrice?.for_1000
                );
              }

              if (calculatedPrice !== undefined) {
                priceRs.push(calculatedPrice);
              }
            });
            return (
              <div
                className="d-flex flex-wrap mt-4 align-items-start"
                key={index}
              >
                <div
                  className="col-md-6 mt-4 rounded bg-primary2"
                  style={{
                    borderRadius: "32px",
                    padding: "12px",
                    textAlign: "center",
                  }}
                >
                  {imageItems.length > 0 && <ImageGallery items={imageItems} />}
                </div>

                <div className="col-md-6 p-4">
                  <div style={{}}>
                    <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                      {item?.product?.name}
                    </h2>
                    <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                      {item?.product?.description}
                    </p>
                  </div>
                  {/* {user.pricing_status === 1 && ( */}
                  <div
                    className="my-3 bg-primary2 p-3"
                    style={{ lineHeight: "40px" }}
                  >
                    {user.pricing_status === 1 && (
                      <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2 mt-3">
                        Pricing
                      </p>
                    )}
                    <table className="w-100 overflow-scroll">
                      <tbody>
                        {user.pricing_status === 1 && (
                          <tr className="text-center">
                            <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                              QTY
                            </td>
                            {qtyList.map((qty, index) => (
                              <td
                                key={index}
                                className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                              >
                                {qty}
                              </td>
                            ))}
                          </tr>
                        )}
                        {user.pricing_status === 1 && (
                          <tr className="text-center">
                            <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                              RS
                            </td>
                            {priceRs?.map((qty, index) => (
                              <td
                                key={index}
                                className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                              >
                                {[`${qty}`]}
                              </td>
                            ))}
                          </tr>
                        )}
                        {user.pricing_status === 1 && (
                          <tr>
                            <td
                              colSpan={prices.length + 1}
                              className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 primary"
                              style={{ textAlign: "start" }}
                            >
                              Fixed Charges
                            </td>
                          </tr>
                        )}
                        {user.pricing_status === 1 && (
                          <>
                            {fixedChargesArray.map(([name, values], index) => (
                              <tr key={index} className="text-center">
                                <td className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                  {name}
                                </td>
                                {values.map((value, valueIndex) => (
                                  <td
                                    key={valueIndex}
                                    className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                                  >
                                    {value}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </>
                        )}
                        {user.pricing_status === 1 && (
                          <tr className="text-center  my-4 bg-primary2 w-100">
                            <td className="f3 p-3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2">
                              Total Price
                            </td>
                            {rsList?.map((rs, index) => (
                              <td
                                key={index}
                                className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                              >
                                {rs}
                              </td>
                            ))}
                          </tr>
                        )}
                        <div className="d-flex flex-wrap ">
                          <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2 mb-0">
                            Colors:
                          </p>
                          <div className="d-flex">
                            <div className="lt-Blue1">
                              <FiberManualRecordIcon
                                style={{
                                  color:
                                    item?.product_sku_id?.color?.hex_code ||
                                    item?.product_sku?.color?.hex_code,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </tbody>
                    </table>

                    {/* <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 gray my-3 ">
                    Comments :
                  </h2>
                  <div className="d-flex my-1">
                    <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                      Sakthi :
                    </h2>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 gray mx-1">
                      Nice Product
                    </p>
                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16  mx-1">
                      - Delete
                    </p>
                  </div>
                  <div>
                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 gray mb-3">
                      04/04/2024 - 06:11PM
                    </p>
                  </div> */}

                    {/* <div className="w-100">
                    <textarea
                      className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                      style={{ height: "100px" }}
                      placeholder="Comment on this item..."
                    ></textarea>
                  </div>
                  <div className="d-flex ac-jb">
                    <button className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4">
                      Add Comment <PlaylistAddCheckIcon />
                    </button>
                  </div> */}
                  </div>
                  {/* )} */}{" "}
                </div>

                {detail.map((artwork, index) => {
                  return (
                    <div className="col-md-5">
                      <div className="d-flex flex-wrap w-100 ac-jb mb-4">
                        <table
                          className="ms-3 mt-2 w-100"
                          style={{ lineHeight: "30px" }}
                        >
                          <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                            ARTWORK DETAILS
                          </p>
                          <tr>
                            <td>
                              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                DESIGN NAME
                              </p>
                            </td>
                            <td>
                              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                {artwork.logo_name ? artwork.logo_name : "N/A"}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                IMPRINT TYPE
                              </p>
                            </td>
                            <td>
                              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                {artwork?.decorative_method_id?.name}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                DESIGN LOCATION
                              </p>
                            </td>
                            <td>
                              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                {artwork?.uom === "1"
                                  ? "Inches"
                                  : artwork?.uom === "2"
                                  ? "Stitch"
                                  : "N/A"}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                DESIGN SIZE
                              </p>
                            </td>
                            <td>
                              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                {artwork?.logo_length}*{artwork?.logo_breadth}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                DESIGN COLOR
                              </p>
                            </td>
                            <td>
                              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                {artwork?.logo_color}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  );
                })}
                <div>
                  {/* {item?.artwork_status === 3 && (
    <button
      style={{
        backgroundColor: "#0082ca",
        borderRadius: "10px",
        padding: "10px",
        border: "none",
        marginLeft: "5px",
      }}
      onClick={() => {
        navigate("/poproduct-request-a", { state: { item } });
      }}
    >
      <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
        Approve Order
      </p>
    </button>
  )} */}
                </div>

                <div className="d-flex justify-content-end">
                  <div>
                    {item?.artwork_status !== 3 &&
                    item?.artwork_status !== 4 ? (
                      <button
                        style={{
                          backgroundColor: "#f82a2a",
                          borderRadius: "10px",
                          padding: "10px",
                          border: "none",
                        }}
                        onClick={() => {
                          handleUserPopup();
                          setSelectedItem(item);
                        }}
                      >
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                          Request Change
                        </p>
                      </button>
                    ) : (
                      <button
                        disabled
                        style={{
                          backgroundColor: "#f82a2a",
                          borderRadius: "10px",
                          padding: "10px",
                          border: "none",
                          opacity: 0.5,
                        }}
                      >
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                          Request Change
                        </p>
                      </button>
                    )}
                  </div>
                  <div>
                    {item?.artwork_status !== 3 &&
                    item?.artwork_status !== 4 ? (
                      <button
                        style={{
                          backgroundColor: "#0082ca",
                          borderRadius: "10px",
                          padding: "10px",
                          border: "none",
                          marginLeft: "5px",
                        }}
                        onClick={() => {
                          setSelectedItem(item);
                          submitHandler(3, item?.id);
                          // navigate("/poproduct-request-a", { state: { item } });
                        }}
                      >
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                          Approve Design
                        </p>
                      </button>
                    ) : (
                      <button
                        disabled
                        style={{
                          backgroundColor: "#0082ca",
                          borderRadius: "10px",
                          padding: "10px",
                          border: "none",
                          marginLeft: "5px",
                          opacity: 0.5,
                        }}
                      >
                        <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                          Approve Design
                        </p>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            );
          }
        })}
      </div>

      <div className="d-flex flex-wrap w-100 ac-jb mb-4">
        <div className="d-flex justify-content-end w-100 pe-3"></div>
      </div>
      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="d-flex flex-wrap ac-jb w-90 m-auto">
            <div className="">
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <button
                class=" d-flex ac-jc ps-3 mt-5"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>

            <div className="col-md-4">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <div class="d-flex white mt-sm-2 ">
                <FacebookRoundedIcon className="me-2" />

                <TwitterIcon className="mx-2" />

                <LinkedInIcon className="mx-2" />

                <InstagramIcon className="mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PresentationListA;
