// @ts-nocheck
import { Link } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import HomeTab from "../../Home/HomeTab";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import OwlCarousel from "react-owl-carousel";
import { row2, row3 } from "../../../redux/api/constants";
import {
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  noLogo,
  products1,
  products2,
  products3,
  products4,
  robotIcon,
  Searcher,
} from "../../../assets/img";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PresetListTabcomp from "./PresetListTabcomp";
import {
  useColorListMutation,
  useEventListMutation,
  useLazyTagsfilterQuery,
  usePresetPackListMutation,
} from "../../../redux/api/api";
import DoneIcon from "@mui/icons-material/Done";
import { ArrowDropDown } from "@mui/icons-material";
import RangeSet from "../../Range/RangeSet";
import CachedIcon from "@mui/icons-material/Cached";

const RightProducts = ({ showPopupHander }) => {
  const carouselRef = useRef(null);
  const containerRef = useRef([]);
  const [scrollId, setScrollId] = useState("");
  const [eventList, setEventList] = useState([]);
  const [presetList, setPresetList] = useState([]);
  const [color, setColor] = useState(false);
  const [colorId, setColorId] = useState("");
  const [normal, setNormal] = useState([]);
  const [event, setEvent] = useState([]);
  const [icon, setIcon] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [normalid, setnormalid] = useState();
  const [eventid, setEventid] = useState();
  const [iconid, setIconid] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [drag, setDrag] = useState(false);
  const [normalids, setNormalId] = useState([]);
  const [values, setValues] = useState([5, 1000]);
  const [priceSort, SetPriceSort] = useState("");
  const [search, setSearch] = useState("");

  const handleClick = (event) => {
    console.log(event, "event");
    event.preventDefault(); // Prevent the default behavior of the anchor link
    const targetId = event.currentTarget.getAttribute("href").slice(1); // Extract target ID from href
    const targetElement = document.getElementById(targetId); // Find the target element
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" }); // Scroll to the target element
    }
  };
  //APK
  const [eventListApi] = useEventListMutation();
  // const [presetListApi]= usePresetPackListMutation();
  const [tagsListApi] = useLazyTagsfilterQuery();
  const [colorListApi] = useColorListMutation();

  const getTagsList = () => {
    tagsListApi()
      .unwrap()
      .then((res) => {
        setNormal(res?.normalTag);
        setEvent(res?.eventTag);
        setIcon(res?.iconTag);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getColorsList = () => {
    colorListApi()
      .unwrap()
      .then((res) => {
        setColorList(res?.colors);
        // console.log(res,"res")
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getTagsList();
    getColorsList();
  }, []);

  const getEvent = (colorId, event) => {
    const formData = new FormData();
    if (colorId) {
      formData.append("color_id", colorId);
    }
    if (event) {
      formData.append("search", event);
    }
    formData.append("start_price", values[0]);
    formData.append("end_price", values[1]);
    if (normalids?.length > 0) {
      normalids?.map((item, ind) => {
        formData.append(`tag_id[${ind}]`, item);
      });
    }
    priceSort == "high"
      ? formData.append("high_to_low", 1)
      : formData.append("low_to_high", 1);
    eventListApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res);
        setEventList(res?.event_categories);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvent();
  }, [values, normalids, priceSort]);

  const ColorDropdown = () => {
    setColor(!color);
  };
  const handleColorClick = (id) => {
    setColorId(id);
    setColor(!color);
  };
  // console.log("eventList", eventList);

  const tags = () => {
    let temp = [];
    if (normalid) {
      temp.push(normalid);
    }
    if (iconid) {
      temp.push(iconid);
    }
    if (eventid) {
      temp.push(eventid);
    }
    //  }else if(eventid){
    //  }else{
    //  }
    setNormalId(temp);
  };
  useEffect(() => {
    tags();
  }, [normalid, eventid, iconid]);

  const lowpriceHandel = () => {
    SetPriceSort("low");
    setShowFilter(!showFilter);
  };
  const highpriceHandel = () => {
    SetPriceSort("high");
    setShowFilter(!showFilter);
  };
  const HandledDrag = () => {
    setDrag(!drag);
  };
  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const refreshHandel = () => {
    SetPriceSort("low");
    setSearch("");
    setColorId("");
    setnormalid("");
    setEventid("");
    setIconid("");
    setValues([5, 1000]);
    getEvent("", "");
  };
  return (
    <div className="rit-prod pack-prod mt-0 text-center">
      <div
        className="bread-crumb d-flex ac-jc bg-lt-blue"
        style={{ marginLeft: "8%" }}
      >
        <Link className="primary1">Home</Link>
        <ArrowForwardIosIcon className="primary" />
        <Link className="primary">Preset Kit</Link>
      </div>
      <div className="w-lg-70 w-xl-50 mt-2 mt-lg-4 mx-auto mb-2">
        <h4 className="sect-title mb-2 mb-lg-1">Pre-set Kits</h4>
        <p className="blue fs-20 f3">How about this as an alternative?</p>
        <p className="parag disc text-center">
          Easy is not easy enough. We’ve made it easier! We’ve done all the
          ground-work to curate kits with extreme thought and care. Our preset
          kits are the perfect shortcut to all your gifting nightmares.
        </p>
      </div>
      <div className="d-flex gap-4 mt-4 mb-3 justify-content-between w-90 flex-wrap  m-auto">
        {/* <div> */}
        <div className="position-relative">
          <button
            className="btn bg-btn-blue white shad px-5"
            onClick={ColorDropdown}
          >
            {" "}
            <p className="f3 m-0 fs-lg-18"> Color</p>
          </button>
          {color && (
            <div className="popup d-flex flex-column as-jc">
              <h6>Select Color</h6>
              <div className="d-flex  gap-1">
                {colorList?.map((item, ind) => (
                  <button
                    style={{ background: `${item?.hex_code}` }}
                    className="img"
                    onClick={() => {
                      handleColorClick(item?.id);
                      getEvent(item?.id);
                    }}
                  >
                    {colorId == item?.id ? (
                      <DoneIcon className="text-white fs-25" />
                    ) : (
                      ""
                    )}
                    {/* <img src={ticker} alt="" className="img" /> */}
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        {/* </div> */}
        <div className="position-relative">
          <select
            className="btn btn-light shadow-box1 px-3 text-start f3 m-0 fs-lg-18"
            value={normalid}
            onChange={(e) => {
              setnormalid(e.target.value);
            }}
          >
            {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
            <option value={""} className="f3 m-0 fs-lg-18">
              <p> Normal Tags</p>
            </option>
            {normal?.map((item, ind) => (
              <option className="f3 m-0 fs-lg-18" value={item?.id}>
                <p>{item?.name}</p>
              </option>
            ))}
          </select>
        </div>
        <div className="position-relative">
          <select
            className="btn btn-light shadow-box1 px-3 text-start f3 m-0 fs-lg-18"
            value={eventid}
            onChange={(e) => {
              setEventid(e.target.value);
            }}
          >
            {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
            <option value={""} className="f3 m-0 fs-lg-18">
              <p> Event Tags</p>
            </option>
            {event?.map((item, ind) => (
              <option className="f3 m-0 fs-lg-18" value={item?.id}>
                <p>{item?.name}</p>
              </option>
            ))}
          </select>
        </div>
        <div className="position-relative">
          <select
            className="btn btn-light shadow-box1 px-3 text-start f3 m-0 fs-lg-18"
            value={iconid}
            onChange={(e) => {
              setIconid(e.target.value);
            }}
          >
            {/* <p className="f3 m-0 fs-lg-18">Event Tags</p> */}
            <option value={""} className="f3 m-0 fs-lg-18">
              <p> Icon Tags</p>
            </option>
            {icon?.map((item, ind) => (
              <option className="f3 m-0 fs-lg-18" value={item?.id}>
                <p>{item?.name}</p>
              </option>
            ))}
          </select>
        </div>
        <div className="position-relative">
          <button
            className="btn btn-light shadow-box2 px-3"
            onClick={HandledDrag}
          >
            <p className="f3 m-0 fs-lg-18">Budget</p>{" "}
          </button>
          <div className="mt-2">
            {drag && <RangeSet setValues={setValues} values={values} />}
          </div>
        </div>
      </div>
      <div className="d-sm-flex sm-ac-jb gap-2 w-90 m-auto mb-3">
        <div className="d-flex">
          <div className="border-5 position-relative me-1">
            <img src={Searcher} alt="" className="imgs" />
            <input
              type="text"
              className="inputcontainer  border-0"
              value={search}
              onChange={(event) => {
                getEvent("", event.target.value);
                setSearch(event.target.value);
              }}
              placeholder="Search by tags/Products"
            />
          </div>
          <button
            className="cust-btn addbtn1 d-flex ac-jc rounded-3 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 p-2 rounded"
            onClick={() => refreshHandel()}
          >
            <CachedIcon />
          </button>
        </div>
        <div className="w-100 d-flex align-center justify-content-sm-end position-relative my-2">
          <p className="filters mb-0 f3">Sort By :</p>
          <div className="d-flex ac-jc">
            <p className="filtertxt mb-0">Featured</p>
            <ArrowDropDown onClick={toggleFilter} />
          </div>

          {showFilter && (
            <div className="filter-options position-absolute ">
              <p>Price</p>
              <div>
                <p
                  onClick={() => {
                    lowpriceHandel();
                  }}
                  className={`${priceSort == "low" ? "active" : ""}`}
                >
                  Low to High
                </p>
                <p
                  onClick={() => {
                    highpriceHandel();
                  }}
                  className={`${priceSort == "high" ? "active" : ""}`}
                >
                  High to Low
                </p>
              </div>
            </div>
          )}
        </div>
      </div>{" "}
      <div className="d-flex w-80 ac-jc m-auto  mt-5">
        <button className="iconbg" onClick={() => carouselRef.current.prev()}>
          <ArrowBackIosIcon className="mb-3 mb-md-0 primary f1 fs-15 fs-md-16 fs-lg-17 fs-xl-18" />
        </button>
        <OwlCarousel ref={carouselRef} {...row3} className="OwlCarousel_cust">
          {eventList?.map((item, ind) => {
            return (
              <>
                <div
                  // href={`#${item?.id}`}
                  // onClick={handleClick}
                  onClick={() => {
                    if (item?.presetPack?.length > 0) {
                      containerRef?.current[ind].focus();
                    }
                  }}
                  className="px-3 text-dark"
                  key={ind}
                  style={{ width: "150.857px" }}
                >
                  <button className="bg-transparent border-0 slisd">
                    <img
                      src={item?.image_url ? item?.image_url : noLogo}
                      className=""
                      alt="icon"
                    />
                  </button>
                  <p className="mb-0 text-nowrap mt-2">{item?.name}</p>
                </div>
              </>
            );
          })}
        </OwlCarousel>
        <button className="iconbg" onClick={() => carouselRef.current.next()}>
          <ArrowForwardIosIcon className="mb-3 mb-md-0 primary f1 fs-15 fs-md-16 fs-lg-17 fs-xl-18" />
        </button>
      </div>
      <PresetListTabcomp
        type="product"
        onCLickhandler={showPopupHander}
        eventList={eventList}
        containerRef={containerRef}
      />
    </div>
  );
};

export default RightProducts;
