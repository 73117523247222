// @ts-nocheck
import React from "react";
import {
  cardboardboxIcon,
  clinet1,
  colorPanelIcon,
  dashboardIcon,
  globeShipIcon,
  redBagIcon,
  securityIcon,
  serv1,
  serviceBack,
} from "../../assets/img";

const HomeServices = () => {
  return (
    <div className="our-services w-70 m-auto" style={{ marginTop: "100px" }}>
      <h4 className="sect-title text-center mb-4 mb-sm-5">
        What we bring to the table
      </h4>
      <div className="box-merch d-flex flex-wrap w-100">
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center text-center ps-md-4">
            <img className="" src={colorPanelIcon} alt="icon" />
            <h5>Design with us</h5>
            <p>
              Create your custom merch designs with the help of our tech
              expertise.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center text-center ps-md-4">
            <img className="" src={cardboardboxIcon} alt="icon" />
            <h5>Store with us</h5>
            <p>
              No more storage boxes. We will store your merch until you need it.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center text-center ps-md-4">
            <img className="" src={globeShipIcon} alt="icon" />
            <h5>Ship globally</h5>
            <p>
              Ship on demand. Just simply give us the details, and we’ll do the
              rest.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center text-center ps-md-4">
            <img className="" src={dashboardIcon} alt="icon" />
            <h5>A dashboard created just for you</h5>
            <p>
              Your dashboard gives you a view into everything MerchHQ, sit back
              & track.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center text-center ps-md-4">
            <img className="" src={securityIcon} alt="icon" />
            <h5>Integrations and Privacy</h5>
            <p>
              Our various integrations and security enhance your merch
              experience.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center text-center ps-md-4">
            <img className="" src={redBagIcon} alt="icon" />
            <h5>Create your brand store</h5>
            <p>
              Build your brand store with MerchHQ so all your people can access
              your merch.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeServices;
