// @ts-nocheck
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { downArrow, searchIcon } from "../../assets/img";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useProductrequestListMutation, useRequestListMutation } from "../../redux/api/api";
import React, { useEffect, useState } from "react";
import ProductList from "../../component/myProduct/ProductList";

  const ProductFilter = ({
    showPopupHander,
  addProductHandler,
  tick,
  showCartHandler,
    btnChange
  }) =>{
    console.log(btnChange,"btnChange")
  const [listApi]=useRequestListMutation();
  const [productApi]=useProductrequestListMutation();
  const [search,setSearch]=useState("")
  const [order,setOrder]=useState("")
  const [rows,setRows]=useState(10)

  const [userList, setUserList] = useState([]);

  const getProducttaskList = (
    searchs,
    rowss,
    orders,
    
  ) => {
    let formdata = new FormData();
    // let params = `?page=${page}`;

 
      formdata.append("rows", 10);
    
    if (searchs) {
      formdata.append("search", searchs);
    }

    if (orders) {
      formdata.append("order", orders);
    }
    setSearch(searchs);
    setOrder(orders);
    setRows(rows);
    const apiCall = btnChange === 2 ? productApi : listApi;

    apiCall(formdata)
      .unwrap()
      .then((res) => {
        setUserList(res?.lists);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getProducttaskList(search, rows, order);
  }, [btnChange, rows, search, order]);
  return (
    <div className="my-product w-100 w-md-95 w-xl-85 w-xxl-80 mb-5">
      <div className="filter-product d-flex flex-column flex-md-row ac-jb mt-3 mt-lg-4 px-2 px-lg-3">
        <div className="d-flex ac-js mb-3 mb-md-0 flex-wrap">
          <div className="position-relative">
            <FilterAltIcon className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 text-dark" />
          </div>
          <div className="position-relative d-flex ac-jc ">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}>
              Status
            </p>
            <div className="dropdownbox m-3">
              <select className="rm-select-arrow cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0 text-center">
                <option value="Enquiry Received">Enquiry Received</option>
                <option value="In Process">In Process</option>
                <option value="Present Pending">Present Pending</option>
                <option value="Present Approval">Present Approval</option>
                <option value="Present Rejection">Present Rejection</option>
                <option value="Design Ready">Design Ready</option>
                <option value="Design Approved">Design Approved</option>
                <option value="Design Rework">Design Rework</option>
              </select>
              <img
                className="arrow-icon"
                style={{ left: "100%" }}
                alt="icon"
                src={downArrow}
              />
            </div>
          </div>

          <div className="position-relative d-flex ac-jc">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}>
              Category
            </p>
            <div className="dropdownbox">
              <select className="rm-select-arrow cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
                <option value="all">All</option>
                <option value="Pack">Pack</option>
                <option value="Products">Products</option>
              </select>
              <img className="arrow-icon" alt="icon" src={downArrow} />
            </div>
          </div>
        </div>
        <div className="d-flex ac-js mb-0  flex-column flex-sm-row">
          <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
            <img src={searchIcon} alt="" />
            <input
              type="text"
              className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
              placeholder="Search Products"
              name=""
              id=""
              onChange={(e) =>
                getProducttaskList(
                  e.target.value,
                  order,
                  
                )
              }
              value={search}
            />
          </div>

          <div className="position-relative d-flex ac-jc">
            <p
              className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}>
              Sort By
            </p>
            <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
              <option value="Newest">Newest</option>
              <option value="Oldest">Oldest</option>
              {/* <option value=" A-Z"> A-Z</option>
              <option value=" Z-A"> Z-A</option> */}
            </select>
          </div>
        </div>
      </div>
      <ProductList
       setSearch={setSearch}
       setOrder={setOrder}
       setRows={setRows}
       rows={rows}
       search={search}
       order={order}
       setUserList={setUserList}
       userList={userList}
      />
     
    </div>
    
  );
};

export default ProductFilter;
