import React, { useState } from 'react'
import { closeIcon, file, noteIcon, product, redeem_animated } from '../../../assets/img'
import { Col } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import { PlusOneOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import ProductPopup from './ProductPopup';

const FormFour = ({ F3, F5 }) => {
    const [productShow, setProductShow] = useState(false)
    const [popupShow, setPopupShow] = useState(false)

    const ProductHanler = () => {
        setProductShow(true)
    }

    const PopupHanler = () => {
        setPopupShow(!popupShow)
    }
    return (
        <div className=' align-custom1 d-flex flex-column mt-3'
            style={{
                // height: '100vh',
                alignItems: 'end'
            }
            }>
            {popupShow && <ProductPopup ProductHanler={ProductHanler} PopupHanler={PopupHanler} />}
            <div className="box1 w-85">
                <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Product Question*
                </p>
                <input placeholder="What product do you prefer?"
                    className="f2  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120"
                    value="What product do you prefer?"
                />
            </div>
            <div className="box w-85 mt-4">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Select your product to be about-shipped (limit to 4)
                </p>
            </div>
            <div className="box1 w-85">
                <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Limits
                </p>
                <input placeholder="eg.4"
                    className="f2  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120 w-50"
                    // value=""
                    type='number'
                />
            </div>
            <div className="d-flex gap-3
             flex-wrap w-85 mt-4">
                {productShow && <button style={{
                    height: '100px',
                    width: '100px',
                    background: '#E2ECFF'
                }} className='ac-jc d-flex border-0 rounded-3'>
                    <img src={product} className='h-75 w-75 object-fit-contain' />
                    {/* <AddIcon className='f5 primary1 fs-2 mt-0' /> */}
                </button>}
                {productShow && <button style={{
                    height: '100px',
                    width: '100px',
                    background: '#E2ECFF'
                }} className='ac-jc d-flex border-0 rounded-3'>
                    <img src={product} className='h-75 w-75 object-fit-contain' />
                </button>}
                <button style={{
                    height: '100px',
                    width: '100px',
                    background: '#E2ECFF'
                }} className='ac-jc d-flex border-0 rounded-3'
                    onClick={PopupHanler}>
                    <img src={closeIcon} className='h-20 w-20 object-fit-contain' style={{ transform: ' rotate(45deg)' }} />
                </button>

            </div>
            <div className='d-flex align-items-end w-85 mt-5'
                style={{ height: '40vh', overflow: 'scroll', scrollBehavior: 'smooth' }}
            >
                <div className='d-flex align-items-end w-100 mt-5 ac-jb'>
                    <button className='cust-btn text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '
                        onClick={F3}>
                        <ArrowBackIcon
                            className='text-center mb-0 f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-14 fs-xs-15' /> Previous Step</button>
                    <button className='get-start-btn border-0 mt-0'
                        onClick={F5}
                    >Continue</button>
                </div>
            </div>
        </div>
    )
}

export default FormFour