// @ts-nocheck
import React from "react";
import { work1, work2, work3 } from "../../../assets/img";

const HowItWork = () => {
  return (
    <div className="how-work d-flex flex-wrap w-95 w-md-80 w-lg-90 w-xxl-75 d-flex text-center mx-auto my-3 my-md-4 my-xl-5 ">
      <h4 className="sect-title text-center w-100">How it work</h4>
      <div className="d-flex flex-column flex-sm-row ac-jc m-auto">
        <div className="d-flex flex-column justify-content-center p-3 text-center position-relative">
          <h5 className="number-btn fs-13 fs-lg-15 fs-xl-16 ">01</h5>
          <div className="">
            <img src={work1} className="work1" alt="" />
          </div>
          <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20  text-dark mb-1 mt-2 mt-lg-3">
            Select A Membership
          </h6>
          <p className="parag">Selection an option that works for you!</p>
        </div>
        <div className="d-flex flex-column justify-content-center p-3 text-center position-relative">
          <h5 className="number-btn fs-13 fs-lg-15 fs-xl-16 ">02</h5>
          <div className="">
            <img src={work2} className="work1" alt="" />
          </div>

          <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20  text-dark mb-1 mt-2 mt-lg-3">
            Pick Product you want
          </h6>
          <p className="parag">Selection an option that works for you!</p>
        </div>
        <div className="d-flex flex-column justify-content-center p-3 text-center position-relative">
          <h5 className="number-btn fs-13 fs-lg-15 fs-xl-16 ">03</h5>
          <div className="">
            <img src={work3} className="work1" alt="" />
          </div>

          <h6 className="f4 fs-13 fs-md-15 fs-lg-19 fs-xl-20  text-dark mb-1 mt-2 mt-lg-3">
            Earn Reward & Perks
          </h6>
          <p className="parag">Selection an option that works for you!</p>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
