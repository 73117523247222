import React from "react";

const RedeemInput = ({
  title,
  placeholder,
  validation,
  value,
  type,
  onChange,
}) => {
  return (
    <div className="wi-100 w-md-100 mb-4">
      <p>{title}</p>
      <input
        type={type}
        placeholder={placeholder}
        className="py-2"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export default RedeemInput;
