import React from "react";
import ProfileLeftTab from "../component/Profile/ProfileLeftTab";
import { checkIcon, rocketIcon } from "../assets/img";

const AccountMembership = () => {
  return (
    <div className="product-screen d-flex flex-column flex-md-row">
      <ProfileLeftTab />
      <div className="profile-screen">
        <h4 className="sect-title mb-2 mb-lg-1">Membership</h4>

        <div className="membership">
          <div className="plan">
            <img alt="rocket" src={rocketIcon} />
            <div>
              <p>Basic</p>
              <p>Free Plan</p>
            </div>
          </div>
          <div className="upgrade">
            <p>Upgrade Now</p>
          </div>
        </div>

        <div className="benefits">
          <p>Benefits</p>
          <div className="points-wrap">
            <div className="single-point">
              <div>
                <img alt="icon" src={checkIcon} />
                <p>Reward Points Rate</p>
              </div>
              <p>1x</p>
            </div>
            <div className="single-point">
              <div>
                <img alt="icon" src={checkIcon} />
                <p>Product Discount</p>
              </div>
              <p>0%</p>
            </div>
            <div className="single-point">
              <div>
                <img alt="icon" src={checkIcon} />
                <p>Rush Production Discount</p>
              </div>
              <p>0%</p>
            </div>
            <div className="single-point">
              <div>
                <img alt="icon" src={checkIcon} />
                <p>Shipping Discount</p>
              </div>
              <p>0%</p>
            </div>
            <div className="single-point">
              <div>
                <img alt="icon" src={checkIcon} />
                <p>Storage Discount</p>
              </div>
              <p>0%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountMembership;
