import React from 'react'
import ProfileLeftTab from '../component/Profile/ProfileLeftTab'

const InvoicesScreen = () => {
    return (
        <div className="product-screen d-flex flex-column flex-md-row">
            <ProfileLeftTab />
            InvoicesScreen
        </div>
    )
}

export default InvoicesScreen