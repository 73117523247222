import React from 'react'
import { file, noteIcon, redeem_animated } from '../../../assets/img'
import { Col } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const FormFive = ({ F4, F6 }) => {
    const navigation = useNavigate()
    return (
        <div className=' align-custom1 d-flex flex-column mt-3'
            style={{
                // height: '100%',
                alignItems: 'end'
            }
            }>
            <div className="box1 w-85">
                <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Heading Text*
                </p>
                <input placeholder="Congratulations" className="f2  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" value='Congratulations' />
            </div>

            <div className="box1 w-85 mt-3">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Subtitle Text*
                </p>
                <input placeholder="Your’re on the short list of future recipients"
                    value="Your’re on the short list of future recipients"
                    className="f2  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
            </div>

            <div className="w-85 mt-3">
                <p className="f4 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 mt-0 mb-3">
                    Subtitle Text*
                </p>
                <div className='d-flex ac-jb w-100 mb-3'>
                    <p className="f2 fs-xl-12 primary1 fs-lg-10 fs-md-13 fs-sm-12 fs-xs-11 mt-0">
                        Do you want recipients to se the Product they chose/are receiving?
                    </p>
                    <div class="form-check form-switch">
                        <input class="form-check-input text-danger" type="checkbox" role="switch" checked />
                    </div>
                </div>
            </div>

            <div className='d-flex align-items-end w-85 mt-5'
            >
                <div className='d-flex ac-jb w-100' >
                    <button className='cust-btn text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'
                        onClick={F4}>
                        <ArrowBackIcon
                            className='text-center mb-0 f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-14 fs-xs-15' /> Previous Step</button>
                    <button className='get-start-btn border-0 mt-0'
                        onClick={F6}
                    >Continue</button>
                </div>
            </div>
        </div>
    )
}

export default FormFive