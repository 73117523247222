import React from "react";
import ProfileLeftTab from "../component/Profile/ProfileLeftTab";
import { noteIcon } from "../assets/img";

const TransactionScreen = () => {
  return (
    <div className="product-screen d-flex flex-column flex-md-row">
      <ProfileLeftTab />
      <div className="profile-screen">
        <h4 className="sect-title mb-2 mb-lg-1">Transaction</h4>

        <div className="no-orders">
          <img alt="icon" src={noteIcon} />
          <p>You’ve placed no Swag orders recently.</p>
        </div>
      </div>
    </div>
  );
};

export default TransactionScreen;
