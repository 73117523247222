import React, { useEffect, useRef, useState } from "react";
import { closeIcon, head, logos, product, tshirt1 } from "../assets/img";
import UnsubscribeIcon from "@mui/icons-material/Unsubscribe";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useLazyEstimateDetailQuery } from "../redux/api/api";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import ImageGallery from "react-image-gallery";

const EstimationPageA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [serachParams, setSearchParams] = useSearchParams();
  const [presentionlist, setPresentationList] = useState([]);
  const [billing, setBilling] = useState({});
  const [artwork, setArtwork] = useState([]);
  const [artworkdetails, setArtworkdetails] = useState([]);
  const [userPopup, setUserPopup] = useState(false);
  const [image, setImages] = useState([]);
  const [gstFinal, setGstFinal] = useState("");
  const [gstTax, setGstTax] = useState("");
  const [discount, setDiscount] = useState("");
  const [totalFinal, setTotalFinal] = useState("");
  const [projectData, setProjectData] = useState({});
  const [discount_percent, setdiscount_percent] = useState("");

  const [tax_percentGst, settax_percentGst] = useState("");
  const [tax_percentGst1, settax_percentGst1] = useState("");
  const [mainShipAddress, setMainShipAddress] = useState({});
  const [gstType, setGSTType] = useState(0);
  const [discountType, setDiscountType] = useState(0);
  const [discount_overall, setSubtotal_overall] = useState("");
  const [subtotal, setSubtotal] = useState("");

  const handleUserPopup = () => {
    setUserPopup(!userPopup);
  };

  // RTK QUERY
  const [estimateViewApi] = useLazyEstimateDetailQuery();

  const getView = () => {
    estimateViewApi(
      location?.state?.data?.id || serachParams?.get("project_id")
    )
      .unwrap()
      .then((res) => {
        if (res?.status == "success" || res?.data?.status == "success") {
          const projectData = res?.project || {};
          const { billing_address, project, shipping_address } = res;
          setBilling({
            ...billing_address,
            project,
            shipping_address: shipping_address,
          });
          setProjectData(projectData);
          let project_details_with_sizes = [];

          res?.project_details_with_sizes?.map((item, ind) => {
            project_details_with_sizes.push(item);
          });

          let projectPresentationOrder = [];

          res?.project?.projectPresentationOrder?.map((item, ind) => {
            let obj = {
              ...item,
            };

            let finder = project_details_with_sizes?.find(
              (i) => i?.detail?.id == item?.project_detail_id?.id
            );

            if (finder?.detail?.id) {
              obj.project_detail = finder;
            } else {
              obj.project_detail = null;
            }

            projectPresentationOrder.push(obj);
          });

          projectPresentationOrder.sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          });

          // console.log("projectPresentationOrder", projectPresentationOrder);

          setPresentationList(projectPresentationOrder);
          extra_charges_cus(projectPresentationOrder);
          if (projectData?.tax_type == 2) {
            if (projectData?.tax_percent) {
              if (projectData?.tax1_percent) {
                settax_percentGst1(projectData?.tax1_percent);
              }
              settax_percentGst(projectData?.tax_percent);
              setGstTax(projectData?.tax_percent);
              setDiscount(projectData?.discount_amount);
              setdiscount_percent(projectData?.discount_percent);
            } else {
              // getTax(data);
            }
            let temp = projectData?.projectDetail?.reduce(
              (n, { retail_price }) => n + retail_price,
              0
            );

            setTotalFinal(projectData?.total_amount);
            setSubtotal(Number(temp).toFixed(2));
            setGstFinal(projectData?.tax_total_amount);
          } else if (projectData?.tax_type == 1) {
            let temp = projectData?.projectDetail?.reduce(
              (n, { retail_price }) => n + retail_price,
              0
            );
            setSubtotal(Number(temp).toFixed(2));
            setGstTax(projectData?.tax_percent + projectData?.tax1_percent);
            setGstFinal(projectData?.tax_total_amount);
            setTotalFinal(projectData?.total_amount);
            setDiscount(projectData?.discount_amount);
            setdiscount_percent(projectData?.discount_percent);
          }
          if (projectData?.discount_type) {
            setDiscountType(projectData?.discount_type);
          }
          let discountAmount = projectData?.projectDetail?.reduce(
            (n, { discount_amount }) => n + +discount_amount,
            0
          );
          if (discountAmount) {
            setSubtotal_overall(discountAmount);
          }

          const projectDetails = res?.project_details_with_sizes || [];
          const projectdetail = res?.project?.projectDetail;
          const allArtworks = projectdetail?.map(
            (detail) => detail.artworks || []
          );
          const detailsArray = projectDetails.map((item) => item.detail);
          setArtwork(detailsArray);
          const art = detailsArray.map((item) => item.project_artworks);
          setArtworkdetails(art);
          const imagess = detailsArray.map((item) => item.product);
          setImages(imagess);
        }
      })
      .catch((err) => {
        console.log("Error fetching project details:", err);
      });
  };

  useEffect(() => {
    if (location?.state?.data?.id || serachParams?.get("project_id")) {
      getView();
    }
  }, []);
  useEffect(() => {
    if (location?.state?.data?.id) {
      getView();
    }
  }, []);

  const extra_charges_cus = (project_detail) => {
    if (project_detail?.length > 0) {
      let extra_charges_arr = [];
      project_detail?.map((item, ind) => {
        let temp = [];
        let include_price = item?.project_detail_id?.extra_charges?.filter(
          (i) => +i?.client_price == 1
        );
        let display_to_client = item?.project_detail_id?.extra_charges?.filter(
          (i) => +i?.client_price == 2
        );

        let extra_obj = {};
        include_price?.forEach((price) => {
          if (extra_obj[price.client_price]) {
            extra_obj[price.client_price].final_amount += +price.final_amount;
            extra_obj[price.client_price].net_price += +price.net_price;
            extra_obj[price.client_price].margin += +price.margin;
          } else {
            extra_obj[price.client_price] = {
              name: price?.name,
              client_price: price?.client_price,
              margin: +price?.margin,
              net_price: +price?.net_price,
              final_amount: +price?.final_amount,
            };
          }
        });

        let discount_amount =
          +item?.project_detail_id?.discount_type == 1
            ? +item?.project_detail_id?.discount_amount
            : 0;

        // FOR INCLUDE PRICE
        if (Object.values(extra_obj)?.length > 0) {
          Object.values(extra_obj).map((price, Pind) => {
            let discount_percent = 0;

            if (item?.project_detail_id?.discount_percent) {
              discount_percent =
                (+item?.project_detail_id?.net_price *
                  item?.project_detail_id?.discount_percent) /
                100;
            }

            let discount_value = +item?.project_detail_id?.net_price;
            // let discount_value = +item?.project_detail_id?.net_price - discount_percent;

            let net_amount = +discount_value - discount_amount;

            let final = +net_amount + +price?.final_amount;
            let net_price = +net_amount + +price?.net_price;
            let net_price_ =
              item?.project_detail_id?.net_price /
              +item?.project_detail_id?.qty;

            let tax =
              item?.project_detail_id?.tax_percent +
              item?.project_detail_id?.tax1_percent;
            let tax_value = (+final * tax) / 100;
            let tax_amount = +final + +tax_value;
            let obj = { ...price };
            obj.margin = +price?.margin;

            obj.net_price = net_price_ + price?.net_price;

            obj.final_amount = +discount_value + +price?.final_amount;
            obj.discount_amount = +discount_amount;
            obj.tax = tax;
            obj.tax_value = tax_value;
            obj.tax_amount = tax_amount;

            temp.push(obj);
          });
        } else if (
          item?.project_detail_id?.extra_charges?.length > 0 &&
          Object.values(extra_obj).length == 0
        ) {
          let discount_percent = 0;

          if (item?.project_detail_id?.discount_percent) {
            discount_percent =
              (+item?.project_detail_id?.net_price *
                item?.project_detail_id?.discount_percent) /
              100;
          }

          let discount_value =
            +item?.project_detail_id?.net_price - discount_percent;

          // let net_amount = +discount_value;
          let net_amount = +item?.project_detail_id?.net_price;

          let final = +net_amount - discount_amount;
          let tax =
            item?.project_detail_id?.tax_percent +
            item?.project_detail_id?.tax1_percent;
          let tax_value = (+final * tax) / 100;
          let tax_amount = +final + +tax_value;
          let obj = {};
          obj.margin = +item?.project_detail_id?.margin_percent;
          // obj.net_price = +item?.project_detail_id?.net_price;
          obj.net_price =
            +item?.project_detail_id?.net_price / +item?.project_detail_id?.qty;
          obj.final_amount = +net_amount;
          obj.discount_amount = +discount_amount;
          obj.tax = tax;
          obj.tax_value = tax_value;
          obj.tax_amount = tax_amount;

          temp.push(obj);
        }

        // FOR DISPLAY TO CLIENT
        display_to_client?.forEach((price) => {
          let tax =
            item?.project_detail_id?.tax_percent +
            item?.project_detail_id?.tax1_percent;
          let tax_value = (+price?.final_amount * tax) / 100;
          let tax_amount = +price?.final_amount + +tax_value;
          let obj = { ...price };
          obj.margin = +price?.margin;
          obj.net_price = +price?.net_price;
          obj.final_amount = +price?.final_amount;
          obj.tax = tax;
          obj.tax_value = tax_value;
          obj.tax_amount = tax_amount;

          temp.push(obj);
        });

        let prject_img_url = "";

        if (item?.project_detail_id?.product_sku?.color_id) {
          let finder = item?.project_detail_id?.product?.colorImages?.find(
            (i) => i?.color_id == item?.project_detail_id?.product_sku?.color_id
          );
          prject_img_url = finder?.image_url;
        } else {
          prject_img_url = item?.project_detail_id?.product?.defaultImage;
        }

        let extra_charges_obj = {
          ...item,
          prject_img_url: prject_img_url,
        };

        let project_detail_id = {
          ...item?.project_detail_id,
        };

        if (temp?.length > 0) {
          project_detail_id.extra_charges = temp;
        } else {
          project_detail_id.extra_charges = temp;

          if (+item?.project_detail_id?.discount_type == 1) {
            let discount_amount_ = item?.project_detail_id?.discount_amount
              ? +item?.project_detail_id?.discount_amount
              : 0;

            let net_price_ = +item?.project_detail_id?.net_price;

            let tax_ =
              +item?.project_detail_id?.tax1_percent +
              +item?.project_detail_id?.tax_percent;

            let minus_price_ = net_price_ - discount_amount_;

            let tax_value_ = (minus_price_ * tax_) / 100;

            let tax_price_ = tax_value_ + minus_price_;

            // project_detail_id.net_amount = minus_price_;
            // project_detail_id.tax_amount = tax_value_;
            // project_detail_id.final_amount = tax_price_
          }
        }

        extra_charges_obj.project_detail_id = project_detail_id;

        extra_charges_arr.push(extra_charges_obj);
      });

      let filters = [];

      extra_charges_arr?.map((item, ind) => {
        if (item?.project_detail_id?.status == 1) {
          filters.push(item);
        }

        if (item?.project_detail_id?.status == 2) {
          filters.push(item);
        }

        if (item?.title) {
          let obj = {};
          obj.id = item?.id;
          obj.order = item?.order;
          obj.prject_img_url = null;
          obj.project_detail_id = null;
          obj.project_id = item?.project_id;
          obj.title = item?.title;
          filters.push(obj);
        }
      });

      filters.sort((a, b) => {
        if (a.order < b.order) return -1;
        if (a.order > b.order) return 1;
        return 0;
      });

      setPresentationList(filters);
    }
  };

  const totalSum = (sub_total, project_data) => {
    let amount = sub_total;

    if (project_data?.shipping_storage_amount) {
      amount += +project_data?.shipping_storage_amount;
    }

    if (project_data?.discount_type == 1) {
      let discountAmount = project_data?.projectDetail?.reduce(
        (n, { discount_amount }) => n + +discount_amount,
        0
      );

      amount = +amount - +discountAmount;
    }

    if (project_data?.discount_type == 2) {
      amount = +amount - +project_data?.discount_amount;
    }

    let price = Number(amount).toFixed(2);

    return price.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="bg-white" style={{ backgroundColor: "#fff" }}>
      {userPopup && (
        <div className="modal-popup">
          <div className="back-close" />
          <div
            style={{ width: "60%" }}
            className="center-content d-flex flex-column flex-lg-row p-0 m-0"
          >
            <button
              onClick={() => handleUserPopup()}
              className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
            >
              <img src={closeIcon} alt="" />
            </button>
            <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mb-5">
              <div className="w-100 d-flex ac-jc flex-column flex-md-row">
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-lg-2">
                    <label className="w-100 position-relative">
                      <div className="rej-img">
                        <img src={tshirt1} alt="No Image" />
                      </div>
                    </label>
                  </div>
                </div>
                <div className="w-xs-100 w-md-50 px-2">
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Location
                    </p>
                    <input
                      type="text"
                      placeholder="Specify the placement of your artwork"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                  <div className="ac-jb mt-3 mt-lg-4 pop-input">
                    <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                      Dimensions
                    </p>
                    <input
                      type="text"
                      placeholder="W 2.3 X H 4.5"
                      className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                    />
                  </div>
                </div>
              </div>
              <div className="ac-jb mt-3 mt-lg-4 pop-input w-100">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-2">
                  Additional Notes
                </p>
                <input
                  type="text"
                  placeholder="Notes"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                />
              </div>
              <button
                className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
                onClick={() => handleUserPopup()}
              >
                Continue
              </button>
            </div>
          </div>
        </div>
      )}
      <div className=" " style={{ width: "90%", margin: "auto" }}>
        <div className="d-flex flex-wrap">
          <div
            className="d-flex flex-wrap align-items-center mt-3 py-2  banner col-md-5"
            style={{ backgroundColor: "#ecfaff", borderRadius: "20px" }}
          >
            <div className="">
              <img
                alt="img"
                src={logos}
                className=" ps-3"
                style={{ width: "150px", objectFit: "cover" }}
              />
            </div>

            <div className="ps-2 ms-2 font-blue2">
              <h2 className="f4 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20">
                MerchHQ Pvt.Ltd.
              </h2>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-18">
                ESTIMATION #{billing?.project?.estimation_code} for{" "}
                {billing?.project?.project_request?.user?.first_name}
              </p>
              <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                Project {billing?.project?.project_code}
              </p>
            </div>
          </div>
          <div className="col-md-7 mt-3">
            <img alt="img" src={head} className="ps-lg-3 w-100" />
          </div>
        </div>
        <div
          className="d-flex flex-wrap  bg-primary2  w-100  mt-4  me-3 p-4"
          style={{
            // backgroundColor: "#ecfaff",
            borderRadius: "20px",
            lineHeight: "35px",
            flexWrap: "wrap",
          }}
        >
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                SHIPPPING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
                {billing?.shipping_address?.address1}
              </p>
            </div>
          </div>
          <div className="col-md-3 mt-sm-2 p-2">
            <div className="common">
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                BILLING ADDRESS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 w-80">
                {billing?.address1}
              </p>
            </div>
          </div>

          <div
            className="col-md-2 ps-4 mt-sm-2 p-2 left-line"
            style={{
              borderLeft: "1px",
              borderStyle: "solid",
              borderColor: "#cacaca",
              borderRight: "0",
              borderBottom: "0",
              borderTop: "0",
            }}
          >
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                PROJECT #
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                {billing?.project?.project_code}
              </p>
            </div>
            <div>
              <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                TERMS
              </p>
              <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                {billing?.project?.terms_condition}
              </p>
            </div>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              ESTIMATE #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.estimation_code}
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CUSTOMER PO #
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.customer_po}
            </p>
          </div>
          <div className="col-md-2 mt-sm-2 p-2">
            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
              IN HAND DATE
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              {billing?.project?.in_hand_date}
            </p>

            <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
              CURRENCY
            </p>
            <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
              INR
            </p>
          </div>
        </div>
        {presentionlist?.map((item, index) => {
          // if (item?.status === 2 || item?.status === 1) {
          // if (item?.pricing_status === 1) {
          // console.log(billing?.project?.projectTags, "projectdetail");
          const size = item?.project_detail?.product_skus_with_matched_sizes;
          const match = size?.map((item) => item?.matched_sizes);
          const detail = item?.project_detail?.detail?.project_artworks;
          let pricePerPack =
            +item?.project_detail_id?.net_price / +item?.project_detail_id?.qty;
          // let sub_total_return = item?.project_detail_id?.discount_amount
          //   ? item?.project_detail_id?.net_price -
          //     item?.project_detail_id?.discount_amount
          //   : item?.project_detail_id?.net_price;

          let sub_total_return = item?.project_detail_id?.net_price;

          if (item?.project_detail_id?.extra_charges?.length > 0) {
            item?.project_detail_id?.extra_charges?.map((items, inds) => {
              if (+items?.client_price == 1) {
                sub_total_return += +items?.final_amount;
              } else if (+items?.client_price == 2) {
                sub_total_return += +items?.final_amount;
              }
            });
          }
          return (
            <div>
              {item?.title ? (
                <div className=" d-flex mt-3">
                  <p className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2 px-3 py-2 bg-primary2">
                    {item?.title}
                  </p>
                </div>
              ) : (
                <div
                  className="d-flex flex-wrap  bg-primary2  w-100  mt-4  me-3 p-4"
                  style={{
                    // backgroundColor: "#ecfaff",
                    borderRadius: "20px",
                    lineHeight: "35px",
                    flexWrap: "wrap",
                    overflow: "scroll",
                  }}
                >
                  <div className="d-flex flex-wrap  align-items-start">
                    <div
                      className="col-md-6 mt-4 rounded bg-primary2"
                      style={{
                        // backgroundColor: "#ecfaff",
                        borderRadius: "32px",
                        padding: "12px",
                        textAlign: "center",
                      }}
                    >
                      {/* {item?.image_urls > 0 && ( */}

                      <ImageGallery
                        key={index}
                        items={(item?.project_detail?.image_urls || []).map(
                          (img) => ({
                            // items={item?.image_url.map((img) => ({
                            original: img,
                            thumbnail: img,
                          })
                        )}
                      />
                      {/* )} */}
                      {/* )})} */}
                    </div>
                    <div className="col-md-6 p-4">
                      <div className="w-100 ps-4 ">
                        <div style={{}}>
                          <h2 className="f3 fs-xs-11 fs-sm-12 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 font-blue2">
                            {item?.project_detail?.detail?.product?.name}
                          </h2>
                          <p className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                            {item?.project_detail?.detail?.product?.description}
                          </p>
                        </div>
                        <div class=" tab mt-4 overflow-scroll">
                          <table className=" ">
                            <thead>
                              <tr>
                                <th className="f3 text-center fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 ">
                                  ITEM
                                </th>
                                <th className="f3 text-center fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap mx-4 font-blue2">
                                  QTY
                                </th>
                                <th className="text-center f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                  PRICE
                                </th>
                                <th className="text-center f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                  Total Before Tax
                                </th>
                                {projectData?.discount_type == 1 && (
                                  <th className="text-center f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                    Discount
                                  </th>
                                )}
                                {projectData?.discount_type == 1 && (
                                  <th className="text-center f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                    After Discount
                                  </th>
                                )}
                                <th className=" text-center f3  fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                  HSN
                                </th>
                                <th className="f3 text-center fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                  Tax
                                </th>
                                <th className=" f3 text-center fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                  Tax Value
                                </th>
                                <th className="f3 text-center fs-xs-11 fs-sm-12 fs-md-13 fs-lg-15 fs-xl-16 fs-xxl-18 text-nowrap font-blue2 mx-4">
                                  Sub Total
                                </th>
                              </tr>
                            </thead>

                            <tbody style={{ lineHeight: "30px" }}>
                              {item?.project_detail_id?.extra_charges?.length >
                              0 ? (
                                item?.project_detail_id?.extra_charges?.map(
                                  (charge, eInd) => {
                                    return (
                                      <tr className="rowspan" key={eInd}>
                                        <td className="text-start mb-3 ">
                                          {+charge?.client_price == 2
                                            ? charge?.name
                                            : item?.project_detail_id
                                                ?.product_sku?.variant_sku}
                                        </td>

                                        <td className="text-start mb-3 px-4">
                                          {item?.project_detail_id?.qty
                                            ? item?.project_detail_id?.qty
                                            : ""}
                                        </td>
                                        <td className="text-start mb-3 px-4">
                                          {" "}
                                          {charge?.net_price
                                            ? "₹" +
                                              commaValue(
                                                Number(
                                                  charge?.net_price
                                                ).toFixed(2)
                                              )
                                            : ""}
                                        </td>
                                        <td className="text-start mb-3 px-4">
                                          {charge?.final_amount
                                            ? "₹" +
                                              commaValue(
                                                Number(
                                                  charge?.final_amount
                                                ).toFixed(2)
                                              )
                                            : ""}
                                        </td>
                                        {projectData?.discount_type == 1 && (
                                          <td className="text-start mb-3 px-3">
                                            {charge?.discount_amount
                                              ? "₹" +
                                                commaValue(
                                                  Number(
                                                    charge?.discount_amount
                                                  ).toFixed(2)
                                                )
                                              : ""}
                                          </td>
                                        )}
                                        {projectData?.discount_type == 1 && (
                                          <td className="text-start mb-3 px-3">
                                            {charge?.final_amount &
                                            charge?.discount_amount
                                              ? "₹" +
                                                commaValue(
                                                  Number(
                                                    +charge?.final_amount -
                                                      +charge?.discount_amount
                                                  ).toFixed(2)
                                                )
                                              : charge?.final_amount
                                              ? "₹" +
                                                commaValue(
                                                  Number(
                                                    +charge?.final_amount
                                                  ).toFixed(2)
                                                )
                                              : ""}
                                          </td>
                                        )}
                                        <td className="text-start mb-3 px-4">
                                          {item?.project_detail_id?.hsn_code
                                            ? item?.project_detail_id?.hsn_code
                                            : ""}
                                        </td>
                                        <td className="text-start mb-3 px-4">
                                          {charge?.tax ? charge?.tax + "%" : ""}
                                        </td>

                                        <td className="text-start mb-3 px-4">
                                          {charge?.tax_value
                                            ? "₹" +
                                              commaValue(
                                                Number(
                                                  charge?.tax_value
                                                ).toFixed(2)
                                              )
                                            : ""}
                                        </td>
                                        <td className="text-start mb-3 px-4">
                                          {charge?.tax_amount
                                            ? "₹" +
                                              commaValue(
                                                Number(
                                                  charge?.tax_amount
                                                ).toFixed(2)
                                              )
                                            : ""}
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr className="rowspan">
                                  <td className="text-start mb-3 ">
                                    {item?.project_detail_id?.product_sku
                                      ?.variant_sku &&
                                    item?.project_detail_id?.retail_price > 0
                                      ? item?.project_detail_id?.product_sku
                                          ?.variant_sku
                                      : ""}
                                  </td>
                                  <td className="text-start mb-3 px-4">
                                    {item?.project_detail_id?.qty &&
                                    item?.project_detail_id?.retail_price > 0
                                      ? item?.project_detail_id?.qty
                                      : ""}
                                  </td>
                                  <td className="text-start mb-3 px-4">
                                    {" "}
                                    {item?.project_detail_id?.retail_price &&
                                    item?.project_detail_id?.retail_price > 0
                                      ? "₹" + commaValue(pricePerPack)
                                      : ""}
                                  </td>
                                  <td className="text-start mb-3 px-4">
                                    {" "}
                                    {item?.project_detail_id?.retail_price &&
                                    item?.project_detail_id?.retail_price > 0
                                      ? "₹" +
                                        commaValue(
                                          Number(sub_total_return).toFixed(2)
                                        )
                                      : ""}
                                  </td>
                                  {projectData?.discount_type == 1 && (
                                    <td className="text-start mb-3 px-3">
                                      {" "}
                                      {item?.project_detail_id
                                        ?.discount_amount &&
                                      item?.project_detail_id?.discount_amount >
                                        0
                                        ? "₹" +
                                          commaValue(
                                            Number(
                                              item?.project_detail_id
                                                ?.discount_amount
                                            ).toFixed(2)
                                          )
                                        : ""}
                                    </td>
                                  )}
                                  {projectData?.discount_type == 1 && (
                                    <td className="text-start mb-3 px-3">
                                      {" "}
                                      {item?.project_detail_id
                                        ?.discount_amount &&
                                      item?.project_detail_id?.discount_amount >
                                        0
                                        ? "₹" +
                                          commaValue(
                                            Number(
                                              +sub_total_return -
                                                +item?.project_detail_id
                                                  ?.discount_amount
                                            ).toFixed(2)
                                          )
                                        : ""}
                                    </td>
                                  )}
                                  <td className="text-start mb-3 px-4">
                                    {item?.project_detail_id?.hsn_code
                                      ? item?.project_detail_id?.hsn_code
                                      : ""}
                                  </td>
                                  <td className="text-start mb-3 px-4">
                                    {item?.project_detail_id?.tax_percent &&
                                    item?.project_detail_id?.retail_price > 0
                                      ? item?.project_detail_id?.tax_percent +
                                        item?.project_detail_id?.tax1_percent +
                                        "%"
                                      : ""}
                                  </td>
                                  <td className="text-start mb-3 px-4">
                                    {item?.project_detail_id?.tax_amount
                                      ? "₹" +
                                        commaValue(
                                          item?.project_detail_id?.tax_amount
                                        )
                                      : ""}
                                  </td>
                                  <td className="text-start mb-3 px-4">
                                    {" "}
                                    {item?.project_detail_id?.final_amount &&
                                    item?.project_detail_id?.retail_price > 0
                                      ? "₹" +
                                        commaValue(
                                          item?.project_detail_id?.final_amount
                                        )
                                      : ""}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="mt-3">
                        <div className="d-flex flex-wrap ">
                          <div className="col-md-4">
                            <p className="f2 fs-xs-11 fs-sm-14 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 font-blue2">
                              Colors:
                            </p>
                            <div className="d-flex">
                              <div className="lt-Blue">
                                <FiberManualRecordIcon
                                  style={{
                                    color:
                                      item?.project_detail?.color?.hex_code,
                                  }}
                                />
                              </div>
                              <p>{item?.project_detail?.color?.name}</p>
                            </div>
                          </div>
                        </div>
                        {/* {tab == 1 && ( */}

                        {/* )} */}
                        {/* <div className="w-100">
                <textarea
                  className="editBtn bg-primary2 rounded border-0 w-100 mt-2 p-2 w-90 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16"
                  style={{ height: "100px" }}
                  placeholder="Comment on this item..."
                ></textarea>
              </div>
              <div className="d-flex ac-jb">
                <button className="my-3 bg-primar border-0 p-2 rounded f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white px-4">
                  Add Comment <PlaylistAddCheckIcon />
                </button>

              </div> */}
                      </div>
                    </div>
                    {detail?.map((item, index) => {
                      // console.log(item,"artworkdetails")
                      return (
                        <div className="col-md-5">
                          <div className="d-flex flex-wrap w-100 ac-jb mb-4">
                            {/* <div className="bg-primary2  rounded p-3 mt-3 w-50"> */}
                            {/* <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                  TYPE : 1
                </p> */}
                            {/* </div> */}

                            <div>
                              {/* {item && item.map((artwork, artworkIndex) => ( */}
                              <table
                                className="ms-3 mt-2 w-100"
                                style={{ lineHeight: "30px" }}
                              >
                                <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 font-blue2">
                                  ARTWORK DETAILS
                                </p>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN NAME
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_name
                                        ? item?.logo_name
                                        : "N/A"}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      IMPRINT TYPE
                                    </p>
                                  </td>

                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.decorative_method?.name}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN LOCATION
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.uom === "1"
                                        ? "Inches"
                                        : item?.uom === "2"
                                        ? "Stitch"
                                        : "N/A"}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN SIZE
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_length}*{item?.logo_breadth}
                                    </p>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <p className="f3 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16">
                                      DESIGN COLOR
                                    </p>
                                  </td>
                                  <td>
                                    <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 me-3">
                                      {item?.logo_color}
                                    </p>
                                  </td>
                                </tr>
                              </table>
                              {/* ))} */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div class="col-md-5 ms-auto ">
        <div className="d-flex ac-jc w-100 ms-5 mt-2">
          <div className="w-100">
            {discountType == 1 && <p className="mt-3 mb-3">Discount Amount</p>}
            <p className="mt-3 mb-3">Subtotal</p>
            {discountType == 2 && (
              <p className="mt-3 mb-3">Discount % {discount_percent}</p>
            )}
            {discountType == 2 && <p className="mt-3 mb-3">Overall Subtotal</p>}
            <p className="mt-3 mb-3">Shipping Amount</p>
            <p className="mt-3 mb-3">Total</p>
            <p className="mt-3 mb-3">Products GST</p>
            <p className="mt-3 mb-3">Shipping GST</p>
            <p className="mt-3 mb-3">Total GST</p>
            <p className="mt-3 mb-3">Final Amount</p>
          </div>
          <div className="w-100">
            {discountType == 1 && (
              <p className="mt-3 mb-3">
                {discount_overall ? "₹" + commaValue(discount_overall) : "0"}
              </p>
            )}
            <p className="mt-3 mb-3">
              {subtotal ? "₹" + commaValue(subtotal) : "0"}
            </p>
            {discountType == 2 && (
              <p className="mt-3 mb-3">
                {discount ? "₹" + commaValue(Number(discount).toFixed(2)) : "0"}
              </p>
            )}

            {discountType == 2 && (
              <p className="mt-3 mb-3">
                {subtotal
                  ? "₹" + commaValue(Number(+subtotal - +discount).toFixed(2))
                  : "0"}
              </p>
            )}
            <p className="mt-3 mb-3">
              {projectData?.shipping_storage_amount
                ? "₹" + commaValue(projectData?.shipping_storage_amount)
                : "0"}
            </p>
            <p className="">₹{totalSum(+subtotal, projectData)}</p>
            <p className="mt-3 mb-3">
              {gstFinal ? "₹" + commaValue(Number(+gstFinal).toFixed(2)) : "0"}
            </p>
            <p className="mt-3 mb-3">
              {projectData?.shipping_storage_tax
                ? `₹${commaValue(
                    Number(+projectData?.shipping_storage_tax).toFixed(2)
                  )}`
                : "0"}
            </p>
            <p className="mt-3 mb-3">
              {gstFinal && projectData?.shipping_storage_tax >= 0
                ? `₹${commaValue(
                    Number(
                      +gstFinal + +projectData?.shipping_storage_tax
                    ).toFixed(2)
                  )}`
                : "0"}
            </p>
            <p className="mt-3 mb-3">
              {totalFinal ? "₹" + commaValue(totalFinal) : "0"}
            </p>
          </div>
        </div>
      </div>
      <div className="">
        <div
          className="mt-3  px-2"
          style={{
            backgroundColor: "#07679c",
            padding: "20px",
            lineHeight: "30px",
          }}
        >
          <div className="w-90 m-auto d-flex flex-wrap ac-jb">
            <div className="">
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                Questions About this estimate?
              </p>
              <h2 className="f4 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-16 fs-xl-18 fs-xxl-20 white">
                Roger Daniel
              </h2>
              <a className="f1 fs-xs-10 fs-sm-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 lt-Blue">
                roger@companystore.io
              </a>
              <p className="f3 fs-xs-12 fs-sm-14 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 white">
                merchHQ Pvt.Ltd.
              </p>
              <p className="f1 fs-xs-11 fs-sm-12 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 white ">
                #39 Krishna Reddy Colony, Domlur Layout Banglore- 560071
                Kartnataka, India.
              </p>
              <button
                class=" d-flex ac-jc ps-3 mt-sm-2"
                style={{
                  backgroundColor: "#0082ca",
                  border: "none",
                  borderRadius: "7px",
                  padding: "5px",
                  color: "#fff",
                }}
              >
                <UnsubscribeIcon />

                <p className="f2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 white">
                  Contact us
                </p>
              </button>
            </div>
            <div className="col-md-4 d-flex ae-je mt-3">
              <div class="d-flex white mt-2  justify-content-around">
                <FacebookRoundedIcon className="me-2" />

                <TwitterIcon className="mx-2" />

                <LinkedInIcon className="mx-2" />

                <InstagramIcon className="mx-2" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EstimationPageA;
