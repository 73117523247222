import React from "react";
import { bx_filter, searchIcon } from "../../assets/img";
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';

const AutoshipNameHeader = ({ title, btnText, showClickHandler }) => {
  return (
    <div className="filter-product d-flex w-100 flex-column flex-md-row  mt-lg-4 px-lg-3 justify-content-between align-items-center">
      <div className="d-flex ac-js mb-3 mb-md-0">
        <div className="position-relative">
          <img src={bx_filter} className="dis-icon ms-1" />
        </div>
        <div className="position-relative d-flex ac-jc">
          <p
            className='text-dark cust-btn f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1 ps-2 pe-1  ps-lg-4 mb-0'
          >
            Product Selected
          </p>
          <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-15 fs-xl-15 fs-xxl-15 primary mb-0"
            style={{ wordSpacing: 'normal' }}>
            <option value="all">All</option>
            <option value="Pack">Pack</option>
            <option value="Products">Products</option>
          </select>
        </div>

        <div className="position-relative d-flex ac-jc">
          <p
            className='text-dark cust-btn f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1 ps-2 pe-1  ps-lg-4 mb-0'
          >
            Shipment Status
          </p>
          <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
            <option value="all">All</option>
            <option value="Pack">Pack</option>
            <option value="Products">Products</option>
          </select>
        </div>
      </div>
      <div className="d-flex ac-js mb-0 ">
        <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
          <img src={searchIcon} alt="" />
          <input
            type="text"
            className="bg-transparent border-0 ps-1 f3 f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15"
            placeholder="Search Products"
            name=""
            id=""
          />
        </div>
        <a href="https://images.pexels.com/photos/268533/pexels-photo-268533.jpeg?cs=srgb&dl=pexels-pixabay-268533.jpg&fm=jpg"
          Download="test_image" className="position-relative d-flex ac-jc">
          <button
            // onClick={() => navigate("/select-product")}
            className="cust-btn f2 f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary mb-0 sm-mb-2">
            <VerticalAlignBottomIcon className="cust-btn f5 fs-13 fs-md-14 fs-lg-16 fs-xl-20 fs-xxl-20 primary mb-0" />
            Export CSV
          </button>
        </a>
      </div>
    </div>
  );
};

export default AutoshipNameHeader;
