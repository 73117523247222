import React from 'react'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import LooksTwoIcon from '@mui/icons-material/LooksTwo';
import { numebr_three, numebr_two, product, quickIcon } from '../../assets/img';

const RedeemUserAccount = () => {
    return (
        <div className="mx-auto mt-4 wi-100 w-sm-90 w-lg-95 w-xl-85">
            <button className="d-flex gap-2 parag bg-transparent border-0 ac-js ">
                <ArrowBackIosIcon className='' />
                <CheckCircleIcon className='f2 primary fs-xl-20 fs-md-19 fs-sm-18 fs-xs-17' />
                <p className='mb-0 fs-xl-15 fs-md-13 fs-sm-12 fs-xs-11 '>Select Products</p>
                <div style={{ width: '2px', background: '#839DD1', height: '18px' }} />
                {/* <img src={numebr_two} className='f4 primary rounded-5' style={{ height: '25px', width: '25px', borderRadius: '50px' }} /> */}
                {/* <p className='mb-0 fs-xl-15 fs-md-13 fs-sm-12 fs-xs-11'>Select Sizes and Quantities</p> */}
                <button className="parag4 bg-transparent border-0 d-flex ac">
                    <p className="text-white mb-0 naviPtag fs-xl-12 fs-md-12 fs-sm-12 fs-xs-9" style={{ background: '#07679c' }}>
                        2
                    </p>
                    <p className="text-primary1 fs-xs-11 mb-0">Select Sizes and Quantities</p>
                </button>
                <button className="parag4 bg-transparent border-0 d-flex ac">
                    <p className="text-white mb-0 naviPtag fs-xl-12 fs-md-12 fs-sm-12 fs-xs-9" style={{ background: '#07679c' }}>
                        3
                    </p>
                    <p className="text-primary1 fs-xs-11 mb-0">Create Shipments</p>
                </button>
            </button>
            <div className='borer-r w-100 py-4 ac-js mt-5 rounded-4 d-flex flex-column flex-lg-row'>
                <img src={product} className='object-fit-contain'
                    style={{ height: '250px', width: '250px' }}
                />
                <div style={{ width: '300px', }} className='w-s2'>
                    <h3 class="f4 fs-xs-20 fs-sm-22 fs-lg-23 fs-xl-25 text-start">Bella + Canvas Tee</h3>
                    <p class="parag mb-0 text-start mb-3">Color : <span className='parag text-start text-dark'>Aqua</span></p>
                    <h4 className="f3 text-dark text-start fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-2 ">
                        Enter quantity by sizes
                    </h4>
                    <div className="d-flex flex-wrap">
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center ">
                                XS
                            </p>
                            <input class="num-cls-btn cust-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark mt-2 text-center"
                                // value='100'
                                type="number"
                                placeholder="100"
                                maxlength={3}
                            // value={number}
                            // onChange={(e) => setNumber(e.target.value)}
                            />
                        </div>
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                S
                            </p>
                            <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                                placeholder="0"
                                type="number"
                            />
                        </div>
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                M
                            </p>
                            <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                                placeholder="0"
                                type="number"
                            />
                        </div>
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                L
                            </p>
                            <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                                placeholder="0"
                                type="number"
                            />
                        </div>
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                XL
                            </p>
                            <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                                placeholder="0"
                                type="number"
                            />
                        </div>
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                2XL
                            </p>
                            <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                                placeholder="0"
                                type="number"
                            />
                        </div>
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                3XL
                            </p>
                            <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                                placeholder="0"
                                type="number"
                            />
                        </div>
                        <div className="mx-2 mt-2">
                            <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                4XL
                            </p>
                            <input class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                                placeholder="0"
                                type="number"
                            />
                        </div>
                    </div>
                </div>
                <div className="px-4 vs-s" style={{ width: '50%' }}>
                    <p class="text-dark cust-btn f3 fs-15 fs-md-13 fs-lg-14 fs-xl-17 text-start fs-xxl-20 primary1 pe-1 mb-0 mb-2">Estimated Production time</p>
                    <div className=" w-90 d-flex flex-column ac-jc flex-md-row gap-3">
                        <div className="estimate-box w-100 py-3 px-3 bg-lt-blue rounded rounded-3">
                            <div className="d-flex ac-jb">
                                <h4 className="f3 text-dark fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18">
                                    Standard
                                </h4>
                            </div>
                            <p className="primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 mt-0 mb-0">
                                +3-5 business days
                            </p>
                        </div>
                        <div className="estimate-box select-custom-boxes py-3 px-3 bg-lt-blue2 w-100 rounded rounded-3">
                            <div className="d-flex  ac-js">
                                <h4 className="f3 text-dark fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18 text-start">
                                    Quick
                                    <img src={quickIcon} className="dis-icon ms-1" />
                                </h4>
                            </div>
                            <p className="primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 mt-0 mb-0">
                                +1-3 business days
                            </p>
                        </div>
                    </div>
                    <div className='w-100'>
                        <div className="d-flex flex-row ac-jb mx-2 pt-1 mt-2">
                            <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                                Total Items
                            </p>
                            <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                                0
                            </h4>
                        </div>
                        <div className="d-flex ac-jb mx-2 pt-1 mt-2">
                            <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                                Price Per Item
                            </p>
                            <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                                $0.00
                            </h4>
                        </div>
                        <div className="d-flex ac-jb mx-2 pt-1 mt-5">
                            <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                                Total
                            </p>
                            <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                                $0.00
                            </h4>
                        </div>
                        <p className='err f2 fs-10 ms-2 mt-4 fs-sm-11 fs-md-12 fs-lg-13 ac-js mt-1'>Total Items must be 1 or more</p>
                    </div>
                </div>
            </div>
            <div className=" cust-hv d-flex w-45 float-end ac-jb mx-2 pt-1 mt-3 mb-5">
                <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
                    Total
                </p>
                <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
                    $0.00
                </h4>
            </div>
        </div>
    )
}

export default RedeemUserAccount