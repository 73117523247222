import React from 'react'
import { closeIcon, commentIcon, discountIcon, happyEmoj, product } from '../../assets/img'
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { productDetailImg } from '../../redux/api/constants';

const YourStorageComp = ({ showClickHandler, showUpdate }) => {
    return (
        <div className="tl-update-qty">
            <div
                className="back-update-qty"
                style={{ display: showUpdate ? "block" : "none" }}></div>
            <div
                className="update-qty"
                style={{ right: showUpdate ? "0%" : "-100%" }}>
                <div className="d-flex ac-jb mt-2 mt-lg-4">
                    <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
                        Your Storage
                    </h6>
                    <button
                        onClick={showClickHandler}
                        className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2">
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div className="btm-scroll pt-3 d-flex flex-md-row ac-jb">
                    <div className="prod-pack">
                        <div className="ac-jb mt-2 mt-lg-4">
                            <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">Your Storage</p>
                            <h4 className="mt-2 mb-4 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">100</h4>
                        </div>
                    </div>
                    <div className="ms-3">
                        <div className="px-2">
                            <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0 text-end">Total Storage Cost</p>
                            <h4 className="mt-2 mb-4 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark text-end">$100.00</h4>
                        </div>


                        {/* Btn */}

                    </div>

                </div>
                <div className='d-flex'>
                    <p className="f2 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0">Your swag is automatically stored to
                        SwagUp Warehouse at various pricing.
                        <span className="f2 fs-10 fs-sm-11 fs-md-12 fs-lg-13 text-dark mt-0 mb-0">View Detailes</span>
                    </p>
                </div>
                <div className="d-flex flex-sm-row ac-jb p-2 my-2 product-list-detail mt-4">
                    <div className="box-shadow-product2 w-100 w-xs-50 w-sm-60 w-md-100 px-3 pb-4 pt-3">
                        <div className="btm-scroll pt-3 d-flex flex-column flex-md-row">
                            <div className="w-100 d-flex flex-column flex-sm-row my-2 product-list-detail">
                                <div className='d-flex w-100'>
                                    <img src={product} className=' img-box-tee2 object-fit-contain' />
                                    <div className='d-flex right-said-box
                                    w-xs-50
                                    w-sm-60
                                    w-90
                                '>
                                        <div className=''>
                                            <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0  ">The Triblend Unisex Tee</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Quantity : 100</p>
                                        </div>
                                        <div className=''>
                                            <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0 text-end text-nowrap">Storage Price
                                                <ErrorOutlineIcon className="f3 fs-xs-9 fs-9 fs-sm-10 fs-md-11 fs-lg-12 text-dark ms-1 mb-0 pb-0 text-dark mt-0 " />
                                            </p>
                                            <p class="f2 fs-9 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-end">$100.00</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="estimate-box rounded rounded-4"
                        >
                            <h4 className="f2 primary1 fs-xs-11 fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 mb-2 ">
                                Quantity by sizes
                            </h4>
                            <div className="d-flex flex-wrap w-100">
                                <div className="mx-2 mt-2 mb-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center ">
                                        XS
                                    </p>
                                    <button class="num-cls-btn cust-btn f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark mt-2">
                                        100
                                    </button>
                                </div>
                                <div className="mx-2 mt-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                        S
                                    </p>
                                    <button class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2">
                                        0
                                    </button>
                                </div>
                                <div className="mx-2 mt-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                        M
                                    </p>
                                    <button class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2">
                                        0
                                    </button>
                                </div>
                                <div className="mx-2 mt-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                        L
                                    </p>
                                    <button class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2">
                                        0
                                    </button>
                                </div>
                                <div className="mx-2 mt-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                        XL
                                    </p>
                                    <button class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2">
                                        0
                                    </button>
                                </div>
                                <div className="mx-2 mt-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                        2XL
                                    </p>
                                    <button class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2">
                                        100
                                    </button>
                                </div>
                                <div className="mx-2 mt-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                        3XL
                                    </p>
                                    <button class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2">
                                        0
                                    </button>
                                </div>
                                <div className="mx-2 mt-2">
                                    <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                                        4XL
                                    </p>
                                    <button class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2">
                                        0
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YourStorageComp