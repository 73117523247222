import React from "react";
import { our_process, service_banner } from "../../assets/img";
import { Col } from "react-bootstrap";

const ServicesHeadComp = () => {
  return (
    <div className="d-flex w-90 flex-column flex-md-row gap-4 flex-column-reverse ">
      {/* <div className="d-flex align-items-center justify-content-center w-100">
        <div className="text-custom-align">
          <h4 class="sect-title mb-2">The MerchHQ Method</h4>
          <p class="mb-3 mb-md-0 primary1 f2 fs-15 fs-md-19 fs-lg-20 fs-xl-22">
            Your Merchandise Journey, Simplified!
          </p>
          <div className="btn-align">
            <button class=" get-start-btn border-0 my-0 mx-2 mt-2">
              Start a Project
            </button>
          </div>
        </div>
      </div> */}
      <div className="d-flex align-items-center justify-content-center w-100 w-lg-50">
      
          <img
            src={our_process}
            className="w-100 h-100 object-fit-contain"
          />
        
      </div>
    </div>
  );
};

export default ServicesHeadComp;
