import React from 'react'
import ProfileLeftTab from '../component/Profile/ProfileLeftTab'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useState } from 'react';
import { shippmentIcon } from '../assets/img';
import AccountFormComp from '../component/MyAccount/AccountFormComp';

const MyAccountsScreen = () => {
    const [check, setChech] = useState(false)
    const [formpop, setFormPop] = useState(false)


    const selectCard = () => {
        setChech(!check)
    }

    const formOpen = () => {
        setFormPop(!formpop)
    }

    return (
        <div className="product-screen d-flex flex-column flex-md-row">
            <ProfileLeftTab />
            {formpop && <AccountFormComp formOpen={formOpen} />}
            <div className="profile-screen">
                <h4 className="sect-title mb-2 mb-lg-1">My Accounts</h4>
                <h3 class="cust-btn mb-0 f3 fs-14 fs-md-15 mt-5 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark w-50">Payment Method</h3>
                <button class=" upload-btn-emty bg-transparent mt-4">Credit Card
                    <button className=' border-0 bg-transparent'>
                        {!check ? <CheckBoxOutlineBlankIcon className='ms-5 text-end primary' onClick={selectCard} /> :
                            <CheckBoxIcon className='text-end ms-5 primary ' onClick={selectCard} />}
                    </button>
                </button>
                {check && <div className="shipment-inventory w-100 d-flex flex-column d-flex ac-jc my-5">
                    <h4 class="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-20 text-dark mb-3">No Saved Credit cards</h4>
                    <p class="parag my-0">Add a Credit Card to make </p>
                    <p class="parag my-0">  payments easy!.</p>
                    <button class="f4 mt-3 get-start-btn border-0 " onClick={() => setFormPop(!formpop)}>Add Credit Card</button>
                </div>}
            </div>

        </div>
    )
}

export default MyAccountsScreen