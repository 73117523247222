import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";
import { useState } from "react";

const ReasonPopup = ({
  showReasonPopupHander,
  positionNumber,
  setPoistionNumber,
  positionData,
  positionNumberErr,
  setPositionData,
  changePosition,
  positionBtn,
  getDelete,
}) => {
  // console.log("positionNumber", positionNumber);
  const [reasons, setReason] = useState("");
  const [reasonsErr, setReasonErr] = useState(false);

  const submitHandle = () => {
    if (reasons?.length == 0) {
      setReasonErr(true);
    } else {
      getDelete(reasons);
    }
  };

  return (
    <div className="modal-popup">
      <div
        onClick={() => {
          showReasonPopupHander();
        }}
        className="back-close"
      />
      <div className="center-content-contact3 w-40 p-4">
        <div className="w-100 d-flex ac-jb">
          <p className="f4 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 primary">
            Delete Reason
          </p>
          <CloseIcon
            onClick={() => {
              showReasonPopupHander();
            }}
            className="pointerView white p-1 rounded-5 f2 bg-primar fs-xs-28 fs-sm-28 fs-md-28 fs-lg-28 fs-xl-28 fs-xxl-25"
          />
        </div>
        <div className="mt-4 w-xl-100 w-lg-100 w-md-100 w-sm-100 w-xs-100">
          <p className="gray f2 fs-xs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-17 fs-xxl-18">
            Reason
          </p>
          <textarea
            className="w-100 editBtnSelect mt-4 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-14 rounded-3 mt-2"
            rows={4}
            style={{ resize: "vertical" }}
            onChange={(e) => setReason(e.target.value)}
            value={reasons}
          />

          {reasons?.length == 0 && reasonsErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Reason</p>
            </div>
          )}
        </div>
        <div className="d-flex mt-4 ac-jc">
          <button
            disabled={positionBtn}
            className={`cust-btn addbtn mt-sm-3 ms-1 mt-2 bg-primar text-white f3 fs-11 fs-lg-12 fs-xl-13 fs-xxl-14 px-5 rounded p-2 ${
              positionBtn && "opacity-50"
            }`}
            onClick={() => submitHandle()}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReasonPopup;
