// @ts-nocheck
import React from "react";
import { shippmentIcon } from "../../assets/img";
import InventoryFilter from "./InventoryFilter";

const InventoryContact = () => {
  return (
    <div className="shipment-inventory w-100 h-100">
      <InventoryFilter />
    </div>
  );
};

export default InventoryContact;
