// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { banner, dashboard, redBagIcon } from "../assets/img";
import DistributeQuality from "../component/Home/DistributeQuality";
import FeedBackHome from "../component/Home/FeedBackHome";
import HomeBanner from "../component/Home/HomeBanner";
import HomeFun from "../component/Home/HomeFun";
import HomeServices from "../component/Home/HomeServices";
import HomeSupport from "../component/Home/HomeSupport";
import HomeTab from "../component/Home/HomeTab";
import ImprossiveFeature from "../component/Home/ImprossiveFeature";
import MerchHqPack from "../component/Home/MerchHqPack";
import SendMerch from "../component/Home/SendMerch";
import ServingFor from "../component/Home/ServingFor";
import SimplePack from "../component/Home/SimplePack";
import Sustainability from "../component/Home/Sustainability";
import Footer from "../navbar/Footer";
import {
  useTestiMonialMutation,
  useUserBannerMutation,
} from "../redux/api/api";
import { type } from "@testing-library/user-event/dist/type";

const HomeScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);
  const [hqList, setHqList] = useState([]);
  const [testimonial, setTestimonial] = useState([]);
  const [index, setIndex] = useState(0);

  // RTK QUERY
  const [bannerListApi] = useUserBannerMutation();
  const [testimonilApi] = useTestiMonialMutation();

  // GET BANNERS
  const getBannerList = () => {
    const formdata = new FormData();
    formdata.append("type", "1");
    bannerListApi(formdata)
      // .unwrap()
      .then((res) => {
        let temp = [];
        res?.data?.lists?.map((item, ind) => {
          let jsons = JSON.parse(item?.contents);
          if (jsons?.name) {
            temp.push({
              ...item,
              ...jsons,
            });
          }
        });
        temp?.sort((a, b) => a.priority - b.priority);
        setList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //TRUSTEDBY
  const getTrustedBy = () => {
    const formData = new URLSearchParams();
    formData.append("type", "2");
    bannerListApi(formData)
      // .unwrap()
      .then((res) => {
        const temp = [];
        res?.data?.lists?.map((item, ind) => {
          const json = JSON.parse(item?.contents);
          if (typeof json === "object") {
            temp.push({
              ...item,
              ...json,
            });
          }
        });
        temp?.sort((a, b) => a.priority - b.priority);
        setLists(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // console.log("new", lists);

  //GETHQLIST
  const getHqList = () => {
    const formData = new URLSearchParams();
    formData.append("type", "3");
    bannerListApi(formData)
      // .unwrap()
      .then((res) => {
        const temp = [];
        res?.data?.lists.map((item, ind) => {
          const json = JSON.parse(item?.contents);
          if (json?.name) {
            temp.push({
              ...item,
              ...json,
            });
          }
        });
        temp?.sort((a, b) => a.priority - b.priority);
        setHqList(temp);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //TESTIMONAIL

  const getTestimonial = () => {
    const formData = new URLSearchParams();

    testimonilApi(formData)
      .then((res) => {
        // console.log(res?.data?.lists,"res")
        setTestimonial(res?.data?.lists);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getBannerList();
    getTrustedBy();
    getHqList();
    getTestimonial();
  }, []);
  return (
    <>
     

      <HomeBanner list={list} />
      {/* <ServingFor /> */}
      {/* <div className="w-100 d-flex ac-jc">
        <img
          src={dashboard}
          alt="icon"
          style={{ height: "380px" }}
          className="object-fit-cover1"
        />
      </div> */}
      <HomeTab type="home" lists={lists} />
      <ImprossiveFeature hqList={hqList} />
      <HomeServices />
      {/* <SendMerch /> */}
      {/* <DistributeQuality /> */}
      <Sustainability />
      <HomeFun />
      <FeedBackHome testimonial={testimonial} />
      <HomeSupport />
      {/* <MerchHqPack /> */}
      <SimplePack />
      <Footer />
    </>
  );
};

export default HomeScreen;
