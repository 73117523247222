import { createSlice } from "@reduxjs/toolkit";

const cartLists = createSlice({
  name: "alert",
  initialState: {
    arrayOfObjects: [],
    // other properties can be added here
  },
  reducers: {
    saveCartLists: (state, action) => {
      return {
        ...state,
        arrayOfObjects: [...state.arrayOfObjects, action.payload],
      };
    },
    removeCartLists: (state, action) => {
      return {
        ...state,
        arrayOfObjects: state.arrayOfObjects.filter(
          (obj) => obj.id !== action.payload
        ),
      };
    },
  },
});

export const { saveCartLists, removeCartLists } = cartLists.actions;
export default cartLists.reducer;
