import React from "react";

const FlatRate = () => {
  return (
    <div className="w-90 w-lg-95 w-xxl-70 mx-auto">
      <h4 className="sect-title text-center mb-2 mb-lg-2 mt-3 mt-lg-5">
        Flat Rate Storage and Fulfillment
      </h4>
      <p className="parag text-center mb-4 w-60 m-auto">
        Units that stay in our facility for more than 6 months will be
        re-charged every 6 months.To avoid these costs inquire about Memberships
      </p>
      <div className="plan-tl-detail ">
        <div className="plan-detail tit-box d-flex ac-jb">
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
            Storage
          </h6>
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
            Merchhq Provided
          </h6>
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
            3rd Party Provided
          </h6>
        </div>
        <div className="px-4">
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              Pack Storage
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              $5.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              N/A
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              Pack Storage Small Item Fee (Stickers, Pins, Buttons, Insert
              cards)
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              $5.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              $2.00
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              Medium Item Fee (Bottles, Journals, Shirts)
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              $1.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              $4.00
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              Large Item Fee (Backpacks, Hoodies, Jackets)
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1 ">
              $1.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
              $4.00
            </p>
          </div>
        </div>
      </div>
      <div className="plan-tl-detail ">
        <div className="plan-detail tit-box d-flex ac-jb">
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
            Shipping
          </h6>
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
            Standard
          </h6>
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
            Express
          </h6>
        </div>
        <div className="px-4">
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Pack (1 - 5 lbs)
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $15.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $23.00
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Pack (Under 1 lb)
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $11.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $23.00
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Bulk Shipments
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $11.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $23.00
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Bulk Shipments
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1 ">
            Varies
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
             -
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f3 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Calculate Shipment Price
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1 ">
            
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
             
            </p>
          </div>
        </div>
      </div>
      <div className="plan-tl-detail ">
        <div className="plan-detail tit-box d-flex ac-jb">
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
          Packing
          </h6>
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
          SwagUp Provided
          </h6>
          <h6 className="f4 fs-md-18 fs-lg-19 fs-xl-20 fs-14 text-dark mb-1">
          3rd Party Provider
          </h6>
        </div>
        <div className="px-4">
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Initial Swag Pack Assembly
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Free
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Free
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Repacking 25 - 49 packs (per pack)
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $16.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $16.00
            </p>
          </div>
          <div className="plan-detail d-flex ac-jb">
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            Repacking 50+ packs (per pack)
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $8.00
            </p>
            <p className="f2 primary1 fs-md-16 fs-lg-17 fs-xl-18 fs-14 mb-1">
            $8.00
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlatRate;
