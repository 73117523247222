// @ts-nocheck
import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { build_Pack, bulkPack, presetPack } from "../../../../assets/img";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const LoginMenuItem = ({
  showMenu,
  setShowMenu,

}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(splitLocation);

  return (
    <div
      className="menu-item d-md-flex"
      style={{
        right: showMenu ? "0px" : "-100%",
        visibility: showMenu ? "visible" : "hindden",
      }}
    >
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <p
          className={`${showMenu ? "d-flex" : "d-none"} closebtn`}
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        >
          <CloseIcon />
        </p>
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/tendrncy");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Dashboards
        </button>
        {splitLocation[1] === "tendrncy" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/start-project");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Start a Project
        </button>
        {splitLocation[1] === "start-project" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/our-project");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Our Projects
        </button>
        {splitLocation[1] === "our-project" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>

      {/* <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/my-products");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Products
        </button>
        {splitLocation[1] === "my-products" && (
          <div className="active d-none d-md-flex" />
        )}
      </div> */}
      {/* <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/my-order");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Orders
        </button>
        {splitLocation[1] === "my-order" && (
          <div className="active d-none d-md-flex" />
        )}
      </div> */}
      {/* <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/my-inventory");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Inventory
        </button>
        {splitLocation[1] === "my-inventory" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/shipment-home");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Shipments
        </button>
        {splitLocation[1] === "shipment-home" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            navigate("/redeem");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Redeem
        </button>
        {splitLocation[1] === "pricing" && (
          <div className="active d-none d-md-flex" />
        )}
      </div>
      <div className="position-relative d-flex flex-column flex-lg-row justify-content-lg-center">
        <button
          onClick={() => {
            setShowMenu(false);
            // navigate("/pricing");
            navigate("/integrations");
          }}
          className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start"
        >
          Integrations
        </button>
        {splitLocation[1] === "integrations" && (
          <div className="active d-none d-md-flex" />
        )}
      </div> */}
    </div>
  );
};

export default LoginMenuItem;
