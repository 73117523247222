import React from 'react';
import { useNavigate } from "react-router-dom";

const LetStartComp = () => {
    const navigate = useNavigate();
    return (
     
        <div className='d-flex w-80 justify-content-center gap-4 py-3'>
            {/* <div className='d-flex ac-jb w-100 flex-column flex-md-row' >
                <div className='text-custom-align'>
                    <h4 class="sect-title mb-2 primary">Let’s get started today!</h4>
                    <h5 class="f2 fs-17 fs-md-18 fs-lg-19 fs-xl-20 w-100 primary1">It’s that simple. We do all the work, and you take the credit!</h5>
                </div>
                
            </div> */}
            <div>
                    <button class=" get-start-btn border-0 my-0 mx-2 mt-2" onClick={()=>
                    {
                        navigate("/lets-start");
                    }}>Get Started</button>
                </div>
        </div>
    )
}

export default LetStartComp;