// @ts-nocheck
import React, { useEffect, useState } from "react";
import { echo, img1, kit1, kit2, kit3, logist } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { logo2, logo3, logo4, logo5, logo6, logo7 } from "../../assets/img";
import { row2 } from "../../redux/api/constants";
import { useUserBannerMutation } from "../../redux/api/api";

const HomeTab = ({ type, onCLickhandler, lists }) => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(lists);
  }, [lists]);

  return (
    <div className="home-tab mt-lg-5 mb-4 mb-lg-5">
      <div className="serving-for mb-5">
        <h3 className="sect-title mb-4 text-center">Trusted by</h3>
        {/* <p class="mb-3 mb-md-0 primary1 f2 fs-15 fs-md-19 fs-lg-20 fs-xl-22 text-center">100+ Fortune 500s est. 2006</p> */}
        <div className="logos w-90">
          <OwlCarousel {...row2}>
            {/* <img src={logo5} className="" /> */}
            {lists?.map((item, ind) => {
              return (
                <img
                  src={item?.image_url ? item?.image_url : logo5}
                  className=""
                />
              );
            })}
          </OwlCarousel>
        </div>
      </div>
      {type !== "product" && (
        <h3 className="sect-title text-center my-4 ">
          Let's get down to Business!
        </h3>
      )}
      {/* <div className="tab-pack d-flex justify-contetnt-center m-auto">
        <button className="get-start-btn1 bg-white primary border-0 mb-3">
          Preset Pack
        </button>
        <button className="get-start-btn1 bg-white primary border-0 mb-3">
          Custom Pack
        </button>
        <button className="get-start-btn1 border-0 mb-3">Bulk Swag</button>
      </div>
      <p className="parag text-center">
        For the hustler - quick launch your Swag pack and get on with life.
      </p> */}
      <div className="w-70 contain d-flex flex-wrap flex-column flex-sm-row justify-content-between pt-2">
        <div className={`${type == "product" && "prod-pac-box"} pac-box `}>
          <div className="pac-box1 ">
            <button
              className="cust-btn"
              onClick={() => {
                if (type === "home") {
                  navigate("/products/build-pack");
                } else {
                  onCLickhandler();
                }
              }}
            >
              <div className="align-items-center align-items-md-start text-center text-md-start">
                <div className="top-img">
                  <img className="img1" src={kit1} alt="" />
                </div>
              </div>

              <div className="p-2">
                <h5>Design Your Kit</h5>
                {type !== "home" && (
                  <div className="d-flex  justify-content-between">
                    <p className="parag f3">₹ 2500/Pack</p>
                    <div className="d-flex align-items-center">
                      <img className="logist" src={logist} alt="" />
                      <p className="parag sec mb-0 ms-2 f4"> </p>
                    </div>
                  </div>
                )}
              </div>
              {type !== "home" && (
                <button
                  onClick={() =>
                    navigate("/products/preset-pack/preset-pack-list")
                  }
                  className="get-start-btn1 border-0 mb-3 mt-3 mx-auto"
                >
                  Select Pack
                </button>
              )}
            </button>
          </div>
        </div>
        <div className={`${type == "product" && "prod-pac-box"} pac-box `}>
          <div className="pac-box1 ">
            <button
              className="cust-btn"
              onClick={() => {
                if (type === "home") {
                  navigate("/products/preset-pack");
                } else {
                  onCLickhandler();
                }
              }}
            >
              <div className="align-items-center align-items-md-start text-center text-md-start">
                <div className="top-img">
                  <img className="img1" src={kit2} alt="" />
                </div>
              </div>
              {/* <button
              // onClick={onCLickhandler}
              onClick={() => navigate("/preset-pack")}
              className="h-100 w-100 border-0 mb-3 mt-3 mx-auto bg-transparent fs-23 f5 text-dark cust-btn px-2 mb-0 mx-2"
            >
              Preset Packs
            </button> */}
              <div className="p-2">
                <h5>Preset Kits</h5>
                {type !== "home" && (
                  <div className="d-flex  justify-content-between">
                    <p className="parag f3">₹ 2500/Pack</p>
                    <div className="d-flex align-items-center">
                      <img className="logist" src={logist} alt="" />
                      <p className="parag sec mb-0 ms-2 f4">min 24 packs</p>
                    </div>
                  </div>
                )}
              </div>
              {type !== "home" && (
                <button
                  onClick={() =>
                    navigate("/products/preset-pack/preset-pack-list")
                  }
                  className="get-start-btn1 border-0 mb-3 mt-3 mx-auto"
                >
                  Select Pack
                </button>
              )}
            </button>
          </div>
        </div>
        <div className={`${type == "product" && "prod-pac-box"} pac-box `}>
          <div className="pac-box1 ">
            <button
              className="cust-btn"
              onClick={() => {
                if (type === "home") {
                  navigate("/products/bulk-pack");
                } else {
                  onCLickhandler();
                }
              }}
            >
              <div className="align-items-center align-items-md-start text-center text-md-start">
                <div className="top-img">
                  <img className="img1" src={kit3} alt="" />
                </div>
              </div>
              {/* <button
              // onClick={onCLickhandler}
              onClick={() => navigate("/preset-pack")}
              className="h-100 w-100 border-0 mb-3 mt-3 mx-auto bg-transparent fs-23 f5 text-dark cust-btn px-2 mb-0 mx-2"
            >
              Preset Packs
            </button> */}
              <div className="p-2">
                <h5>Bulk Merchandise</h5>
                {type !== "home" && (
                  <div className="d-flex  justify-content-between">
                    <p className="parag f3">₹ 2500/Pack</p>
                    <div className="d-flex align-items-center">
                      <img className="logist" src={logist} alt="" />
                      <p className="parag sec mb-0 ms-2 f4">min 24 packs</p>
                    </div>
                  </div>
                )}
              </div>
              {type !== "home" && (
                <button
                  onClick={() =>
                    navigate("/products/preset-pack/preset-pack-list")
                  }
                  className="get-start-btn1 border-0 mb-3 mt-3 mx-auto"
                >
                  Select Pack
                </button>
              )}
            </button>
          </div>
        </div>
      </div>
      <div className="echo">
        <div
          className="echo-img mt-5"
          style={{ width: "70%", margin: "50px auto 0" }}
        >
          <img src={echo} className="echo w-100" alt="" />
        </div>
        <div style={{ marginTop: "50px" }}>
          <h4 className="sect-title text-center mb-2 ">
            ECHO your brand’s story.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default HomeTab;
