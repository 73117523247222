import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import { product1, productd } from "../../../assets/img";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useState } from "react";
import ListIcon from "@mui/icons-material/List";
import { categList } from "../../../json/staticJson";
import { useDispatch } from "react-redux";
import { saveLeftCategIndex } from "../../../redux/slice/leftCategIndex";

const RightProducts = ({
  showPopupHander,
  addProductHandler,
  tick,
  categoryList,
  getProductView,
  cartData,
  deleteCart,
  onClickProduct,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [activeSection, setActiveSection] = useState();

  const toggleFilter = () => {
    setShowFilter(!showFilter);
  };
  const dispatch = useDispatch();
  const [categShow, setCategShow] = useState(false);
  const categShowHandler = () => {
    setCategShow(!categShow);
  };
  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
    // setSidebarOpen(false);
  };
  // console.log(cartData, "cartData");
  return (
    <>
      <div
        className={`${
          categShow ? "left-product-show" : "left-product-hid"
        } left-product`}
      >
        <button
          onClick={categShowHandler}
          className="cat-title justify-content-lg-center cust-btn"
        >
          <ListIcon />
          <p>Categories</p>
        </button>
        <div className="d-flex align-items-center justify-content-lg-center">
          <div className="">
            {categoryList.map((item, index) => {
              return (
                // <button
                //   onClick={() => {
                //     console.log(index);
                //     dispatch(saveLeftCategIndex(index));
                //   }}
                //   className="d-flex align-items-center list-box"
                // >
                //   <div className=" border-0 img-back  d-flex ac-jc my-1">
                //     <img src={item?.icon_url} alt="" />
                //   </div>
                //   <p className="mb-0 ms-3 f3">{item?.prod}</p>
                // </button>
                <li
                  className="d-flex align-items-center list-box"
                  key={item?.id}
                >
                  <div className=" border-0 img-back  d-flex ac-jc my-1">
                    <img src={item?.icon_url} alt="" />
                  </div>
                  <a
                    className={`mb-0 ms-3 f3  ${
                      +activeSection === item?.id ? "activelist" : ""
                    }`} style={{width:"135px"}}
                    href={`#${item?.id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToSection(item?.id);
                    }}
                  >
                    {item?.name}
                  </a>
                </li>
              );
            })}
          </div>
        </div>
      </div>
      <div className="withoutcart rit-prod bulk-prod mt-3"
        // className={` ${tick ? "withcart" : "withoutcart"} rit-prod bulk-prod`}
      >
        <h4 className="sect-title mb-4 mb-lg-1 build">Buy a Blank</h4>
        <h5 className="pack-title my-4 mb-lg-1">
          Here, the logo’s take a day off :)
        </h5>
        <p className="parag disc mt-3 w-90">
          Do you love our merch collection and wan’t a piece just for yourself?
          We got you! You can now shop your favourite unbranded merch from this
          collection.
        </p>
        <div className="d-flex align-items-center justify-content-between position-relative">
          <FilterAltIcon
            onClick={toggleFilter}
            className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 pack-title"
          />
          {showFilter && (
            <div className="filter-options position-absolute ">
              <p>Price</p>
              <div>
                <p onClick={toggleFilter} className="active">
                  Low to High
                </p>
                <p onClick={toggleFilter}>High to Low</p>
              </div>
            </div>
          )}
        </div>

        {/* <div className="d-flex flex-wrap"> */}
        {categoryList?.map((item, ind) => {
          return (
            item?.products?.length > 0 && (
              <section className="mt-3" id={item?.id} key={ind}>
                <h5 className="pack-title mb-0 mb-lg-3">{item?.title}</h5>
                <p className="parag disc w-90">{item?.description}</p>

                <div className="d-flex flex-wrap w-100 ac-je">
                  <div className="d-flex flex-wrap w-100 ac-js">
                    {item?.products?.map((product, inx) => {
                      return (
                        <div
                          className="p-2 p-md-3 p-xxl-4 prod-list-box-tl"
                          key={inx}
                        >
                          <div className="prod-list-box">
                            <button
                              // onClick=>{showPopupHander,setViewId(product?.id)}
                              onClick={() => {
                                showPopupHander();
                                getProductView(product?.id);
                              }}
                              className="cust-btn prod-list-box-img"
                            >
                              <img
                                src={
                                  product?.defaultImage
                                    ? product?.defaultImage
                                    : product1
                                }
                                alt=""
                              />
                            </button>
                            <div className="btm-prod">
                              <h3>{product?.name}</h3>
                              <div className="d-flex justify-content-between">
                                <p className=" f2">
                                  Starting at {+product?.for_25}
                                </p>
                                <button
                                  onClick={() => {
                                    // addProductHandler(item, ind, product, inx);
                                    if (cartData?.[product?.id]?.cart_id) {
                                      deleteCart(
                                        cartData?.[product?.id]?.cart_id,
                                        product?.id
                                      );
                                    } else {
                                      onClickProduct(product?.id);
                                    }
                                    // getAddToCart(product?.id, product?.for_100);
                                  }}
                                  className="plus"
                                >
                                  {tick?.includes(product?.id) ? (
                                    <CheckIcon />
                                  ) : (
                                    <AddIcon />
                                  )}
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </section>
            )
          );
        })}
        {/* <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={product1} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={productd} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={product1} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={productd} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={product1} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={productd} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={product1} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={productd} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <h5 className="pack-title  mb-lg-1">
          Would you like to add a bottle to your kit?
        </h5> */}

        {/* <div className="d-flex flex-wrap">
          <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
            <div className="prod-list-box">
              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img"
              >
                <img src={productd} alt="" />
              </button>
              <div className="btm-prod">
                <h3>1dInsert Cards by Moo - 5" x 7"</h3>
                <div className="d-flex justify-content-between">
                  <p className=" f2">Starting at 0.56</p>
                  <button onClick={() => addProductHandler(1)} className="plus">
                    {tick ? <CheckIcon /> : <AddIcon />}
                  </button>
                </div>
              </div>
            </div>
          </div> */}
        {/* <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
          <div className="prod-list-box">
            <button
              onClick={showPopupHander}
              className="cust-btn prod-list-box-img"
            >
              <img src={product1} alt="" />
            </button>
            <div className="btm-prod">
              <h3>1dInsert Cards by Moo - 5" x 7"</h3>
              <div className="d-flex justify-content-between">
                <p className=" f2">Starting at 0.56</p>
                <button onClick={() => addProductHandler(1)} className="plus">
                  {tick ? <CheckIcon /> : <AddIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
          <div className="prod-list-box">
            <button
              onClick={showPopupHander}
              className="cust-btn prod-list-box-img"
            >
              <img src={productd} alt="" />
            </button>
            <div className="btm-prod">
              <h3>1dInsert Cards by Moo - 5" x 7"</h3>
              <div className="d-flex justify-content-between">
                <p className=" f2">Starting at 0.56</p>
                <button onClick={() => addProductHandler(1)} className="plus">
                  {tick ? <CheckIcon /> : <AddIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
          <div className="prod-list-box">
            <button
              onClick={showPopupHander}
              className="cust-btn prod-list-box-img"
            >
              <img src={product1} alt="" />
            </button>
            <div className="btm-prod">
              <h3>1dInsert Cards by Moo - 5" x 7"</h3>
              <div className="d-flex justify-content-between">
                <p className=" f2">Starting at 0.56</p>
                <button onClick={() => addProductHandler(1)} className="plus">
                  {tick ? <CheckIcon /> : <AddIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
          <div className="prod-list-box">
            <button
              onClick={showPopupHander}
              className="cust-btn prod-list-box-img"
            >
              <img src={productd} alt="" />
            </button>
            <div className="btm-prod">
              <h3>1dInsert Cards by Moo - 5" x 7"</h3>
              <div className="d-flex justify-content-between">
                <p className=" f2">Starting at 0.56</p>
                <button onClick={() => addProductHandler(1)} className="plus">
                  {tick ? <CheckIcon /> : <AddIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
          <div className="prod-list-box">
            <button
              onClick={showPopupHander}
              className="cust-btn prod-list-box-img"
            >
              <img src={product1} alt="" />
            </button>
            <div className="btm-prod">
              <h3>1dInsert Cards by Moo - 5" x 7"</h3>
              <div className="d-flex justify-content-between">
                <p className=" f2">Starting at 0.56</p>
                <button onClick={() => addProductHandler(1)} className="plus">
                  {tick ? <CheckIcon /> : <AddIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
          <div className="prod-list-box">
            <button
              onClick={showPopupHander}
              className="cust-btn prod-list-box-img"
            >
              <img src={productd} alt="" />
            </button>
            <div className="btm-prod">
              <h3>1dInsert Cards by Moo - 5" x 7"</h3>
              <div className="d-flex justify-content-between">
                <p className=" f2">Starting at 0.56</p>
                <button onClick={() => addProductHandler(1)} className="plus">
                  {tick ? <CheckIcon /> : <AddIcon />}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="p-2 p-md-2 p-xxl-4 prod-list-box-tl">
          <div className="prod-list-box">
            <button
              onClick={showPopupHander}
              className="cust-btn prod-list-box-img"
            >
              <img src={product1} alt="" />
            </button>
            <div className="btm-prod">
              <h3>1dInsert Cards by Moo - 5" x 7"</h3>
              <div className="d-flex justify-content-between">
                <p className=" f2">Starting at 0.56</p>
                <button onClick={() => addProductHandler(1)} className="plus">
                  {tick ? <CheckIcon /> : <AddIcon />}
                </button>
              </div>
            </div>
          </div>
        </div> */}
        {/* </div> */}
      </div>
    </>
  );
};

export default RightProducts;
