// @ts-nocheck
import React, { useState } from "react";

import {
  commentIcon,
  closeIcon,
  happyEmoj,
  discountIcon,
  quickIcon,
  product1,
} from "../assets/img";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DoneIcon from "@mui/icons-material/Done";
import FilterAltIcon from "@mui/icons-material/FilterAlt";


const ReorderSelectProduct = () => {
  const navi = useNavigate();
  const [number, setNumber] = useState("");
  const [enableReci, setEnableReci] = useState(true);
  const navigate = useNavigate();
  return (
    <div className="my-product mx-auto w-100">
      <div className="navView ms-5">
        <button className="parag4 bg-transparent border-0 d-flex ac">
          <ArrowBackIosIcon className="arrowImg1" />
          {!enableReci && (
            <p className="text-white bg-primar naviPtag fs-xl-12 fs-md-12 fs-sm-12 fs-xs-9">
              1
            </p>
          )}
          {enableReci && (
            <DoneIcon className="tickBoxDesign6 mb-3 naviPtag rounded-5" />
          )}
          <p className="text-black fs-xs-11">Select Products</p>
        </button>
        <button className="parag4 bg-transparent border-0 d-flex ac">
          <div className="cust-btn splitView bg-primary1 rounded-2" />
          <p
            className={`text-white ${enableReci ? "bg-primar" : "bg-primary1"
              }  naviPtag fs-xl-12 fs-md-12 fs-sm-12 fs-xs-9 `}
          >
            2
          </p>
          <p
            className={`${enableReci ? "text-black" : "text-primary1"
              } fs-xs-11`}
          >
            Select Sizes and Quantites
          </p>
        </button>
        <button className="parag4 bg-transparent border-0 d-flex ac">
          <div className="cust-btn splitView bg-primary1 rounded-2" />
          <p className="text-white bg-primary1 naviPtag fs-xl-12 fs-md-12 fs-sm-12 fs-xs-9">
            3
          </p>
          <p className="text-primary1 fs-xs-11">Review Order</p>
        </button>
      </div>
      <div className="btm-scroll d-flex flex-column flex-lg-row">
        <img src={product1} className="productImgReOrer" alt="" />
        <div className="estimate-box px-md-5 mt-5 rounded rounded-4 ms-5 right-box-cont w-xs-90">
          <div>
            <h3 className="text-dark cust-btn f3 fs-14 fs-md-18 fs-lg-20 fs-xl-21 fs-xxl-22 mb-0">
              Pack 9-2-2023
            </h3>
            <p className="gray d-flex cust-btn fs-14 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 mb-0">
              Color:{" "}
              <p className="text-dark cust-btn ms-0 fs-14 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ms-1 mb-0">
                Custom
              </p>
            </p>
          </div>
          <h4 className="f3 text-dark fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-2 ">
            Quantity by sizes
          </h4>
          <div className="d-flex flex-wrap w-80">
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center ">
                XS
              </p>
              <input
                class="num-cls-btn cust-btn
               f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-dark mt-2 text-center"
                // value='100'
                type="number"
                placeholder="100"
                maxlength={3}
                value={number}
                onChange
              />
            </div>
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                S
              </p>
              <input
                class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                placeholder="0"
                type="number"
              />
            </div>
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                M
              </p>
              <input
                class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                placeholder="0"
                type="number"
              />
            </div>
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                L
              </p>
              <input
                class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                placeholder="0"
                type="number"
              />
            </div>
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                XL
              </p>
              <input
                class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                placeholder="0"
                type="number"
              />
            </div>
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                2XL
              </p>
              <input
                class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                placeholder="0"
                type="number"
              />
            </div>
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                3XL
              </p>
              <input
                class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                placeholder="0"
                type="number"
              />
            </div>
            <div className="mx-2 mt-2">
              <p class="f1 fs-10 fs-sm-11 fs-md-12 fs-lg-13 primary1 mt-0 mb-0 text-center">
                4XL
              </p>
              <input
                class="num-cls-btn cust-btn f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 primary1 mt-2 text-center"
                placeholder="0"
                type="number"
              />
            </div>
          </div>
          <div className="mx-2 mt-2">
            <input
              class="border border-1 rounded-2 px-2 py-2 cust-btn
               f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 text-gray mt-2"
              // value='100'
              type="number"
              placeholder="Custom Quantity"
              maxlength={3}
              value={number}
            />
          </div>
        </div>
        <div className="ms-3 right-box-cont w-xs-90">
          <div className="d-flex px-2 mt-4 align-items-center">
            <p className="cust-btn f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 mb-0 text-dark mt-0">
              Estimated Production time
            </p>
            <ErrorOutlineIcon className="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-lg-17 ms-2 mb-0 pb-0 primary1 mt-0 " />
          </div>
          <div className="estimate-box p-3 p-lg-4 bg-lt-blue mt-3 rounded rounded-4">
            <div className="d-flex ac-jb">
              <h4 className="f3 primary fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 ">
                Standard
              </h4>
              <h4 className="f4 primary fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17 ">
                $0.00
              </h4>
            </div>
            <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">
              17 Business Days
            </p>
          </div>
          <div className="estimate-box p-3 p-lg-4 bg-lt-blue2 mt-3 rounded rounded-4">
            <div className="d-flex ac-jb">
              <h4 className="f3 text-dark fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18">
                Quick
                <img src={quickIcon} className="dis-icon ms-1" />
              </h4>
              <h4 className="f4 text-dark fs-13 fs-md-15 fs-lg-15 fs-xl-16 fs-xxl-17 ">
                +$0.00
              </h4>
            </div>
            <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">
              14 Business Days(10%)
            </p>
          </div>
          {/* Btn */}

          <div className=" bg-lt-blue mt-3 rounded">
            <div className="d-flex ac-jb mx-2 pt-1">
              <h4 className="f2 text-dark fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ac-js mt-1">
                <img src={discountIcon} className="dis-icon mx-1" />
                Membership Discounts
              </h4>
              <h4 className="f2 text-dark fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 ac-js mt-1 ">
                $0.00
              </h4>
            </div>
          </div>
          <div className="d-flex ac-jb mx-2 pt-1 mt-2">
            <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
              Total Quantity
            </p>
            <h4 className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark ">
              25
            </h4>
          </div>
          <div className="Under-Line w-100 bg-lt-blue" />
          <div className="d-flex ac-jb mx-2 pt-1 mt-2">
            <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
              Subtotal
            </p>
            <h4 className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
              $1,0234
            </h4>
          </div>
          <div className="d-flex ac-jb mx-2 pt-1 mt-2">
            <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
              Rush Production
            </p>
            <h4 className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
              $0.00
            </h4>
          </div>
          <div className="Under-Line w-100 bg-lt-blue mt-2" />
          <div className="d-flex ac-jb mx-2 pt-1 mt-2">
            <p className="f2 fs-11 fs-sm-12 fs-md-13 fs-lg-14 primary1 mt-0 mb-0">
              Total Price
            </p>
            <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 ac-js mt-1 text-dark">
              $1,0234
            </h4>
          </div>
          <div className="d-flex ac-jc">
            <button
              onClick={() => navigate("/my-shipment")}
              class="get-start-btn mt-4 mb-3 border-0"
            >
              Review Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReorderSelectProduct;
