import React, { useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { samplePack } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const TransactionPrice = () => {
  const [tab1, setTab1] = useState(true);
  const [tab2, setTab2] = useState(false);
  const navigation = useNavigate();

  const tabHand1 = () => {
    setTab1(true);
    setTab2(false);
  };
  const tabHand2 = () => {
    setTab1(false);
    setTab2(true);
  };

  const step = 5;
  const maxi = 1000;
  const devid = maxi / 5;
  const stepArray = [];

  for (let i = devid; i <= maxi; i += devid) {
    stepArray.push(i);
  }
  console.log(stepArray);
  return (
    <div>
      <div className="trans-range mx-auto w-90 w-lg-85 w-xxl-80">
        <div className=" d-flex flex-column ac-jb my-3 my-md-5 flex-md-row">
          <div className="">
            <h4 className="sect-title mb-2">Simple Transparent Pricing</h4>
            <p className="parag mb-md-0">Get a Sense of the Swag Pricing</p>
          </div>
          <div className="d-flex ac-jc">
            {/* <button className={`get-start-btn border-0 my-0 mx-2`}>Pack</button> */}
            <button
              className={`${
                tab1 ? "get-start-btn" : "get-start-btn bg-lt-blue primary"
              } border-0 my-0 mx-2`}
              onClick={tabHand1}
            >
              Kit
            </button>
            <button
              className={`${
                tab2 ? "get-start-btn" : "get-start-btn bg-lt-blue primary"
              } border-0 my-0 mx-2`}
              onClick={tabHand2}
            >
              Bulk
            </button>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-center justify-content-between pricing-pc-box w-98 w-md-90 w-xl-75 mx-auto d-flex flex-column mb-5">
          <div className="w-90 ms-xl-0 w-sm-95 w-md-90 w-lg-60 w-xl-55 mt-0">
            <div className="d-flex ac-jb">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 ">
                Pack Quantity
              </h5>
              <h5 className="f4 primary fs-13 fs-sm-15 fs-md-17 fs-lg-18 fs-xxl-21 ">
                {maxi}
              </h5>
            </div>
            <RangeSlider
              step={1}
              defaultValue={[0, 1000]}
              thumbsDisabled={[true, false]}
              rangeSlideDisabled={true}
            />
            <div className="d-flex ac-jb mt-2 mt-lg-3">
              {stepArray?.map((it, ind) => (
                <h5 className="f3 text-black fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xxl-17 ">
                  {it}
                </h5>
              ))}
            </div>
            <div className="d-flex ac-jb mt-4">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 ">
                Color in Logo
              </h5>
              <h5 className="f4 primary fs-13 fs-sm-15 fs-md-17 fs-lg-18 fs-xxl-21 ">
                2
              </h5>
            </div>
            <RangeSlider
              step={1}
              defaultValue={[0, 1000]}
              thumbsDisabled={[true, false]}
              rangeSlideDisabled={true}
            />
            <div className="d-flex ac-jb mt-2 mt-lg-3">
              {stepArray?.map((it, ind) => (
                <h5 className="f3 text-black fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xxl-17 ">
                  {it}
                </h5>
              ))}
            </div>
            <div className=" pric-per-pack mt-3 mt-xl-5"></div>
            <div className="d-flex ac-jb mt-4">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 ">
                Price per Pack
              </h5>
              <h5 className="f4 primary fs-18 fs-sm-20 fs-md-25 fs-lg-30 fs-xxl-35 ">
                $908.99
              </h5>
            </div>
          </div>
          <div className="rit-pack-sample">
            <div className="d-flex ac-jb mt-4 mt-lg-0">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-22 ">
                The sample Pack
              </h5>
              <button
                onClick={() => navigation("/pricing/preset-pack-list")}
                className="bg-transparent border-0 f3 primary fs-14 fs-md-15 fs-lg-16 fs-xxl-17 "
              >
                Pack Details
              </button>
            </div>
            <img
              src={samplePack}
              className="w-xs-60 w-sm-50 w-md-100 w-lg-100 w-xl-90 mt-2"
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="trans-range mx-auto w-90 w-lg-85 w-xxl-80">
        <div className=" d-flex flex-column ac-jb my-3 my-md-5 flex-md-row">
          <div className="">
            <h4 className="sect-title mb-2">Brand Impressions Calculator</h4>
            <p className="parag mb-md-0">Understand how brand impressions work!</p>
          </div>
          <div className="d-flex ac-jc">
            {/* <button className={`get-start-btn border-0 my-0 mx-2`}>Pack</button> */}
            <button
              className={`${
                tab1 ? "get-start-btn" : "get-start-btn bg-lt-blue primary"
              } border-0 my-0 mx-2`}
              onClick={tabHand1}
            >
              Kit
            </button>
            <button
              className={`${
                tab2 ? "get-start-btn" : "get-start-btn bg-lt-blue primary"
              } border-0 my-0 mx-2`}
              onClick={tabHand2}
            >
              Bulk
            </button>
          </div>
        </div>
        <div className="d-flex flex-column flex-lg-row align-items-start align-items-center justify-content-between pricing-pc-box w-98 w-md-90 w-xl-75 mx-auto d-flex flex-column mb-5">
          <div className="w-90 ms-xl-0 w-sm-95 w-md-90 w-lg-60 w-xl-55 mt-0">
            <div className="d-flex ac-jb">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 ">
                Pack Quantity
              </h5>
              <h5 className="f4 primary fs-13 fs-sm-15 fs-md-17 fs-lg-18 fs-xxl-21 ">
                {maxi}
              </h5>
            </div>
            <RangeSlider
              step={1}
              defaultValue={[0, 1000]}
              thumbsDisabled={[true, false]}
              rangeSlideDisabled={true}
            />
            <div className="d-flex ac-jb mt-2 mt-lg-3">
              {stepArray?.map((it, ind) => (
                <h5 className="f3 text-black fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xxl-17 ">
                  {it}
                </h5>
              ))}
            </div>
            <div className="d-flex ac-jb mt-4">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 ">
                Color in Logo
              </h5>
              <h5 className="f4 primary fs-13 fs-sm-15 fs-md-17 fs-lg-18 fs-xxl-21 ">
                2
              </h5>
            </div>
            <RangeSlider
              step={1}
              defaultValue={[0, 1000]}
              thumbsDisabled={[true, false]}
              rangeSlideDisabled={true}
            />
            <div className="d-flex ac-jb mt-2 mt-lg-3">
              {stepArray?.map((it, ind) => (
                <h5 className="f3 text-black fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xxl-17 ">
                  {it}
                </h5>
              ))}
            </div>
            <div className=" pric-per-pack mt-3 mt-xl-5"></div>
            <div className="d-flex ac-jb mt-4">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 ">
                Price per Pack
              </h5>
              <h5 className="f4 primary fs-18 fs-sm-20 fs-md-25 fs-lg-30 fs-xxl-35 ">
                $908.99
              </h5>
            </div>
          </div>
          <div className="rit-pack-sample">
            <div className="d-flex ac-jb mt-4 mt-lg-0">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-22 ">
                The sample Pack
              </h5>
              <button
                onClick={() => navigation("/pricing/preset-pack-list")}
                className="bg-transparent border-0 f3 primary fs-14 fs-md-15 fs-lg-16 fs-xxl-17 "
              >
                Pack Details
              </button>
            </div>
            <img
              src={samplePack}
              className="w-xs-60 w-sm-50 w-md-100 w-lg-100 w-xl-90 mt-2"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionPrice;
