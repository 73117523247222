import React from "react";
import BannerBottom from "../component/template/BannerBottom";
import BannerComp from "../component/template/BannerComp";
import BoxListComp from "../component/template/BoxListComp";
import Headercomp from "../component/template/Header";
import { templateColor, tempMenu } from "../redux/api/DummyJson";

const TemplateScreen = () => {
  return (
    <div className="w-100 d-flex flex-column flex-wrap">
      <Headercomp />
      <BannerComp />
      <BannerBottom />
      <BoxListComp />
    </div>
  );
};

export default TemplateScreen;
