import { createSlice } from "@reduxjs/toolkit";

const leftCategIndex = createSlice({
  name: "alert",
  initialState: false,
  reducers: {
    saveLeftCategIndex: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveLeftCategIndex } = leftCategIndex.actions;
export default leftCategIndex.reducer;
