import React from 'react'
import { bx_filter, searchIcon } from '../../../assets/img'

const SelectProductHeader = () => {
    return (
        <div className="filter-product d-flex w-100 flex-column flex-lg-row mt-lg-4 px-lg-3 justify-content-between">
            <div className="d-flex ac-js mb-3 mb-md-0 align-center-custom2 flex-column flex-sm-row">
                <div className="position-relative d-flex ac-jc">
                    <div className="position-relative">
                        <img src={bx_filter} className="dis-icon ms-1" />
                    </div>
                    <p
                        className='text-dark cust-btn f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1 ps-2 pe-1  ps-lg-4 mb-0'
                    >
                        Category
                    </p>
                    <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-15 fs-xl-15 fs-xxl-15 primary mb-0"
                        style={{ wordSpacing: 'normal' }}>
                        <option value="all">All</option>
                        <option value="Pack">Pack</option>
                        <option value="Products">Products</option>
                    </select>
                </div>

                <div className="position-relative d-flex ac-jc">
                    <p
                        className='text-dark cust-btn f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1 ps-2 pe-1  ps-lg-4 mb-0'
                    >
                        Category
                    </p>
                    <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
                        <option value="all">All</option>
                        <option value="Pack">Pack</option>
                        <option value="Products">Products</option>
                    </select>
                </div>
                <div className="position-relative d-flex ac-jc">
                    <p
                        className='text-dark cust-btn f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1 ps-2 pe-1  ps-lg-4 mb-0'
                    >
                        Shipping Options
                    </p>
                    <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
                        <option value="all">All</option>
                        <option value="Pack">Pack</option>
                        <option value="Products">Products</option>
                    </select>
                </div>
            </div>
            <div className="d-flex ac-js mb-0  align-center-custom2 flex-column flex-sm-row">
                <div className="place-holde2 position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
                    <img src={searchIcon} alt="" />
                    <input
                        type="text"
                        className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-14 fs-lg-15 fs-xl-15 fs-xxl-15"
                        placeholder="Search Products"
                        name=""
                        id=""
                    />
                </div>

                <div className="position-relative d-flex ac-jc">
                    <p
                        className='text-dark cust-btn f3 fs-11 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15 primary1 ps-2 pe-1  ps-lg-4 mb-0'
                    >
                        Sort By
                    </p>
                    <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
                        <option value="Newest">Most Recent</option>
                        <option value="Oldest">Oldest</option>
                        <option value=" A-Z"> A-Z</option>
                        <option value=" Z-A"> Z-A</option>
                    </select>
                </div>
            </div>
        </div>
    )
}

export default SelectProductHeader






// <div className='d-flex w-100'>
// <div className='mt-5 d-flex flex-column gap-4 flex-md-row ac-jb'>
//     <div className='w-25 responcive-size-w py-3 rounded-3 px-3 d-flex ac-jb' style={{ background: '#cde2fe' }}>
//         <div className='d-flex flex-column gap-3'>
//             <p className='parag mb-0'>Redemptions</p>
//             <p className='parag f4 mb-0'>1</p>
//         </div>
//         <div>
//             <img src={product} className=' img-box-tee2 object-fit-contains' />
//         </div>
//     </div>
//     <div className='w-25 py-3 rounded-3 px-3 d-flex ac-jb' style={{ background: '#F7DFFF' }}>
//         <div className='d-flex flex-column gap-3'>
//             <p className='parag mb-0'>Redemptions</p>
//             <p className='parag f4 mb-0'>2 Products</p>
//         </div>
//         <div className='' style={{ width: '1px', height: '95%', background: '#C9DBFF' }} />
//         <p class="f2 fs-9 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 text-primary mt-0 mb-0 text-end">View Details</p>
//         <div>
//             <img src={product} className=' img-box-tee2 object-fit-contains' />
//         </div>
//     </div>
// </div>
// <div className='d-flex w-50'>
//     <div className='w-25 py-3 rounded-3 px-3 d-flex ac-jb' style={{ background: '#DDFFE4' }}>
//         <div className='d-flex flex-column gap-3'>
//             <p className='parag mb-0'>Credit Balance</p>
//             <p className='parag f4 mb-0'>$5,007.46</p>
//         </div>
//         <div className='' style={{ width: '1px', height: '95%', background: '#C9DBFF' }} />
//         <p class="f2 fs-9 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 text-primary mt-0 mb-0 text-end">Reload</p>
//         <div>
//             <img src={product} className=' img-box-tee2 object-fit-contains' />
//         </div>
//     </div>
//     <div className='py-3 rounded-3 px-3 d-flex ac-jb' style={{ background: '#EAF1FF' }}>
//         <div className='d-flex flex-column gap-3'>
//             <p className='parag mb-0'>Page Status</p>
//             <p className='parag f4 mb-0 dark-green'>Active</p>
//         </div>
//     </div>
// </div>
// </div>