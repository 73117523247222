import React from "react";
import { dashboard } from "../assets/img";
import BlogTab from "../component/Company/Blog/BlogTab";
import RedeemDetail from "../component/Services/Redeem/RedeemDetail";
import ServiceBanner from "../component/Services/ServiceBanner";
import Footer from "../navbar/Footer";

const banner = {
  title: "Launching Effortlessly With Redeem Pages",
  parag: "Only a 10-minute read",
};
const redeem = [
  {
    title: "Redeem-1",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit Commodi voluptatibus accusamus sequi nam quasi distinctio accusantium temporibus inventore eaque modi voluptatem incidunt ipsam magnam blanditiis quia iusto veritatis ad a",
    img: dashboard,
  },
  {
    title: "Redeem-2",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi voluptatibus, accusamus, sequi nam quasi distinctio accusantium temporibus inventore eaque modi voluptatem incidunt ipsam magnam blanditiis quia iusto veritatis ad a!",
    img: dashboard,
  },
];
const RedeemScreen = () => {
  return (
    <div className="tl-redeem">
      <ServiceBanner title={banner?.title} paragraph={banner?.parag} />
      <RedeemDetail redeem={redeem} />
      <BlogTab />
      <Footer />
    </div>
  );
};

export default RedeemScreen;
