// @ts-nocheck
import React from "react";
import { blog_loop, presetPack, stayProd } from "../../../assets/img";
import { useNavigate } from "react-router-dom";

const BlogEnquiry = () => {
  const navigation = useNavigate()
  return (
    <div className="blog-enquiry  py-4 py-lg-4 py-xl-4" style={{backgroundColor:"#FFF0DB"}}>
      <div className="d-flex flex-column flex-md-row ac-jc w-90 mx-auto">
        <img src={blog_loop} className="blog-prod me-0 mb-3" alt="" />
        <div className="rit-loop text-white text-center text-md-start wi-100 w-md-75 w-xl-60 px-5">
          <h5 className="sect-title blue">Stay in the loop</h5>
          <p className="parag ">
            Be the first to learn about the latest and culture building tips
          </p>
          <div className="loop w-100">
            <div className="d-flex flex-wrap ac-jb stay-loop w-100">
              <input type="text" className=" w-60" />
              <button className="get-start-btn  border-0 ms-2 ms-md-3 ms-lg-2 px-3  mt-0 " onClick={() => navigation('/company/blog/membership')}>
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogEnquiry;
