// @ts-nocheck
import React from "react";
import OwlCarousel from "react-owl-carousel";
import DoneIcon from "@mui/icons-material/Done";
import { closeIcon } from "../../assets/img";
import { productDetailImg } from "../../redux/api/constants";
// import CloseIcon from "@mui/icons-material/Close";

const ProductPopupComponent = ({ showPopupHander }) => {
  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div className="center-content d-flex flex-column flex-lg-row p-0 m-0">
        <button
          onClick={showPopupHander}
          className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="left-popup1 d-flex justify-content-center bg-lt-blue mx-0 px-3 wi-100 w-lg-50 mx-auto">
          <div className="imges me-auto">
            <OwlCarousel {...productDetailImg}>
              <img
                src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                className=""
              />
              <img
                src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                className=""
              />
              <img
                src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                className=""
              />
            </OwlCarousel>
          </div>
        </div>
        <div className="rit-pop1 d-flex flex-column justify-content-md-center px-3 mx-auto px-lg-3 w-80 w-lg-50 my-5">
          <h6 className="mb-0 f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
            Packs 8-11-2023
          </h6>
          <div className="d-flex ac-js pack-box p-2 w-100 my-2">
            <div className="img-box-tee me-2">
              <img
                src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column justify-content-md-center ms-2">
              <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20">
                Next Level Crew Tee
              </h3>
              <div className="d-flex ac-js">
                <p className="parag mb-0">Color: Black</p>
                <div
                  style={{ background: "black" }}
                  className="cust-btn color-box1 d-flex ac-jc ms-2"
                ></div>
              </div>
            </div>
          </div>
          <div className="d-flex ac-js border-primar   pack-box p-2 w-100 my-2">
            <div className="img-box-tee me-2">
              <img
                src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
                className="w-100"
              />
            </div>
            <div className="d-flex flex-column justify-content-md-center ms-2">
              <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20">
                Next Level Crew Tee
              </h3>
              <div className="d-flex ac-js">
                <p className="parag mb-0">Color: Black</p>
                <div
                  style={{ background: "black" }}
                  className="cust-btn color-box1 d-flex ac-jc ms-2"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductPopupComponent;
