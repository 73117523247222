import { DateRangeOutlined } from '@mui/icons-material'
import React from 'react'
import { product } from '../../../../assets/img'

const ProjectComp = () => {
    return (
        <div className='overflow-y-scroll' style={{ overflow: 'scroll' }}>
            <table class="rwd-table float-start mt-3 d-flex ac-jc">
                <tbody>
                    <div className='borer-r rounded-4 mt-3'>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '380px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50   w-sm-60   w-90'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <DateRangeOutlined />
                                            <div>
                                                <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Presentation
                                                    New Hire 20-Sep-2023 (#210216)</p>
                                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Presentation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex ac-jc h-100' style={{ width: '200px' }}>
                                <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">View Project</button>
                            </td>
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '380px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50   w-sm-60   w-90'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <DateRangeOutlined />
                                            <div>
                                                <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Presentation
                                                    New Hire 20-Sep-2023 (#210216)</p>
                                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Presentation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex ac-jc h-100' style={{ width: '200px' }}>
                                <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">View Project</button>
                            </td>
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '380px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50   w-sm-60   w-90'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <DateRangeOutlined />
                                            <div>
                                                <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Presentation
                                                    New Hire 20-Sep-2023 (#210216)</p>
                                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Presentation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex ac-jc h-100' style={{ width: '200px' }}>
                                <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">View Project</button>
                            </td>
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '380px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50   w-sm-60   w-90'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <DateRangeOutlined />
                                            <div>
                                                <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Presentation
                                                    New Hire 20-Sep-2023 (#210216)</p>
                                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Presentation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex ac-jc h-100' style={{ width: '200px' }}>
                                <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">View Project</button>
                            </td>
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '380px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50   w-sm-60   w-90'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <DateRangeOutlined />
                                            <div>
                                                <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Presentation
                                                    New Hire 20-Sep-2023 (#210216)</p>
                                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Presentation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex ac-jc h-100' style={{ width: '200px' }}>
                                <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">View Project</button>
                            </td>
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '380px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50   w-sm-60   w-90'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <DateRangeOutlined />
                                            <div>
                                                <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Presentation
                                                    New Hire 20-Sep-2023 (#210216)</p>
                                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Presentation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex ac-jc h-100' style={{ width: '200px' }}>
                                <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">View Project</button>
                            </td>
                        </tr>
                        <tr className='d-flex  select-custom-boxes'>
                            <td className='d-flex align-items-center gap-3' style={{ width: '380px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box w-xs-50   w-sm-60   w-90'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <DateRangeOutlined />
                                            <div>
                                                <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">Presentation
                                                    New Hire 20-Sep-2023 (#210216)</p>
                                                <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Presentation</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '500px' }}>
                                <div className='p-4 w-100'>
                                    <div className='d-flex right-said-box'>
                                        <div className='d-flex ac-jc gap-3'>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                            <div style={{ width: '100px' }} className='h-100'>
                                                <img src={product} className='object-fit-contain h-100 w-100' />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                            <td className='d-flex align-items-center gap-3' style={{ width: '200px' }}>
                                <div className='p-4 w-100'>
                                    <p class="f3 fs-10 fs-sm-11 fs-xs-12 fs-md-13 fs-lg-14 primary mt-0 mb-0">2023-09-20
                                    </p>
                                </div>
                            </td>
                            <td className='d-flex ac-jc h-100' style={{ width: '200px' }}>
                                <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">View Project</button>
                            </td>
                        </tr>
                    </div>
                </tbody>
            </table>
        </div>
    )
}

export default ProjectComp