import React from "react";

const ProductDetail = () => {
  return (
    <div className="">
      <div className="d-flex flex-column flex-sm-row ac-jb p-2 my-2 product-list-detail">
        <div className="d-flex box-shadow-product">
          <div className="img-box-tee me-2">
            <img
              src="https://thedotmode.com/cdn/shop/files/mockup-of-a-t-shirt-hanging-against-a-solid-background-26878.png?v=1686928473&width=823"
              className="w-100"
            />
          </div>
          <div className=" ms-2">
            <h3 className="mb-2 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20">
              Next Level Crew Tee
            </h3>
            <p className="parag mb-0">$18.59/per item</p>
            <p className="border-0 text-center mb-2 f2 fs-xs-12 fs-sm-13 fs-xl-14 fs-xxl-15 bg-pending text-white px-2 py-1 d-initial rounded">
              Pending Review
            </p>
          </div>
        </div>
        <button className="mb-2 border-0 bg-pending text-white mt-3 mt-sm-0 py-2 rounded rounded-3 px-3 py-lg-3 px-lg-4 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-18">
          Approve Design
        </button>
      </div>
    </div>
  );
};

export default ProductDetail;
