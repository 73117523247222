import React from 'react'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ArrowDownwardRounded, ArrowDropDownRounded } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { searchIcon } from '../../assets/img';
import ShipmentHomeScreen from '../../routes/ShipmentHomeScreen';
import { useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ShipmentDatas from './ShipmentDatas';
import ShipmentHeader from './ShipmentHeader';



const ShipmentsContact = () => {

    return (
        <div className='shipment-inventory  w-100 h-100'>
            <ShipmentDatas />
        </div>

    )
}

export default ShipmentsContact