import React, { useState } from "react";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import { samplePack } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const ShippingPrice = () => {
  const [tab1, setTab1] = useState(true)
  const [tab2, setTab2] = useState(false)
  const navigation = useNavigate()


  const tabHand1 = () => {
    setTab1(true)
    setTab2(false)
  }
  const tabHand2 = () => {
    setTab1(false)
    setTab2(true)
  }


  const step = 5;
  const maxi = 1000;
  const devid = maxi / 5;
  const stepArray = [];

  for (let i = devid; i <= maxi; i += devid) {
    stepArray.push(i);
  }
  console.log(stepArray);
  return (
    <div className="trans-range w-90 w-lg-85 w-xxl-80 m-auto">
      <div className=" d-flex flex-column ac-jb my-3 my-md-5 flex-md-row w-90 w-xxl-90 m-auto">
        <div className="">
          <h4 className="sect-title mb-2">Simple Price calculator</h4>
          <p className="parag mb-md-0">
            Take advantage of our discounted rates
          </p>
        </div>
        <div className="d-flex ac-jc">
          <button className={`${tab1 ? 'get-start-btn' : 'get-start-btn bg-lt-blue primary'} border-0 my-0 mx-2`} onClick={tabHand1}>Kit</button>
          <button className={`${tab2 ? 'get-start-btn' : 'get-start-btn bg-lt-blue primary'} border-0 my-0 mx-2`} onClick={tabHand2}>
            Bulk
          </button>
        </div>
      </div>
      <div className="d-flex flex-column flex-lg-row align-items-lg-start align-items-center justify-content-between pricing-pc-box w-98 w-md-90 w-xl-90 mx-auto d-flex flex-column mb-5">
        <div className="w-90 ms-xl-0 w-sm-95 w-md-90 w-lg-60 w-xl-55 mt-0">
          <div className="d-flex flex-column">
            <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-17 fs-lg-18 fs-xxl-22 w-100 mt-4">
              Pack Quantity
            </h5>
            <input
              className="shipping-input"
              // className="border-0 f2 fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 px-3 py-2 bg-lt-blue rounded rounded-3 w-100"
              type="number"
            />
          </div>
          <div className="d-flex flex-column">
            <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-17 fs-lg-18 fs-xxl-22 w-100 mt-4">
              Shipping Location
            </h5>
            <select
              className="shipping-input"
              // className="border-0 f2 fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 px-3 py-2 bg-lt-blue rounded rounded-3 w-100"
              name=""
              id="">
              <option value="">India</option>
              <option value="">USA</option>
              <option value="">UK</option>
            </select>
          </div>
          <div className="d-flex ac-jb w-100 shippingPrice-inputs">
            <div className="d-flex flex-column w-45">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-17 fs-lg-18 fs-xxl-22 w-100 mt-4">
                State
              </h5>
              <select
                className="shipping-input"
                // className="border-0 f2 fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 px-3 py-2 bg-lt-blue rounded rounded-3 w-100"
                name=""
                id="">
                <option value="">Karnadaka</option>
                <option value="">Tamilnadu</option>
                <option value="">Kerala</option>
              </select>
            </div>
            <div className="d-flex flex-column w-45">
              <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-17 fs-lg-18 fs-xxl-22 w-100 mt-4">
                Zip Code
              </h5>
              <input
                className="shipping-input"
                // className="border-0 f2 fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 px-3 py-2 bg-lt-blue rounded rounded-3 w-100"
                type="number"
              />
            </div>
          </div>
          <div className="d-flex ac-js mt-3 mt-lg-4">
            <button className="get-start-btn border-0 my-0 mx-2">
              Calculate Price
            </button>
          </div>

          <div className=" pric-per-pack mt-3 mt-xl-4"></div>
          <div className="d-flex ac-jb mt-4">
            <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-23 ">
              Estimated Shipping Price
            </h5>
            <h5 className="f4 primary fs-18 fs-sm-20 fs-md-25 fs-lg-30 fs-xxl-35 ">
              $908.99
            </h5>
          </div>
        </div>
        <div className="rit-pack-sample">
          <div className="d-flex ac-jb mt-4 mt-lg-0">
            <h5 className="f3 text-dark fs-14 fs-sm-16 fs-md-18 fs-lg-21 fs-xxl-22 ">
              The sample Pack
            </h5>
            <button onClick={() => navigation('/pricing/preset-pack-list')} className="bg-transparent border-0 f3 primary fs-14 fs-md-15 fs-lg-16 fs-xxl-17 ">
              Pack Details
            </button>
          </div>
          <img
            src={samplePack}
            className="w-xs-60 w-sm-50 w-md-100 w-lg-100 w-xl-90 mt-2"
            alt=""
          />
        </div>
      </div>
    </div>
  );
};

export default ShippingPrice;
