import React from "react";

const ServiceBanner = ({ title, paragraph }) => {
  return (
    <div className="d-flex flex-column ac-jc sus-banner">
      <div className="w-80 w-lg-75 w-xl-65 w-xxl-60 text-center mx-auto">
        {/* <h4 className="sect-title">{title}</h4> */}
        <p className="parag">{paragraph}</p>
      </div>
    </div>
  );
};

export default ServiceBanner;
