import React from 'react'
import { file, noteIcon, quickIcon, redeem_animated } from '../../../assets/img'
import { Col } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import CreditBalanceSlide from './CreditBalanceSlide';

const FormSix = ({ F5 }) => {
    const navigation = useNavigate()
    const [showUpdate, setShowUpdate] = useState(false)
    const [showredeem, setShowRedeem] = useState(false);


    const showUpdatehand = () => {
        setShowUpdate(!showUpdate)
    }
    return (
        <div className='align-custom1 d-flex flex-column mt-3'
            style={{
                // height: '150%',
                alignItems: 'end'
            }
            }>
            <CreditBalanceSlide showUpdatehand={showUpdatehand} showUpdate={showUpdate} />
            <div className="w-85 ac-jb mt-3">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Shipping method
                </p>
                <div className=" w-100 d-flex flex-column flex-md-row ac-jc gap-4">
                    <div className="estimate-box w-100 py-3 px-3 bg-lt-blue rounded rounded-3">
                        <div className="d-flex ac-jb">
                            <h4 className="f3 text-dark fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18">
                                Standard
                            </h4>
                        </div>
                        <p className="primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 mt-0 mb-0">
                            +3-5 business days
                        </p>
                    </div>
                    <div className="estimate-box select-custom-boxes py-3 px-3 bg-lt-blue2 w-100 rounded rounded-3">
                        <div className="d-flex  ac-js">
                            <h4 className="f3 text-dark fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18 text-start">
                                Quick
                                <img src={quickIcon} className="dis-icon ms-1" />
                            </h4>
                        </div>
                        <p className="primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 mt-0 mb-0">
                            +1-3 business days
                        </p>
                    </div>
                </div>
            </div>

            <div className="w-85 ac-jb mt-3">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Shipping method
                </p>
                <div className=" w-100 d-flex flex-column flex-sm-row ac-jc gap-4">
                    <div className="estimate-box w-100 py-3 px-3 bg-lt-blue rounded rounded-3 d-flex ac-jc">
                        <h4 className="mb-0 f3 text-dark fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18">
                            Available Inventory
                        </h4>
                    </div>
                    <div className="estimate-box select-custom-boxes  py-3 px-3 bg-lt-blue2 w-100 rounded rounded-3 d-flex ac-jc">
                        <h4 className="mb-0 f2 fs-12 fs-md-13 primary1 fs-lg-14 fs-xl-15 fs-xxl-18">
                            Quick
                        </h4>
                    </div>
                </div>
            </div>

            <div className="w-85 ac-jb mt-3">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Allow Shippments
                </p>
                <div className=" w-100 d-flex flex-column flex-sm-row ac-jc gap-4">
                    <div className="estimate-box w-100 py-3 px-3 bg-lt-blue rounded rounded-3 d-flex ac-jc">
                        <h4 className="mb-0 f3 text-dark fs-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-18">
                            Globally
                        </h4>
                    </div>
                    <div className="estimate-box select-custom-boxes  py-3 px-3 bg-lt-blue2 w-100 rounded rounded-3 d-flex ac-jc">
                        <h4 className="mb-0 f2 fs-12 fs-md-13 primary1 fs-lg-14 fs-xl-15 fs-xxl-18">
                            U.S Only
                        </h4>
                    </div>
                </div>
                <p class="f3 text-dark fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 my-0 box-text-form text-sx-center mt-4">Credits Overview</p>
                <div className='d-flex ac-jb'>
                    <div>
                        <p className="f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 mt-4">Your Credit balance</p>
                        <h4 class="mb-0 f2 fs-12 fs-md-13 text-primary fs-lg-14 fs-xl-15 fs-xxl-18 mt-2">Reload Credit Balance</h4>
                    </div>
                    <div>
                        <p className="f4 dark-green fs-xl-20 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 mt-4 text-end">$ 5,007.46</p>
                        <p class="primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 mb-0 mt-2">Minimum req: $100.00</p>
                    </div>
                </div>

            </div>
            <div style={{ height: '50vh', overflow: 'scroll' }} className='d-flex w-85 align-items-end pb-4'>
                <div className='d-flex flex-column w-100'>
                    <p class="primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 mt-0 mb-0">By creating this Autoship Redeem Page, you acknowledge and agree to our
                    </p>
                    <a className='text-primary text-decoration-underline my-0 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 mb-3'> Return/Refund Policy</a>
                    <div className='d-flex ac-jb w-100'>
                        <button className='cust-btn text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '
                            onClick={F5}>
                            <ArrowBackIcon
                                className='text-center mb-0 f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-14 fs-xs-15' /> Previous Step</button>
                        <button className='get-start-btn border-0 mt-0' onClick={showUpdatehand}>Create</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormSix