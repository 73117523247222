import { createSlice } from "@reduxjs/toolkit";

const saveLogin = createSlice({
  name: "alert",
  initialState: "",
  reducers: {
    saveLoginHeader: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveLoginHeader } = saveLogin.actions;
export default saveLogin.reducer;
