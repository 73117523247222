import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { product, product1 } from "../../assets/img";
import AutoshipNameHeader from "./AutoshipNameHeader";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useState } from "react";
import AutoShipPopup from "./RedeemForm/AutoShipPopup";
import AvailableInventorySlide from "./RedeemForm/AvailableInventorySlide";
import { Col } from "react-bootstrap";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CircleIcon from "@mui/icons-material/Circle";
import { useLocation, useNavigate } from "react-router-dom";
import RedeemHeader from "./RedeemHeader";

const AutoshipNameScreen = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const [share, setShare] = useState(false);
  const [edit, setEdit] = useState(false);
  const [save, setSave] = useState(true);
  const [listvisible, setListVisible] = useState(true);
  const [inventoryslide, setInventorySlide] = useState(false);
  const navigate = useNavigate();
  const PopupHanler = () => {
    setShare(!share);
    setEdit(false);
    setSave(true);
    setInventorySlide(false);
  };
  const btnhand = () => {
    setEdit(true);
    setSave(false);
    setInventorySlide(!inventoryslide);
  };
  const [check, setCheck] = useState(false);
  const checkBox = () => {
    setCheck(!check);
  };
  const listShowHand = () => {
    setListVisible(false);
  };
  return (
    <div className="my-product mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85">
      {share && (
        <AutoShipPopup listShowHand={listShowHand} PopupHanler={PopupHanler} />
      )}
      {inventoryslide && <AvailableInventorySlide btnhand={btnhand} />}

      {/* <button className="parag bg-transparent border-0">
                <ArrowBackIosIcon /><a onClick={() => { navigate('/redeem') }}>Back to Redeem Pages</a> / <a>Autoship Name</a>
            </button> */}

      <RedeemHeader title="Redeem" btnText="Create Redeem Page" />

      <div className="d-flex flex-column flex-sm-row ac-jb mx-2 mt-3 my-sm-3 my-md-4 flex-row">
        <div className="">
          <h4 className="sect-title mb-2">Autoship Name</h4>
        </div>
        <div className="d-flex ac-jc w-s">
          <button
            onClick={btnhand}
            className={
              edit
                ? "get-start-btn border-0 my-0 mx-2"
                : "f4 get-start-btn bg-lt-blue border-0 bg-transparent primary my-0 mx-2 border-primary"
            }>
            Edit Page
          </button>
          <button
            onClick={PopupHanler}
            className={
              save
                ? "get-start-btn border-0 my-0 mx-2"
                : "f4 get-start-btn bg-lt-blue border-0 bg-transparent primary my-0 mx-2 border-primary"
            }>
            Share
          </button>
        </div>
      </div>
      {/*  */}
      <div className="w-100">
        <Col
          xs={12}
          md={6}
          className="w-100 gap-3 d-flex flex-column flex-md-row">
          <div className="d-flex w-100 gap-3  flex-column flex-sm-row">
            <div
              className="responcive-size-w py-3 rounded-3 px-3 d-flex ac-jb"
              style={{ background: "#cde2fe", width: "50%" }}>
              <div className="d-flex flex-column gap-3">
                <p className="parag mb-0">Redemptions</p>
                <p className="parag f4 mb-0">1</p>
              </div>
              <div>
                <img
                  src={product}
                  className=" img-box-tee2 object-fit-contains"
                />
              </div>
            </div>
            <div
              className=" responcive-size-w py-3 rounded-3 px-3 d-flex ac-jb"
              style={{ background: "#F7DFFF", width: "50%" }}>
              <div className="d-flex flex-column gap-3">
                <p className="parag mb-0">Redemptions</p>
                <p className="parag f4 mb-0">2 Products</p>
              </div>
              <div
                className=""
                style={{ width: "1px", height: "95%", background: "#C9DBFF" }}
              />
              <p class="f2 fs-9 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 text-primary mt-0 mb-0 text-end">
                View Details
              </p>
              <div>
                <img
                  src={product}
                  className=" img-box-tee2 object-fit-contains"
                />
              </div>
            </div>
          </div>
          <div className="d-flex w-100 gap-3 flex-column flex-sm-row">
            <div
              className="responcive-size-w py-3 rounded-3 px-3 d-flex ac-jb"
              style={{ background: "#DDFFE4", width: "50%" }}>
              <div className="d-flex flex-column gap-3">
                <p className="parag mb-0">Credit Balance</p>
                <p className="parag f4 mb-0">$5,007.46</p>
              </div>
              <div
                className=""
                style={{ width: "1px", height: "95%", background: "#C9DBFF" }}
              />
              <p class="f2 fs-9 fs-xs-9 fs-sm-10 fs-md-11 fs-lg-12 text-primary mt-0 mb-0 text-end">
                Reload
              </p>
              <div>
                <img
                  src={product}
                  className=" img-box-tee2 object-fit-contains"
                />
              </div>
            </div>
            <div
              className="custom-res-hw responcive-size-w py-3 rounded-3 px-3 d-flex ac-jb w-md-90"
              style={{ background: "#EAF1FF", width: "50%" }}>
              <div className="d-flex flex-column gap-3">
                <p className="parag mb-0">Page Status</p>
                <p className="parag f4 mb-0 dark-green">Active</p>
              </div>
            </div>
          </div>
        </Col>
      </div>
      {/*  */}

      <div className="mt-5">
        <AutoshipNameHeader />
      </div>
      <div className="w-100 d-flex ac-jb mt-4 px-2">
        <p className="mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-14 fs-xxl-16 text-center text-dark">
          Total Count: 1
        </p>
        <p className="mb-0 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-14 fs-xxl-16 text-center text-dark">
          Total Shipping Cost: $7.46
        </p>
      </div>
      {/* <div className='px-5 mt-4 d-flex flex-column w-100'> */}
      <div
        className="d-flex mb-5"
        style={{ overflow: "scroll", width: "100%" }}>
        <table class="rwd-table float-start w-100 mt-3">
          <tbody>
            <tr className="d-flex">
              {/* <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'></th> */}
              <th
                className="primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11"
                style={{ width: "198px" }}>
                {/* {check && <CheckBoxIcon onClick={checkBox} className='me-2 f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' />} */}
                <CheckBoxOutlineBlankIcon
                  className={`${
                    listvisible ? "visible" : "invisible"
                  } f4 me-2 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22`}
                />
                {/*         <div className={`${type == "product" && "prod-pac-box"} pac-box `}> */}
                Name
              </th>
              <th
                className="primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11"
                style={{ width: "198px" }}>
                Shipping Status
              </th>
              <th
                className="primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11  "
                style={{ width: "200px" }}>
                Shipping Cost
              </th>
              <th
                className="primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 "
                style={{ width: "300px" }}>
                Product Selected
              </th>
              <th
                className="primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 "
                style={{ width: "200px" }}>
                Date Added+
              </th>
            </tr>
            {!listvisible && (
              <tr className="d-flex mt-3 borer-r rounded-4  select-custom-boxes py-4">
                <td
                  data-th="Supplier Code"
                  className="d-flex align-items-center gap-3"
                  style={{ width: "200px" }}>
                  {check && (
                    <CheckBoxIcon
                      onClick={checkBox}
                      className="f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22"
                    />
                  )}
                  {!check && (
                    <CheckBoxOutlineBlankIcon
                      onClick={checkBox}
                      className="f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22"
                    />
                  )}
                  <div className="d-flex w-100">
                    <div
                      className="d-flex right-said-box
                                    w-xs-50
                                    w-sm-60
                                 w-90">
                      <div className="">
                        <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0  ">
                          Rob Test
                        </p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">
                          rob@gmail.com
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  data-th="Supplier Name parag text-center"
                  style={{ width: "198px" }}>
                  <p className="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0">
                    <CircleIcon className="f3 fs-7  fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 green1 mt-0 mb-0" />
                    Sheduled
                  </p>
                  <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">
                    Shipping Date : 11/25/2023
                  </p>
                </td>
                <td
                  data-th="Supplier Name parag text-center"
                  style={{ width: "200px" }}>
                  <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0">
                    $4.98
                  </p>
                </td>
                <td
                  data-th="Invoice Number"
                  className="parag f2 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 "
                  style={{ width: "172px" }}>
                  <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0">
                    Bella + Canvas Tax
                  </p>
                </td>
                <td
                  data-th="Invoice Number"
                  className="parag f2 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 "
                  style={{ width: "200px" }}>
                  <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0 text-end">
                    11/25/2023
                  </p>
                </td>
              </tr>
            )}
            {!listvisible && (
              <tr className="d-flex mt-3 borer-r rounded-4  select-custom-boxes py-4">
                <td
                  data-th="Supplier Code"
                  className="d-flex align-items-center gap-3"
                  style={{ width: "200px" }}>
                  {check && (
                    <CheckBoxIcon
                      onClick={checkBox}
                      className="f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22"
                    />
                  )}
                  {!check && (
                    <CheckBoxOutlineBlankIcon
                      onClick={checkBox}
                      className="f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22"
                    />
                  )}
                  <div className="d-flex w-100">
                    <div
                      className="d-flex right-said-box
                                    w-xs-50
                                    w-sm-60
                                 w-90">
                      <div className="">
                        <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0  ">
                          Rob Test
                        </p>
                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">
                          rob@gmail.com
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td
                  data-th="Supplier Name parag text-center"
                  style={{ width: "198px" }}>
                  <p className="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0">
                    <CircleIcon className="f3 fs-7  fs-xs-8 fs-sm-9 fs-md-10 fs-lg-11 green1 mt-0 mb-0" />
                    Sheduled
                  </p>
                  <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">
                    Shipping Date : 11/25/2023
                  </p>
                </td>
                <td
                  data-th="Supplier Name parag text-center"
                  style={{ width: "200px" }}>
                  <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0">
                    $4.98
                  </p>
                </td>
                <td
                  data-th="Invoice Number"
                  className="parag f2 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 "
                  style={{ width: "172px" }}>
                  <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0">
                    Bella + Canvas Tax
                  </p>
                </td>
                <td
                  data-th="Invoice Number"
                  className="parag f2 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 "
                  style={{ width: "200px" }}>
                  <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0 text-end">
                    11/25/2023
                  </p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {/* <div className='d-flex ms-5 align-items-center'>
                    <button class="get-start-btn bg-lt-blue primary  my-0 mx-2 f4 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11" style={{ borderColor: 'primary' }}>Send merch</button>
                    <DeleteRoundedIcon className='bg-lt-blue primary' />
                </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default AutoshipNameScreen;
