import React from 'react'
import { redeem_animated } from '../../assets/img'
import { Col } from 'react-bootstrap'

const FormRightComp3 = () => {
    return (
        <div className='w-100 ac-jc d-flex'
            style={{
                height: '100vh',
                background: '#E2ECFF',
            }}>
            <div className="d-flex flex-column form_cont3 ac-jc w-75">
                <p className='f5 text-dark fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 my-0 box-text-form text-sx-center'>Congratulations</p>
                <p class=" primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 my-0 box-text-form text-sx-center">Select one </p>
                <div className="d-flex gap-2 mt-3 flex-column flex-md-row ac-jb cust-w form-box-cont">
                    <img src={redeem_animated} className='custom-img-form2 rounded-4' />
                    <div className=''>
                        <p class=" primary1 fs-xl-13 fs-xs-11 fs-md-11 fs-sm-12 fs-lg-13 f2 my-0 box-text-form text-sx-center">"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormRightComp3