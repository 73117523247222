// @ts-nocheck
import React from "react";
import { bg1 } from "../assets/img";
import LeftAuth from "../component/Auth/LeftAuth";
import RegistrationAuth from "../component/Auth/RegistrationAuth";
import { useNavigate } from "react-router-dom";

const SignupScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="w-xs-90 w-lg-70 d-flex flex-column flex-lg-row mx-auto mb-5">
      {/* <h5
        onClick={() => navigate("/")}
        className="sect-title mb-0 primary log-logo">
        merchhq
      </h5> */}
      {/* <img src={bg1} alt="" className="bg1" /> */}
      <div className="mt-5 my-md-auto d-block">
        <LeftAuth />
      </div>
      <RegistrationAuth />
      {/* <img src={bg1} alt="" className="bg2" /> */}
    </div>
  );
};

export default SignupScreen;
