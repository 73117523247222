import React from "react";
import {
  analysis,
  dislike,
  files,
  happyEmoj,
  logist,
  maili,
  map,
  sadEmoj,
  setting,
  starYellow,
  tick,
  tshirticon,
} from "../../assets/img";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";

const HomeFun = () => {
  const navigate = useNavigate();
  return (
    <div className="home-should" sty={{ marginTop: "50px" }}>
      <div className="contain d-flex justify-content-xl-between">
        {/* <div className="lft-swag text-center text-xl-start me-0 me-lg-3">
          <h4 className="sect-title mt-3">Take it to the Next Level</h4>
          <p className="parag">
            Curate an amazing merch kit seamlessly, we've made it just so
            simple!
          </p>
        </div> */}
        <div className="rit-swag d-flex flex-column flex-sm-row justify-content-around w-80 m-auto">
          <div className="swag-box">
            <div className="btn-merch">
              <h5 className="btn-blue">The Old Way</h5>
              {/* <img src={sadEmoj} alt="icon" /> */}
            </div>
            {/* <h3>Out with the old</h3> */}
            <div className="list-warn mt-3">
              <div className="d-flex align-items-center mb-3">
                <img src={setting} alt="icon" />
                <p>Limited customization options</p>
              </div>
              <div className="d-flex  align-items-center mb-3">
                <img src={dislike} alt="icon" />
                <p>Quality and branding issues</p>
              </div>
              <div className="d-flex  align-items-center mb-3">
                <img src={files} alt="icon" />
                <p>Stress of scattered data</p>
              </div>
              <div className="d-flex  align-items-center ">
                <img src={maili} alt="icon" />
                <p>Constant follow-ups about your merch</p>
              </div>
            </div>
          </div>
          <div className="swag-box">
            <div className="btn-merch">
              <h5 className="btn-blue">The MerchHQ Way</h5>
              {/* <img src={happyEmoj} alt="icon" /> */}
            </div>
            {/* <h3>In with the new</h3> */}
            <div className="list-warn mt-3">
              <div className="d-flex as-js align-items-center mb-3">
                <img src={tshirticon} alt="icon" />
                <p>100+ product options available</p>
              </div>
              <div className="d-flex as-js align-items-center mb-3">
                <img src={tick} alt="icon" />
                <p>Consistent and high quality branding</p>
              </div>
              <div className="d-flex as-js align-items-center mb-3">
                <img src={analysis} alt="icon" />
                <p>A dashboard designed specially for you.</p>
              </div>
              <div className="d-flex as-js align-items-center ">
                <img src={map} alt="icon" />
                <p>Track you order from design to delivery</p>
              </div>
            </div>
            {/* <div className="btn-build bg-orange mt-1">
              <img src={logist} alt="icon" />
              <h5 className="text-white">Order a Sample Pack</h5>
            </div> */}
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <button
          className="cust-btn get-start-btn bg-btn-blue"
          onClick={() => {
            navigate("/lets-start");
          }}
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default HomeFun;
