import React from "react";
import { dashboard, exclusive, listIcon } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";

const ImprossiveFeature = ({ hqList }) => {
  const navigate = useNavigate();
  // console.log(hqList,"l")
  return (
    <div
      style={{ marginTop: "70px", background: "#eff3fa", padding: "70px 0" }}
    >
      <div className="improsive-feature w-70" style={{ marginTop: "0px" }}>
        {hqList?.map((item, ind) => {
          return (
            <>
              <div className="left-improsive-feature">
                {/* <h4>The HQ Exclusive</h4>  */}
                <h4>{item?.name.substring(0, 200)}</h4>
                <div className="">
                  {/* <div className="d-flex align-items-start">
            <img src={listIcon} alt="" />
            <p>Connect to over 5,000 platforms to automate process</p>
          </div> */}
                  <div className="  text-justify black">
                    {/* <img src={listIcon} alt="" /> */}
                    {/* <p className="gray1">
              Psst! You don’t want to scroll past this. Boring isn’t in our
              playbook.
            </p>
            <p className="gray1 w-90 me-2">
              {" "}
              Here is our monthly product spotlight! Unlock this exceptional
              deal available only for this month. Act quickly and don’t miss out
              on this exclusive offer!
            </p> */}
                    <p
                      className="gray1 w-90 me-2"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          item?.description?.substring(0, 250)
                        ),
                      }}
                    >
                      {/* {item?.description.substring(0,250)} */}
                    </p>
                  </div>
                  {/* <div className="d-flex align-items-start">
            <img src={listIcon} alt="" />
            <p>Use branded landing pages to send swag for Marketing & Brand</p>
          </div>
          <div className="d-flex align-items-start">
            <img src={listIcon} alt="" />
            <p>Let's get more swag out with less work for you!</p>
          </div> */}
                </div>
                <div className="d-flex ">
                  <a
                    href={item?.link}
                    className="get-start-btn m-0"
                    // href="#/products/build-pack"
                  >
                    {item?.button_name.substring(0, 20)}
                  </a>
                </div>
              </div>
              <div className="rit-improsive-feature d-flex justify-content-end">
                <img src={item?.image_url ? item?.image_url : dashboard} />
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

export default ImprossiveFeature;
