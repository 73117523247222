import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const HeaderProduct = ({
  title,
  btnText,
  onClick,
  thirdTitle,
  secondTitle,
  type,
  setBtnChange,
  btnChange,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    console.log(splitLocation[2]);
    if (title === "Inventory" && splitLocation[2] === undefined) {
      navigate("/my-inventory/in-of-stock");
    }
  }, []);
  return (
    <div className="top-product d-flex flex-column flex-sm-row ac-jb mt-3 mb-2">
      <div className="w-70 mb-2">
        <h6 className="sect-title px-2 px-lg-3">{title}</h6>
        <div className="d-flex ac-js">
          <div className="position-relative">
            {/* <button
              className={`cust-btn f3 fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 ${
                // btnChange == 0 ? "border_click_cust" : "cust-btn "

                (splitLocation[2] !== "out-of-stock" ||
                  splitLocation[2] !== "in-of-stock") &&
                title === "Inventory"
                  ? "primary1"
                  : "text-dark"
              } px-2 px-lg-3`}
              onClick={() => {
                // setBtnChange(0);
                // getAddresslist(search, 1, order);
              }}>
              All
            </button> */}
             <button
                className={`${
                  btnChange == 0 ? "border_click_cust" : "cust-btn "
                } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                onClick={() => {
                  setBtnChange(0);
                  // getAddresslist(search, 1, order);
                }}
              >All
              </button>
            <div className="position-absolute" />
          </div>
          <div className="position-relative">
            {/* <button
              onClick={() => {
                
                if (title === "Inventory") {
                  navigate("/my-inventory/in-of-stock");
                }
              }}
              className={`cust-btn f3 fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 px-2 px-lg-3 w-100 text-nowrap ${
                splitLocation[2] === "in-of-stock" && title === "Inventory"
                  ? "text-dark"
                  : "primary1"
              } `}
              
              >
              {secondTitle ? secondTitle : "Packs"}
            </button> */}
               <button
                className={`${
                  btnChange == 1 ? "border_click_cust" : "cust-btn "
                } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                onClick={() => {
                  setBtnChange(1);
                  // getAddresslist(search, 1, order);
                }}
              >Packs
              </button>
            {/* <div
              className={`ms-4 ${
                splitLocation[2] === "in-of-stock" ? "active" : null
              }`}></div> */}
          </div>
          <div className="position-relative">
            {/* <button
              onClick={() => {
                if (title === "Inventory") {
                  navigate("/my-inventory/out-of-stock");
                }
              }}
              className={`cust-btn f3 fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 px-2 px-lg-3 w-100 text-nowrap ${
                splitLocation[2] === "out-of-stock" ? "text-dark" : "primary1"
              }`}>
              {thirdTitle ? thirdTitle : title}
            </button> */}
               <button
                className={`${
                  btnChange == 2 ? "border_click_cust" : "cust-btn "
                } bg-transparent py-1 f3 fs-15 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 px-3 `}
                onClick={() => {
                  setBtnChange(2);
                  // getAddresslist(search, 1, order);
                }}
              >My Products
              </button>
            <div
              className={`ms-4 ${
                splitLocation[2] === "out-of-stock" ? "active" : null
              }`}></div>
          </div>
        </div>
      </div>
      <button
        onClick={onClick}
        className={`cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3`}>
        {btnText}
      </button>
    </div>
  );
};

export default HeaderProduct;
