// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLoginMutation, useVerifyTokenMutation } from "../../redux/api/api";
import { saveLoginHeader } from "../../redux/slice/loginHeader";
import ErrorIcon from "@mui/icons-material/Error";
import { saveLoader } from "../../redux/slice/loaderNoti";

const LoginAuth = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginHandler = () => {
    navigate("/dashboard");
    dispatch(saveLoginHeader("login"));
  };

  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [nameErr, setNameErr] = useState(false);
  const [passwordErr, setPasswordErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  //  RTK QUERY

  const [loginApi] = useLoginMutation();
  const [tokenVerifyApi] = useVerifyTokenMutation();

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  useEffect(() => {
    if (searchParams.get("token")) {
      let formdata = new FormData();
      formdata.append("token", searchParams.get("token"));
      tokenVerifyApi(formdata)
        .unwrap()
        .then((res) => {})
        .catch((err) => {
          console.log("err", err);
        });
    }
  }, []);

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  const submitHandler = () => {
    if (name?.length == 0 || password?.length == 0) {
      setNameErr(true);
      setPasswordErr(true);
    } else if (errorMessage?.length == 0) {
      let formdata = new FormData();

      formdata.append("email", name);
      formdata.append("password", password);
      setBtn(true);
      dispatch(saveLoader(true));
      loginApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          if (res?.status == "success") {
            localStorage.setItem("token", res?.token);
            localStorage.setItem("user_details", JSON.stringify(res?.user));
            setTimeout(() => {
              navigate("/dashboard");
              window.location.reload();
            }, 500);
          }
          setBtn(false);
        })
        .catch((err) => {
          console.log("err");
          dispatch(saveLoader(false));
          setBtn(false);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandler();
    }
  };

  // useEffect(() => {
  //   document.addEventListener("keydown", keydownHandler);

  //   return () => {
  //     document.removeEventListener("keydown", keydownHandler);
  //   };
  // }, []);

  // useEffect(() => {
  //   if (eventHandle == "Enter") {
  //     submitHandler();
  //   }
  // }, [eventHandle]);

  return (
    <div className="rit-auth reg-auth-rt">
      <div className="form-box">
        <h5>Login</h5>
        <div className="box">
          <p>Email</p>
          <input
            className=""
            onChange={(e) => setName(e.target.value)}
            value={name}
            onKeyDown={keydownHandler}
          />
          {validEmail.test(name) == false && nameErr && (
            <div className="d-flex gap-1 mt-2">
              {" "}
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Valid Email</p>
            </div>
          )}
        </div>
        <div className="box">
          <p>Password</p>
          <input
            className=""
            onChange={handlePassword}
            value={password}
            onKeyDown={keydownHandler}
          />
          {password?.length == 0 && passwordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Password</p>
            </div>
          )}
          {errorMessage && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">{errorMessage}</p>
            </div>
          )}
        </div>
        <button
          onClick={() => navigate("/forgot-password")}
          style={{ padding: "0 10px", fontFamily: "f1", fontSize: "14px" }}
          className="text-danger ms-auto me-0 d-flex mb-2 cust-btn"
        >
          Forgot Password
        </button>
        <div className="box">
          <button
            onClick={submitHandler}
            disabled={btn}
            className="w-100 mx-auto d-flex ac-jc border-0 login-btn bg-primar mb-3 rounded rounded-2 text-white"
          >
            Login
          </button>
        </div>

        {/* <button
          onClick={() => navigate("/forgot-password")}
          className="text-danger ms-auto me-0 d-flex mb-2 cust-btn">
          Forgot Password
        </button> */}
        <p className="parag f2 text-center d-flex flex-wrap ac-jc">
          Don’t you have an account?
          <button
            onClick={() => navigate("/signup")}
            className="f3 primary ms-1 cust-btn"
          >
            Signup
          </button>
        </p>
      </div>
    </div>
  );
};

export default LoginAuth;
