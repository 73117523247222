import { createSlice } from "@reduxjs/toolkit";

const loaderNoti = createSlice({
  name: "loader",
  initialState: false,
  reducers: {
    saveLoader: (state, action) => {
      return action.payload;
    },
  },
});

export const { saveLoader } = loaderNoti.actions;
export default loaderNoti.reducer;
