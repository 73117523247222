import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { discountIcon } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const RequiredSummaryPayment = ({
  OpenSummery,
  showClickHandler,
  showUpdate,
  openSummeryHandler,
  list,
  viewBreakdown,
  isLoading,
  submitHandler,
}) => {
  const navi = useNavigate();

  const totalSum = (sub_total, data) => {
    let amount = +sub_total;
    if (list?.storing_type == 1 || list?.storing_type == 2) {
      amount += +data?.shipping_storage_amount;
    }
    amount = amount - +data?.discount_amount;
    return amount
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
  };

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div
      className="req-summary"
      style={{ right: OpenSummery ? "0px" : "-400px" }}
    >
      <button
        style={
          OpenSummery
            ? {
                right: "270px",
                position: "fixed",
                top: "102px",
                zIndex: "1",
              }
            : {
                right: "0px",
                position: "fixed",
                top: "102px",
                zIndex: "1",
              }
        }
        onClick={openSummeryHandler}
        className="cust-btn d-flex flex-column ac-jc f4 fs-14 fs-md-15 fs-lg-17 close-btn11"
      >
        <CloseIcon />
      </button>
      <h4 className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-20 ms-2 primary">
        Request Summary
      </h4>
      <h4 className="f4 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-3">
        {list?.project_code}
      </h4>
      <p className="f2 fs-12 fs-sm-13 fs-md-14 fs-lg-14 ms-2 primary1 mt-2">
        Date Requested : {list?.start_date}
      </p>

      <div className="d-flex w-100 ac-jb mt-0 memb-dics">
        <div className="d-flex w-100 ac-js mt-0">
          <img src={discountIcon} alt="" className="w-10" />
          <p className="f2 fs-13 fs-sm-13 fs-md-14 ms-1 ps-0 text-dark mt-0 mb-0 ">
            MemberShip Discounts
          </p>
        </div>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 ms-0 ps-0 text-dark mt-0  mb-0">
          ₹{list?.discount_amount ? list?.discount_amount : 0}
        </p>
      </div>

      <div className="d-flex w-100 ac-jb mt-3">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Subtotal
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹{list?.sub_total ? commaValue(list?.sub_total) : ""}
        </p>
      </div>

      {(list?.storing_type == 1 || list?.storing_type == 2) && (
        <div className="d-flex w-100 ac-jb mt-0">
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 text-nowrap">
            Shipping Amount
            <ErrorOutlineIcon
              className="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-lg-17 ms-1 mb-0 pb-0 primary1 mt-0 "
              onClick={() => viewBreakdown(list)}
              role="button"
            />
          </p>
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
            ₹
            {list?.shipping_storage_amount
              ? commaValue(list?.shipping_storage_amount)
              : ""}
          </p>
        </div>
      )}

      {list?.storing_type == 3 && (
        <div
          className="d-flex w-100 ac-jb mt-0"
          onClick={() => viewBreakdown(list)}
          role="button"
        >
          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
            Storage
            <ErrorOutlineIcon className="f3 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-lg-17 ms-2 mb-0 pb-0 primary1 mt-0 " />
          </p>

          <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0 ">
            ₹
            {list?.shipping_storage_amount
              ? commaValue(list?.shipping_storage_amount)
              : ""}
          </p>
        </div>
      )}

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Total
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0">
          ₹{totalSum(+list?.sub_total, list)}
        </p>
      </div>

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Products GST
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹{list?.estimate_tax ? commaValue(list?.estimate_tax) : ""}
        </p>
      </div>

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Shipping GST
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0 op-50">
          ₹{list?.estimate_tax ? commaValue(list?.shipping_tax_amount) : ""}
        </p>
      </div>

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 primary1 mt-0">
          Total GST
        </p>
        <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-0">
          ₹{commaValue(+list?.estimate_tax + +list?.shipping_tax_amount)}
        </p>
      </div>

      <div className="d-flex w-100 ac-jb mt-0">
        <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
          Final Amount
        </p>
        <p className="f4 fs-13 fs-sm-14 fs-md-15 fs-lg-16 ms-2 text-dark mt-4">
          ₹{list?.total ? commaValue(list?.total) : ""}
        </p>
      </div>
      <button
        onClick={() => submitHandler()}
        // disabled={isLoading}
        className="w-100 bg-primar border-0 text-white py-2 rounded f3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-2"
      >
        Continue
      </button>
    </div>
  );
};

export default RequiredSummaryPayment;
