import React, { useState } from 'react'
import { banner, redeem_animated } from '../../assets/img'
import { Col, Row } from 'react-bootstrap'
import FormOne from './RedeemForm/FormOne'
import FormTwo from './RedeemForm/FormTwo'
import FormRightComp1 from './FormRightComp1'
import FormThree from './RedeemForm/FormThree'
import FormRightComp2 from './FormRightComp2'
import FormFour from './RedeemForm/FormFour'
import FormFive from './RedeemForm/FormFive'
import FormRightComp3 from './FormRightComp3'
import FormSix from './RedeemForm/FormSix'

const AutoshipForm = () => {
    const [form1, setForm1] = useState(true)
    const [form2, setForm2] = useState(false)
    const [form3, setForm3] = useState(false)
    const [form4, setForm4] = useState(false)
    const [form5, setForm5] = useState(false)
    const [form6, setForm6] = useState(false)
    const [count, setCount] = useState('')
    const [content, setContent] = useState('')
    const F1 = () => {
        setForm1(true)
        setForm2(false)
        setForm3(false)
        setForm4(false)
        setForm5(false)
        setForm6(false)
    }
    const F2 = () => {
        setForm2(true)
        setForm1(false)
        setForm3(false)
        setForm4(false)
        setForm5(false)
        setForm6(false)

        setCount(2)
        setContent('Choose your theme')
    }
    const F3 = () => {
        setForm2(false)
        setForm1(false)
        setForm3(true)
        setForm4(false)
        setForm5(false)
        setForm6(false)

        setCount(3)
        setContent('Design your landing page')
    }
    const F4 = () => {
        setForm2(false)
        setForm1(false)
        setForm3(false)
        setForm4(true)
        setForm5(false)
        setForm6(false)

        setCount(4)
        setContent('Product Selection')
    }
    const F5 = () => {
        setForm2(false)
        setForm1(false)
        setForm3(false)
        setForm4(false)
        setForm5(true)
        setForm6(false)
        setCount(5)
        setContent('Edit your confirmation page')
    }
    const F6 = () => {
        setForm2(false)
        setForm1(false)
        setForm3(false)
        setForm4(false)
        setForm5(false)
        setForm6(true)
        setCount(6)
        setContent('Shipment Settings')
    }

    return (
        <div className='d-flex gap-5 flex-column w-100 left-form flex-lg-row right-comp position-relative z-3'
            style={{ overflow: 'hidden' }}
        >
            <div style={{
                overflow: 'scroll',
                scrollBehavior: 'smooth',
                height: '100%'
            }} className='w-100 pt-3 pb-3'>

                {!form1 && <p className="f2 primary1 fs-xl-15 margin-custom fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 ">
                    Step {count}/6
                </p>}
                {form1 && <p className="f2 primary1 fs-xl-15 margin-custom fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 ">
                    Step 1/6
                </p>}
                {!form1 && <p
                    className="f4 fs-xs-18 fs-sm-19 margin-custom fs-md-20 fs-lg-21 fs-xl-23 mt-3 mb-0" >
                    {content}
                </p>}
                {form1 && <p
                    className="f4 fs-xs-18 fs-sm-19 margin-custom fs-md-20 fs-lg-21 fs-xl-23 mt-3 mb-0" >
                    Start with the basics
                </p>}
                {form1 && <FormOne F2={F2} />}
                {form2 && <FormTwo F1={F1} F3={F3} />}
                {form3 && <FormThree F2={F2} F4={F4} />}
                {form4 && <FormFour F3={F3} F5={F5} />}
                {form5 && <FormFive F4={F4} F6={F6} />}
                {form6 && <FormSix F5={F5} />}
            </div>
            <div className='w-100 custom-bottom-res' style={{
                // overflow: 'scroll',
                // scrollBehavior: 'smooth',
                height: '100%',
            }}>
                {form3 && <FormRightComp1 />}
                {form2 && <FormRightComp1 />}
                {form1 && <FormRightComp1 />}
                {form4 && <FormRightComp2 />}
                {form5 && <FormRightComp3 />}
                {form6 && <FormRightComp3 />}
            </div>
        </div>
    )
}

export default AutoshipForm


