import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { support, supportPhone } from "../../assets/img";

const HomeSupport = () => {
  const navigate = useNavigate();
  return (
    <div
      className=" d-flex flex-column flex-md-row ac-jc "
      style={{
        backgroundColor: "#F2F7FF",
        padding: "60px 0",
        marginBottom: "40px",
      }}
    >
      <div className=" d-flex flex-wrap ac-jb">
        <div className="col-md-6 d-flex ac-jc">
          <img
            className="support-img m-4"
            src={support}
            alt="icon"
            style={{ width: "340px" }}
          />
        </div>
        <div className="col-md-6 d-flex flex-column align-items-center align-items-md-start">
          <p className="parag mt-2 w-80 mb-5">
            Got something on your mind? We’re all about keeping it real and
            staying connected. No doubts go un clarified.
          </p>
          <div className="d-flex support-cont-home align-items-center mt-4 mt-md-0">
            <img src={supportPhone} al="icon" />
            {/* <h4 className="ms-2">Call us @ 9945012000</h4> */}
            <button
              className="cust-btn mx-3"
              onClick={() => navigate("/book-consult")}
            >
              <Link className="get-start-btn1 px-3 " to="/">
                Request a call back
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSupport;
