// @ts-nocheck
import React from "react";
import { bg1, logo } from "../assets/img";
import LeftAuth from "../component/Auth/LeftAuth";
import LoginAuth from "../component/Auth/LoginAuth";
import { useNavigate } from "react-router-dom";
import MenuItem from "../navbar/MenuItem";

const LoginScreen = () => {
  const navigate = useNavigate();
  return (
    <div className="sign-up-new1w-xs-90 w-lg-70 d-flex flex-column flex-lg-row mx-auto mb-5">
      {/* <div>
        <img
          src={logo}
          onClick={() => navigate("/")}
          className="main-logo"
       />
      </div> */}
      {/* <img src={bg1} alt="" className="bg1" /> */}
      <div className=" mt-0">
        <LeftAuth />
      </div>
      <LoginAuth />
      {/* <img src={bg1} alt="" className="bg2" /> */}
    </div>
  );
};

export default LoginScreen;
