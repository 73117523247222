import React, { useState } from 'react'
import { file, noteIcon, redeem_animated } from '../../../assets/img'
import { Col } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const FormOne = ({ F2 }) => {
    const navigation = useNavigate()
    const [imgfile, setImgFile] = useState('')

    const handleChange = (e) => {
        console.log(e.target.files);
        setImgFile(URL.createObjectURL(e.target.files[0]));
    }
    return (
        <div className='align-custom1 d-flex flex-column mt-3'
            style={{
                // height: '132%',
                alignItems: 'end',
                overflow: 'scroll',
                scrollBehavior: 'smooth',
            }
            }>
            <div className="box1 w-85">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Page Name*
                </p>
                <input placeholder="Enter Page Name" className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
            </div>
            <div className="box1 w-85">
                <p className="f2 primary1 mt-4 mb-3 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Company Name
                </p>
                <input placeholder="Daniel Scott" className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
            </div>
            <div className=" w-85">
                <p className="f2 mt-4 mb-3 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Choose Logo
                </p>
                <div className='d-flex flex-column flex-md-row w-100 rounded-5 form-box-shadow py-5 d-flex align-items-center justify-content-around'>
                    <img src={imgfile.length === 0 ? file : imgfile} className='file-up-img mb-res-5' />
                    <div className=''>
                        <p className='text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-nowrap'>Drag and drop to uplode or
                            <span>
                                <label class="label">
                                    <input type="file" className=''
                                        // value={imgfile}
                                        // onChange={(e) => setImgFile(e.target.value)}
                                        onChange={handleChange}
                                    />
                                    <span className='text-center mb-0 f3 text-primary fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-nowrap ps-1'>Choose file</span>
                                </label>
                            </span>
                        </p>
                        <p className='text-center mb-0  primary1 fs-xl-11 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-11 f2'>Accepted File Types: .PNG .JPEG .JPG .SVG </p>
                        <p className='text-center mb-0  primary1 fs-xl-11 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-11 f2'>(Max size 5MB)</p>
                    </div>
                </div>
                <div className='d-flex align-items-end w-100 mt-5 ac-jb'
                // style={{ height: '35vh', }}
                >
                    {/* <div className='d-flex ac-jb w-100'> */}
                    <button className='cust-btn text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '
                        onClick={() => navigation('/redeem')}
                    >
                        <ArrowBackIcon
                            className='text-center mb-0 f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-14 fs-xs-15' /> Previous Step</button>
                    <button className='get-start-btn border-0 mt-0' onClick={F2}>Continue</button>
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}

export default FormOne