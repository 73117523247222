import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useRegisterMutation,
  useVerifyTokenMutation,
} from "../../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const RegistrationAuth = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [btn, setBtn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  const [emailErr, setEmailErr] = useState(false);
  const [passwordErr, setSetPasswordErr] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState(false);
  const [lastNameErr, setLastNameErr] = useState(false);
  const [organizationErr, setOrganizationErr] = useState(false);
  const [phoneErr, setPhoneErr] = useState(false);

  // RTK QUER
  const [registerApi] = useRegisterMutation();

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  const submitHandle = () => {
    if (
      email?.length == 0 ||
      password?.length < 8 ||
      firstName?.length == 0 ||
      lastName?.length == 0 ||
      organization?.length == 0 ||
      phone?.length < 10
    ) {
      setEmailErr(true);
      setSetPasswordErr(true);
      setFirstNameErr(true);
      setLastNameErr(true);
      setOrganizationErr(true);
      setPhoneErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("first_name", firstName);
      formdata.append("email", email);
      formdata.append("mobile", phone);
      formdata.append("organisation", organization);
      formdata.append("password", password);
      formdata.append("last_name", lastName);
      formdata.append("type", 1);
      dispatch(saveLoader(true));
      setBtn(true);
      registerApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          toast.success(
            "Account Created Successfully! Please Check Your Email"
          );
          navigate("/login");
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandle();
    }
  };

  useEffect(() => {
    if (searchParams.get("email")) {
      setEmail(searchParams.get("email"));
    }
  }, []);

  return (
    <div className="rit-auth reg-auth-rt">
      <div className="form-box form-box1">
        <h5>Create an Account</h5>
        <div className="box">
          <p>Email</p>
          <input
            className=""
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            onKeyDown={keydownHandler}
          />
          {validEmail.test(email) == false && emailErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Valid Email ID</p>
            </div>
          )}
        </div>
        <div className="box">
          <p>Password</p>
          <input
            className=""
            onChange={(e) => handlePassword(e)}
            value={password}
            onKeyDown={keydownHandler}
          />
          {password?.length == 0 && passwordErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Password</p>
            </div>
          )}
          {errorMessage && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">{errorMessage}</p>
            </div>
          )}
        </div>
        <div className="d-flex">
          <div className="box">
            <p>First Name</p>
            <input
              className=""
              onChange={(e) => setFirstName(e.target.value)}
              value={firstName}
            />
            {firstName?.length == 0 && firstNameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter First Name</p>
              </div>
            )}
          </div>
          <div className="box">
            <p>Last Name</p>
            <input
              className=""
              onChange={(e) => setLastName(e.target.value)}
              value={lastName}
            />
            {lastName?.length == 0 && lastNameErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Last Name</p>
              </div>
            )}
          </div>
        </div>
        <div className="box">
          <p>Organization</p>
          <input
            className=""
            onChange={(e) => setOrganization(e.target.value)}
            value={organization}
          />
          {organization?.length == 0 && organizationErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Organization</p>
            </div>
          )}
        </div>
        <div className="box">
          <p>Phone</p>
          <input
            className=""
            onInput={(e) => {
              if (e.target.value.length > e.target.maxLength)
                e.target.value = e.target.value.slice(0, e.target.maxLength);
              if (e.target.value.length !== 10) {
                e.target.setCustomValidity("invalid Number");
              } else if (e.target.value.length == 10) {
                e.target.setCustomValidity("");

                setPhone(e.target.value);
              }
            }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
            maxLength={10}
            name="mobile"
            onChange={(e) => {
              setPhone(e.target.value);
            }}
            type="number"
            value={phone}
            onKeyDown={keydownHandler}
          />
          {phone?.length < 10 && phoneErr && (
            <div className="d-flex gap-1 mt-2">
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Phone No.</p>
            </div>
          )}
        </div>
        <div className="box">
          <button
            onClick={() => submitHandle()}
            disabled={btn}
            className="w-100 mx-auto d-flex ac-jc border-0 login-btn bg-primar rounded rounded-2 text-white mb-2"
          >
            Register
          </button>
        </div>
        <p className="parag f2 text-center d-flex flex-wrap ac-jc">
          Already have an account?{"  "}
          <Link to="/login" className="f3 primary ms-1">
            Login
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegistrationAuth;
