import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const BookConsultRight = () => {
  return (
    <div className="Integration-screen col-md-6">
      <h4 className="mb-0 w-80 f4 fs-xs-23 fs-sm-30 fs-md-32 fs-lg-35 fs-xl-40">
      Ring, Ring! We’ll call you back ASAP.
      </h4>
      <div className="d-flex w-100 mt-3">
        <div className="w-50">
          <p className="parag2 text-primary1 mt-1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
            Call Us
          </p>
          <div className="d-flex">
            <LocalPhoneIcon className="primary fs-xs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21" />
            <h6 className="ms-2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
              +91 98765 43210
            </h6>
          </div>
        </div>
        <div className="w-50">
          <p className="parag2 text-primary1 mt-1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
            Send Email
          </p>
          <div className="d-flex">
            <EmailIcon className="primary fs-xs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21" />
            <h6 className="ms-2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
              team@merchhq.com
            </h6>
          </div>
        </div>
      </div>
      <h6 className="mt-4 primary fs-xs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-17">
        Fill out this quick form to schedule a call with out team.
      </h6>
      <div className="d-flex flex-column flex-md-row w-90 ac-jb">
        <div className="box w-100">
          <p className="primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
            First Name
          </p>
          <input placeholder="name" className="" />
        </div>
        <div className="box w-100">
          <p className="primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
            Last Name
          </p>
          <input placeholder="name" className="" />
        </div>
      </div>
      <div className="d-flex flex-column flex-md-row w-90 ac-jb mt-2">
        <div className="box w-100">
          <p className="primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
            Email
          </p>
          <input placeholder="name@example.com" className="" />
        </div>
        <div className="box w-100 position-relative">
          <p className="primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
            Organization
            <span className="icon-pop1 ms-1">
              <ErrorOutlineIcon
                style={{ cursor: "pointer" }}
                className="f3 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-lg-15 mb-0 pb-0 primary1 mt-0 text-center"
              />
              <div className="icon-pop2 position-absolute justify-content-center align-items-center d-flex">
                <p className="f3 mb-0 pb-0 text-dark mt-0 text-center px-3">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </p>
              </div>
            </span>
          </p>
          <input placeholder="Ninos IT Solution" className="" />
        </div>
      </div>
      <div className="mt-4">
        <button className="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-5 rounded px-lg-4x">
          Submit
        </button>
      </div>
    </div>
  );
};

export default BookConsultRight;
