import React, { useState } from "react";
import ListIcon from "@mui/icons-material/List";
import {
  billingIcon,
  companyIcon,
  listIcon,
  membershipIcon,
  profileIcon,
  SpecialCateg,
} from "../../assets/img";
import { useLocation, useNavigate } from "react-router-dom";

const ProfileLeftTab = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [ntg, pathname1, pathname2] = location.pathname.split("/");
  const [categShow, setCategShow] = useState("");
  const [billingNavShow, setBillingNavShow] = useState(false);

  // console.log(pathname2);
  return (
    <div
      className={`${
        categShow ? "left-product-show" : "left-product-hid"
      } left-product`}
    >
      <div className="d-flex align-items-center justify-content-lg-center">
        <div className="">
          <button
            onClick={() => navigate("/account/profile")}
            className="d-flex align-items-center list-box"
          >
            <div
              className={` ${
                pathname2 === "profile" ? "active" : "img-back"
              } border-0  d-flex ac-jc my-1`}
            >
              <img src={profileIcon} alt="" />
            </div>
            <p className="mb-0 ms-3 f3">Profile</p>
          </button>
          <button
            onClick={() => navigate("/account/company")}
            className="d-flex align-items-center list-box"
          >
            <div
              className={` ${
                pathname2 === "company" ? "active" : "img-back"
              } border-0  d-flex ac-jc my-1`}
            >
              <img src={companyIcon} alt="" />
            </div>
            <p className="mb-0 ms-3 f3">Company</p>
          </button>
          <button
            onClick={() => navigate("/account/branches")}
            className="d-flex align-items-center list-box"
          >
            <div
              className={` ${
                pathname2 === "branches" ? "active" : "img-back"
              } border-0  d-flex ac-jc my-1`}
            >
              <img src={companyIcon} alt="" />
            </div>
            <p className="mb-0 ms-3 f3">Branches</p>
          </button>
          {/* <button
            onClick={() => setBillingNavShow(!billingNavShow)}
            className="d-flex align-items-center list-box">
            <div
              className={` ${billingNavShow || pathname2 === 'accounts' || pathname2 === 'invoices' || pathname2 === 'transactions' ? "active" : "img-back"
                } border-0  d-flex ac-jc my-1`}>
              <img src={billingIcon} alt="" />
            </div>
            <p className="mb-0 ms-3 f3">Billing</p>
          </button>
          {billingNavShow && (
            <>
              <button
                onClick={() => navigate("/billing/accounts")}
                className="d-flex align-items-center list-box">
                <div style={{ width: "50px" }}></div>
                <p className="mb-0 ms-3 f3">My Accounts</p>
              </button>
              <button
                onClick={() => navigate("/billing/invoices")}
                className="d-flex align-items-center list-box">
                <div style={{ width: "50px" }}></div>
                <p className="mb-0 ms-3 f3">Invoices</p>
              </button>
              <button
                onClick={() => navigate("/billing/transactions")}
                className="d-flex align-items-center list-box">
                <div style={{ width: "50px" }}></div>
                <p className="mb-0 ms-3 f3">Transactions</p>
              </button>
            </>
          )}
          <button
            onClick={() => navigate("/account/membership")}
            className="d-flex align-items-center list-box">
            <div
              className={` ${pathname2 === "membership" ? "active" : "img-back"
                } border-0  d-flex ac-jc my-1`}>
              <img src={membershipIcon} alt="" />
            </div>
            <p className="mb-0 ms-3 f3">Membership</p>
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default ProfileLeftTab;
