import React from 'react'
import ShipmentHeader from './ShipmentHeader'
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useState } from 'react';
const ShipmentDatas = () => {
    const [check, setCheck] = useState(false)
    const checkBox = () => {
        setCheck(!check)
    }
    return (
        <div className='shipment-inventory  h-100' style={{ width: '100%' }}>
            <ShipmentHeader />
            <div className='d-flex ms-1 px-5' style={{ overflow: 'scroll', width: '100%' }}>
                <table class="rwd-table float-start w-80">
                    <tbody>
                        <tr className='d-flex align-items-center justify-content-evenly'>
                            <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Name</th>
                            <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11  text-start'>Groups</th>
                            <th className='primary1 f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '>Email</th>
                        </tr>
                        <tr className='d-flex mt-3'>
                            <td data-th="Supplier Code" className='d-flex align-items-center gap-3' style={{ width: '310px' }}>
                                {check && <CheckBoxIcon onClick={checkBox} />}
                                {!check && <CheckBoxOutlineBlankIcon onClick={checkBox} />}
                                <p class="parag mb-0 mt-0 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9">JA</p>
                                <div>
                                    <p class="parag mb-0 mt-0 text-dark s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9">Jake Adams</p>
                                    <p class="parag mb-0 mt-0 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9">Manager</p>
                                    <p class="parag mb-0 mt-0 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9">Building No.172, Shop No.230 BC Third Floor, Chennai, TN 600026, IN</p>
                                </div>
                            </td>
                            <td data-th="Supplier Name parag text-center" style={{ width: '140px', }}>
                                -
                            </td>
                            <td data-th="Invoice Number" className='parag f2 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9' style={{ width: '200px' }}>
                                operations@ecphasisinfotech.com
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className='d-flex ms-5 align-items-center'>
                    <button class="get-start-btn bg-lt-blue primary  my-0 mx-2 f4 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11" style={{ borderColor: 'primary' }}>Send merch</button>
                    <DeleteRoundedIcon className='bg-lt-blue primary' />
                </div>
            </div>
        </div>
    )
}

export default ShipmentDatas