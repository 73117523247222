import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
const IntegrationScreen = () => {
  return (
    <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2">
      <div className="Integration-screen d-flex d-xs-grid d-xl-flex d-lg-flex d-md-flex  flex-wrap  ac-jb">
        <h4 className="mb-0 w-50 f4 fs-xs-23 fs-sm-30 fs-md-32 fs-lg-35 fs-xl-40 mb-2">
          Integrations
        </h4>
        <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3  ">Create Automation</button>
      </div>
      <div className="Integration-screen">
        <h4 class="fs-xs-19 fs-sm-20 fs-md-21 fs-lg-22 fs-xl-23 f4 mt-3">
          Zapier connects MerchHQ to all the software you rely on
        </h4>
        <div className="d-flex flex-wrap w-100">
          <div className="d-flex mt-3 timeCont col-md-6">
            <AccessTimeIcon className="fs-xs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21" />
            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 primary1 mb-0 ms-2">
              Automate repetitive tasks without writing code
            </p>
          </div>
          <div className="d-flex mt-3 timeCont col-md-6">
            <TrendingUpIcon className="fs-xs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21" />
            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 primary1 mb-0 ms-2">
              Build custom workflows to save time
            </p>
          </div>
        </div>
        <div className="d-flex flex-wrap w-100">
          <div className="d-flex mt-3 timeCont col-md-6">
            <IntegrationInstructionsIcon className="fs-xs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21" />
            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 primary1 mb-0 ms-2">
              Connect 5,000+ apps you already use
            </p>
          </div>
          <div className="d-flex mt-3 timeCont col-md-6">
            <CardGiftcardIcon className="fs-xs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xl-21" />
            <p className="fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 primary1 mb-0 ms-2">
              Free forever for core features, 14-day free trial for premium
              features
            </p>
          </div>
        </div>
        <h4 class="fs-xs-18 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 f4 mt-4">
          Your MerchHQ Zaps
        </h4>
      </div>
    </div>
  );
};

export default IntegrationScreen;
