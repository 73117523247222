// @ts-nocheck
import React, { useState } from "react";
import { upload1 } from "../assets/img";
import RedeemInput from "../component/RedeemInput/RedeemInput";

const RedeemProduct = () => {
  const [pageName, setPageName] = useState("");

  const [uploadImage, setUploadImage] = useState();

  const fileChange = (e) => {
    setUploadImage(URL.createObjectURL(e.target.files[0]));
    console.log(e.target.files);
  };

  const onChangeHandler = (e) => {
    setPageName(e.target.value);
  };

  return (
    <div className="redeem-product">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2 my-4">
        <p className="parag">Step 1/6</p>
        <h4 className="sect-title">Start with the basics</h4>
        <div className="input-box">
          <RedeemInput
            title="Page Name"
            placeholder="Enter Page Name *"
            validation="Enter Page Name"
            value={pageName}
            type="text"
            onChange={onChangeHandler}
          />
          <RedeemInput
            title="Company Name"
            placeholder="Company Name *"
            validation="Enter Page Name"
            value={pageName}
            type="text"
            onChange={onChangeHandler}
          />
          <p>Choose Logo</p>
        </div>
        <div className="file-upload-input1 w-100 d-flex flex-column ac-jc">
          <label className="w-100 position-relative">
            <input
              className="op-0"
              onChange={fileChange}
              type="file"
              id="images"
              required
            />
            <div className="d-flex ac-jb w-90 mx-auto">
              <img className="upload1" src={upload1} alt="" />
              <div className="text-center ps-3">
                <h6 className="f3 fs-13 fs-md-15 fs-xl-17 primary">
                  Drag and drop to upload or{" "}
                  <span className="text-primary">Choose File</span>
                </h6>
                <p className="f1 fs-12 fs-md-14  primary1 mb-0">
                  Accepted File Types: .PNG, .GIf, .JPEG, .JPG, .SVG (Max size
                  5MB)
                </p>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default RedeemProduct;
