import React, { useEffect, useState } from "react";
import BlogEnquiry from "../component/Company/Blog/BlogEnquiry";
import BlogTab from "../component/Company/Blog/BlogTab";
import Footer from "../navbar/Footer";
import BlogBanner from "../component/Company/Blog/BlogBanner";
import { blog_bg, blog_tip } from "../assets/img";
import { useLocation, useNavigate } from "react-router-dom";
import { useBlog_viewQuery, useLazyBlog_viewQuery } from "../redux/api/api";
import DOMPurify from "dompurify";

const BlogTip = () => {
  const [list,setList]=useState([]);
  const location= useLocation();
  console.log(location?.state?.id,"location");
  
  //RTK
  const [blogsViewApi]=useLazyBlog_viewQuery();
   
  const getBlogList=()=>{
    blogsViewApi(location?.state?.id)
    .unwrap()
    .then(res=>{
      console.log(res?.blog)
      setList(res?.blog)
    }).catch(err=>{
      console.log(err)
    })
  }
 useEffect(()=>{
  if(location?.state?.id){

    getBlogList()
  }
 },[]) 
 const [limit,setLimit]=useState("");
 const [limit150,setLimit150]=useState("");
 const handleChange=(event)=>{
  setLimit(event.target.value.slice(0,50))
  console.log(limit)
 }
 const handleChange150=(event)=>{
  setLimit(event.target.value.slice(0,150))
  console.log(limit150)
 }

  return (
    <div className="tl-blog">
      <div className="bg-blue1 p-lg-5 p-sm-4 p-3">
        <h4 className="f4 mb-0 white fs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xxl-22 ms-2 ps-1">Blog Details</h4>
      </div>
      <div className="w-90 m-auto">
        <h4 className="sect-title fs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xxl-22 my-3  blue" value={limit}
        onChange={(event)=>handleChange(event)}
        >
          {list?.title}
        </h4>
        {/* <h4 className="sect-title fs-16 fs-sm-18 fs-md-19 fs-lg-20 fs-xxl-22 my-3  blue">
          MerchHQ Launches Membership Plans
        </h4> */}
        
        <div className="blogg-img">
          <img src={list?.image_url?list?.image_url:blog_tip} alt="" className="w-100 rounded mt-2" />
        </div>
        <br />
        <div>
          <h5 className="f3 text-dark fs-15 fs-sm-17 fs-md-18 fs-lg-19 fs-xxl-20 w-100 mt-4"  value={limit150}
        onChange={(event)=>handleChange150(event)}>
            {list?.short_description}
          </h5>
          {/* <h5 className="f3 text-dark fs-15 fs-sm-17 fs-md-18 fs-lg-19 fs-xxl-20 w-100 mt-4">
            MerchHQ is offering an opportunity to get more from your swag
            experience with their brand new membership program. However, it's
            important to approach investing with a strategy that minimizes risk
            and maximizes return. One key element of a successful investment
            strategy is diversification.
          </h5> */}
        </div>
        <br />
        <div>
          <p class="f1 blue2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18 mb-3"
          
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              list?.description
            ),
          }}
          >
          {/* {list?.description} */}
          </p>
        </div>
        <br />
        {/* <div>
          <p class="f1 blue2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18 mb-3">
            2. Increases Returns Diversification can also help increase returns
            by allowing you to take advantage of multiple market opportunities.
            By spreading your investments across different sectors, asset
            classes, and geographic regions, you can tap into a wide range of
            investment opportunities. This can help you capture gains in areas
            that are performing well, while minimizing losses in areas that are
            underperforming.
          </p>
        </div>
        <br /> */}
        {/* <div>
          <p class="f1 blue2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18 mb-3">
            3. Provides Portfolio Stability Diversification can also provide
            portfolio stability by helping to balance out your investments. When
            you invest in a variety of assets, your portfolio becomes less
            reliant on any one particular investment. This can help provide a
            more consistent rate of return over time, which is critical for
            achieving your long-term investment goals.
          </p>
        </div>
        <br /> */}
        {/* <div>
          <p class="f1 blue2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18 mb-3">
            4. Helps to Manage Emotions Investing can be an emotional
            rollercoaster, and it's not uncommon for investors to get caught up
            in market fluctuations. Diversification can help manage these
            emotions by providing a buffer against volatility. By having a
            well-diversified portfolio, investors are less likely to react
            emotionally to market ups and downs, which can help them stay the
            course and remain focused on their long-term investment goals.
          </p>
        </div>
        <br /> */}
        {/* <div>
          <p class="f1 blue2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 fs-xl-16 fs-xxl-18 mb-3">
            5. Helps to Simplify Investing Finally, diversification can help to
            simplify investing by providing a more structured approach to
            portfolio management. By diversifying across a variety of
            investments, you can build a portfolio that is aligned with your
            investment goals, risk tolerance, and time horizon. This can help to
            streamline the investment process and make it easier to manage your
            investments over time.
          </p>
        </div> */}
        {/* <br /> */}
        
      </div>
      <Footer />
    </div>
  );
};

export default BlogTip;
