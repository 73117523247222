// @ts-nocheck
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Container } from "@mui/system";
import { Button } from "react-bootstrap";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import DeleteIcon from "@mui/icons-material/Delete";
import TellProject from "./TellProject";
import EcommerceMockReqCart from "./EcommerceMockReqCart";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useAddressAddMutation,
  useAddressListMutation,
  useAddress_EditMutation,
  useAddToCartMutation,
  useCartListMutation,
  useDeleteCartMutation,
  useLazyAddressDeleteQuery,
  useLazyAddressViewQuery,
  useProductReqMutation,
  useProductViewMutation,
} from "../../../redux/api/api";
import { saveLoader } from "../../../redux/slice/loaderNoti";
import { useDispatch } from "react-redux";
import RequestView from "./RequestView";
import { toast } from "react-hot-toast";
import ProductPopupComponent from "../Ecommerce/ProductPopupComponent";

const ContactDetails = ({ showContactHandler }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user_details");
  const userDetails = JSON.parse(localStorage.getItem("user_details"));
  const json = JSON.parse(user);
  const [name, setName] = useState(json?.first_name);
  const [num, setNum] = useState(json?.mobile);
  const [email, setEmail] = useState(json?.email);
  const [viewId, setViewId] = useState("");
  const [packsPrice, setPacksPrice] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [value, setValue] = useState();
  const [viewList, setViewList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tick, setTick] = useState([]);
  const [style, setStyle] = useState({});
  const [size, setSize] = useState({});
  const [material, setMaterial] = useState({});
  const [selectedColorId, setSelectedColorId] = useState("");
  const [selectColorId, setSelectColorId] = useState("");
  const [cartData, setCartData] = useState({});
  const [imagesid, setImagesId] = useState({});
  const [bigImg, setBigImg] = useState(0);
  const [count, setCount] = useState(1);
  const [selectedIds, setSelectedIds] = useState([]);
  const [ListPrice, setListPrice] = useState({});
  const [skuCodeList, setCodeList] = useState([]);
  const [no_pack, setNo_of_pack] = useState(25);
  const [cartBtn, setCartBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [cortList, setCartList] = useState([]);
  const [priceList, setPriceList] = useState();
  const [pack, setPack] = useState(25);
  const [totalPrice, setTotalPrice] = useState("");
  const [showoption, setShowOption] = useState(true);
  const [btn, setBtn] = useState(false);
  const [viewData, setViewData] = useState({});
  const [addressList, setAddressList] = useState([]);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [updateAddressId, setUpdateAddressId] = useState("");
  const [addressId, setAddressId] = useState({});
  const [qtyBtn, setQtyBtn] = useState(false);

  const [nameAdd, setNameAdd] = useState("");
  const [address1Add, setAddress1Add] = useState("");
  const [address2Add, setAddress2Add] = useState("");
  const [cityAdd, setCityAdd] = useState("");
  const [countryAdd, setCountryAdd] = useState("");
  const [stateAdd, setStateAdd] = useState("");
  const [landmarkAdd, setLandmarkAdd] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [landmark, setLandmark] = useState("");
  const [altphone, setAltphone] = useState("");
  const [total, setTotal] = useState("");
  const [userCartID, setUserCartID] = useState("");

  const [nameErr, setNameErr] = useState(false);
  const [numErr, setNumErr] = useState(false);
  const [nameAddErr, setNameAddErr] = useState(false);
  const [address1AddErr, setAddress1AddErr] = useState(false);
  const [address2AddErr, setAddress2AddErr] = useState(false);
  const [cityAddErr, setCityAddErr] = useState(false);
  const [countryAddErr, setCountryAddErr] = useState(false);
  const [stateAddErr, setStateAddErr] = useState(false);
  const [landmarkAddErr, setLandmarkAddErr] = useState(false);
  const [address1Err, setAddress1Err] = useState(false);
  const [address2Err, setAddress2Err] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [countryErr, setCountryErr] = useState(false);
  const [stateErr, setStateErr] = useState(false);
  const [landmarkErr, setLandmarkErr] = useState(false);
  const [altphoneErr, setAltphoneErr] = useState(false);
  const [emailErr, setEmailErr] = useState(false);

  const [addressShow, setAddressShow] = useState(0);

  const showPopupHander = () => {
    setShowPopup(!showPopup);
  };
  const addProductHandler = (e) => {
    setTick(!tick);
  };

  //RTK
  const [productViewApi] = useProductViewMutation();
  const [addToCartApi] = useAddToCartMutation();
  const [cartListApi] = useCartListMutation();
  const [deleteApi] = useDeleteCartMutation();
  const [productReqApi] = useProductReqMutation();
  const [addressListApi] = useAddressListMutation();
  const [addressAddApi] = useAddressAddMutation();
  const [addressEditApi] = useAddress_EditMutation();
  const [addressDeleteApi] = useLazyAddressDeleteQuery();
  const [addressViewApi] = useLazyAddressViewQuery();

  // ADD TO CART
  const varients = (
    id,
    qty,
    packsPrice,
    colors_name,
    styles_name,
    materials_name,
    sizes_name,
    skuCodes,
    type
  ) => {
    let temp = "";

    if (selectedColorId) {
      temp = `/${selectedColorId}`;
    }

    if (size) {
      temp = temp + `/${size}`;
    }

    if (material) {
      temp = temp + `/${material}`;
    }

    if (style) {
      temp = temp + `/${style}`;
    }
    console.log(temp, "temp");
    let codeFinder;

    // if (type == "no_cart") {
    //   codeFinder = skuCodes?.find((i) => i?.variant_name == temp);
    // } else {
    codeFinder = skuCodeList?.find((i) => i?.variant_name == temp);
    // }

    // setCode(skuCode)
    if (codeFinder?.id) {
      dispatch(saveLoader(true));
      setCartBtn(true);
      // if (Token) {
      getAddToCart(codeFinder?.id, id, qty, packsPrice);
      // } else {
      // addLocalCart(codeFinder?.id, id, qty, packsPrice, type);
      // }
    } else {
      dispatch(saveLoader(false));
      setCartBtn(false);
    }
  };
  const varientsNew = (
    id,
    qty,
    packsPrice,
    colorID,
    colorName,
    noOfColors,
    size,
    material,
    style
  ) => {
    let temp_str = "";

    if (colorName) {
      temp_str = `/${colorName}`;
    } else {
      temp_str = temp_str + `/`;
    }

    if (size) {
      temp_str = temp_str + `/${size}`;
    } else {
      temp_str = temp_str + `/`;
    }

    if (material) {
      temp_str = temp_str + `/${material}`;
    } else {
      temp_str = temp_str + `/`;
    }

    if (style) {
      temp_str = temp_str + `/${style}`;
    } else {
      temp_str = temp_str + `/`;
    }

    let codeFinder = skuCodeList?.find((i) => i?.variant_name == temp_str);
    if (codeFinder?.id) {
      let packs_val = +cartData?.[id]?.packs_price
        ? +cartData?.[id]?.packs_price
        : +cartData?.[id]?.for_price;

      let pack_value = cartData?.[id]?.pack;

      let count_value = cartData?.[id]?.count
        ? +cartData?.[id]?.count
        : +cartData?.[id]?.count_value;

      let no_of_colors = noOfColors
        ? noOfColors
        : cartData?.[id]?.no_of_colors
        ? +cartData?.[id]?.no_of_colors
        : 1;

      let temp = [];

      temp.push({
        product_id: id,
        product_sku_id: codeFinder?.id,
        no_of_colors: no_of_colors,
        qty: qty ? qty : count_value,
        price: +packsPrice ? +packsPrice : +packs_val,
      });

      const formData = new FormData();
      formData.append("type", 4);
      formData.append("no_of_packs", 1);

      if (temp?.length > 0) {
        temp?.map((item, ind) => {
          formData.append(`cart_details[${ind}][qty]`, item?.qty);
          formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
          if (item?.product_sku_id) {
            formData.append(
              `cart_details[${ind}][product_sku_id]`,
              item?.product_sku_id
            );
          }

          formData.append(`cart_details[${ind}][price]`, item?.price);
          formData.append(
            `cart_details[${ind}][no_of_colors]`,
            item?.no_of_colors
          );
        });

        addToCartApi(formData)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            getCartApi();
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      }
    }
  };
  // ADD CART
  const getAddToCart = (skuId, productId, qty, packsPrice) => {
    let pack_value = cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let no_of_colors = cartData?.[productId]?.no_of_colors
      ? +cartData?.[productId]?.no_of_colors
      : 1;

    let temp = [];

    temp.push({
      product_id: productId,
      product_sku_id: skuId,
      no_of_colors: no_of_colors,
      qty: qty ? qty : count_value,
      price: +packsPrice ? +packsPrice : +packs_val,
    });

    const formData = new FormData();
    formData.append("type", 4);
    formData.append("no_of_packs", 1);
    // formData.append("product_id", productId);
    // formData.append("product_sku_id", skuId);
    // formData.append("qty", qty ? qty : count_value);
    // formData.append("no_of_colors", colorcount);
    // formData.append("price", +packsPrice ? +packsPrice : +packs_val);

    if (temp?.length > 0) {
      temp?.map((item, ind) => {
        let qty_sum = +item?.qty * +no_pack;

        if (+qty_sum >= 1 && +qty_sum <= 25) {
          qty_sum = 25;
        } else if (+qty_sum > 25 && +qty_sum <= 50) {
          qty_sum = 50;
        } else if (+qty_sum > 50 && +qty_sum <= 100) {
          qty_sum = 100;
        } else if (+qty_sum > 100 && +qty_sum <= 150) {
          qty_sum = 150;
        } else if (+qty_sum > 150 && +qty_sum <= 250) {
          qty_sum = 250;
        } else if (+qty_sum > 250 && +qty_sum <= 500) {
          qty_sum = 500;
        } else if (+qty_sum > 500 && +qty_sum <= 1000) {
          qty_sum = 1000;
        } else if (+qty_sum > 1000) {
          qty_sum = 1000;
        }

        formData.append(`cart_details[${ind}][qty]`, item?.qty);
        formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
        formData.append(
          `cart_details[${ind}][product_sku_id]`,
          item?.product_sku_id
        );

        if (item?.product_id == ListPrice?.id) {
          formData.append(
            `cart_details[${ind}][price]`,
            ListPrice?.[`for_${qty_sum}`]
          );
        } else {
          formData.append(`cart_details[${ind}][price]`, item?.price);
        }
      });
    }

    // console.log("productId", productId);

    addToCartApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        if (!qty) {
          showPopupHander();
        }
        const temp = [];
        temp.push(viewId);
        setTick(temp);
        getCartApi();
        // console.log(res);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };
  // console.log(selectedColorId, "selectedColorId");
  // DELETE CART
  const deleteCart = (id, pro_id) => {
    // if (!Token) {
    //   deleteLocalCart(pro_id);
    // } else {
    const formData = new FormData();
    formData.append("user_cart_detail_id", id);
    // console.log("pro_id", pro_id);
    dispatch(saveLoader(true));
    setDeleteBtn(true);
    deleteApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res?.navigations);
        // setCategoryList(res?.navigations);

        dispatch(saveLoader(false));
        setDeleteBtn(false);
        let obj = { ...cartData };
        delete obj[pro_id];
        setCartData(obj);
        let temp = [...tick];
        let inx = temp.indexOf(pro_id);
        temp.splice(inx, 1);
        getCartApi();
        setTick(temp);
        setShowPopup(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setDeleteBtn(false);
        console.log("err", err);
      });
    // }
  };

  // PRODUCT VIEW
  const getProductView = (id, cartID, item) => {
    const formData = new FormData();
    setViewId(id);
    formData?.append("product_ids", id);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        // console.log(res, "res");
        let obj = { ...cartData };
        let colorFinder = res?.product?.colors?.find(
          (i) => i?.id == item?.product_sku?.color_id
        );

        let findIndex = res?.product?.colors?.findIndex(
          (i) => i?.id == item?.product_sku?.color_id
        );

        setUserCartID(cartID);
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);

        let object = obj[res?.product?.id];
        let obj_val = {
          ...object,
          for_price: +res?.product?.for_25,
          count_value: 1,
        };
        if (obj[res?.product?.id]?.pack) {
          obj_val.pack = obj[res?.product]?.pack;
          obj_val.no_of_colors = obj[res?.product?.id]?.no_of_colors;
          obj_val.sku_id = obj[res?.product_id]?.sku_id;
        } else {
          obj_val.pack = 25;
          obj_val.no_of_colors = 1;
          obj_val.sku_id = res?.product?.product_varient_skus?.[0]?.id;
        }
        if (obj[res?.product?.id]?.packs_price) {
          obj_val.packs_price = obj[res?.product?.id]?.packs_price;
        }

        if (!obj[res?.product?.id]?.cart_id) {
          setStyle((val) => ({
            ...val,
            [res?.product?.id]: res?.product?.styles?.[0]?.value,
          }));
          setMaterial((val) => ({
            ...val,
            [res?.product?.id]: res?.product?.materials?.[0]?.value,
          }));
          setSize((val) => ({
            ...val,
            [res?.product?.id]: res?.product?.sizes?.[0]?.value,
          }));
        }

        let final = {
          ...obj,
          [res?.product?.id]: obj_val,
        };
        setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            let color_id_finder = item?.colors?.find(
              (i) => i?.id == color?.color_id
            );
            image.push({
              ...color,
              color: color_id_finder,
            });
          });
        });

        if (item?.product_sku?.id) {
          setSelectedColorId(colorFinder?.name);
          setSelectColorId(colorFinder?.id);
          setBigImg(findIndex);
        } else {
          setSelectedColorId(res?.product?.colors?.[0]?.name);
          setSelectColorId(res?.product?.colors?.[0]?.id);
          setBigImg(0);
        }

        // console.log(image, "images");
        setViewList([
          {
            ...res?.product,
            images: image,
          },
        ]);
        showPopupHander();
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  // CART LIST
  const getCartApi = () => {
    const formData = new FormData();
    formData.append("type", 4);
    dispatch(saveLoader(true));
    cartListApi(formData)
      .unwrap()
      .then((res) => {
        const data = res?.cart_detail?.cartDetail;
        const temp = [];
        let tempArr = [];
        data?.map((item) => {
          temp?.push(item?.product?.id);
          setTick(temp);
        });

        if (res?.cart_detail?.id) {
          let qtys = 25;
          let total_price_cart;
          setNo_of_pack(25);
          res?.cart_detail?.cartDetail?.map((item, ind) => {
            let qty_val = item?.product?.[`for_${qtys}`];
            let sum = +item?.qty * qtys;
            let obj = {
              for_price: +qty_val,
              pack: 25,
              count: +item?.qty,
              cart_id: item?.id,
              no_of_colors: item?.no_of_colors,
            };

            if (qty_val) {
              obj.for_price = +qty_val;
            } else {
              obj.for_price = +qtys;
            }

            if (+sum >= 1 && +sum <= 25) {
              obj.packs_price = +item?.product?.for_25;
              total_price_cart = +item?.product?.for_25;
            } else if (+sum > 25 && +sum <= 50) {
              obj.packs_price = +item?.product?.for_50;
              total_price_cart = +item?.product?.for_50;
            } else if (+sum > 50 && +sum <= 100) {
              obj.packs_price = +item?.product?.for_100;
              total_price_cart = +item?.product?.for_100;
            } else if (+sum > 100 && +sum <= 150) {
              obj.packs_price = +item?.product?.for_150;
              total_price_cart = +item?.product?.for_150;
            } else if (+sum > 150 && +sum <= 250) {
              obj.packs_price = +item?.product?.for_250;
              total_price_cart = +item?.product?.for_250;
            } else if (+sum > 250 && +sum <= 500) {
              obj.packs_price = +item?.product?.for_500;
              total_price_cart = +item?.product?.for_500;
            } else if (+sum > 500 && +sum <= 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            } else if (+sum > 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            }

            tempArr.push({
              ...item,
              // total_price: total_price_cart,
            });

            setCartData((val) => ({
              ...val,
              [item?.product?.id]: obj,
            }));
          });

          let varient_size = {};
          let varient_material = {};
          let varient_style = {};

          res?.cart_detail?.cartDetail?.map((item, ind) => {
            let variant_name = item?.product_sku?.variant_name;
            let remove = variant_name?.split("/");
            varient_size[item?.product?.id] = remove[2];
            varient_material[item?.product?.id] = remove[3];
            varient_style[item?.product?.id] = remove[4];
          });

          setSize(varient_size);
          setMaterial(varient_material);
          setStyle(varient_style);

          let resobj = {
            ...res?.cart_detail,
            cartDetail: tempArr,
          };

          setViewData(resobj);
          setPack(res?.cart_detail?.no_of_packs);
          setTotalPrice(res?.cart_detail?.total_amount);
          // setTotalPerPrice(res?.cart_detail?.price_per_pack);
          setCartList(tempArr);
          setPriceList(res?.pack_prices);
        } else {
          setCartList([]);
          setPriceList({});
        }
        dispatch(saveLoader(false));
        setQtyBtn(false);
      })
      .catch((err) => {
        setQtyBtn(false);
        dispatch(saveLoader(false));
        console.log(err);
      });
  };

  const calculations = (qty, id, packs, type) => {
    setQtyBtn(true);
    let qty_val;
    if (type == "decrement") {
      qty_val = +qty - 1;
    } else {
      qty_val = +qty + 1;
    }

    let qty_sum = +qty_val * +cartData?.[id]?.pack;

    // if (+qty_sum <= 1000) {
    let obj = {};
    if (+qty_sum >= 1 && +qty_sum <= 25) {
      setPacksPrice(+ListPrice?.for_25);
      obj.packs_price = +ListPrice?.for_25;
    } else if (+qty_sum > 25 && +qty_sum <= 50) {
      setPacksPrice(+ListPrice?.for_50);
      obj.packs_price = +ListPrice?.for_50;
    } else if (+qty_sum > 50 && +qty_sum <= 100) {
      setPacksPrice(+ListPrice?.for_100);
      obj.packs_price = +ListPrice?.for_100;
    } else if (+qty_sum > 100 && +qty_sum <= 150) {
      setPacksPrice(+ListPrice?.for_150);
      obj.packs_price = +ListPrice?.for_150;
    } else if (+qty_sum > 150 && +qty_sum <= 250) {
      setPacksPrice(+ListPrice?.for_250);
      obj.packs_price = +ListPrice?.for_250;
    } else if (+qty_sum > 250 && +qty_sum <= 500) {
      setPacksPrice(+ListPrice?.for_500);
      obj.packs_price = +ListPrice?.for_500;
    } else if (+qty_sum > 500 && +qty_sum <= 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    } else if (+qty_sum > 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    }

    obj.pack = +packs;
    obj.count = +qty_val;
    obj.for_price = +cartData?.[id]?.for_price;
    obj.no_of_colors = +cartData?.[id]?.no_of_colors;
    obj.sku_id = +cartData?.[id]?.sku_id;

    let final = {
      ...cartData,
      [id]: obj,
    };

    setCartData(final);
    setNo_of_pack(+packs);

    if (+cartData?.[id]?.cart_id) {
      obj.cart_id = +cartData?.[id]?.cart_id;
      varients(
        id,
        qty_val,
        obj?.packs_price ? obj?.packs_price : obj.for_price
      );
    } else {
      setQtyBtn(false);
    }
  };
  // console.log(viewData, "viewData");

  const getAddressList = () => {
    let formdata = new FormData();
    addressListApi(formdata)
      .unwrap()
      .then((res) => {
        setAddressList(res?.user_address);
        setAddressId(res?.user_address?.[0]);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handelSubmitAdd = () => {
    if (
      nameAdd?.length == 0 ||
      address1Add?.length == 0 ||
      cityAdd?.length == 0 ||
      stateAdd?.length == 0 ||
      countryAdd?.length == 0 ||
      landmarkAdd?.length == 0
    ) {
      setNameAddErr(true);
      setAddress1AddErr(true);
      setAddress2AddErr(true);
      setCityAddErr(true);
      setStateAddErr(true);
      setCountryAddErr(true);
      setLandmarkAddErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("type", nameAdd);
      formdata.append("address1", address1Add);
      if (address2Add) {
        formdata.append("address2", address2Add);
      }
      formdata.append("city", cityAdd);
      formdata.append("state", stateAdd);
      formdata.append("country", countryAdd);
      formdata.append("pincode", landmarkAdd);

      dispatch(saveLoader(true));
      setBtn(true);
      if (updateAddress) {
        addressEditApi({ payload: formdata, id: updateAddressId })
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            errHandle();
            getAddressList();
            setAddressShow(0);
            setUpdateAddress(false);
            setUpdateAddressId("");
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      } else {
        addressAddApi(formdata)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setBtn(false);
            errHandle();
            getAddressList();
            setAddressShow(0);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const errHandle = () => {
    setNameAdd("");
    setAddress1Add("");
    setAddress2Add("");
    setLandmarkAdd("");
    setCountryAdd("");
    setCityAdd("");
    setStateAdd("");

    setNameAddErr(false);
    setAddress1AddErr(false);
    setAddress2AddErr(false);
    setLandmarkAddErr(false);
    setCountryAddErr(false);
    setCityAddErr(false);
    setStateAddErr(false);
  };

  const handleViewer = (item) => {
    setUpdateAddress(true);
    setUpdateAddressId(item?.id);
    setNameAdd(item?.type);
    setAddress1Add(item?.address1);
    setAddress2Add(item?.address1);
    setLandmarkAdd(item?.pincode);
    setCountryAdd(item?.country);
    setCityAdd(item?.city);
    setStateAdd(item?.state);
    setAddressShow(1);
  };

  const deleteAddress = (id) => {
    dispatch(saveLoader(true));
    setBtn(true);
    addressDeleteApi(id)
      .unwrap()
      .then((res) => {
        getAddressList();
        errHandle();
        setBtn(false);
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        setBtn(false);
        dispatch(saveLoader(false));
        console.log("err", err);
      });
  };

  useEffect(() => {
    getCartApi();
    getAddressList();
  }, []);

  const addProductNav = () => {
    setAddress1(addressId?.address1);
    setAddress2(addressId?.address2);
    setCity(addressId?.city);
    setState(addressId?.state);
    setAddressShow(2);
  };

  const handelSubmit = () => {
    if (
      name?.length == 0 ||
      num?.length == 0 ||
      address1?.length == 0 ||
      city?.length == 0 ||
      state?.length == 0
      // ||
      // landmark?.length == 0 ||
      // altphone?.length == 0
      // email?.length == 0
      // total?.length == 0
    ) {
      setNameErr(true);
      setNumErr(true);
      setAddress1Err(true);
      // setAddress2Err(true);
      setCityErr(true);
      setStateErr(true);
      // setLandmarkErr(true);
      // setAltphoneErr(true);
      // setEmailErr(true);
      // setTotalErr(true);
    } else {
      let formdata = new FormData();
      let qty_sum = viewData?.no_of_packs;
      formdata.append("name", name);
      formdata.append("email", email);
      formdata.append("mobile", num);
      formdata.append("address1", address1);

      if (userDetails?.id) {
        formdata.append("user_id", userDetails?.id);
      }

      if (address2) {
        formdata.append("address2", address2);
      }

      if (landmark) {
        formdata.append("landmark", landmark);
      }

      if (altphone) {
        formdata.append("alter_mobile", altphone);
      }

      formdata.append("user_address_id", addressId?.id);

      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("request_type", 4);
      formdata.append("no_of_packs", viewData?.no_of_packs);
      formdata.append("total_amount", viewData?.total_amount);
      formdata.append("price_per_pack", viewData?.price_per_pack);
      viewData?.cartDetail?.map((item, ind) => {
        formdata.append(
          `project_request_details[${ind}][product_id]`,
          item?.product?.id
        );

        formdata.append(
          `project_request_details[${ind}][no_of_colors]`,
          item?.no_of_colors
        );

        formdata.append(`project_request_details[${ind}][qty]`, item?.qty);

        if (+qty_sum >= 1 && +qty_sum <= 25) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_25`]
          );
        } else if (+qty_sum > 25 && +qty_sum <= 50) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_50`]
          );
        } else if (+qty_sum > 50 && +qty_sum <= 100) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_100`]
          );
        } else if (+qty_sum > 100 && +qty_sum <= 150) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_150`]
          );
        } else if (+qty_sum > 150 && +qty_sum <= 250) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_250`]
          );
        } else if (+qty_sum > 250 && +qty_sum <= 500) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_500`]
          );
        } else if (+qty_sum > 500 && +qty_sum <= 1000) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_1000`]
          );
        } else if (+qty_sum > 1000) {
          formdata.append(
            `project_request_details[${ind}][price]`,
            item?.product?.[`for_1000`]
          );
        }

        if (item?.product_sku?.id) {
          formdata.append(
            `project_request_details[${ind}][product_sku_id]`,
            item?.product_sku?.id
          );
        } else {
          formdata.append(
            `project_request_details[${ind}][product_sku_id]`,
            item?.product?.productSku?.[0]?.id
          );
        }
      });
      dispatch(saveLoader(true));
      setBtn(true);
      productReqApi(formdata)
        .unwrap()
        .then((res) => {
          dispatch(saveLoader(false));
          setBtn(false);
          navigate("/shipment");
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  return (
    <div className="d-flex mock-left-req">
      {addressShow == 0 && (
        <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-70 px-2 px-lg-2 left-mock-req">
          <div className="d-flex ac-jb">
            <h4 className="sect-title">Delivery Information</h4>
          </div>
          {addressList?.map((item, ind) => {
            return (
              <div className="d-flex bg-white delivery-box mt-3" key={ind}>
                <div className="address-box  wi-100 w-md-100 mb-2 d-flex  align-items-center">
                  <div onClick={() => setAddressId(item)}>
                    {addressId?.id == item?.id ? (
                      <RadioButtonCheckedIcon className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 m-2 fs-xxl-20 pack-title" />
                    ) : (
                      <RadioButtonUncheckedIcon className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 m-2 fs-xxl-20 pack-title" />
                    )}
                  </div>
                  <div>
                    {/* <h5>Charles</h5> */}
                    <p className="mb-0 fs-16 f2">
                      {item?.address1}
                      {item?.address2 ? ", " + item?.address2 : ""}
                      {item?.pincode ? ", " + item?.pincode : ""}
                      {item?.city ? ", " + item?.city : ""}
                      {item?.state ? ", " + item?.state : ""}
                    </p>
                  </div>
                </div>
                <div className="d-flex prod-cart-list align-items-center">
                  <p
                    className="primary1 fs-16 f2 mb-0 me-2 border-none "
                    onClick={() => handleViewer(item)}
                    role={"button"}
                  >
                    Edit
                  </p>
                  <button
                    className="cust-btn"
                    onClick={() => deleteAddress(item?.id)}
                    disabled={btn}
                  >
                    <DeleteIcon />
                  </button>
                </div>
              </div>
            );
          })}
          <div className="address-box mt-3">
            <button onClick={() => setAddressShow(1)}>+ Add address</button>
          </div>
        </div>
      )}

      {addressShow == 1 && (
        <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-70 px-2 px-lg-2 left-mock-req">
          <h4 className="sect-title">Delivery Information</h4>
          <div className="d-flex flex-wrap">
            <div className="input-box wi-100 w-md-50 mb-2">
              <p>Type</p>
              <select
                type="name"
                className="py-2"
                placeholder="Name"
                value={nameAdd}
                onChange={(e) => setNameAdd(e.target.value)}
              >
                <option value={""}>Select Type</option>
                <option value={1}>Shipping</option>
                <option value={2}>Billing</option>
              </select>
              {nameAdd?.length == 0 && nameAddErr && (
                <div className="d-flex gap-1">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Select Type</p>
                </div>
              )}
            </div>
          </div>
          <div className="input-box w-100 mb-2 position-relative">
            <p>Address 1</p>
            <div className="w-100 d-flex">
              <textarea
                type="address"
                className="py-4"
                placeholder="address"
                value={address1Add}
                onChange={(e) => setAddress1Add(e.target.value)}
              />
            </div>
            {address1Add?.length == 0 && address1AddErr && (
              <div className="d-flex gap-1">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Address 1</p>
              </div>
            )}
          </div>
          <div className="input-box wi-100 w-md-100 mb-2">
            <p>Address 2</p>
            <textarea
              type="address"
              placeholder="address"
              className="py-4"
              value={address2Add}
              onChange={(e) => setAddress2Add(e.target.value)}
            />
            {address2Add?.length == 0 && address2AddErr && (
              <div className="d-flex gap-1">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Address 2</p>
              </div>
            )}
          </div>

          <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-4 mt-lg-4">
            <div className="d-flex flex-wrap  w-100 mb-2 position-relative">
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>Pincode</p>
                <input
                  type="name"
                  className="py-2"
                  placeholder="Pincode"
                  value={landmarkAdd}
                  onChange={(e) => setLandmarkAdd(e.target.value)}
                />
                {landmarkAdd?.length == 0 && landmarkAddErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Pincode</p>
                  </div>
                )}
              </div>
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>City</p>
                <input
                  type="name"
                  className="py-2"
                  placeholder="City"
                  value={cityAdd}
                  onChange={(e) => setCityAdd(e.target.value)}
                />
                {cityAdd?.length == 0 && cityAddErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter City</p>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap  w-100 mb-2 position-relative">
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>State</p>
                <input
                  type="text"
                  className="py-2"
                  placeholder="State"
                  value={stateAdd}
                  onChange={(e) => setStateAdd(e.target.value)}
                />
                {stateAdd?.length == 0 && stateAddErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter State</p>
                  </div>
                )}
              </div>
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>Country</p>
                <input
                  type="name"
                  className="py-2"
                  placeholder="Country"
                  value={countryAdd}
                  onChange={(e) => setCountryAdd(e.target.value)}
                />
                {countryAdd?.length == 0 && countryAddErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Country</p>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex align-items-center  w-100 mb-2 ">
              <div>
                <button
                  className="get-start-btn border-0 mb-2 w-auto px-3 px-md-4 "
                  onClick={() => handelSubmitAdd()}
                >
                  Save Address
                </button>
              </div>

              <div className="address-box mt-3">
                <button
                  onClick={() => {
                    setAddressShow(0);
                    errHandle();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {addressShow == 2 && (
        <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-70 px-2 px-lg-2 left-mock-req">
          <div className="d-flex justify-content-between">
            <h4 className="sect-title">Delivery Information</h4>
            <div className="address-box mt-3">
              <button onClick={() => setAddressShow(0)}>Change Address</button>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="input-box wi-100 w-md-50 mb-2">
              <p>Name</p>
              <input
                type="name"
                className="py-2"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {name?.length == 0 && nameErr && (
                <div className="d-flex gap-1">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Name</p>
                </div>
              )}
            </div>
            <div className="input-box wi-100 w-md-50 mb-2">
              <p>Mobile Number</p>
              <input
                type="number"
                className="py-2"
                placeholder="Mobile Number"
                value={num}
                onChange={(e) => setNum(e.target.value)}
              />
              {num?.length == 0 && numErr && (
                <div className="d-flex gap-1">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Mobile</p>
                </div>
              )}
            </div>
          </div>
          <div className="input-box w-100 mb-2 position-relative">
            <p>Address 1</p>
            <div className="w-100 d-flex">
              <textarea
                type="address"
                className="py-4"
                placeholder="address"
                value={address1}
                onChange={(e) => setAddress1(e.target.value)}
                disabled
              />
            </div>
            {address1?.length == 0 && address1Err && (
              <div className="d-flex gap-1">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Address 1</p>
              </div>
            )}
          </div>
          <div className="input-box wi-100 w-md-100 mb-2">
            <p>Address 2</p>
            <textarea
              type="address"
              placeholder="address"
              className="py-4"
              value={address2}
              onChange={(e) => setAddress2(e.target.value)}
              disabled
            />
            {address2?.length == 0 && address2Err && (
              <div className="d-flex gap-1">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Address 2</p>
              </div>
            )}
          </div>

          <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-4 mt-lg-4">
            <div className="d-flex flex-wrap  w-100 mb-2 position-relative">
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>Email</p>
                <input
                  type="name"
                  className="py-2"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  disabled
                />
                {email?.length == 0 && emailErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Email</p>
                  </div>
                )}
              </div>
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>Total Price</p>
                <input
                  type="text"
                  className="py-2"
                  placeholder="Total Price"
                  value={totalPrice}
                  onChange={(e) => setTotal(e.target.value)}
                  disabled
                />
                {/* {total?.length == 0 && totalErr && (
                <div className="d-flex gap-1">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Total</p>
                </div>
              )} */}
              </div>
            </div>
            <div className="d-flex flex-wrap  w-100 mb-2 position-relative">
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>City</p>
                <input
                  type="name"
                  className="py-2"
                  placeholder="City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  disabled
                />
                {city?.length == 0 && cityErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter City</p>
                  </div>
                )}
              </div>
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>State</p>
                <input
                  type="text"
                  className="py-2"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  disabled
                />
                {state?.length == 0 && stateErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter State</p>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex flex-wrap  w-100 mb-2 position-relative">
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>Landmark (optional)</p>
                <input
                  type="name"
                  className="py-2"
                  placeholder="Landmark"
                  value={landmark}
                  onChange={(e) => setLandmark(e.target.value)}
                />
                {landmark?.length == 0 && landmarkErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Landmark</p>
                  </div>
                )}
              </div>
              <div className="input-box wi-100 w-md-50 mb-2">
                <p>Alternate Phone (optional)</p>
                <input
                  type="number"
                  className="py-2"
                  placeholder="Alternate Phone"
                  value={altphone}
                  onChange={(e) => setAltphone(e.target.value)}
                />
                {altphone?.length == 0 && altphoneErr && (
                  <div className="d-flex gap-1">
                    <ErrorIcon className="svg_log" />
                    <p className="err-input_log mt-1">Enter Alternate Phone</p>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex align-items-center  w-100 mb-2 ">
              <div>
                <button
                  onClick={() => handelSubmit()}
                  className={`get-start-btn border-0 mb-2 w-auto px-3 px-md-4  ${
                    btn && "opacity-50"
                  }`}
                  disabled={btn}
                >
                  Save and Deliver Here
                </button>
              </div>
              <div>
                <p
                  className="primary1 fs-16 f2 border-0 mt-4 w-auto px-3 px-md-4"
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </p>
              </div>
            </div>
          </div>
        </div>
      )}

      {addressShow !== 1 && (
        <EcommerceMockReqCart
          pack="bulk"
          tick={tick}
          categoryList={categoryList}
          addProductHandler={addProductHandler}
          addProductNav={addProductNav}
          cortList={cortList}
          priceList={priceList}
          setShowPopup={showPopupHander}
          deleteCart={deleteCart}
          getProductView={getProductView}
          packs={pack}
          totalPrice={totalPrice}
          showoption={showoption}
          deleteBtn={deleteBtn}
          addressShow={addressShow}
          setAddressShow={setAddressShow}
          handelSubmit={handelSubmit}
          btn={btn}
        />
      )}

      {showPopup && (
        <ProductPopupComponent
          varientsNew={varientsNew}
          calculations={calculations}
          showPopupHander={showPopupHander}
          viewList={viewList}
          setMaterial={setMaterial}
          material={material}
          setSize={setSize}
          size={size}
          setStyle={setStyle}
          style={style}
          setCartData={setCartData}
          cartData={cartData}
          setSelectedColorId={setSelectedColorId}
          selectedColorId={selectedColorId}
          setSelectColorId={setSelectColorId}
          selectColorId={selectColorId}
          cartData={cartData}
          imagesid={imagesid}
          bigImg={bigImg}
          setBigImg={setBigImg}
          count={count}
          setCount={setCount}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          varients={varients}
          deleteCart={deleteCart}
          cartBtn={cartBtn}
          deleteBtn={deleteBtn}
          userCartID={userCartID}
          qtyBtn={qtyBtn}
        />
      )}
    </div>
  );
};

export default ContactDetails;
