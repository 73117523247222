// @ts-nocheck
import React from "react";
import { Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { inovFut } from "../../assets/img";

const SimplePack = () => {
  return (
    <div className="w-70 contain simple-pack bg-white d-flex flex-column flex-md-row ac-jc ">
      {/* <div className=""> */}
      <img src={inovFut} className="pac-img1" alt="" />

      <div className="left-simple ms-md-5 ms-lg-5">
        <h3 className="f4 sect-title mb-2 mb-lg-2 dark">
          It’s a no-brainer. Welcome to the HQ.
        </h3>
        {/* <p className="parag">
            We’re looking for ambitious individuals who strive in chaotic
            unstructured environments, and love swag, culture, branding, and
            proactively solving problems!
          </p> */}
        <div className="d-flex justify-content-center">
          <Link className="get-start-btn" to="/lets-start">
            Explore Now
          </Link>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default SimplePack;
