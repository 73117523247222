// @ts-nocheck
import React from "react";
import { faqImg, searchIcon } from "../../../assets/img";
import { faqlist } from "../../../redux/api/DummyJson";
import { useState } from "react";
import DOMPurify from "dompurify";

const FaQuestion = ({ list, search, setSearch, getQafList }) => {
  const [showcount, setShowCount] = useState(null);

  const eventShow = (e) => {
    if (showcount == e) {
      setShowCount(null);
    } else {
      setShowCount(e);
    }

    console.log(e);
  };
  const onChange = (e) => {
    getQafList(e.target.value);
  };
  return (
    <div className="question-ans wi-100 w-lg-90 w-xxl-85 mx-auto d-flex flex-column flex-lg-row justify-content-between">
      <div className="faq-img mx-auto mb-4 mb-lg-auto">
        <img src={faqImg} alt="" />
      </div>
      <div className="left-quest wi-100 w-lg-70 w-xxl-70">
        <div className="search-faq d-flex ac-jb">
          <input
            className="fs-13 fs-md-14 fs-lg-17 fs-xl-19 f2"
            type="text"
            placeholder="search here..."
            onChange={(e) => onChange(e)}
          />
          <img src={searchIcon} alt="" />
        </div>

        {list?.map((item, index) => {
          return (
            <div>
              <div className="search-faq d-flex ac-jb">
                <h5 className="text-black fs-16 fs-md-14 fs-lg-18 fs-xl-19 fs-xxl-20 f3 mb-0 two-line-paragraph">
                  {item?.name?.substring(0, 150)}
                </h5>
                <button
                  onClick={() => eventShow(index)}
                  className="bg-transparent border-0 fs-20 fs-md-23 fs-lg-25 fs-xxl-35 f6 mb-0 primary mb-0"
                >
                  +
                </button>
              </div>
              {showcount === index && (
                <p
                  className="bg-lt-blue parag mb-0 w-100 rounded rounded-4 w-lg-55 w-xl-60 w-xxl-65 text-justify p-4 mb-5"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      item?.description?.substring(0, 200)
                    ),
                  }}
                >
                  {/* {item?.description?.substring(0,200)} */}
                </p>
              )}
            </div>
          );
        })}
        {/* <div className="search-faq d-flex ac-jb">
          <h5 className="text-black fs-16 fs-md-14 fs-lg-18 fs-xl-19 fs-xxl-20 f3 mb-0">
            Is there a fee to store Merchhq?
          </h5>
          <h6 className="fs-20 fs-md-23 fs-lg-25 fs-xxl-35 f6 mb-0 primary mb-0">
            +
          </h6>
        </div> */}
      </div>
    </div>
  );
};

export default FaQuestion;
