import { PointOfSale } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import NearMeIcon from "@mui/icons-material/NearMe";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import HubOutlinedIcon from "@mui/icons-material/HubOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import {
  useAddressAddListMutation,
  useLazyAddressViewOrderQuery,
} from "../../redux/api/api";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { countryList } from "../../redux/api/DummyJson";
import { useDispatch } from "react-redux";
import { saveLoader } from "../../redux/slice/loaderNoti";

const AddressPopup = ({
  poppupAddressToggle,
  showType,
  firstName,
  setfirstName,
  lastName,
  setLastname,
  email,
  setEmail,
  address1,
  setAddress1,
  address2,
  setAddress2,
  LandMark,
  setLandMark,
  state,
  setState,
  city,
  setCity,
  pincode,
  setPincode,
  num,
  setNum,
  alternum,
  setAlterNum,
  gst,
  setGst,
  type,
  setType,
  project,
  getData,
  update,
  setUpdate,
  selected,
  setSelected,
  getAddresslist,
  country,
  setCountry,
  listState,
  setListState,
}) => {
  const [dropDwon, setDropDown] = useState(undefined);
  // console.log("Passing getData:", selected); // In the parent component

  const [department, setDepartment] = useState("");
  const [address, setAddress] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [firstNameErr, setfirstNameErr] = useState(false);
  const [lastNameErr, setlastNameErr] = useState(false);
  const [address1Err, setaddress1Err] = useState(false);
  const [address2Err, setaddress2Err] = useState(false);
  const [cityErr, setcityErr] = useState(false);
  const [stateErr, setstateErr] = useState(false);
  const [pincodeErr, setpincodeErr] = useState(false);
  const [countryErr, setcountryErr] = useState(false);
  const [email_idErr, setemail_idErr] = useState(false);
  const [land_markErr, setland_markErr] = useState(false);
  const [phone_noErr, setphone_noErr] = useState(false);
  const [gstErr, setgstErr] = useState(false);
  // const [update, setUpdate] = useState({});

  const [types, setTypes] = useState(5);

  const user_details = localStorage.getItem("user_details");

  const dispatch = useDispatch();
  const [alternative_phone_noErr, setalternative_phone_noErr] = useState(false);
  // const [state, setState] = useState("");
  const [addressAdd] = useAddressAddListMutation();
  // const [addressView]=useLazyAddressViewOrderQuery();

  const getState = (value) => {
    setCountry(value);
    let finder = countryList?.find((i) => i?.name == value);
    setListState(finder?.states);
  };

  const getAddress = async () => {
    setfirstNameErr(firstName.length === 0);
    setlastNameErr(lastName.length === 0);
    setaddress1Err(address1.length === 0);
    setaddress2Err(address2.length === 0);
    // setland_markErr(LandMark.length === 0);
    setpincodeErr(pincode.length === 0);
    setcityErr(city.length === 0);
    setstateErr(state.length === 0);
    setphone_noErr(num.length === 0);
    // setalternative_phone_noErr(alternum.length === 0);
    setcountryErr(country.length === 0);
    setemail_idErr(email.length === 0);

    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      address1.length > 0 &&
      address2.length > 0 &&
      // LandMark.length > 0 &&
      pincode.length > 0 &&
      city.length > 0 &&
      state.length > 0 &&
      num.length > 0 &&
      // alternum.length > 0 &&
      country.length > 0 &&
      email.length > 0
    ) {
      const formdata = new FormData();
      formdata.append("project_id", project?.id);
      formdata.append("first_name", firstName);
      formdata.append("last_name", lastName);
      formdata.append("email_id", email);
      formdata.append("address1", address1);
      formdata.append("address2", address2);
      formdata.append("city", city);
      formdata.append("state", state);
      formdata.append("pincode", pincode);
      formdata.append("phone_no", num);
      formdata.append("country", country);

      if (types == 3) {
        formdata.append("type", types);
      } else {
        formdata.append("type", type);
      }

      let userData = JSON.parse(user_details);
      if (userData?.id) {
        formdata.append("user_id", userData?.id);
      }

      if (alternum) {
        formdata.append("alter_phone_no", alternum);
      }

      if (LandMark) {
        formdata.append("landmark", LandMark);
      }

      if (selected?.id) {
        formdata.append("user_address_id", selected?.id);
      }
      try {
        dispatch(saveLoader(true));
        const res = await addressAdd(formdata).unwrap();
        toast.success(res?.message);
        getAddresslist("", type, "");
        // Reset fields
        setfirstName("");
        setLastname("");
        setEmail("");
        setAddress1("");
        setAddress2("");
        setLandMark("");
        setPincode("");
        setCity("");
        setState("");
        setNum("");
        setAlterNum("");
        setCountry("");
        setGst("");
        setUpdate({});
        setSelected({});
        // Close the modal after a successful save
        poppupAddressToggle();
      } catch (err) {
        console.error(err);
        toast.error("An error occurred while saving the address.");
      } finally {
        dispatch(saveLoader(false));
      }
    }
  };

  const typeChanger = (event) => {
    if (types == event) {
      setTypes(5);
    } else {
      setTypes(3);
    }
  };

  return (
    <div className="modal-popup_2">
      <div onClick={poppupAddressToggle} className="back-close" />
      <div className="z_ind_cust center-content w-90 w-md-55 ">
        <div className="w-100 d-flex ac-jb mt-4 px-md-5 px-3">
          <p className="f3 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-22 fs-xxl-23 primary">
            {showType} Contact
          </p>
          <div className="">
            <button
              className="mb-5 pt-2 pb-2 ps-3 pe-3 bg-warning border-0 text-white py-2 rounded-3 f4 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ms-2 text-dark mt-2"
              onClick={poppupAddressToggle}
            >
              Cancel
            </button>
            <button
              className="mb-5 pt-2 pb-2 ps-3 pe-3 bg-primar border-0 text-white py-2 rounded-3 f4 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ms-2 text-dark"
              onClick={getAddress}
            >
              Save
            </button>
          </div>
        </div>
        {selected?.id ? null : (
          <div className="w-100 gap-2 mx-4  d-flex w-100 mt-1">
            <input
              type={"checkbox"}
              onClick={() => typeChanger(3)}
              value={types == 3}
            />{" "}
            {type == 2 ? "Same as Shipping Address" : "Same as Billing Address"}
          </div>
        )}
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="First Name"
                onChange={(e) => setfirstName(e.target.value)}
                value={firstName}
              />
              {firstName?.length == 0 && firstNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter First Name</p>
                </div>
              )}
            </div>
            <div className="box">
              <input
                className=""
                placeholder="Last Name"
                onChange={(e) => setLastname(e.target.value)}
                value={lastName}
              />
              {lastName?.length == 0 && lastNameErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Last Name</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="Email ID"
                onChange={(e) => setEmail(e.target.value)}
                value={email}
              />
              {email?.length == 0 && email_idErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Email</p>
                </div>
              )}
            </div>
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="Address Line 1"
                onChange={(e) => setAddress1(e.target.value)}
                value={address1}
              />
              {address1?.length == 0 && address1Err && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Address1</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="Address Line 2"
                onChange={(e) => setAddress2(e.target.value)}
                value={address2}
              />
              {address2?.length == 0 && address2Err && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Address2</p>
                </div>
              )}
            </div>
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="Land Mark"
                onChange={(e) => setLandMark(e.target.value)}
                value={LandMark}
              />
              {LandMark?.length == 0 && land_markErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter LandMark</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                onChange={(e) => getState(e.target.value)}
                value={country}
              >
                <option>Select Country</option>
                {countryList?.map((item, ind) => {
                  return (
                    <option value={item?.name} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>

              {country?.length == 0 && countryErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Country</p>
                </div>
              )}
            </div>

            <div className="box">
              <select
                className="editBtn w-100 rounded-3 mt-2 p-2 fs-xs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 p-cust"
                onChange={(e) => setState(e.target.value)}
                value={state}
              >
                <option>Select State</option>
                {listState?.map((item, ind) => {
                  return (
                    <option value={item?.name} key={ind}>
                      {item?.name}
                    </option>
                  );
                })}
              </select>

              {state?.length == 0 && stateErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter State</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="City"
                onChange={(e) => setCity(e.target.value)}
                value={city}
              />
              {city?.length == 0 && cityErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter City</p>
                </div>
              )}
            </div>

            <div className="box">
              <input
                className=""
                placeholder="Pincode"
                onChange={(e) => setPincode(e.target.value)}
                value={pincode}
              />
              {pincode?.length == 0 && pincodeErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Pincode</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="Phone Number"
                onChange={(e) => setNum(e.target.value)}
                value={num}
              />
              {num?.length == 0 && phone_noErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Phone Number</p>
                </div>
              )}
            </div>

            <div className="box">
              <input
                className=""
                placeholder="Alternate Phone Number"
                onChange={(e) => setAlterNum(e.target.value)}
                value={alternum}
              />
              {alternum?.length == 0 && alternative_phone_noErr && (
                <div className="d-flex gap-1 mt-2">
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Alternative Number</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="btm-scroll profile-screen1 w-100 pt-3 d-flex flex-column flex-md-row w-100 mt-1">
          <div className="profile-input">
            <div className="box">
              <input
                className=""
                type="text"
                placeholder="GST (optional)"
                onChange={(e) => setGst(e.target.value)}
                value={gst}
              />
              {/* {gst?.length == 0 && alternative_phone_noErr && (
                    <div className="d-flex gap-1 mt-2">
                      <ErrorIcon className="svg_log" />
                      <p className="err-input_log mt-1">Enter GST</p>
                    </div>
                  )} */}
            </div>
          </div>
        </div>
        <div className="continueBtn2 text-center">
          {/* <button
            onClick={() => getAddress()}
            className="mb-5 pt-2 pb-2 ps-3 pe-3 bg-primar border-0 text-white py-2 rounded-3 f4 fs-11 fs-sm-12 fs-md-13 fs-lg-14 fs-xl-15 fs-xxl-16 ms-2 text-dark mt-2"
          >
            Add Content
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default AddressPopup;
