// @ts-nocheck
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { logo2, logo3, logo4, logo5, logo6 } from "../../../assets/img";
import { row2 } from "../../../redux/api/constants";

const ServingFor = () => {
  return (
    <div className="serving-for mt-5 text-center">
      <h3>MerchHQ Serving for</h3>
      <p>
        Partner with us for excellence in every step, your success is our
        priority!
      </p>
      <div className="logos">
        <OwlCarousel {...row2}>
          <img src={logo2} className="" />
          <img src={logo3} className="" />
          <img src={logo4} className="" />
          <img src={logo5} className="" />
          <img src={logo6} className="" />
        </OwlCarousel>
      </div>
    </div>
  );
};

export default ServingFor;
