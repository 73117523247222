// @ts-nocheck
import React from "react";
import { blog_ban, presetPack, stayProd } from "../../../assets/img";
import { useNavigate } from "react-router-dom";

const BlogBanner = () => {
  const navigation = useNavigate()
  return (
    <div className="blog">
      <img src={blog_ban} className="w-100" style={{objectFit:"cover",objectPosition:"center",height:"50%"}}/>
    </div>
  );
};

export default BlogBanner;
