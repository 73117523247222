import { configureStore } from "@reduxjs/toolkit";
import { rtkQueryErrorLogger } from "../slice/errorLogger";
import { api } from "../api/api";
import alertMessage from "../slice/alertMessage";
import loginHeader from "../slice/loginHeader";
import leftCategIndex from "../slice/leftCategIndex";
import cartLists from "../slice/cartLists";
import loaderNoti from "../slice/loaderNoti";

const store = configureStore({
  reducer: {
    alertMessage: alertMessage,
    leftCategIndex: leftCategIndex,
    cartLists: cartLists,
    loginHeader: loginHeader,
    loaderNoti: loaderNoti,
    [api.reducerPath]: api.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, rtkQueryErrorLogger),
});

export default store;
