import React from 'react'
import { useNavigate } from 'react-router-dom'

const ProjectSubmit = () => {
    const navigate = useNavigate()
    return (
        <div className="rit-prod mt-0 d-flex ac-jc h-100">
            <div className="w-70">
                <div className='borer-r1 w-100 h-100 ac-jc mt-5 rounded-4 px-5 py-5'>
                    <h5 class="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 w-100 text-dark mb-5">Start Project</h5>
                    <h6 class="text-dark fs-14 fs-md-16 fs-lg-18 fs-xl-19 f4 text-center">Hiii</h6>
                    <div className='w-100 h-100 d-flex ac-jc flex-column'>
                        <button class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 pt-0 py-md-2 px-3 rounded px-lg-3 mt-3" onClick={() => navigate('/tendrncy')}>Go Home</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ProjectSubmit