import React from "react";
import { dash1, product, product1, service_banner } from "../../assets/img";

const ServicesComp = () => {
  return (
    <div className="d-flex w-90 flex-column flex-md-row gap-4 py-3">
      
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="text-custom-align">
          <h5 class="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 w-100 text-dark">
            Design & Decoration:
          </h5>
          <p class="mb-3 mb-md-0 primary1 f2 fs-13 fs-md-14 fs-lg-15 fs-xl-16">
            Simply upload your logo and watch our design experts work their
            magic! Expect custom designs for your review within 24 hours.
          </p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center w-100 w-lg-50">
        
          <img src={dash1} className="w-80 h-100 object-fit-contain" />
        
      </div>
    </div>
  );
};

export default ServicesComp;
