// @ts-nocheck
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { bg1, shippmentIcon } from "../assets/img";

const ShippmentSuccess = () => {
  const navigate = useNavigate();
  const Token = localStorage.getItem("token");

  // useEffect(() => {
  //   if (!Token) {
  //     setTimeout(() => {
  //     navigate("/signup");
  //   }, 1500);
  //   } else {
  //     setTimeout(() => {
  //       navigate("/dashboard");
  //     }, 1500);
  //   }
  // }, []);

  return (
    <div className="d-flex flex-column flex-md-row sign-up position-relative align-items-center justify-content-center">
      <div className="d-flex flex-column ac-jc w-100 h-80 shippment-succ">
        <img src={bg1} alt="" className="bg1" />
        <img src={shippmentIcon} alt="" />
        <h4 className="sect-title text-center">Order Request has been sent</h4>
        <img src={bg1} alt="" className="bg2" />
      </div>
    </div>
  );
};

export default ShippmentSuccess;
