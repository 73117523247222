import React from 'react'
import { closeIcon } from '../../../assets/img'
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const AutoShipPopup = ({ PopupHanler, listShowHand }) => {
    const events = () => {
        PopupHanler()
        listShowHand()
    }
    return (
        <div className="modal-popup d-flex ac-jc">
            <div className="back-close" />
            <div className='center-content3 px-4 py-4'>
                <div className=" d-flex ac-jb">
                    <p class="f4 fs-xl-20 fs-lg-19 fs-md-18 fs-sm-17 fs-xs-16 mt-0 mb-3">Share Autoship Name</p>
                    <button
                        className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
                        onClick={PopupHanler}
                    >
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <div className='d-flex ac-jb w-100'>
                    <p className="f4 f4 fs-xl-18 fs-lg-17 fs-md-15 fs-sm-14 fs-xs-13 mt-0 mb-3" style={{ color: '#07679C' }}>
                        Page Status
                    </p>
                    <div class="form-check form-switch">
                        <input class="form-check-input text-danger" type="checkbox" role="switch" checked />
                    </div>
                </div>
                <p className="f2 fs-xl-12 primary1 fs-lg-10 fs-md-13 fs-sm-12 fs-xs-11 mt-2">
                    Anyone with this link can view tha redeem page, but cannot submit                </p>
                <div className='w-100 d-flex ac-jb mt-5'>
                    <button className='bg-transparent f2 fs-10 fs-sm-11 fs-lg-12 ms-2 text-primary border-0'>
                        Copy Link
                        <InsertLinkIcon className="bg-transparent f2 fs-10 fs-sm-11 fs-lg-12 ms-1 text-primary" style={{ transform: 'rotate(130deg)' }} />
                    </button>
                    <button
                        onClick={events}
                        className="get-start-btn border-0 my-0 mx-2">
                        Save
                    </button>

                </div>
            </div>
        </div>
    )
}

export default AutoShipPopup