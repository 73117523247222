import React from 'react'
import { banner, closeIcon } from '../../assets/img'
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const AccountFormComp = ({ formOpen }) => {
    return (
        <div className="modal-popup d-flex ac-jc">
            <div className="back-close" />
            <div className='center-content3 px-4 py-4 w-custom-form' style={{ height: '90vh', width: '59%' }}>
                <div className='d-flex ac-jb w-100'>
                    <p className="f4 f4 fs-xl-25 fs-lg-24 fs-md-23 fs-sm-22 fs-xs-21 mt-0 mb-3rk text-da">
                        Add new Credit Card
                    </p>
                    <button
                        className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
                        onClick={formOpen}
                    >
                        <img src={closeIcon} alt="" />
                    </button>
                </div>
                <p className="f2 fs-xl-15 primary1 fs-lg-13 fs-md-16 fs-sm-15 fs-xs-12 mt-2">
                    Give this payment method a name so it can easily be identified                      </p>
                <div className='w-100 d-flex ac-jb mt-2 form-box'>
                    <div className="box1 w-100 custom-palce">
                        <input placeholder="Nickname" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className='w-100 d-flex ac-jb mt-2 form-box'>
                    <div className="box1 w-100 custom-palce">
                        <p className="f2 text-dark mb-3 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                            Card Details
                        </p>
                        <input placeholder="Credit card number" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className='w-100 d-flex ac-jb mt-4 form-box'>
                    <div className="box1 w-100 custom-palce">
                        <input placeholder="Name on card" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className='w-100 d-flex flex-column flex-md-row gap-3 mt-4 ac-jb mt-2 form-box'>
                    <div className="box1 w-100 custom-palce">
                        <input placeholder="Expiration date" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                    <div className="box1 custom-palce w-100">
                        <input placeholder="CVC" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className='w-100  mt-4 ac-jb mt-2 form-box'>
                    <p className="f2 text-dark mb-3 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                        Billing address
                    </p>
                    <div className="box1 custom-palce w-100 d-flex gap-3 flex-column flex-md-row">
                        <input placeholder="First name" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                        <input placeholder="Last name" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className="Integration-screen3 mt-4 p-0">
                    <div className='box d-flex custom-palce flex-row p-0'>
                        <select placeholder='State' className="cust-btn f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 mb-0">
                            <option value="all" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>India</option>
                            <option value="Pack" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Pack</option>
                            <option value="Products" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Products</option>
                        </select>
                    </div>
                </div>
                <div className='w-100 d-flex ac-jb mt-4 form-box'>
                    <div className="box1 custom-palce w-100">
                        <input placeholder="Street address" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className='w-100  mt-4 ac-jb mt-2 form-box'>
                    <div className="box1 custom-palce w-100 d-flex gap-3 flex-column flex-md-row">
                        <input placeholder="Floor, suite, unit (optional)" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                        <input placeholder="City" className="f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className='w-100 mt-4 ac-jb form-box'>
                    <div className="box1 custom-palce w-100 d-flex gap-3 flex-column flex-md-row">
                        <div className="Integration-screen3 p-0">
                            <div className='box d-flex flex-row p-0'>
                                <select placeholder='State' className="cust-btn f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 mb-0">
                                    <option value="all" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>State</option>
                                    <option value="Pack" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Pack</option>
                                    <option value="Products" className='f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Products</option>
                                </select>
                            </div>
                        </div>
                        <input placeholder="Postal Code" className="pe-1 f2 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
                    </div>
                </div>
                <div className='d-flex ac-jc w-100'>
                    <button onClick={formOpen} className='mt-4 get-start-btn border-0 align-self-center'>Continue</button>
                </div>
            </div>
        </div>
    )
}

export default AccountFormComp