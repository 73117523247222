// @ts-nocheck
import React from "react";
import { Col } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { internationalShippment } from "../../../assets/img";
const SimplePack = () => {
  const navigation = useNavigate()
  return (
    <div className="simple-pack1  bg-lt-blue d-flex flex-column flex-md-row align-items-center justify-content-center px-2 py-2 py-md-4 mb-3">
      <div className="mb-3 mb-lg-0">
        <img src={internationalShippment} className="pac-img1" alt="" />
      </div>
      <Col xs={10} md={7} lg={5} className="left-simple ms-md-5 ms-lg-5">
        <h4 className="f4 fs-23 fs-md-27 fs-lg-30 fs-xxl-35 mb-2 mb-lg-2">
          Delivering the best product experience for over 5,000 customers with
          ease
        </h4>
        <p className="parag">
          Getting custom swag right can be tricky. If you're not in love with
          the end result, just let us know and we'll make it right, guaranteed!
        </p>

        <button className="get-start-btn border-0" onClick={() => {
          navigation('/services/membership/book-consult')
        }}>
          Enquiry Now
        </button>
        {/* <Link className="get-start-btn" to="/">
          Enquiry Now
        </Link> */}
      </Col>
    </div>
  );
};

export default SimplePack;
