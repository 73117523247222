import React from 'react'
import { searchIcon } from '../../../../assets/img'

const OurProjectHeader = () => {
    return (
        <div className="my-product mx-auto w-90">
            <div className="filter-product d-flex ac-jb ac-jb mt-3 mt-lg-4 px-2 px-lg-3">
                <h5 class="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 text-dark">Projects
                </h5>
                <div className="position-relative d-flex ac-jc search-icon">
                    <img src={searchIcon} alt="" />
                    <input
                        type="text"
                        className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
                        placeholder="Search Products"
                        name=""
                        id=""
                    />
                </div>
            </div>
        </div>
    )
}

export default OurProjectHeader