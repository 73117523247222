// @ts-nocheck
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { build_Pack, bulkPack, presetPack, userIcon } from "../../../../assets/img";
import { useDispatch, useSelector } from "react-redux";
import LoginMenuItem from "./LoginMenuItem";
// import { saveLoginHeader } from "../redux/slice/loginHeader";

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [showMenu, setShowMenu] = useState(false);
    const [showSigndropList, setSignShowdropList] = useState(false);
    const [showdropList, setShowdropList] = useState(false);
    const [companyDropdown, setCompanyDropdown] = useState(false);
    const [plotformMenu, setPlotformMenu] = useState(false);

    const loginHeaderData = useSelector((state) => state.loginHeader);

    console.log("loginHeaderData :", loginHeaderData);

    const onCLickDropdownShowHandler = () => {
        setShowdropList(false);
        setShowMenu(false);
        setCompanyDropdown(false);
        setPlotformMenu(false);
    };
    return (
        <div className="top-header d-flex">
            <div className="d-flex justify-content-center align-items-center">
                <button
                    onClick={() => {
                        navigate("/tendrncy");
                        setCompanyDropdown(false);
                        setSignShowdropList(false);
                        setShowdropList(false);
                        setPlotformMenu(false);
                    }}
                    className="menu-list text-start text-lg-center menu-head-btn fs-18 border-0 bg-transparent d-flex align-items-center justify-content-between justify-content-md-start logo">
                    <h3 className="f4 fs-25 primary mb-0">merchhq</h3>
                </button>

            </div>
            <div className="d-flex justify-content-center align-items-center">
                <div className="float-right">
                    <LoginMenuItem
                        setSignShowdropList={setSignShowdropList}
                        onCLickDropdownShowHandler={onCLickDropdownShowHandler}
                        showdropList={showdropList}
                        setShowdropList={setShowdropList}
                        showMenu={showMenu}
                        setShowMenu={setShowMenu}
                        companyDropdown={companyDropdown}
                        setCompanyDropdown={setCompanyDropdown}
                        plotformMenu={plotformMenu}
                        setPlotformMenu={setPlotformMenu}
                    />
                </div>
            </div>
            <button
                onClick={() => {
                    setShowMenu(!showMenu);
                    setSignShowdropList(false);
                    setCompanyDropdown(false);
                }}
                className="d-flex d-md-none menu-icon ms-2 ">
                {!showMenu ? <MenuIcon /> : <CloseIcon />}
            </button>
        </div>
    );
};

export default Header;
