// @ts-nocheck
import React from "react";
import { Link } from "react-router-dom";
import { Lin, fb, google, logo2, owllogo, twitter } from "../assets/img";

const Footer = () => {
  return (
    <div className="footer">
      <div className="d-flex flex-column flex-lg-row justify-content-between p-5 pb-0 mt-0">
        <div className="logos text-center text-lg-start m-auto ms-lg-0 mb-3 mt-0">
          <img src={owllogo} className="logo-footer w-60" />
          {/* <h4 className="sect-title primary mb-0">merchhq</h4> */}
          <div className="d-flex social-ftr-lnk">
            <Link className="social-footer-icon ac-jc" to="#">
              <img src={fb} alt="" />
            </Link>
            <Link className="social-footer-icon ac-jc" to="#">
              <img src={google} alt="" />
            </Link>
            <Link className="social-footer-icon ac-jc" to="#">
              <img src={twitter} alt="" />
            </Link>
            <Link className="social-footer-icon ac-jc" to="#">
              <img src={Lin} alt="" />
            </Link>
          </div>
        </div>
        <div className="d-flex flex-wrap justify-content-end rit-ftr">
          <div className="ftr-list">
            <h5 className="mb-0">Order</h5>
            <div className="">
              <Link className="parag py-1">Membership</Link>
              <Link className="parag py-1">Build a pack</Link>
              <Link className="parag py-1">Preset packs</Link>
              <Link className="parag py-1">Bulk Merchhq</Link>
            </div>
          </div>
          <div className="ftr-list">
            <h5 className="mb-0">Explore</h5>
            <div className="">
              <Link className="parag py-1">Fulfilment Times</Link>
              <Link className="parag py-1">services</Link>
              <Link className="parag py-1">Pricing</Link>
              <Link className="parag py-1">FAQ</Link>
            </div>
          </div>
          <div className="ftr-list">
            <h5 className="mb-0">Connect</h5>
            <div className="">
              <Link className="parag py-1">Talk to sales</Link>
              <Link className="parag py-1">Book a Demo</Link>
              <Link className="parag py-1">Partnerships</Link>
              <Link className="parag py-1">Vendor & Suppliers</Link>
              <Link className="parag py-1">Careers</Link>
            </div>
          </div>
          <div className="ftr-list">
            <h5 className="mb-0">Legal</h5>
            <div className="">
              <Link className="parag py-1">Privacy</Link>
              <Link className="parag py-1">Terms of Use</Link>
              <Link className="parag py-1">MemberShip Terms of Service</Link>
              <Link className="parag py-1">Return/Refund Policy</Link>
              <Link className="parag py-1">Help</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="ftr-btm w-80 mx-auto">
        <p className="parag text-center d-flex flex-wrap justify-content-center mb-2">
          All rights reserved to{" "}
          <b className="ms-1 text-black">{"  "}MerchHQ</b>, design and and
          developed by
          <Link className="d-flex parag">
            <b className="ms-1 text-black">Ninos IT Solution</b>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Footer;
