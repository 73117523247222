// @ts-nocheck
import React from "react";
import { logist, product1, productd } from "../../assets/img";
import CheckIcon from "@mui/icons-material/Check";
import AddIcon from "@mui/icons-material/Add";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";

const ProductList = ({
  showPopupHander,
  addProductHandler,
  tick,
  showCartHandler,
  setSearch,
  setOrder,
  setRows,
  rows,
  search,
  order,
  setUserList,
  userList,
  btnChange,
}) => {
  // console.log(rows, "rows");
  // console.log(userList, "userlist");
  const navigate = useNavigate();

  const commaValue = (price) => {
    let amount = price
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    return amount;
  };

  return (
    <div className="login-product d-flex flex-wrap">
      {userList?.map((item, ind) => {
        // console.log(item?.price, "item")
        // console.log(
        //   item?.project_request?.project_request_id, "hj"
        // )
        const first = item?.projectPresentationPrice;
        // console.log(first, "presentation")
        const firstTotal = first && first.length > 0 ? first[0].total : null;
        const lastTotal =
          first && first.length > 1 ? first[first.length - 1].total : null;
        return (
          // <div className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3">
          <div
            className="p-2 p-md-2 my-2 my-lg-3 px-xxl-3 prod-list-box-tl "
            key={ind}
          >
            <div className="prod-list-box">
              <div className="d-flex ac-jb">
                <p
                  className={`${
                    item?.project?.status >= 5
                      ? "bg-approved_pro text-white"
                      : "bg-lt-design design"
                  }  fs-11 fs-md-12 f2 rounded rounded-2 mb-0 py-1 px-2`}
                >
                  {item?.project?.status >= 5
                    ? "Approved By Me"
                    : "Pending Mockup"}
                </p>
                <div className="d-flex align-items-center">
                  <img className="logist" src={logist} alt="" />
                  <p className="parag sec mb-0 ms-2 f4">
                    {" "}
                    {item?.req_no_of_packs}
                  </p>
                </div>
              </div>

              <button
                onClick={showPopupHander}
                className="cust-btn prod-list-box-img pt-2"
              >
                <img
                  style={{
                    height: "200px",
                    borderRadius: "10px",
                    objectFit: "contain",
                  }}
                  src={
                    item?.product?.defaultImage
                      ? item.product.defaultImage
                      : product1
                  }
                  alt=""
                />{" "}
              </button>
              <div className="btm-prod">
                <h3 className="f2 fs-10 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  {item?.project?.project_request
                    ? item?.project?.project_request?.project_request_id
                    : item?.project_request_id}
                </h3>
                <h3 className="f2 fs-10 fs-md-12 fs-lg-13 fs-xl-14 fs-xxl-15">
                  {item?.product?.name}
                </h3>
                {/* <p className="primary1 f2 mb-0 pb-0">
  {`₹${firstTotal}`} 
  {lastTotal !== null ? ` - ₹${lastTotal}` : ''} 
  {item?.total_amount !== undefined ? ` || ₹${item.total_amount}` : ''}
</p> */}
                <p className="primary1 f2 mb-0 pb-0">
                  {firstTotal !== null ? `₹${commaValue(firstTotal)}` : ""}
                  {lastTotal !== null ? ` - ₹${commaValue(lastTotal)}` : ""}
                  {item?.total_amount !== undefined
                    ? `  ₹${commaValue(item.total_amount)}`
                    : ""}
                </p>

                <div className="d-flex justify-content-between mt-2">
                  {btnChange == 2 ? (
                    <div
                      onClick={() => {
                        navigate("/my-product-View_Proof_detail", {
                          state: item,
                        });
                      }}
                      className="cust-btn f4 fs-15 fs-md-15 primary pointer d-flex ac-jc"
                    >
                      <ArrowForwardIosIcon className="f4 fs-15 fs-md-15" />
                      View Proof
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        if (item?.project) {
                          if (item.project.status === 4) {
                            navigate("/my-product-View_Proof_detail", {
                              state: item,
                            });
                          } else {
                            navigate("/my-order");
                          }
                        } else {
                          navigate("/my-product-detail", { state: item });
                        }
                      }}
                      className="cust-btn f4 fs-15 fs-md-15 primary pointer d-flex ac-jc"
                    >
                      <ArrowForwardIosIcon className="f4 fs-15 fs-md-15" />
                      {item?.project !== null ? "View Proof" : "View"}
                    </div>
                  )}

                  {/* <button onClick={() => showCartHandler(1)} className="plus">
                {tick ? <CheckIcon /> : <AddIcon />}
              </button> */}
                </div>
              </div>
            </div>
          </div>
          // </div>
        );
      })}
    </div>
  );
};

export default ProductList;
