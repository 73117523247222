import { CalendarMonthOutlined } from '@mui/icons-material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const StartProjectComp = () => {
    const navigate = useNavigate()
    return (
        <div className='borer-r1 w-100 h-100 py-4 ac-jc mt-5 rounded-4 d-flex'>
            <div className="box w-85 mt-3">
                <h5 class="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 w-100 text-dark">Start Project</h5>
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Let us know some details of what you're looking for (*)
                </p>
                <textarea
                    // placeholder="We want to send product your way! Click below and enter your Shopping details "
                    // value="We want to send product your way! Click below and enter your Shopping details"
                    className="f2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120 text-wrap"
                    style={{ height: '100px' }}
                ></textarea>
                <div className="box1 gap-5 w-100 mt-3 d-flex flex-column flex-md-row">
                    <div className='w-100 position-relative'>
                        <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                            When do you need it by? (*)
                        </p>
                        <input
                            // placeholder="We want to send product your way! Click below and enter your Shopping details "
                            // value="We want to send product your way! Click below and enter your Shopping details"
                            className="f2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120 text-wrap"
                            type='date'
                        />
                        {/* <div className='position-absolute d-flex ac-jc'
                            style={{ right: '12px', bottom: '0px', height: '48px' }}>
                            <CalendarMonthOutlined />
                        </div> */}
                    </div>
                    <div className='w-100'>
                        <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                            What is your budget?
                        </p>
                        <input
                            // placeholder="We want to send product your way! Click below and enter your Shopping details "
                            // value="We want to send product your way! Click below and enter your Shopping details"
                            className="f2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120 text-wrap"
                            type='number'
                            inputmode="decimal"
                            step='10'
                            placeholder='₹0.00'
                        />
                    </div>
                </div>
                <button onClick={() => navigate('/submit-project')} class="cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3 mt-5 float-end">Submit</button>
            </div>
        </div>
    )
}

export default StartProjectComp