import React from "react";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const ProductBreadCrumb = ({ navi, title }) => {
  return (
    <div className="d-flex ac-jb w-100">
      <div className="bread-crumb d-flex ac-jc bg-lt-blue">
        <Link to="/my-products" className="primary1">
          {navi}
        </Link>
        <ArrowForwardIosIcon className="primary" />
        <Link to="" className="primary">
          {title}
        </Link>
      </div>
      <button className="mb-2 border-0 bg-pending text-white mt-3 mt-sm-0 py-2 rounded rounded-3 px-3 py-lg-3 px-lg-4 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-18">
        Approve Design
      </button>
    </div>
  );
};

export default ProductBreadCrumb;
