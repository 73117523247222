import React, { useState } from "react";
import ProductBreadCrumb from "../component/MyProductDetail/ProductBreadCrumb";
import ProductHeader from "../component/MyProductDetail/ProductHeader";
import ProductDetail from "../component/MyProductDetail/ProductDetail";
import { ProductColor } from "../component/MyProductDetail/ProductColor";
import ProductZoom from "../component/MyProductDetail/ProductZoom";
import ProductPopupComponent from "../component/MyProductDetail/ProductPopupComponent";

const ProofDetailScreen = () => {
  const [showModal, setShowModal] = useState(false);
  const showPopupHander = () => {
    setShowModal(!showModal);
  };
  return (
    <div className="d-flex my-peoduct-det">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2 left-mock-req">
        <ProductBreadCrumb navi="Order" title="The Triblend Unisex Tee" />
        <ProductHeader title="The Triblend Unisex Tee" />
        <ProductDetail />
        <div className="d-flex flex-column flex-md-row product-zoom-color me-0 ms-auto d-flex">
          <ProductZoom />
          <ProductColor showPopupHander={showPopupHander} />
        </div>
      </div>
      {showModal && <ProductPopupComponent showPopupHander={showPopupHander} />}
    </div>
  );
};

export default ProofDetailScreen;
