// @ts-nocheck
import React from "react";
import { product1 } from "../../assets/img";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link, useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const EcommerceProductCart = ({
  tick,
  pack,
  cortList,
  priceList,
  setShowPopup,
  deleteCart,
  getProductView,
  packs,
  totalPrice,
  showoption,
  deleteBtn,
}) => {
  // console.log(pack);
  const navigate = useNavigate();

  const addProductHandler = () => {
    // navigate("/ecommerce-mock", { pack: "build" });
    navigate("/ecommerce-delivery");
  };

  return (
    <div className={`${tick ? "show-cart" : "hide-cart"} prod-cart`}>
      <div className="position-relative h-100">
        <h4>Your Cart</h4>
        <div className="bulk-list-cart">
          {showoption && (
            <div>
              {cortList?.map((item, ind) => {
                return (
                  <div className="prod-cart-list w-100 d-flex ac-jb">
                    <div
                      className=" d-flex ac-js"
                      onClick={() => {
                        setShowPopup();
                        getProductView(item?.product?.id, item?.id, item);
                      }}
                      role={"button"}
                    >
                      <div className="img-box me-1">
                        <img
                          src={
                            item?.product?.defaultImage
                              ? item?.product?.defaultImage
                              : product1
                          }
                          alt=""
                        />
                      </div>
                      <div className="">
                        <h5>{item?.product?.name}</h5>
                        <p>Price starting at ₹{item?.total_price}</p>
                        <h6>Quantity: {item?.qty}/pack</h6>
                      </div>
                    </div>
                    <button
                      className={`cust-btn ${deleteBtn && "opacity-50"}`}
                      // onClick={() => addProductHandler("", "", item, ind)}
                      onClick={() => {
                        deleteCart(item?.id, item?.product?.id);
                      }}
                      disabled={deleteBtn}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className="d-flex ac-jb mb-3">
            <h6 className="mb-0">
              Quantity<span className="primary1 fs-14"> (Min 25)</span>
            </h6>
            <select className="cust-btn f4" name="" id="">
              <option value="100">100</option>
              <option value="25">500</option>
            </select>
          </div> */}

          {/* <div className="d-flex ac-jb mb-3">
            <h6 className="mb-0">
              Quantity<span className="primary1 fs-14"> (Min 25)</span>
            </h6>
            <select className="cust-btn f4" name="" id="">
              <option value="100">100</option>
              <option value="25">500</option>
            </select>
          </div> */}
        </div>
        <div
          className={`${pack == "build" ? "build-btm-cart" : "bulk-btm-cart"}`}
        >
          <div className="d-flex ac-jb mb-2">
            <h5 className="">
              Total Estimate <ErrorOutlineIcon className="fs-13 primary1" />
            </h5>
            <h5 className="">₹{totalPrice}</h5>
          </div>
          <button
            onClick={addProductHandler}
            className="cust-btn bg-primar get-start-btn mx-auto mb-0 mb-1 mt-0 w-90"
          >
            Place order
          </button>
          <p className="primary1 fs-16 f2 text-center">
            No credit card info required
          </p>
        </div>
      </div>
    </div>
  );
};

export default EcommerceProductCart;
