// @ts-nocheck
import React from "react";
import {
  clinet1,
  cloudIcon,
  globeLinesIcon,
  handHeartIcon,
  partyPopperIcon,
  pinkHeartIcon,
  serv1,
  serviceBack,
  vrIcon,
} from "../../../assets/img";

const GuideValue = () => {
  return (
    <div className=" w-90 w-md-80 mt-4 mt-md-5 pt-3 mb-2 mb-lg-3 mx-auto">
      <h4 className="sect-title text-center mt-3 mt-xl-5 ">
        The heart of MerchHQ
      </h4>
      <p className="text-center primary1 f2 fs-18 mb-4 mb-sm-3 mb-xl-5">
        We at MHQ live and believe in these values
      </p>

      <div className="box-merch d-flex flex-wrap">
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center align-items-md-start text-center text-md-start ps-md-4">
            <div className="d-flex ac-js">
              <img className="" src={handHeartIcon} alt="icon" />
              <h5 className="ms-1">Brand Custodians</h5>
            </div>
            <p>
              We believe that you mortgage the equity of your brand to us and it
              is of upmost importance for us to protect it.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center align-items-md-start text-center text-md-start ps-md-4">
            <div className="d-flex ac-js">
              <img className="" src={cloudIcon} alt="icon" />
              <h5 className="ms-1">Ideate, Create, Innovate</h5>
            </div>
            <p>
              We ideate, create and innovate with you to bring relevance to your
              merchandise programs.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center align-items-md-start text-center text-md-start ps-md-4">
            <div className="d-flex ac-js">
              <img className="" src={partyPopperIcon} alt="icon" />
              <h5 className="ms-1">Merch should be fun</h5>
            </div>
            <p>Merch is fun, so we’ve made the process easy and exciting!</p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center align-items-md-start text-center text-md-start ps-md-4">
            <div className="d-flex ac-js">
              <img className="" src={vrIcon} alt="icon" />
              <h5 className="ms-1">Future-focused</h5>
            </div>
            <p>
              Start anywhere and we will grow your program with you, as your
              brand and needs evolve.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center align-items-md-start text-center text-md-start ps-md-4">
            <div className="d-flex ac-js">
              <img className="" src={globeLinesIcon} alt="icon" />
              <h5 className="ms-1">360 degree thinking</h5>
            </div>
            <p>
              We keep your brand, sustainability & fair trade practices at the
              core of everything we do.
            </p>
          </div>
        </div>
        <div className="rit-send-merch px-1">
          <div className="content-box align-items-center align-items-md-start text-center text-md-start ps-md-4">
            <div className="d-flex ac-js">
              <img className="" src={pinkHeartIcon} alt="icon" />
              <h5 className="ms-1">Everybody belongs here</h5>
            </div>
            <p>
              We welcome diversity and difference to ignite creativity and
              foster collaboration!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideValue;
