// @ts-nocheck
import React from "react";
import HowItWork from "../component/Services/Membership/HowItWork";
import MemberShipPlan from "../component/Services/Membership/MemberShipPlan";
import ServingFor from "../component/Services/Membership/ServingFor";
import SimplePack from "../component/Services/Membership/SimplePack";
import ServiceBanner from "../component/Services/ServiceBanner";
import Footer from "../navbar/Footer";

const banner = {
  title: "Get more from your MerchHQ experience",
  parag: "Sign up for the SwagUp membership program today!",
};
const Membership = () => {
  return (
    <>
      <ServiceBanner title={banner?.title} paragraph={banner?.parag} />
      <ServingFor />
      <MemberShipPlan />
      <HowItWork />
      <SimplePack />
      <Footer />
    </>
  );
};

export default Membership;
