import React from "react";

const ServiceBanner = () => {
  return (
    <div className="d-flex flex-column ac-jc">
      <div className="w-80 w-lg-75 w-xl-65 w-xxl-60 text-center mx-auto ">
        <h4 className="sect-title">Ready to start with MerchHq?</h4>
        <p className="parag">Choose the package that suits you</p>
      </div>
    </div>
  );
};

export default ServiceBanner;
