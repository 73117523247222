import React, { useState } from "react";
import RedeemFilter from "../component/Redeem/RedeemFilter";
import HeaderProduct from "../component/myProduct/HeaderProduct";
import RedeemHeader from "../component/Redeem/RedeemHeader";
import EmptyRedeemComp from "../component/Redeem/EmptyRedeemComp";
import RedeemRequestListComp from "../component/Redeem/RedeemRequestListComp";
import CreateRedeem from "../component/Redeem/CreateRedeem";

const RedeemScreenTwo = () => {
  const [showredeem, setShowRedeem] = useState(false);
  const [OpenSummery, setOpenSummery] = useState(true);

  const showClickHandler = () => {
    setShowRedeem(!showredeem);
  };
  const openSummeryHandler = () => {
    setOpenSummery(!OpenSummery);
    showredeem(false);
  };
  return (
    <div className="my-product mx-auto w-90 w-md-95 w-xl-85 pb-5 mb-5">
      <RedeemHeader
        title="Redeem"
        btnText="Create Redeem Page"
        showClickHandler={showClickHandler}
      />
      <RedeemFilter />
      {/* <EmptyRedeemComp /> */}
      <RedeemRequestListComp /> 
      <CreateRedeem
        showClickHandler={showClickHandler}
        showredeem={showredeem}
      />
    </div>
  );
};

export default RedeemScreenTwo;
