// @ts-nocheck
import React from "react";
import { ourTeam, ourstory } from "../../../assets/img";

const AboutHQ = ({ handleClick }) => {
  return (
    <>
      <div className="abt-hq w-90 w-md-50 m-auto">
        <h2 className="sect-title text-center mt-5">Our Story</h2>
        <p className="f2 fs-16 text-center">
          From crafting the finest branded watches to mastering the art of
          branding almost any product under the sun, our merchandise journey has
          spanned over 20 years. <br />
          <br />
          We’ve partied with Apple, Google, Salesforce, IBM, Airbnb and the
          likes and have witnessed the magic that top-notch merch can create.
          However, most often - smaller squads, startups and creators are
          trapped in dull ordering procedures and corporate-y, expensive product
          offering. <br /> <br />
          So we hit rewind and came up with a democratised platform to order
          your merch. No more tedious orders - just a tech-savvy, easy process.{" "}
          <br /> <br />
          We’re here to make merch fun again. We got you, at MerchHQ! <br />
        </p>

        {/* <button
        onClick={handleClick}
        className="cust-btn bg-primar get-start-btn mb-3 mt-2 mt-lg-4 mb-lg-5 mx-auto ">
        Read Our Story
      </button> */}
      </div>
      <div className="w-90 w-md-70 mx-auto">
        <img src={ourstory} alt="" className="w-100" />
      </div>
    </>
  );
};

export default AboutHQ;
