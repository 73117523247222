import React from "react";
import { dash2, serv1, service_banner } from "../../assets/img";

const ServicesComp2 = () => {
  return (
    <div className="d-flex w-90 flex-column-reverse flex-md-row gap-4 py-3">
    <div className="d-flex align-items-center justify-content-center w-100 w-lg-50">
        
          <img src={dash2} className="w-80 h-100 object-fit-contain" />
     
      </div>
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="text-custom-align d-flex ac-jc flex-column">
          <h5 class="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 w-100 text-dark">
            Packing:
          </h5>
          <p class="mb-3 mb-md-0 primary1 f2 fs-13 fs-md-14 fs-lg-15 fs-xl-16">
            We're all about creating a great impression! We ensure that everyone
            who receives your merch is genuinely impressed. We take the time to
            put together everything just the way you like it, at no extra cost
            to you. No more assembly lines at your office – we've got it
            covered!
          </p>
        </div>
      </div>
      
    </div>
  );
};

export default ServicesComp2;
