// @ts-nocheck
import React, { useEffect, useState } from "react";
import LeftProduct from "../component/Product/Build/LeftProduct";
import ProductPopupComponent from "../component/Product/Build/ProductPopupComponent";
import RightProducts from "../component/Product/Build/RightProducts";
import ProductCart from "../component/Product/ProductCart";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import { saveLoader } from "../redux/slice/loaderNoti";
import {
  api,
  useAddToCartMutation,
  useCartListMutation,
  useCategoryListMutation,
  useDeleteCartMutation,
  usePackUpdateApiMutation,
  useProductListMutation,
  useProductPriceListMutation,
  useProductViewMutation,
  useUpdateToCartMutation,
} from "../redux/api/api";
import FullProduct from "../component/Product/Build/FullProduct";
import FullProducts from "../component/Product/Build/FullProducts";
import FullProductCopy from "../component/Product/Build/FullProductCopy";
import { useDispatch } from "react-redux";
const BuildProductScreen = () => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [tick, setTick] = useState([]);
  const Token = localStorage.getItem("token");
  const [dataBase, setDataBase] = useState(null);

  //Category
  const [categoryList, setCategoryList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [skuList, setSkuList] = useState([]);
  const [categoryListPra, setCategoryPra] = useState([]);
  const [viewId, setViewId] = useState("");
  const [viewList, setViewList] = useState([]);
  const [imagesid, setImagesId] = useState({});
  const [selectedIds, setSelectedIds] = useState([]);
  const [packsPrice, setPacksPrice] = useState("");
  const [count, setCount] = useState(1);
  const [colorcount, setColorCount] = useState(1);
  const [cortList, setCartList] = useState([]);
  const [priceList, setPriceList] = useState();
  const [bigImg, setBigImg] = useState(0);
  const [style, setStyle] = useState("");
  const [size, setSize] = useState("");
  const [material, setMaterial] = useState("");
  const [selectedColorId, setSelectedColorId] = useState("");
  const [selectColorId, setSelectColorId] = useState("");
  const [skuCodeList, setCodeList] = useState([]);
  const [skuCode, setCode] = useState("");
  const [pack, setPack] = useState(25);
  const [totalPrice, setTotalPrice] = useState("");
  const [totalPerPrice, setTotalPerPrice] = useState("");
  const [ListPrice, setListPrice] = useState({});
  const [cartData, setCartData] = useState({});
  const [userCartID, setUserCartID] = useState("");
  const [cartBtn, setCartBtn] = useState(false);
  const [qtyBtn, setQtyBtn] = useState(false);
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [showoption, setShowOption] = useState(true);
  const [search, setSearch] = useState("");
  const [cartShow, setCartShow] = useState(false);
  const [colorId, setColorId] = useState("");

  const [no_pack, setNo_of_pack] = useState(25);
  const [noOfPack, setNoOfPack] = useState("");
  const [noOfPackErr, setNoOfPackErr] = useState(false);
  const [normalid, setNormalId] = useState([]);
  const [values, setValues] = useState([5, 1000]);
  const [priceSort, SetPriceSort] = useState("");
  const [cartOpen, setCartOpen] = useState(true);

  //RTK
  const [CategoryListApi] = useCategoryListMutation();
  const [ProductListApi] = useProductListMutation();
  const [productViewApi] = useProductViewMutation();
  const [productPriceApi] = useProductPriceListMutation();
  const [addToCartApi] = useAddToCartMutation();
  const [cartListApi] = useCartListMutation();
  const [editToCartApi] = useUpdateToCartMutation();
  const [deleteApi] = useDeleteCartMutation();
  const [packUpdateApi] = usePackUpdateApiMutation();

  const showPopupHander = () => {
    setShowPopup(!showPopup);
  };

  // TICK PRODUCT
  const onClickProduct = (product) => {
    const formData = new FormData();
    setViewId(product);
    formData?.append("product_ids", product);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: product })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setSelectedColorId(res?.product?.colors?.[0]?.name);
        setSelectColorId(res?.product?.colors?.[0]?.id);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let final = {
          ...obj,
          [res?.product?.id]: {
            ...object,
            for_price: +res?.product?.for_25,
            pack: 25,
            count_value: 1,
          },
        };

        let pack_no = no_pack ? no_pack : 25;
        let pack_value;

        if (+pack_no >= 1 && +pack_no <= 25) {
          pack_value = 25;
        } else if (+pack_no > 25 && +pack_no <= 50) {
          pack_value = 50;
        } else if (+pack_no > 50 && +pack_no <= 100) {
          pack_value = 100;
        } else if (+pack_no > 100 && +pack_no <= 150) {
          pack_value = 150;
        } else if (+pack_no > 150 && +pack_no <= 250) {
          pack_value = 250;
        } else if (+pack_no > 250 && +pack_no <= 500) {
          pack_value = 500;
        } else if (+pack_no > 500 && +pack_no <= 1000) {
          pack_value = 1000;
        } else if (+pack_no > 1000) {
          pack_value = 1000;
        }

        if (!Token) {
          addLocalCart(
            res?.product?.product_varient_skus?.[0]?.id,
            res?.product?.id,
            1,
            res?.product?.[`for_${pack_value}`],
            "no_cart"
          );
        } else {
          const temp = [];
          temp?.push(res?.product);

          const list = [];
          const image = [];
          temp?.map((item, ind) => {
            item?.colorImages?.map((color) => {
              list.push({ img: color?.image_url });
            });
            item?.colors?.map((hex) => {
              list?.push({ ...hex, hex_code: hex?.hex_code });
            });

            image?.push({
              ...item,
              images: list,
            });
          });
          // console.log(image);
          // setStyle(ima)
          setViewList(image);

          let tempPro = [];

          tempPro.push({
            product_id: res?.product?.id,
            product_sku_id: res?.product?.product_varient_skus?.[0]?.id,
            no_of_colors: 1,
            qty: 1,
            price: res?.product?.[`for_${pack_value}`],
          });

          const formData = new FormData();
          formData.append("type", 1);
          formData.append("no_of_packs", no_pack);
          // formData.append("product_id", res?.product?.id);
          // formData.append(
          //   "product_sku_id",
          //   res?.product?.product_varient_skus?.[0]?.id
          // );
          // formData.append("qty", 1);
          // formData.append("no_of_colors", 1);
          // formData.append("price", res?.product?.for_25);

          // console.log("productId", productId);

          if (tempPro?.length > 0) {
            tempPro?.map((item, ind) => {
              formData.append(`cart_details[${ind}][qty]`, item?.qty);
              formData.append(
                `cart_details[${ind}][product_id]`,
                item?.product_id
              );
              formData.append(
                `cart_details[${ind}][product_sku_id]`,
                item?.product_sku_id
              );
              formData.append(`cart_details[${ind}][price]`, item?.price);
              formData.append(
                `cart_details[${ind}][no_of_colors]`,
                item?.no_of_colors
              );
            });
          }

          addToCartApi(formData)
            .unwrap()
            .then((resp) => {
              dispatch(saveLoader(false));
              setCartBtn(false);
              const temp = [];
              temp.push(viewId);
              setTick(temp);
              getCartApi();
              // console.log(res);
            })
            .catch((err) => {
              dispatch(saveLoader(false));
              setCartBtn(false);
              console.log(err);
            });
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch(saveLoader(false));
        setCartBtn(false);
      });
  };

  // CART LIST
  const getCartApi = () => {
    const formData = new FormData();
    formData.append("type", 1);
    dispatch(saveLoader(true));
    cartListApi(formData)
      .unwrap()
      .then((res) => {
        setCartOpen(true);
        const data = res?.cart_detail?.cartDetail;
        const temp = [];
        let tempArr = [];
        data?.map((item) => {
          temp?.push(item?.product?.id);
          setTick(temp);
        });

        if (res?.cart_detail?.id) {
          let qtys = res?.cart_detail?.no_of_packs;
          let total_price_cart;
          setNo_of_pack(res?.cart_detail?.no_of_packs);
          res?.cart_detail?.cartDetail?.map((item, ind) => {
            let qty_val = item?.product?.[`for_${qtys}`];
            let sum = +item?.qty * qtys;
            let obj = {
              for_price: +qty_val,
              pack: res?.cart_detail?.no_of_packs,
              count: +item?.qty,
              cart_id: item?.id,
              no_of_colors: item?.no_of_colors,
            };

            if (qty_val) {
              obj.for_price = +qty_val;
            } else {
              obj.for_price = +qtys;
            }

            if (+sum >= 1 && +sum <= 25) {
              obj.packs_price = +item?.product?.for_25;
              total_price_cart = +item?.product?.for_25;
            } else if (+sum > 25 && +sum <= 50) {
              obj.packs_price = +item?.product?.for_50;
              total_price_cart = +item?.product?.for_50;
            } else if (+sum > 50 && +sum <= 100) {
              obj.packs_price = +item?.product?.for_100;
              total_price_cart = +item?.product?.for_100;
            } else if (+sum > 100 && +sum <= 150) {
              obj.packs_price = +item?.product?.for_150;
              total_price_cart = +item?.product?.for_150;
            } else if (+sum > 150 && +sum <= 250) {
              obj.packs_price = +item?.product?.for_250;
              total_price_cart = +item?.product?.for_250;
            } else if (+sum > 250 && +sum <= 500) {
              obj.packs_price = +item?.product?.for_500;
              total_price_cart = +item?.product?.for_500;
            } else if (+sum > 500 && +sum <= 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            } else if (+sum > 1000) {
              obj.packs_price = +item?.product?.for_1000;
              total_price_cart = +item?.product?.for_1000;
            }

            let cart_detail = {
              ...item,
              total_price: total_price_cart,
            };

            if (item?.product_sku?.id) {
              let finder = item?.product?.colorImages?.find(
                (i) => i?.color_id == item?.product_sku?.color_id
              );

              if (finder?.id) {
                cart_detail.selected_image_url = finder?.image_url;
              } else {
                cart_detail.selected_image_url = item?.product?.defaultImage;
              }
            } else {
              cart_detail.selected_image_url = item?.product?.defaultImage;
            }

            tempArr.push(cart_detail);

            // console.log("obj", obj);

            setCartData((val) => ({
              ...val,
              [item?.product?.id]: obj,
            }));
          });
          setPack(res?.cart_detail?.no_of_packs);
          setTotalPrice(res?.cart_detail?.total_amount);
          setTotalPerPrice(res?.cart_detail?.price_per_pack);
          setCartList(tempArr);
          setPriceList(res?.pack_prices);
        } else {
          setCartList([]);
          setPriceList({});
        }
        dispatch(saveLoader(false));
        setQtyBtn(false);
        setDeleteBtn(false);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setQtyBtn(false);
        console.log(err);
      });
  };

  // PRODUCT VIEW
  const getProductView = (id, cartID, item) => {
    const formData = new FormData();
    setViewId(id);
    formData?.append("product_ids", id);
    dispatch(saveLoader(true));
    productViewApi({ payload: formData, id: id })
      .unwrap()
      .then((res) => {
        let obj = { ...cartData };

        // setUserCartID(cartID);
        setListPrice(res?.product);
        setCodeList(res?.product?.product_varient_skus);
        setImagesId(res?.product);
        setPacksPrice(res?.product?.for_25);
        setStyle(res?.product?.styles?.[0]?.value);
        setMaterial(res?.product?.materials?.[0]?.value);
        setSize(res?.product?.sizes?.[0]?.value);

        let object = obj[res?.product?.id];
        let obj_val = {
          ...object,
          for_price: +res?.product?.for_25,
          count_value: 1,
        };
        if (obj[res?.product?.id]?.pack) {
          obj_val.pack = obj[res?.product?.id]?.pack;
          obj_val.no_of_colors = obj[res?.product?.id]?.no_of_colors;
          obj_val.sku_id = obj[res?.product?.id]?.sku_id;
        } else {
          obj_val.pack = 25;
          obj_val.no_of_colors = 1;
          obj_val.sku_id = res?.product?.product_varient_skus?.[0]?.id;
        }

        if (obj[res?.product?.id]?.packs_price) {
          obj_val.packs_price = obj[res?.product?.id]?.packs_price;
        }

        let final = {
          ...obj,
          [res?.product?.id]: obj_val,
        };

        setCartData(final);
        const temp = [];
        temp?.push(res?.product);

        const image = [];
        temp?.map((item, ind) => {
          item?.colorImages?.map((color) => {
            let color_id_finder = item?.colors?.find(
              (i) => i?.id == color?.color_id
            );

            image.push({
              ...color,
              color: color_id_finder,
            });
          });
        });

        const uniqueColors = [];
        temp?.map((item, ind) => {
          item?.product_varient_skus?.map((color) => {
            let finderImage = image?.find(
              (i) => i?.color_id == color?.color_id
            );

            uniqueColors.push(finderImage);
          });
        });
        const uniqueId = new Map(uniqueColors.map((c) => [c.color_id, c]));
        const DefultImages = [...uniqueId.values()];
        // console.log(DefultImages);

        let colorFinder = DefultImages?.find(
          (i) => i?.color_id == item?.product_sku?.color_id
        );
        let findIndex = DefultImages?.findIndex(
          (i) => i?.color_id == item?.product_sku?.color_id
        );

        if (item?.product_sku?.id) {
          setSelectedColorId(colorFinder?.color?.name);
          setSelectColorId(colorFinder?.color?.id);
          setBigImg(findIndex);
        } else {
          setSelectedColorId(DefultImages?.[0]?.color?.name);
          setSelectColorId(DefultImages?.[0]?.color?.id);
          setBigImg(0);
        }

        // setStyle(ima)
        // console.log("temps", temps);
        setViewList([
          {
            ...res?.product,
            images: DefultImages,
          },
        ]);
        showPopupHander();
        dispatch(saveLoader(false));
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };
  // console.log(viewList, "gyyg");
  // DELETE CART
  const deleteCart = (id, pro_id) => {
    if (!Token) {
      deleteLocalCart(pro_id);
    } else {
      const formData = new FormData();
      formData.append("user_cart_detail_id", id);
      // console.log("pro_id", pro_id);
      dispatch(saveLoader(true));
      setDeleteBtn(true);
      deleteApi(formData)
        .unwrap()
        .then((res) => {
          // console.log(res?.navigations);
          // setCategoryList(res?.navigations);

          let obj = { ...cartData };
          delete obj[pro_id];
          setCartData(obj);
          getCartApi();
          let temp = [...tick];
          let inx = temp.indexOf(pro_id);
          temp.splice(inx, 1);
          setTick(temp);
          setShowPopup(false);
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setDeleteBtn(false);
          console.log("err", err);
        });
    }
  };

  const product = (color_id, event, price_value = []) => {
    setSearch(event);
    const formData = new FormData();
    if (color_id) {
      formData.append("color_id", color_id);
      setColorId(color_id);
    } else if (colorId) {
      formData.append("color_id", color_id);
      setColorId(color_id);
    }

    if (event) {
      formData.append("search", event);
    }

    formData.append("start_price", price_value[0]);
    formData.append("end_price", price_value[1]);

    setValues(price_value);

    if (normalid?.length > 0) {
      normalid?.map((item, ind) => {
        formData.append(`tag_id[${ind}]`, item);
      });
    }
    priceSort == "high"
      ? formData.append("high_to_low", 1)
      : formData.append("low_to_high", 1);
    ProductListApi(formData)
      .unwrap()
      .then((res) => {
        // console.log(res?.navigations);
        setCategoryList(res?.navigations);

        let temp = [];
        let skuTemp = [];
        res?.navigations.map((item) => {
          item?.products.map((product) => {
            temp.push(product);
          });
        });

        const ids = temp.map(({ id }) => id);
        const filtered = temp.filter(
          ({ id }, index) => !ids.includes(id, index + 1)
        );

        filtered?.map((item, ind) => {
          item?.productSku?.map((sku) => {
            skuTemp.push(sku);
          });
        });
        setProductList(filtered);
        setSkuList(skuTemp);
        setCategoryPra(res?.navigations);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // ADD TO CART
  const varients = (
    id,
    qty,
    packsPrice,
    colors_name,
    styles_name,
    materials_name,
    sizes_name,
    skuCodes,
    type
  ) => {
    let temp = "";

    if (colors_name) {
      temp = `/${colors_name}`;
    } else if (selectedColorId) {
      temp = `/${selectedColorId}`;
    } else {
      temp = "/";
    }

    if (sizes_name) {
      temp = temp + `/${sizes_name}`;
    } else if (size) {
      temp = temp + `/${size}`;
    } else {
      temp = temp + "/";
    }

    if (materials_name) {
      temp = temp + `/${materials_name}`;
    } else if (material) {
      temp = temp + `/${material}`;
    } else {
      temp = temp + "/";
    }

    if (styles_name) {
      temp = temp + `/${styles_name}`;
    } else if (style) {
      temp = temp + `/${style}`;
    } else {
      temp = temp + "/";
    }

    let codeFinder;

    if (type == "no_cart") {
      codeFinder = skuCodes?.find((i) => i?.variant_name == temp);
    } else {
      codeFinder = skuCodeList?.find((i) => i?.variant_name?.includes(temp));
    }

    // setCode(skuCode)
    if (codeFinder?.id) {
      dispatch(saveLoader(true));
      setCartBtn(true);
      if (Token) {
        getAddToCart(codeFinder?.id, id, qty, packsPrice);
      } else {
        addLocalCart(codeFinder?.id, id, qty, packsPrice, type);
      }
    } else {
      dispatch(saveLoader(false));
      setCartBtn(false);
    }
  };

  // ADD CART
  const getAddToCart = (skuId, productId, qty, packsPrice) => {
    let pack_value = cartData?.[productId]?.pack;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let no_of_colors = cartData?.[productId]?.no_of_colors
      ? +cartData?.[productId]?.no_of_colors
      : 1;

    let temp = [];

    temp.push({
      product_id: productId,
      product_sku_id: skuId,
      no_of_colors: no_of_colors,
      qty: qty ? qty : count_value,
      price: +packsPrice ? +packsPrice : +packs_val,
    });

    const formData = new FormData();
    formData.append("type", 1);
    formData.append("no_of_packs", no_pack);
    // formData.append("product_id", productId);
    // formData.append("product_sku_id", skuId);
    // formData.append("qty", qty ? qty : count_value);
    // formData.append("no_of_colors", colorcount);
    // formData.append("price", +packsPrice ? +packsPrice : +packs_val);

    if (temp?.length > 0) {
      temp?.map((item, ind) => {
        let qty_sum = +item?.qty * +no_pack;

        if (+qty_sum >= 1 && +qty_sum <= 25) {
          qty_sum = 25;
        } else if (+qty_sum > 25 && +qty_sum <= 50) {
          qty_sum = 50;
        } else if (+qty_sum > 50 && +qty_sum <= 100) {
          qty_sum = 100;
        } else if (+qty_sum > 100 && +qty_sum <= 150) {
          qty_sum = 150;
        } else if (+qty_sum > 150 && +qty_sum <= 250) {
          qty_sum = 250;
        } else if (+qty_sum > 250 && +qty_sum <= 500) {
          qty_sum = 500;
        } else if (+qty_sum > 500 && +qty_sum <= 1000) {
          qty_sum = 1000;
        } else if (+qty_sum > 1000) {
          qty_sum = 1000;
        }

        formData.append(`cart_details[${ind}][qty]`, item?.qty);
        formData.append(`cart_details[${ind}][product_id]`, item?.product_id);
        formData.append(
          `cart_details[${ind}][product_sku_id]`,
          item?.product_sku_id
        );

        formData.append(
          `cart_details[${ind}][no_of_colors]`,
          item?.no_of_colors
        );

        if (item?.product_id == ListPrice?.id) {
          formData.append(
            `cart_details[${ind}][price]`,
            ListPrice?.[`for_${qty_sum}`]
          );
        } else {
          formData.append(`cart_details[${ind}][price]`, item?.price);
        }
      });
    }

    // console.log("productId", productId);

    addToCartApi(formData)
      .unwrap()
      .then((res) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        if (!qty) {
          showPopupHander();
        }
        const temp = [];
        temp.push(viewId);
        setTick(temp);
        getCartApi();
        // console.log(res);
      })
      .catch((err) => {
        dispatch(saveLoader(false));
        setCartBtn(false);
        console.log(err);
      });
  };

  //PRODUCT PRICE LIST
  const productPrice = () => {
    const formData = new FormData();
    if (selectedIds?.length > 0) {
      selectedIds?.map((item, ind) => {
        formData.append(`product_ids[${ind}]`, item);
      });
    }
    productPriceApi(formData)
      .unwrap()
      .then((res) => {
        const price = res?.total_price;
        setPacksPrice(price?.[pack]);

        // setPriceList(price);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    product("", "", values);
  }, [normalid, priceSort]);

  // console.log("pack", pack);

  const calculations = (qty, id, packs, type) => {
    setQtyBtn(true);
    let qty_val;
    if (type == "decrement") {
      qty_val = +qty - 1;
    } else {
      qty_val = +qty + 1;
    }

    let qty_sum = +qty_val * +cartData?.[id]?.pack;

    // if (+qty_sum <= 1000) {
    let obj = {};
    if (+qty_sum >= 1 && +qty_sum <= 25) {
      setPacksPrice(+ListPrice?.for_25);
      obj.packs_price = +ListPrice?.for_25;
    } else if (+qty_sum > 25 && +qty_sum <= 50) {
      setPacksPrice(+ListPrice?.for_50);
      obj.packs_price = +ListPrice?.for_50;
    } else if (+qty_sum > 50 && +qty_sum <= 100) {
      setPacksPrice(+ListPrice?.for_100);
      obj.packs_price = +ListPrice?.for_100;
    } else if (+qty_sum > 100 && +qty_sum <= 150) {
      setPacksPrice(+ListPrice?.for_150);
      obj.packs_price = +ListPrice?.for_150;
    } else if (+qty_sum > 150 && +qty_sum <= 250) {
      setPacksPrice(+ListPrice?.for_250);
      obj.packs_price = +ListPrice?.for_250;
    } else if (+qty_sum > 250 && +qty_sum <= 500) {
      setPacksPrice(+ListPrice?.for_500);
      obj.packs_price = +ListPrice?.for_500;
    } else if (+qty_sum > 500 && +qty_sum <= 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    } else if (+qty_sum > 1000) {
      setPacksPrice(+ListPrice?.for_1000);
      obj.packs_price = +ListPrice?.for_1000;
    }

    obj.pack = +packs;
    obj.count = +qty_val;
    obj.for_price = +cartData?.[id]?.for_price;
    obj.no_of_colors = +cartData?.[id]?.no_of_colors;
    obj.sku_id = +cartData?.[id]?.sku_id;

    let final = {
      ...cartData,
      [id]: obj,
    };

    setCartData(final);
    setNo_of_pack(+packs);

    if (+cartData?.[id]?.cart_id) {
      if (!Token) {
        updateLocalCart(id, +qty, "", "", "", type);
      } else {
        obj.cart_id = +cartData?.[id]?.cart_id;
        // console.log("test");
        varients(
          id,
          qty_val,
          obj?.packs_price ? obj?.packs_price : obj.for_price
        );
      }
    } else {
      setQtyBtn(false);
    }
    // }
  };

  const customQty = (no_of_packs) => {
    if (!Token) {
      customQtyLocal(no_of_packs);
    } else {
      const no_of_pack = no_of_packs ? no_of_packs : noOfPack;

      if (no_of_pack?.length == 0) {
        setNoOfPackErr(true);
      } else {
        let formdata = new FormData();
        formdata.append("no_of_packs", no_of_packs ? no_of_packs : noOfPack);
        setNo_of_pack(no_of_packs ? no_of_packs : noOfPack);
        packUpdateApi(formdata)
          .unwrap()
          .then((res) => {
            setNoOfPack("");
            setNoOfPackErr(false);
            getCartApi();
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    }
  };

  const varientsNew = (id, qty, packsPrice, colorID, noOfColors, type) => {
    let codeFinder = skuCodeList?.find((i) => i?.color_id == colorID);
    if (codeFinder?.id) {
      let packs_val = +cartData?.[id]?.packs_price
        ? +cartData?.[id]?.packs_price
        : +cartData?.[id]?.for_price;

      // console.log("codeFinder", codeFinder);
      if (!Token) {
        updateLocalCart(id, qty, packs_val, codeFinder, noOfColors, type);
      } else {
        let pack_value = cartData?.[id]?.pack;

        let count_value = cartData?.[id]?.count
          ? +cartData?.[id]?.count
          : +cartData?.[id]?.count_value;

        let no_of_colors = noOfColors
          ? noOfColors
          : cartData?.[id]?.no_of_colors
            ? +cartData?.[id]?.no_of_colors
            : 1;

        let temp = [];

        temp.push({
          product_id: id,
          product_sku_id: codeFinder?.id,
          no_of_colors: no_of_colors,
          qty: qty ? qty : count_value,
          price: +packsPrice ? +packsPrice : +packs_val,
        });

        const formData = new FormData();
        formData.append("type", 1);
        formData.append("no_of_packs", pack_value);
        // formData.append("preset_pack_id", " ");

        if (temp?.length > 0) {
          temp?.map((item, ind) => {
            formData.append(`cart_details[${ind}][qty]`, item?.qty);
            formData.append(
              `cart_details[${ind}][product_id]`,
              item?.product_id
            );
            if (item?.product_sku_id) {
              formData.append(
                `cart_details[${ind}][product_sku_id]`,
                item?.product_sku_id
              );
            }

            formData.append(`cart_details[${ind}][price]`, item?.price);
            formData.append(
              `cart_details[${ind}][no_of_colors]`,
              item?.no_of_colors
            );
          });
        }

        // console.log("temp", temp);

        addToCartApi(formData)
          .unwrap()
          .then((res) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            // if (!qty) {
            //   showPopupHander();
            // }
            // const temp = [];
            // temp.push(viewId);
            // setTick(temp);
            getCartApi();
            // console.log(res);
          })
          .catch((err) => {
            dispatch(saveLoader(false));
            setCartBtn(false);
            console.log(err);
          });
      }
    }
  };

  // Db Intialize starts
  const createDB = () => {
    // var open = window.indexedDB.open("CartDB");
    var open = indexedDB.open("CartDB");

    open.onupgradeneeded = function () {
      let db = open.result;
      if (!db.objectStoreNames.contains("CartTable")) {
        db.createObjectStore("CartTable");
      }
    };

    open.onsuccess = function () {
      let db = open.result;
      setDataBase(db);
      console.log("success!");
    };

    open.onerror = function () {
      console.log(open.error);
    };
  };

  // GET DATABASE
  const getDB = (skuId) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("individual");
    data.onsuccess = (eve) => {
      // console.log("eve.target.result", eve.target.result);
      let responce = eve.target.result;

      const temp = [];
      let tempArr = [];
      responce?.cartDetail?.map((item) => {
        temp?.push(item?.product?.id);
        setTick(temp);
      });

      if (responce?.total_amount) {
        setNo_of_pack(+responce?.no_of_packs);

        let pack_no = responce?.no_of_packs;
        let pack_value;

        if (+pack_no >= 1 && +pack_no <= 25) {
          pack_value = 25;
        } else if (+pack_no > 25 && +pack_no <= 50) {
          pack_value = 50;
        } else if (+pack_no > 50 && +pack_no <= 100) {
          pack_value = 100;
        } else if (+pack_no > 100 && +pack_no <= 150) {
          pack_value = 150;
        } else if (+pack_no > 150 && +pack_no <= 250) {
          pack_value = 250;
        } else if (+pack_no > 250 && +pack_no <= 500) {
          pack_value = 500;
        } else if (+pack_no > 500 && +pack_no <= 1000) {
          pack_value = 1000;
        } else if (+pack_no > 1000) {
          pack_value = 1000;
        }

        let qtys = responce?.no_of_packs;
        let total_price_cart;
        responce?.cartDetail?.map((item, ind) => {
          let qty_val = item?.product?.[`for_${pack_value}`];
          let sum = +item?.qty * qtys;
          let obj = {
            for_price: +qty_val,
            pack: responce?.no_of_packs,
            count: +item?.qty,
            cart_id: 10,
            no_of_colors: item?.no_of_colors,
            product_id: item?.product?.id,
            sku_id: skuId ? skuId : item?.product_sku_id,
          };

          if (qty_val) {
            obj.for_price = +qty_val;
          } else {
            obj.for_price = +qtys;
          }

          if (+sum >= 1 && +sum <= 25) {
            obj.packs_price = +item?.product?.for_25;
            total_price_cart = +item?.product?.for_25;
          } else if (+sum > 25 && +sum <= 50) {
            obj.packs_price = +item?.product?.for_50;
            total_price_cart = +item?.product?.for_50;
          } else if (+sum > 50 && +sum <= 100) {
            obj.packs_price = +item?.product?.for_100;
            total_price_cart = +item?.product?.for_100;
          } else if (+sum > 100 && +sum <= 150) {
            obj.packs_price = +item?.product?.for_150;
            total_price_cart = +item?.product?.for_150;
          } else if (+sum > 150 && +sum <= 250) {
            obj.packs_price = +item?.product?.for_250;
            total_price_cart = +item?.product?.for_250;
          } else if (+sum > 250 && +sum <= 500) {
            obj.packs_price = +item?.product?.for_500;
            total_price_cart = +item?.product?.for_500;
          } else if (+sum > 500 && +sum <= 1000) {
            obj.packs_price = +item?.product?.for_1000;
            total_price_cart = +item?.product?.for_1000;
          } else if (+sum > 1000) {
            obj.packs_price = +item?.product?.for_1000;
            total_price_cart = +item?.product?.for_1000;
          }

          let cart_detail = {
            ...item,
            total_price: total_price_cart,
          };

          if (item?.product_sku?.id) {
            let finder = item?.product?.colorImages?.find(
              (i) => i?.color_id == item?.product_sku?.color_id
            );

            if (finder?.id) {
              cart_detail.selected_image_url = finder?.image_url;
            } else {
              cart_detail.selected_image_url = item?.product?.defaultImage;
            }
          } else {
            cart_detail.selected_image_url = item?.product?.defaultImage;
          }

          tempArr.push(cart_detail);

          // console.log("obj", obj);

          setCartData((val) => ({
            ...val,
            [item?.product?.id]: obj,
          }));
        });

        // console.log("tempArr", tempArr);

        setPack(responce?.no_of_packs);
        setTotalPrice(responce?.total_amount);
        setTotalPerPrice(responce?.price_per_pack);
        setCartList(tempArr);
        setPriceList(responce?.pack_prices);
        setCartOpen(true);
      } else {
        setCartList([]);
        setPriceList({});
      }

      dispatch(saveLoader(false));
      setCartBtn(false);
      setQtyBtn(false);
    };

    data.onerror = function () {
      // console.log(data.error);
      setQtyBtn(false);
      dispatch(saveLoader(false));
    };
  };

  // ADD LOCAL CART
  const addLocalCart = (skuId, productId, qty, packsPrice, type) => {
    let tx = dataBase.transaction("CartTable", "readwrite");
    let store = tx.objectStore("CartTable");

    let pack_value = +cartData?.[productId]?.pack
      ? cartData?.[productId]?.pack
      : 25;

    let packs_val = +cartData?.[productId]?.packs_price
      ? +cartData?.[productId]?.packs_price
      : +cartData?.[productId]?.for_price;

    let count_value = cartData?.[productId]?.count
      ? +cartData?.[productId]?.count
      : +cartData?.[productId]?.count_value;

    let no_of_colors = cartData?.[productId]?.no_of_colors
      ? +cartData?.[productId]?.no_of_colors
      : 1;

    let temp = [];
    let pro_temp = [];

    if (type == "no_cart") {
      temp.push({
        product_id: +productId,
        product_sku_id: skuId,
        no_of_colors: no_of_colors,
        qty: +qty ? +qty : count_value,
        price: +packsPrice ? +packsPrice : +packs_val,
      });
    }

    Object.keys(cartData).map((item) => {
      if (+item !== +productId && cartData?.[item]?.cart_id == 10) {
        let qty_arr = cartData?.[item]?.count
          ? +cartData?.[item]?.count
          : +cartData?.[item]?.count_value;
        temp.push({
          product_id: +item,
          product_sku_id: cartData?.[item]?.sku_id,
          no_of_colors: cartData?.[item]?.no_of_colors,
          qty: +qty_arr,
          price: cartData?.[item]?.packs_price
            ? +cartData?.[item]?.packs_price
            : +cartData?.[item]?.for_price,
        });
      } else {
        temp.push({
          product_id: +productId,
          product_sku_id: skuId,
          no_of_colors: no_of_colors,
          qty: +qty ? +qty : count_value,
          price: +packsPrice ? +packsPrice : +packs_val,
        });
      }
    });

    const ids = temp.map(({ product_id }) => product_id);
    const filtered = temp.filter(
      ({ product_id }, index) => !ids.includes(product_id, index + 1)
    );

    let packs = [];

    let prices_pack = {
      25: 0,
      50: 0,
      100: 0,
      150: 0,
      250: 0,
      500: 0,
      1000: 0,
    };

    filtered.map((item, ind) => {
      let finder = productList?.find((i) => i?.id == item?.product_id);
      let finder_sku = skuList?.find((i) => i?.id == item?.product_sku_id);
      let qty_sum = cartData?.[item?.product_id]?.pack
        ? cartData?.[item?.product_id]?.pack *
        +cartData?.[item?.product_id]?.count
        : 25 * +cartData?.[item?.product_id]?.count;

      let total_value;

      let pack_sizes = [25, 50, 100, 150, 250, 500, 1000];
      let quantity_1 = +item?.qty;

      pack_sizes.map((price) => {
        let quantity = +quantity_1 * +price;

        if (+quantity >= 1 && +quantity <= 25) {
          prices_pack[price] += finder?.[`for_${25}`] * +quantity_1;
        } else if (+quantity > 25 && +quantity <= 50) {
          prices_pack[price] += finder?.[`for_${50}`] * +quantity_1;
        } else if (+quantity > 50 && +quantity <= 100) {
          prices_pack[price] += finder?.[`for_${100}`] * +quantity_1;
        } else if (+quantity > 100 && +quantity <= 150) {
          prices_pack[price] += finder?.[`for_${150}`] * +quantity_1;
        } else if (+quantity > 150 && +quantity <= 250) {
          prices_pack[price] += finder?.[`for_${250}`] * +quantity_1;
        } else if (+quantity > 250 && +quantity <= 500) {
          prices_pack[price] += finder?.[`for_${500}`] * +quantity_1;
        } else if (+quantity > 500 && +quantity <= 1000) {
          prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
        } else if (+quantity > 1000) {
          prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
        }
      });

      let obj = {
        ...item,
        product: finder,
        product_sku: finder_sku,
        total_price: +packsPrice ? +packsPrice : +packs_val,
      };
      pro_temp.push(obj);

      packs.push({
        25: total_value * +cartData?.[item?.product_id]?.count,
        50: finder?.for_50 * +cartData?.[item?.product_id]?.count,
        100: finder?.for_100 * +cartData?.[item?.product_id]?.count,
        150: finder?.for_150 * +cartData?.[item?.product_id]?.count,
        250: finder?.for_250 * +cartData?.[item?.product_id]?.count,
        500: finder?.for_500 * +cartData?.[item?.product_id]?.count,
        1000: finder?.for_1000 * +cartData?.[item?.product_id]?.count,
      });
    });

    const result = packs?.reduce((accum, current) => {
      Object.entries(current).forEach(([key, value]) => {
        accum[key] = +accum[key] + +value || +value;
      });
      return {
        ...accum,
      };
    }, {});

    let pack_no = no_pack;
    let pack_values;

    if (+pack_no >= 1 && +pack_no <= 25) {
      pack_values = 25;
    } else if (+pack_no > 25 && +pack_no <= 50) {
      pack_values = 50;
    } else if (+pack_no > 50 && +pack_no <= 100) {
      pack_values = 100;
    } else if (+pack_no > 100 && +pack_no <= 150) {
      pack_values = 150;
    } else if (+pack_no > 150 && +pack_no <= 250) {
      pack_values = 250;
    } else if (+pack_no > 250 && +pack_no <= 500) {
      pack_values = 500;
    } else if (+pack_no > 500 && +pack_no <= 1000) {
      pack_values = 1000;
    } else if (+pack_no > 1000) {
      pack_values = 1000;
    }

    let final = {
      no_of_packs: +no_pack,
      price_per_pack: Number(+prices_pack?.[`${pack_values}`]).toFixed(2),
      total_amount: Number(
        +prices_pack?.[`${pack_values}`] * +pack_values
      ).toFixed(2),
      cartDetail: pro_temp,
      pack_prices: prices_pack,
    };

    // console.log("final", final);

    let request = store.put(final, "individual");

    request.onsuccess = function () {
      console.log("success!");
      if (!qty) {
        showPopupHander();
      }
      const temp = [];
      temp.push(viewId);
      setTick(temp);
      getDB(skuId);
    };

    request.onerror = function () {
      console.log(request.error);
    };
  };

  // UPDATE LOCAL CART
  const updateLocalCart = (id, qty, packsPrice, colors, noOfColors, type) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("individual");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let index = arr.findIndex((i) => i.product_id == id);
      let pack_no = obj.no_of_packs;
      let pack_value = obj.no_of_packs;

      // EDIT
      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      arr[index] = {
        ...arr[index],
      };

      if (type == "logo") {
        arr[index].no_of_colors = noOfColors;
      }

      if (type == "color") {
        arr[index].product_sku = colors;
        arr[index].product_sku_id = colors?.id;
      }

      let qty_val;

      if (type == "decrement") {
        qty_val = +qty - 1;
      }
      if (type == "increment") {
        qty_val = +qty + 1;
      }

      let prices_pack = {
        25: 0,
        50: 0,
        100: 0,
        150: 0,
        250: 0,
        500: 0,
        1000: 0,
      };

      if (qty_val) {
        arr?.map((item, ind) => {
          let pack_sizes = [25, 50, 100, 150, 250, 500, 1000];
          let finder = item?.product;
          let quantity_1;

          if (item?.product?.id == id) {
            quantity_1 = qty_val;
          } else {
            quantity_1 = +item?.qty;
          }

          pack_sizes.map((price) => {
            let quantity = +quantity_1 * +price;

            if (+quantity >= 1 && +quantity <= 25) {
              prices_pack[price] += finder?.[`for_${25}`] * +quantity_1;
            } else if (+quantity > 25 && +quantity <= 50) {
              prices_pack[price] += finder?.[`for_${50}`] * +quantity_1;
            } else if (+quantity > 50 && +quantity <= 100) {
              prices_pack[price] += finder?.[`for_${100}`] * +quantity_1;
            } else if (+quantity > 100 && +quantity <= 150) {
              prices_pack[price] += finder?.[`for_${150}`] * +quantity_1;
            } else if (+quantity > 150 && +quantity <= 250) {
              prices_pack[price] += finder?.[`for_${250}`] * +quantity_1;
            } else if (+quantity > 250 && +quantity <= 500) {
              prices_pack[price] += finder?.[`for_${500}`] * +quantity_1;
            } else if (+quantity > 500 && +quantity <= 1000) {
              prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
            } else if (+quantity > 1000) {
              prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
            }

            if (+pack_no >= 1 && +pack_no <= 25) {
              pack_value = 25;
            } else if (+pack_no > 25 && +pack_no <= 50) {
              pack_value = 50;
            } else if (+pack_no > 50 && +pack_no <= 100) {
              pack_value = 100;
            } else if (+pack_no > 100 && +pack_no <= 150) {
              pack_value = 150;
            } else if (+pack_no > 150 && +pack_no <= 250) {
              pack_value = 250;
            } else if (+pack_no > 250 && +pack_no <= 500) {
              pack_value = 500;
            } else if (+pack_no > 500 && +pack_no <= 1000) {
              pack_value = 1000;
            } else if (+pack_no > 1000) {
              pack_value = 1000;
            }
          });
        });

        arr[index] = {
          ...arr[index],
          qty: qty_val,
        };
      }

      // console.log("prices_pack", prices_pack);
      // console.log("qty_val", qty_val);
      // console.log("pack_value", pack_value);

      let final = {
        ...obj,
        cartDetail: arr,
      };

      if (type == "decrement" || type == "increment") {
        final.pack_prices = prices_pack;
        final.price_per_pack = Number(+prices_pack?.[`${pack_value}`]).toFixed(
          2
        );
        final.total_amount = Number(
          +prices_pack?.[`${pack_value}`] * +pack_no
        ).toFixed(2);
      }

      let request = stored.put(final, "individual");
      setNo_of_pack(pack_value);
      request.onsuccess = function () {
        console.log("success!");
        getDB();
      };

      request.onerror = function () {
        console.log(request.error);
      };

      // console.log("final", final);
    };
  };

  // DELETE LOCAL CART
  const deleteLocalCart = (id) => {
    let tx = dataBase.transaction("CartTable", "readonly");
    let store = tx.objectStore("CartTable");

    let data = store.get("individual");
    data.onsuccess = (eve) => {
      let cart_data = eve.target.result;

      // DELETE CART
      let obj = { ...cart_data };
      let arr = [...obj?.cartDetail];
      let index = arr.findIndex((i) => i.product_id == id);
      arr.splice(index, 1);

      let txs = dataBase.transaction("CartTable", "readwrite");
      let stored = txs.objectStore("CartTable");

      let packs = [];
      const temp = [];
      let pack_value = obj?.no_of_packs;

      let prices_pack = {
        25: 0,
        50: 0,
        100: 0,
        150: 0,
        250: 0,
        500: 0,
        1000: 0,
      };

      arr.map((item, ind) => {
        let pack_sizes = [25, 50, 100, 150, 250, 500, 1000];
        let quantity_1 = +item?.qty;
        temp.push(item?.product?.id);
        let finder = item?.product;

        pack_sizes.map((price) => {
          let quantity = +quantity_1 * +price;

          if (+quantity >= 1 && +quantity <= 25) {
            prices_pack[price] += finder?.[`for_${25}`] * +quantity_1;
          } else if (+quantity > 25 && +quantity <= 50) {
            prices_pack[price] += finder?.[`for_${50}`] * +quantity_1;
          } else if (+quantity > 50 && +quantity <= 100) {
            prices_pack[price] += finder?.[`for_${100}`] * +quantity_1;
          } else if (+quantity > 100 && +quantity <= 150) {
            prices_pack[price] += finder?.[`for_${150}`] * +quantity_1;
          } else if (+quantity > 150 && +quantity <= 250) {
            prices_pack[price] += finder?.[`for_${250}`] * +quantity_1;
          } else if (+quantity > 250 && +quantity <= 500) {
            prices_pack[price] += finder?.[`for_${500}`] * +quantity_1;
          } else if (+quantity > 500 && +quantity <= 1000) {
            prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
          } else if (+quantity > 1000) {
            prices_pack[price] += finder?.[`for_${1000}`] * +quantity_1;
          }
        });

        packs.push({
          25: finder?.for_25,
          50: finder?.for_50,
          100: finder?.for_100,
          150: finder?.for_150,
          250: finder?.for_250,
          500: finder?.for_500,
          1000: finder?.for_1000,
        });
      });

      const result = packs?.reduce((accum, current) => {
        Object.entries(current).forEach(([key, value]) => {
          accum[key] = +accum[key] + +value || +value;
        });
        return {
          ...accum,
        };
      }, {});

      let qtyPack;

      if (+pack_value >= 1 && +pack_value <= 25) {
        qtyPack = +prices_pack?.["25"];
      } else if (+pack_value > 25 && +pack_value <= 50) {
        qtyPack = +prices_pack?.["50"];
      } else if (+pack_value > 50 && +pack_value <= 100) {
        qtyPack = +prices_pack?.["100"];
      } else if (+pack_value > 100 && +pack_value <= 150) {
        qtyPack = +prices_pack?.["150"];
      } else if (+pack_value > 150 && +pack_value <= 250) {
        qtyPack = +prices_pack?.["250"];
      } else if (+pack_value > 250 && +pack_value <= 500) {
        qtyPack = +prices_pack?.["500"];
      } else if (+pack_value > 500 && +pack_value <= 1000) {
        qtyPack = +prices_pack?.["1000"];
      } else if (+pack_value > 1000) {
        qtyPack = +prices_pack?.["1000"];
      }

      let final = {
        no_of_packs: +pack_value,
        price_per_pack: Number(+qtyPack).toFixed(2),
        total_amount: Number(+qtyPack * +pack_value).toFixed(2),
        cartDetail: arr,
        pack_prices: prices_pack,
      };

      let request = stored.put(final, "individual");

      request.onsuccess = function () {
        console.log("success!");
        let obj = { ...cartData };
        delete obj[id];
        setCartData(obj);
        setTick(temp);
        setShowPopup(false);
        getDB();
      };

      request.onerror = function () {
        console.log(request.error);
      };
    };

    data.onerror = function () {
      console.log(data.error);
    };
  };

  // PACK QTY CHANGE AND CUSTOM QTY CHANGE
  const customQtyLocal = (no_of_packs) => {
    setQtyBtn(true);
    const no_of_pack = no_of_packs ? no_of_packs : noOfPack;

    if (no_of_pack?.length == 0) {
      setNoOfPackErr(true);
    } else {
      let tx = dataBase.transaction("CartTable", "readonly");
      let store = tx.objectStore("CartTable");

      let data = store.get("individual");
      data.onsuccess = (eve) => {
        let txs = dataBase.transaction("CartTable", "readwrite");
        let stored = txs.objectStore("CartTable");
        let cart_data = eve.target.result;
        let obj = { ...cart_data };
        let result = obj?.pack_prices;

        let qtyPack;

        if (+no_of_pack >= 1 && +no_of_pack <= 25) {
          qtyPack = +result?.["25"];
        } else if (+no_of_pack > 25 && +no_of_pack <= 50) {
          qtyPack = +result?.["50"];
        } else if (+no_of_pack > 50 && +no_of_pack <= 100) {
          qtyPack = +result?.["100"];
        } else if (+no_of_pack > 100 && +no_of_pack <= 150) {
          qtyPack = +result?.["150"];
        } else if (+no_of_pack > 150 && +no_of_pack <= 250) {
          qtyPack = +result?.["250"];
        } else if (+no_of_pack > 250 && +no_of_pack <= 500) {
          qtyPack = +result?.["500"];
        } else if (+no_of_pack > 500 && +no_of_pack <= 1000) {
          qtyPack = +result?.["1000"];
        } else if (+no_of_pack > 1000) {
          qtyPack = +result?.["1000"];
        }

        let final = {
          ...obj,
          no_of_packs: +no_of_pack,
          price_per_pack: Number(+qtyPack).toFixed(2),
          total_amount: Number(+qtyPack * +no_of_pack).toFixed(2),
        };

        let request = stored.put(final, "individual");

        setNo_of_pack(+no_of_pack);
        request.onsuccess = function () {
          getDB();
          setNoOfPack("");
          setNoOfPackErr(false);
        };

        request.onerror = function () {
          console.log(request.error);
        };
      };
    }
  };

  useEffect(() => {
    if (Token) {
      getCartApi();
    } else {
      createDB();
    }
  }, []);

  useEffect(() => {
    if (dataBase?.name) {
      getDB();
    }
  }, [dataBase]);

  useEffect(() => {
    if (cortList?.length == 0) {
      setNo_of_pack(25);
    }
  }, [cortList]);

  const onClickHandler = () => {
    console.log("lklk");

    setCartShow(!cartShow);
  };

  const productCartOpen = () => {
    setCartOpen(!cartOpen);
  }

  return (
    <div className="product-screen d-flex flex-column flex-md-row mt-4 w-100">
      <FullProduct
        tick={tick}
        categoryList={categoryList}
        showPopupHander={showPopupHander}
        setViewId={setViewId}
        setTick={setTick}
        onClickProduct={onClickProduct}
        getAddToCart={getAddToCart}
        getProductView={getProductView}
        cartData={cartData}
        deleteCart={deleteCart}
        userCartID={userCartID}
        product={product}
        setNormalId={setNormalId}
        SetPriceSort={SetPriceSort}
        setValues={setValues}
        values={values}
        search={search}
        setSearch={setSearch}
        priceSort={priceSort}
        setColorId={setColorId}
      />
      {cortList?.length > 0 && cartOpen && (
        <ProductCart
          productCartOpen={productCartOpen}
          pack="build"
          tick={tick}
          categoryList={categoryList}
          cortList={cortList}
          priceList={priceList}
          setShowPopup={showPopupHander}
          deleteCart={deleteCart}
          getProductView={getProductView}
          packs={pack}
          totalPrice={totalPrice}
          totalPerPrice={totalPerPrice}
          deleteBtn={deleteBtn}
          customQty={customQty}
          noOfPack={noOfPack}
          setNoOfPack={setNoOfPack}
          noOfPackErr={noOfPackErr}
          setShowOption={setShowOption}
          showoption={showoption}
          priceSort={priceSort}
          cartShow={cartShow}
        />
      )}

      {showPopup && (
        <ProductPopupComponent
          type={"induvial"}
          varients={varients}
          method={"start"}
          setSelectedColorId={setSelectedColorId}
          selectedColorId={selectedColorId}
          setSelectColorId={setSelectColorId}
          selectColorId={selectColorId}
          showPopupHander={showPopupHander}
          viewList={viewList}
          imagesid={imagesid}
          setSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
          count={count}
          setCount={setCount}
          productPrice={productPrice}
          setTick={setTick}
          packsPrice={packsPrice}
          getAddToCart={getAddToCart}
          setColorCount={setColorCount}
          colorcount={colorcount}
          bigImg={bigImg}
          setBigImg={setBigImg}
          setMaterial={setMaterial}
          material={material}
          setSize={setSize}
          size={size}
          setStyle={setStyle}
          style={style}
          pack={pack}
          calculations={calculations}
          setCartData={setCartData}
          cartData={cartData}
          userCartID={userCartID}
          deleteCart={deleteCart}
          cartBtn={cartBtn}
          deleteBtn={deleteBtn}
          varientsNew={varientsNew}
          updateLocalCart={updateLocalCart}
          qtyBtn={qtyBtn}
        />
      )}
      {tick?.length > 0 && (
        <button
          onClick={() => onClickHandler()}
          className="cust-btn d-flex ac-jc d-xl-none mobile-cart"
        >
          <ShoppingBagIcon />
        </button>
      )}
    </div>
  );
};

export default BuildProductScreen;
