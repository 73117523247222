// @ts-nocheck
import React from "react";
import { whatsNew } from "../../assets/img";
import { useNavigate } from "react-router-dom";

const WhatsNew = () => {
  const navigate = useNavigate()
  return (
    <div className="whats-new  my-3">
      <div className="box bg-lt-pending px-3 px-lg-4 py-2">
        <img src={whatsNew} className="mx-auto d-flex mt-lg-3" alt="" />
        <h5 className="text-dark f4 fs-xl-25 my-lg-3">What's New</h5>
        <h5 className="text-dark f4 fs-15 mb-lg-4">
          View our latest features and updates on improving your experience.
        </h5>
        <button onClick={() => navigate('/dashboard/lets-start')} className="bg-orange get-start-btn text-white border-0 mt-lg-3 mb-2">
          Select Pack
        </button>
      </div>
    </div>
  );
};

export default WhatsNew;
