// @ts-nocheck
import React from "react";
import { Col } from "react-bootstrap";
import { checkIcon, diamondIcon, cancelIcon } from "../../../assets/img";
import { useState } from "react";

const MemberShipPlan = () => {
  const [monthly, setmonthly] = useState(true)
  const [yearly, setYearly] = useState(false)


  const tabHand1 = () => {
    setmonthly(true)
    setYearly(false)
  }
  const tabHand2 = () => {
    setmonthly(false)
    setYearly(true)
  }

  return (
    <div className="w-90 w-lg-95 w-xxl-90 mx-auto">
      <h4 className="sect-title text-center mb-2 mb-lg-3 mt-3 mt-lg-5">
        MerchHQ Membership plans
      </h4>
      <p className="text-black fs-13 f2 fs-md-15 fs-lg-20 fs-xl-27 text-center mb-4">
        Become A Member, Get the Perks!
      </p>
      <div className="d-flex align-items-center justify-content-center justify-content-md-center w-95 mx-auto tab-tl my-3">
        <button className={`${monthly ? 'get-start-btn' : 'get-start-btn bg-transparent primary'} border-0 my-0 mx-2`} onClick={tabHand1}>Monthy</button>
        <button className={`${yearly ? 'get-start-btn' : 'get-start-btn bg-transparent primary'} border-0 my-0 mx-2`} onClick={tabHand2}>
          Yearly
        </button>
      </div>
      <div className="plan-tl-box d-flex flex-wrap ">
        <div className="d-flex p-2 top-plan-box">
          <div className="plan-box text-start bg-white w-100 p-3 p-lg-4">
            <h6 className="f4 fs-md-18 fs-lg-20 fs-xl-22 fs-16 text-dark mb-1">
              Basis
            </h6>
            <p className="f2 fs-16 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-18 primary mb-1">
              I hate Savings
            </p>
            <h3 className="f4 text-black fs-20 fs-sm-22 fs-md-27 fs-lg-29 fs-xxl-31 primary mb-1">
              Free Plan
            </h3>
            <p className="f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 gray mb-1 op-80">
              No Savings
            </p>
            <div className="get-start-btn mt-2 mb-3">Get start</div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={diamondIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={cancelIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex p-2 top-plan-box">
          <div className="plan-box text-start bg-primar w-100 p-3 p-lg-4">
            <h6 className="f4 fs-md-18 fs-lg-20 fs-xl-22 fs-16 text-white mb-1">
              Silver
            </h6>
            <p className="f2 fs-16 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-18 text-white mb-1">
              The merchhq Experience
            </p>
            <h3 className="f4 text-black fs-20 fs-sm-22 fs-md-27 fs-lg-29 fs-xxl-31 text-white mb-1">
              $99 / month
            </h3>
            <p className="f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 text-white mb-1">
              Annual Plan, pain monthly
            </p>
            <div className="get-start-btn mt-2 mb-3 primary bg-white">
              Get start
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-white mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={diamondIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-white mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-white mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-white mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-white mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-white mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={cancelIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-white mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex p-2 top-plan-box">
          <div className="plan-box text-start bg-white w-100 p-3 p-lg-4">
            <h6 className="f4 fs-md-18 fs-lg-20 fs-xl-22 fs-16 text-dark mb-1">
              Gold
            </h6>
            <p className="f2 fs-16 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-18 primary mb-1">
              Hook Me Up!
            </p>
            <h3 className="f4 text-black fs-20 fs-sm-22 fs-md-27 fs-lg-29 fs-xxl-31 primary mb-1">
              $499 / month
            </h3>
            <p className="f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 gray mb-1 op-80">
              Annual plan, paid monthly
            </p>
            <div className="get-start-btn mt-2 mb-3">Get start</div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={diamondIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={cancelIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex p-2 top-plan-box">
          <div className="plan-box text-start bg-white w-100 p-3 p-lg-4">
            <h6 className="f4 fs-md-18 fs-lg-20 fs-xl-22 fs-16 text-dark mb-1">
              Platinum
            </h6>
            <p className="f2 fs-16 fs-sm-17 fs-md-18 fs-lg-19 fs-xl-18 primary mb-1">
              For Power Users
            </p>
            <h3 className="f4 text-black fs-20 fs-sm-22 fs-md-27 fs-lg-29 fs-xxl-31 primary mb-1">
              $999 / month
            </h3>
            <p className="f1 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-17 gray mb-1 op-80">
              Annual Plan, paid monthly
            </p>
            <div className="get-start-btn mt-2 mb-3">Get start</div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={diamondIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={checkIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
            <div className="d-flex list mt-1 w-100">
              <img src={cancelIcon} alt="" />
              <p className="f2 fs-12 fs-md-13 fs-xl-14 text-dark mb-1">
                Self-Service Dashboard AccountSelf-Service Dashboard Account
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberShipPlan;
