import React from "react";
import { dash4, serv1, service_banner } from "../../assets/img";

const ServicesComp4 = () => {
  return (
    <div className="d-flex w-90 flex-column-reverse flex-md-row gap-4 py-3">
     <div className="d-flex align-items-center justify-content-center w-100 w-lg-50">
     
          <img src={dash4} className="w-80 h-100 object-fit-contain" />
       
      </div>
      <div className="d-flex align-items-center justify-content-center w-100">
        <div className="text-custom-align d-flex ac-jc flex-column">
          <h5 class="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 w-100 text-dark">
            Storage & Distribution:
          </h5>
          <p class="mb-3 mb-md-0 primary1 f2 fs-13 fs-md-14 fs-lg-15 fs-xl-16">
            Say goodbye to cluttered spaces! Manage and distribute your merch
            effortlessly from the HQ, sending it out whenever you like, all in
            real-time through your personalized dashboard. It's that simple and
            convenient!
          </p>
        </div>
      </div>
     
    </div>
  );
};

export default ServicesComp4;
