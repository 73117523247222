import React from 'react'
import StartProjectComp from '../Components/StartProjectComp/StartProjectComp'

const StartProjectScreen = () => {
    return (
        <div className="rit-prod mt-0 d-flex ac-jc h-100">
            <div className="w-90">
                <StartProjectComp />
            </div>
        </div>
    )
}

export default StartProjectScreen