import React from "react";
import { bx_filter, searchIcon } from "../../assets/img";
import { useState } from "react";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import FilterAltIcon from "@mui/icons-material/FilterAlt";



const ShipmentHeader = ({ title, btnText, showClickHandler }) => {
  const [check, setCheck] = useState(false)
  const checkBox = () => {
    setCheck(!check)
  }
  return (
    <div className="my-product mx-auto w-100  w-md-95 w-xl-85 w-xxl-80 pb-5 mb-5">
      <div className="filter-product d-flex flex-column flex-md-row ac-jb mt-3 mt-lg-4 px-2 px-lg-3">
        <div className="d-flex ac-js mb-3 mb-md-0 ">
          <div className="position-relative">
            {!check && <CheckBoxIcon onClick={checkBox} className="fs-15 f4 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 primary" />}
            {check && <CheckBoxOutlineBlankIcon onClick={checkBox} className="fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 primary" />}
          </div>
          <div className="position-relative d-flex ac-jc py-2 rounded-3 bg-lt-blue">
            <p
              className={'primary cust-btn f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19  ps-2 pe-1  ps-lg-4 mb-0'}
            >
              Actions
            </p>
            <select className="cust-btn f2 f4 s-xl-16 fs-lg-15 fs-md-14 fs-sm-13 fs-xs-12 fs-xxl-18 primary mb-0">
              <option value="all">All</option>
              <option value="Pack">Pack</option>
              <option value="Products">Products</option>
            </select>
          </div>
          <div className="position-relative search-icon d-flex ac-jc ms-3">
            <img src={searchIcon} alt="" />
            <input
              type="text"
              className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
              placeholder="Search"
              name=""
              id=""
            />
          </div>
        </div>
        <div className="d-flex ac-js mb-0 gap-3 flex-column flex-sm-row">
          <div className="position-relative d-flex ac-jc py-2 rounded-3 bg-lt-blue  mb-2 mb-sm-0">
            <p
              className={'primary1 cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0'}
            >
              Sort :
            </p>
            <select className="cust-btn f4 primary f4 s-xl-17 fs-lg-16 fs-md-15 fs-sm-14 fs-xs-13 fs-xxl-18  mb-0">
              <option value="Newest" className="">Newest First</option>
              <option value="Oldest">Oldest</option>
              <option value=" A-Z"> A-Z</option>
              <option value=" Z-A"> Z-A</option>
            </select>
          </div>
          <div className="position-relative d-flex ac-jc flex-column">
            <button
              // onClick={() => navigate("/lets-start")}
              className="get-start-btn border-0 mt-0 f4 s-xl-16 fs-lg-15 fs-md-14 fs-sm-13 fs-xs-12">
              + Add Contact
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShipmentHeader;
