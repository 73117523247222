// @ts-nocheck
import React from "react";
import {
  abt1,
  abt2,
  abt3,
  abt4,
  abt5,
  abt6,
  abt7,
  circle,
  circle1,
  circle2,
  circle3,
  circle4,
  circle5,
  meetmhq,
  teamIcon,
} from "../../../assets/img";

const MeetCircle = () => {
  return (
    <div className="meet-circle mt-4 w-90 w-md-70 mx-auto mb-2 mb-md-3 ac-jb d-flex flex-column">
      <img src={meetmhq} alt className="rounded w-100 cover" />
      <h4 className="sect-title dark-blue mt-5">Meet the circle</h4>
      <p className="parag text-center">
        Meet the masters behind the merchandise
      </p>
      <div className="team-circle w-100 w-md-90 w-lg-85">
        <div className="d-flex ac-jc flex-column text-center">
          <div className="d-flex ac-jc flex-row py-1 p-md-3">
            <div className="img-box-met">
              <img src={abt1} alt="" />
              <div className="over-lay">
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f4 text-center mb-0">
                  Aahika Harlalka
                </p>
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f2 text-center mb-0">
                  Co-founder
                </p>
              </div>
            </div>
            <div className="img-box-met">
              <img src={abt2} alt="" />
              <div className="over-lay">
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f4 text-center mb-0">
                  Shivani Thapliyal
                </p>
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f2 text-center mb-0">
                  Co-founder
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex ac-jc flex-row p-3">
            <div className="img-box-met">
              <img src={abt3} alt="" />
              <div className="over-lay">
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f4 text-center mb-0">
                  Roger Daniel
                </p>
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f2 text-center mb-0">
                  Co-founder
                </p>
              </div>
            </div>
            <div className=" img-box-met1">
              {/* <h5 className="primary fs-13 fs-md-20 fs-xl-20 fs-xxl-24 f5 text-center mb-0">
                merchHQ
              </h5> */}
              <img src={circle} className="w-70" alt="" />
            </div>
            <div className="img-box-met">
              <img src={abt4} alt="" />
              <div className="over-lay">
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f4 text-center mb-0">
                  Aryaman Harlalka
                </p>
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f2 text-center mb-0">
                  Co-founder
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex ac-jc flex-row py-1 p-md-3">
            <div className="img-box-met">
              <img src={abt5} alt="" />
              <div className="over-lay">
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f4 text-center mb-0">
                  Linston D’souza
                </p>
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f2 text-center mb-0">
                  Principal Merchandise Consultant
                </p>
              </div>
            </div>
            <div className="img-box-met">
              <img src={abt6} alt="" />
              <div className="over-lay">
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f4 text-center mb-0">
                  Nikitha Ananth
                </p>
                <p className="text-white fs-13 fs-xl-17 fs-xxl-17 f2 text-center mb-0">
                  Brand Experience Architect
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetCircle;
