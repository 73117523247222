import React from "react";
import { templateColor, tempMenu } from "../../redux/api/DummyJson";

const Headercomp = () => {
  return (
    <div className={`${"d-flex flex-row ac jsb w-100 py-2 px-3"}`}>
      <h3 className="fs-15 f4 mb-0">NINOS</h3>
      <div className="d-flex ac jc">
        {tempMenu?.map((it, ind) => {
          return (
            <button
              className="fs-15 f2 mb-0 cust-btn px-3"
              style={{ color: templateColor.primary }}
            >
              {it?.menu}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Headercomp;
