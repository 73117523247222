// @ts-nocheck
import React from "react";
import { visitImg } from "../../../assets/img";

const VisitHelp = () => {
  return (
    <div className="w-80 my-0 mt-4 my-lg-5 p-4 mx-auto bg-lt-blue rounded rounded-2 rounded-4 position-relative">
    
      <p className="parag mb-0 wi-100 w-md-60 w-lg-55 w-xl-60 w-xxl-65 text-justify">
        MerchHq builds on redeem page release by adding unique URLs to the list
        of capabilities on their swag platform.
      </p>
      <div className="visit-help d-flex mt-3 mt-md-3 align-items-center justify-content-lg-between">
        <img src={visitImg} alt="" />
        <button className="get-start-btn border-0 ms-3">Visit Help Desk</button>
      </div>
    </div>
  );
};

export default VisitHelp;
