// @ts-nocheck
import React, { useRef, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import DoneIcon from "@mui/icons-material/Done";
import { closeIcon, cloudUpload, noLogo, product, product2, uploadIcon } from "../../assets/img";
import { productDetailImg } from "../../redux/api/constants";
import { useViewProjectProductStatusMutation } from "../../redux/api/api";
import { ErrorIcon } from "react-hot-toast";
import { image_slider } from "../../redux/api/DummyJson";
import ImageGallery from "react-image-gallery";

// import CloseIcon from "@mui/icons-material/Close";

const ProductPopupComponent = ({
  showPopupHander,
  type,
  pendingitem,
  getProduct,
}) => {
  const [uploadImage, setUploadImage] = useState();
  const [location, setLocation] = useState("");
  const [dimension, setDimension] = useState("");
  const [notes, setNotes] = useState("");
  const [locationErr, setLocationErr] = useState(false);
  const [dimensionErr, setDimensionErr] = useState(false);
  const [notesErr, setNotesErr] = useState(false);
  const [uploadErr, setUploadImageErr] = useState(false);
  const [bigImage, setBigImage] = useState({
    image: "",
    ind: "",
    is_default: "",
    id: "",
  });
  const [fistShow, setFirstShow] = useState(false);
  const [fistShows, setFirstShows] = useState(false);
  const proImgRef = useRef();
  const morkupImgRef = useRef();
  const colorImgRef = useRef();
  const [proIndex, setProIndex] = useState(0);
  const [morkupIndex, setMorkupIndex] = useState(0);
  const [colorIndex, setColorIndex] = useState(0);
  const [textShow, setTextShow] = useState(true);
  const [textShows, setTextShows] = useState(true);
  const [indexs, setIndexs] = useState(0);



  //rtk
  const [statusChangeApi] = useViewProjectProductStatusMutation();

  const fileChange = (e) => {
    setUploadImage(e.target.files[0]);
    console.log(e.target.files);
  };
  const handelContinue = () => {
    if (
      uploadImage == undefined ||
      location?.length == 0 ||
      dimension?.length == 0 ||
      notes?.length == 0
    ) {
      setUploadImageErr(true);
      setLocationErr(true);
      setDimensionErr(true);
      setNotesErr(true);
    } else {
      const formdata = new FormData();
      formdata.append("project_detail_id", pendingitem?.id);
      formdata.append("artwork_status", "4");
      formdata.append("user_logo", uploadImage);
      formdata.append("location", location);
      formdata.append("dimension", dimension);
      formdata.append("user_comment", notes);

      statusChangeApi(formdata)
        .unwrap()
        .then((res) => {
          console.log(res, "res");
          getProduct();
          showPopupHander();
        })
        .catch((err) => {
          console.log(err, "err");
        });
    }
  };

  const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/"
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/"
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/"
    }
  ];


  return (
    <div className="modal-popup">
      <div onClick={showPopupHander} className="back-close" />
      <div
        style={{ width: "60%" }}
        className="center-content d-flex flex-column flex-lg-row p-0 m-0"
      >
        <button
          onClick={showPopupHander}
          className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
        >
          <img src={closeIcon} alt="" />
        </button>
        <div className="d-flex flex-column as-jc px-3 mx-auto px-3 px-lg-5 w-100 h-100 mt-4">
          <h6 className="mb-2  f4 fs-xs-20 fs-sm-24 fs-lg-26 fs-xl-28">
            New Decoration1
          </h6>
          <div className="w-100 d-flex  flex-column flex-md-row">
            <div className="w-xs-100 w-md-50 px-2">
              <div className="d-flex flex-wrap tl-log-upload-box ac-jb mt-2 mt-lg-2">
                <p className="parag mb-2">
                  Artwork Allmade unisex Organic cotton Tee
                </p>
                {/* <label className="w-100 position-relative">   
                  <input
                    onChange={fileChange}
                    type="file"
                    id="images"
                    required
                    className="d-none"
                  />
                  {uploadImage ? (
                    <div className="prev-img">
                      <img src={URL.createObjectURL(uploadImage)} alt="" />
                    </div>
                  ) : (
                    <div className="log-upload-box position-absolute">
                      <p className="primary1 fs-14 fs-sm-15 fs-md-17 fs-xl-18">
                        Primary Logo
                      </p>
                      <img src={cloudUpload} className="" alt="" />
                      <p className="text-dark text-center fs-13 fs-md-14">
                        Drag and drop to upload or Click <br />
                        here to upload
                      </p>
                    </div>
                  )}
                </label> */}
                <div className="">
                  <div
                    className="position-relative d-flex ac-jc"
                    onClick={() => {
                      if (!fistShow) {
                        setProIndex(0);
                        proImgRef?.current?.click();
                      }
                    }}
                  >
                    <img src={ product } className="slide_image" />
                  </div>
                  <div className="d-flex ac-jc gap-3 w-100">
                      <div
                        class=" mt-2 gap-3 d-flex ac-jc pb-2 ps-5"
                        style={{ width: "300px", overflow: "scroll" }}
                      >
                        {[0,1,2,3]?.map((item, ind) => {
                          return (
                            <div className="gap-3 d-flex ac-j w-100">
                              <div
                                class="add_img d-flex ac-jc"
                                // onClick={() =>
                                //   imageSelector(
                                //     item?.image,
                                //     ind,
                                //     "product",
                                //     item?.id,
                                //     item?.is_default
                                //   )
                                // }
                              >
                                <img src={ product2} class="cp add_img object-fit-fill"/>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                  </div>
                </div>
              </div>
              {uploadImage == undefined && uploadErr && (
                <div className="d-flex gap-1 mt-2">
                  {" "}
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Upload image</p>
                </div>
              )}
            </div>
            <div className="w-xs-100 w-md-50 px-2">
              <div className="ac-jb mt-3 mt-lg-4 pop-input">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                  Location
                </p>
                <input
                  type="text"
                  value={location}
                  onChange={(e) => setLocation(e.target.value)}
                  placeholder="Specify the placement of your artwork"
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                // required
                />
              </div>
              {location?.length == 0 && locationErr && (
                <div className="d-flex gap-1 mt-2">
                  {" "}
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Location</p>
                </div>
              )}
              <div className="ac-jb mt-2 mt-lg-2 pop-input">
                <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
                  Dimensions
                </p>
                <input
                  type="text"
                  placeholder="W 2.3 X H 4.5"
                  value={dimension}
                  onChange={(e) => setDimension(e.target.value)}
                  className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19"
                // required
                />
              </div>
              {dimension?.length == 0 && dimensionErr && (
                <div className="d-flex gap-1 mt-2">
                  {" "}
                  <ErrorIcon className="svg_log" />
                  <p className="err-input_log mt-1">Enter Dimension</p>
                </div>
              )}
            </div>
          </div>
          <div className="ac-jb mt-2  pop-input w-100">
            <p className="f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 primary1 mb-0">
              Additional Notes
            </p>
            <input
              type="text"
              placeholder="Notes"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              className="w-100 bg-transparent f2 fs-xs-16 fs-sm-17 fs-lg-18 fs-xl-19 mt-1"
            // required
            />
          </div>
          {notes?.length == 0 && notesErr && (
            <div className="d-flex gap-1 mt-2">
              {" "}
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Notes</p>
            </div>
          )}
          <button
            className="get-start-btn border-0 mt-4 mx-auto d-flex py-2"
            onClick={() => handelContinue()}
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductPopupComponent;
