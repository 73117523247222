// @ts-nocheck
import React, { useState } from "react";
import {
  commentIcon,
  closeIcon,
  happyEmoj,
  discountIcon,
  searchIcon,
} from "../../assets/img";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useNavigate } from "react-router-dom";
import DoneIcon from "@mui/icons-material/Done";

const NewContact = ({ showClickHandler, showUpdate, setShow }) => {
  const [selectRec, setSelectRec] = useState(false);
  const [selectSingle, setSelectSingle] = useState(false);

  const navi = useNavigate();
  return (
    <div className="tl-update-qty">
      <div
        className="back-update-qty"
        style={{ display: showUpdate ? "block" : "none" }}
      ></div>
      <div
        className="update-qty"
        style={{ right: showUpdate ? "0%" : "-100%" }}
      >
        <div className="d-flex ac-jb mt-2 mt-lg-4">
          <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
            Add New Contact
          </h6>
          <button
            onClick={showClickHandler}
            className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
          >
            <img src={closeIcon} alt="" />
          </button>
        </div>
        <div className="btm-scroll pt-3 d-flex flex-column flex-md-row justify-content-between">
          <div className="position-relative d-flex ac-jc search-icon mb-2">
            <img src={searchIcon} alt="" className="searchImgIcon" />
            <input
              type="text"
              className="bg-transparent border-0 ps-1 f2 fs-6 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
              placeholder="Search"
              name=""
              id=""
            />
          </div>
          <div className="filterView">
            <p className="fs-xs-11 filterDesign">Filters</p>
            <select className="cust-btn seletDesign"></select>
          </div>
          <div className="filterView">
            <p className="fs-xs-11 filterDesign">Sort by</p>
            <select className="cust-btn seletDesign"></select>
          </div>
          <button
            className="cust-btn filterView1"
            onClick={() => setShow(true)}
          >
            <h4 className="f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-0">
              +Add Recipients
            </h4>
          </button>
        </div>
        <div
          style={{ width: "100%", overflow: "auto" }}
          className="w-100 pt-3 w-100 mt-5"
        >
          <table className="table-design-new">
            <thead>
              <tr className="text-nowrap text-center">
                <th>S.No</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email ID</th>
                <th>Address Line 1</th>
                <th>Address Line 2</th>
                <th>Land Mark</th>
                <th>State</th>
                <th>City</th>
                <th>Pincode</th>
                <th>Phone Number</th>
                <th>Alternate Phone Number</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td>1</td>
                <td>Roger</td>
                <td>Daniel</td>
                <td>roger@companystore.io</td>
                <td>#39 Krishna Reddy Colony</td>
                <td>Domlur Layout</td>
                <td>Accord Road</td>
                <td>Karnataka</td>
                <td>Bangalore</td>
                <td>560071</td>
                <td>9632950798</td>
                <td>9632208028</td>
              </tr>
              <tr className="text-center">
                <td>2</td>
                <td>Sakthi</td>
                <td>Vel</td>
                <td>vel@companystore.io</td>
                <td>#49 Krishna Reddy Colony</td>
                <td>Domlur Layout</td>
                <td>Accord Road</td>
                <td>Chennai</td>
                <td>Tamil Nadu</td>
                <td>600071</td>
                <td>9632950799</td>
                <td>9632208029</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default NewContact;
