// @ts-nocheck
import React from "react";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { searchIcon } from "../../assets/img";

const InventoryFilter = () => {
  return (
    <div className="filter-product d-flex flex-column flex-md-row ac-jb mt-3 mt-lg-4 px-2 px-lg-3">
      <div className="d-flex ac-js mb-3 mb-md-0 me-3">
        <div className="position-relative d-flex ac-jc">
          <select className="cust-btn f3 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
            <option value="all">Actions</option>
            <option value="Pack">Pack</option>
            <option value="Products">Products</option>
          </select>
        </div>

        <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
          <img src={searchIcon} alt="" />
          <input
            type="text"
            className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
            placeholder="Search"
            name=""
            id=""
          />
        </div>
      </div>
      <div className="d-flex ac-js mb-0  flex-column flex-sm-row">
        <div className="position-relative d-flex ac-jc search-icon mb-2 mb-sm-0">
          <img src={searchIcon} alt="" />
          <input
            type="text"
            className="bg-transparent border-0 ps-1 f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
            placeholder="Search Products"
            name=""
            id=""
          />
        </div>

        <div className="position-relative d-flex ac-jc">
          <p
            className={`text-dark cust-btn f3 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 primary1 ps-2 pe-1  ps-lg-4 mb-0`}
          >
            Sort By
          </p>
          <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary mb-0">
            <option value="Newest">Newest</option>
            <option value="Oldest">Oldest</option>
            <option value=" A-Z"> A-Z</option>
            <option value=" Z-A"> Z-A</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default InventoryFilter;
