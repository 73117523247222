// @ts-nocheck
import React, { useState } from "react";
import TellProject from "../component/Product/mock/TellProject";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ContactDetails from "../component/Product/mock/ContactDetails";
import PresetMockReqCart from "../component/Product/PresetMockReqCart";

const PresetMockupRequest = () => {
  const [conatctDetail, setConatctDetail] = useState(false);
  const showContactHandler = () => {
    setConatctDetail(!conatctDetail);
  };
  return (
    <div className="d-flex mock-left-req">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-70 px-2 px-lg-2 left-mock-req">
        <div className="bread-crumb d-flex ac-jc bg-lt-blue">
          <Link to="" className="primary1">
            Build Custom
          </Link>
          <ArrowForwardIosIcon className="primary" />
          <Link to="" className="primary">
            Complete Mockups Request
          </Link>
        </div>
        {conatctDetail ? (
          <ContactDetails showContactHandler={showContactHandler} />
        ) : (
          <TellProject showContactHandler={showContactHandler} />
        )}
      </div>
      <PresetMockReqCart pack="bulk" />
    </div>
  );
};

export default PresetMockupRequest;
