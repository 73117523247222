// @ts-nocheck
import React, { useEffect, useState } from "react";
import { bg1, logo } from "../assets/img";
import ChangePassword from "../component/Auth/ChangePassword";
import ForgotPassword from "../component/Auth/ForgotPassword";
import { useNavigate, useSearchParams } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import {
  useResetPasswordMutation,
  useVerifyUserMutation,
} from "../redux/api/api";
import { useDispatch } from "react-redux";
import { saveLoader } from "../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState(false);
  const [conPassword, setConPassword] = useState("");
  const [conPasswordErr, setConPasswordErr] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [resetPasswordApi] = useResetPasswordMutation();
  // const [verifyUserApi] = useVerifyUserMutation();

  // useEffect(() => {
  //   if (searchParams.get("token")) {
  //     let formdata = new FormData();
  //     formdata.append("token", searchParams.get("token"));
  //     verifyUserApi(formdata)
  //       .then((res) => {})
  //       .catch((err) => {
  //         console.log("err", err);
  //       });
  //   }
  // }, []);

  const handlePassword = (event) => {
    let new_pass = event.target.value;
    if (new_pass?.length !== 0) {
      setPassword(new_pass);

      // regular expressions to validate password
      var lowerCase = /[a-z]/g;
      var upperCase = /[A-Z]/g;
      var numbers = /[0-9]/g;
      if (!new_pass.match(lowerCase)) {
        setErrorMessage("Password should contains lowercase letters!");
      } else if (!new_pass.match(upperCase)) {
        setErrorMessage("Password should contain uppercase letters!");
      } else if (!new_pass.match(numbers)) {
        setErrorMessage("Password should contains numbers also!");
      } else if (new_pass.length < 8) {
        setErrorMessage("Password length should be more than 8.");
      } else {
        // setErrorMessage("Password is strong!");
        setErrorMessage("");
      }
    } else {
      setErrorMessage("");
      setPassword(new_pass);
    }
  };

  const submitHandler = () => {
    if (password?.length < 8 || conPassword?.length < 8) {
      setPasswordErr(true);
      setConPasswordErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("token", searchParams.get("token"));
      formdata.append("password", password);
      formdata.append("password_confirmation", conPassword);
      setBtn(true);
      dispatch(saveLoader(true));
      resetPasswordApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          toast.success(res?.message);
          navigate("/login");
        })
        .catch((err) => {
          setBtn(false);
          dispatch(saveLoader(false));
          console.log("error", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="d-flex flex-column flex-md-row sign-up position-relative align-items-center justify-content-center">
      <h5
        onClick={() => navigate("/")}
        className="sect-title mb-0 primary log-logo"
      >
        {/* Merchhq */}

        <img src={logo} className="logo" alt="" style={{ width: "210px" }} />
      </h5>
      <img src={bg1} alt="" className="bg1" />
      <div className="rit-auth forgot-auth-rt">
        <div className="form-box">
          <h5>Reset Password</h5>
          <div className="box">
            <p>Password</p>
            <input
              className=""
              onChange={(e) => handlePassword(e)}
              value={password}
            />
            {password?.length == 0 && passwordErr && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Password</p>
              </div>
            )}
            {errorMessage && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">{errorMessage}</p>
              </div>
            )}
          </div>
          <div className="box">
            <p>Confirm Password</p>
            <input
              className=""
              onChange={(e) => setConPassword(e.target.value)}
              value={conPassword}
              onKeyDown={keydownHandler}
            />
            {conPassword?.length == 0 && conPasswordErr && (
              <div className="d-flex gap-1 mt-2">
                {" "}
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">Enter Confirm Password</p>
              </div>
            )}

            {conPassword?.length > 1 && conPassword !== password && (
              <div className="d-flex gap-1 mt-2">
                <ErrorIcon className="svg_log" />
                <p className="err-input_log mt-1">
                  {" "}
                  Password And Confirm Password are Not Same
                </p>
              </div>
            )}
          </div>
          <button
            onClick={submitHandler}
            className={`text-white get-start-btn w-100 d-flex mb-2  mt-2 border-0 ${
              btn && "opacity-50"
            }`}
            disabled={btn}
          >
            Submit
          </button>

          <p className="parag f2 text-center d-flex flex-wrap ac-jc">
            Don’t you have an account?
            <button
              onClick={() => navigate("/signup")}
              className={`f3 primary ms-1 cust-btn`}
            >
              Signup
            </button>
          </p>
        </div>
      </div>
      <img src={bg1} alt="" className="bg2" />
    </div>
  );
};

export default ResetPassword;
