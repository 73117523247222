import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RedeemHeader = ({ title, btnText, showClickHandler }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  useEffect(() => {
    console.log(splitLocation[1]);
  }, []);

  return (
    <div className="top-product d-flex ac-jb mt-3">
      <div className="">
        <h6 className="f4 fs-29 fs-lg-30 fs-xl-31 fs-xxl-32 px-2 px-lg-3">
          {title}
        </h6>

        <div className="d-flex ac-js mt-4">
          <div className="position-relative">
            <button
              onClick={() => {
                if (title === "Redeem") {
                  navigate("/redeem");
                }
              }}
              className={`cust-btn f3 fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 px-2 px-lg-3 w-100 ${
                splitLocation[1] === "redeem" &&
                title === "Redeem" &&
                splitLocation[2] === undefined
                  ? "text-dark"
                  : "primary1"
              } `}>
              List
            </button>
            <div
              className={`ms-3 ${
                splitLocation[1] === "redeem" && splitLocation[2] === undefined
                  ? "active"
                  : null
              }`}></div>
          </div>
          <div className="position-relative">
            <button
              onClick={() => {
                if (title === "Redeem") {
                  navigate("/redeem/dashboard");
                }
              }}
              className={`cust-btn f3 fs-15 fs-md-16 fs-lg-18 fs-xl-19 fs-xxl-20 px-2 px-lg-3 w-100 ${
                splitLocation[2] === "dashboard" ? "text-dark" : "primary1"
              }`}>
              Dashboard
            </button>
            <div
              className={`ms-4 ${
                splitLocation[2] === "dashboard" ? "active" : null
              }`}></div>
          </div>
        </div>
      </div>
      {splitLocation[2] === "dashboard" ? null : (
        <button
          onClick={showClickHandler}
          className={`cust-btn bg-primar text-white f3 fs-14 fs-lg-15 fs-xl-16 fs-xxl-17 primary1 py-2 py-md-2 px-3 rounded px-lg-3`}>
          {btnText}
        </button>
      )}
    </div>
  );
};

export default RedeemHeader;
