// @ts-nocheck
import { useState, useEffect } from "react";
import { banner, editIcon, searchIcon, select } from "../../assets/img";
// import { useAddressViewOrderQuery } from "../../redux/api/api";
// import {useLazyAddressViewOrderQuery } from "../../redux/api/api";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const SelectRecipients = ({
  addReciPopup,
  setAddReciPopup,
  poppupAddressEditToggle,
  setShow,
  getAddresslist,
  order,
  type,
  search,
  firstName,
  setfirstName,
  lastName,
  setLastname,
  email,
  setEmail,
  address1,
  setAddress1,
  address2,
  setAddress2,
  LandMark,
  setLandMark,
  state,
  setState,
  city,
  setCity,
  pincode,
  setPincode,
  num,
  setNum,
  alternum,
  setAlterNum,
  gst,
  setGst,
  setCountry,
  country,
  list,
  countryList,
  setListState,
  listState,
  getState,
  getData,
  update,
  setUpdate,
  selected,
  setSelected,

  billingArr,
  setBillingArr,
  shippingArr,
  setShippingArr,
  setPage,
  page,
  pagenation,
  showDeletePopUp,
}) => {
  const [checkbox, setCheckbox] = useState(null);
  // const [selected,setSelected]=useState();
  // const [addressView]=useLazyAddressViewOrderQuery();
  const data = Array.isArray(list?.data) ? list.data : [];
  // const [update, setUpdate] = useState({});

  const checkboxHandler = (event, index) => {
    if (type == 2) {
      let temp = [...billingArr];
      let inx = temp.indexOf(event);
      if (temp.includes(event)) {
        temp.splice(inx, 1);
      } else {
        temp.push(event);
      }

      setBillingArr(temp);
    } else if (type == 1) {
      let temp = [...shippingArr];
      let inx = temp.indexOf(event);

      if (temp.includes(event)) {
        temp.splice(inx, 1);
      } else {
        temp.push(event);
      }

      setShippingArr(temp);
    }
  };

  return (
    <div className="addReciView">
      {/* <div className=" addrecipient">
        <img src={select} className="bannerImge mt-3" />
      </div>
      <h4 class=" f4 mt-3 addrecipient">You don't have any saved recipients</h4>
      <p className=" fs-xs-11 addrecipient1">
        Add recipients to start creating shipments
      </p>
      <div className=" addrecipient">
        <button
          onClick={() => setAddReciPopup(!addReciPopup)}
          className=" cust-btn w-100 p-3 bg-lt-blue rounded rounded-4"
        >
          <h4 className="f4 primary fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-0">
            Add Recipients
          </h4>
        </button>
      </div> */}
      <div className="btm-scroll pt-3 d-flex flex-column flex-md-row justify-content-between">
        <div className="position-relative d-flex ac-jc search-icon mb-2">
          <img src={searchIcon} alt="" className="searchImgIcon" />
          <input
            type="text"
            onChange={(e) => getAddresslist(e.target.value, type, order)}
            className="bg-transparent border-0 ps-1 f2 fs-6 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-19 "
            placeholder="Search"
            value={search}
            name=""
            id=""
          />
        </div>
        {/* <div className="filterView">
          <p className="fs-xs-11 filterDesign">Filters</p>
          <select className="cust-btn seletDesign"></select>
        </div> */}
        <div className="filterView">
          <p className="fs-xs-11 filterDesign">Sort by</p>
          <select
            className="cust-btn seletDesign"
            onChange={(e) => getAddresslist(search, type, e.target.value)}
          >
            <option value={"asc"}>Asc</option>
            <option value={"desc"}>Dec</option>
          </select>
        </div>
        <button
          className="cust-btn filterView1 bg-primar get-start-btn"
          style={{ marginTop: "0px" }}
          onClick={() => setShow(true)}
        >
          <h4 className="f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 mb-0">
            +Add Recipients
          </h4>
        </button>
      </div>
      <div
        style={{ width: "100%", overflow: "auto" }}
        className="w-100 pt-3 w-100 mt-5"
      >
        <table className="table-design-new">
          <thead>
            <tr className="text-nowrap text-center">
              <th>S.No</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email ID</th>
              <th>Address Line 1</th>
              <th>Address Line 2</th>
              <th>Land Mark</th>
              <th>State</th>
              <th>City</th>
              <th>Pincode</th>
              <th>Phone Number</th>
              <th>Alternate Phone Number</th>
              <th>Options</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item, index) => {
              return (
                <tr className="text-center">
                  <td>
                    <div className="d-flex align-items-center gap-2">
                      <div>{index + 1}</div>
                      <input
                        type="checkbox"
                        onClick={() => checkboxHandler(item?.id, index)}
                        checked={
                          type == 2
                            ? billingArr?.includes(item?.id)
                            : shippingArr?.includes(item?.id)
                        }
                      />
                    </div>
                  </td>
                  <td>{item?.first_name}</td>
                  <td>{item?.last_name}</td>
                  <td>{item?.email_id}</td>
                  <td>{item?.address1}</td>
                  <td>{item?.address2}</td>
                  <td>{item?.landmark}</td>
                  <td>{item?.state}</td>
                  <td>{item?.city}</td>
                  <td>{item?.pincode}</td>
                  <td>{item?.phone_no}</td>
                  <td>{item?.alter_mobile}</td>
                  <td>
                    <div className="d-flex ac-jb">
                      <div
                        className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                        onClick={() => {
                          poppupAddressEditToggle();
                          getData(item);
                        }}
                      >
                        <img src={editIcon} alt="Edit" className="viewDes" />
                      </div>
                      <div
                        className="viewBoxDes pointerView ms-2 p-2 bg-white rounded-2"
                        onClick={() => {
                          showDeletePopUp(item?.id);
                        }}
                      >
                        <DeleteForeverIcon className="viewDes text-danger" />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="d-flex justify-content-between mt-3">
        <button
          className={`bg-primar border-0 text-white py-2 rounded f3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-2 ${
            page == 1 && "opacity-50"
          }`}
          onClick={() => setPage(page - 1)}
          disabled={page == 1 ? true : false}
        >
          Previous
        </button>
        <button
          className={`bg-primar border-0 text-white py-2 rounded f3 fs-12 fs-sm-13 fs-md-14 fs-lg-15 fs-xl-16 fs-xxl-18 ms-2 text-dark mt-2 ${
            page == pagenation?.last_page && "opacity-50"
          }`}
          onClick={() => setPage(page + 1)}
          disabled={page == pagenation?.last_page ? true : false}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default SelectRecipients;
