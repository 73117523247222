import React from "react";
import ProfileLeftTab from "../component/Profile/ProfileLeftTab";
import { useState } from "react";
import { Searcher, searchIcon, uploadIcon } from "../assets/img";
import CloseIcon from "@mui/icons-material/Close";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { branch_list } from "../redux/api/DummyJson";
const BranchScreen = () => {
  const [logo, setLogo] = useState({});
  const [select, setSelect] = useState([]);
  const [showlist, setShowList] = useState(false);

  // Search
  const [search, setSearch] = useState("");
  console.log(search);

  const selectFun = (ind) => {
    if (select.includes(ind)) {
      const arrList = select.indexOf(ind);
      const temp = [...select];
      temp.splice(arrList, 1);
      setSelect(temp);
    } else {
      setSelect([...select, ind]);
    }
  };
  const setaddFun = () => {
    if (search?.length > 0) {
      setShowList(true);
    } else {
      setShowList(false);
    }
  };

  return (
    <div className="product-screen d-flex flex-column flex-md-row">
      <ProfileLeftTab />
      <div className="profile-screen h-60  position-relativ">
        <h4 className="sect-title mb-2 mb-lg-1">Search</h4>
        <div className="d-flex w-100 mt-3 flex-column flex-md-row gap-3 flex-wrap">
          <div className="border-5 position-relative ac-js inputcontainer2 py-1 px-3 d-flex">
            <img src={Searcher} alt="" className="imgs2 left-3" />
            <input
              type="text"
              className="border-0 pe-3 ps-2 bg-transparent w-100"
              placeholder="Search Branches"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <button
            onClick={() => setaddFun()}
            className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 text-white border-0 btncontadd bg-primar rounded rounded-2 ">
            Add
          </button>
        </div>

        {showlist && (
          <div className=" listbtn mt-4 gap-3 d-flex flex-wrap ac-js">
            {branch_list?.map((item, ind) => {
              return (
                <button
                  onClick={() => selectFun(ind)}
                  className="listcont bg-transparent border-0 py-md-2 py-1 px-md-3 px-2 d-flex ac-jc gap-2">
                  <p className=" fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 m-0">
                    {item?.list}
                  </p>
                  <button className="closebtns bg-transparent border-0 p-0 d-flex ac-jc">
                    {select.includes(ind) ? (
                      <CheckBoxIcon className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 primary" />
                    ) : (
                      <CheckBoxOutlineBlankIcon className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 primary" />
                    )}
                  </button>
                </button>
              );
            })}
          </div>
        )}
        <div className="d-flex gap-3 botton_btn mt-5 ms-4">
          <button
            onClick={() => {
              setShowList(false);
              setSearch("");
            }}
            style={{ backgroundColor: "#E2ECFF", color: "#839DD1" }}
            className=" fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 border-0 btncontadd rounded rounded-2 ">
            Cancel
          </button>
          <button
            onClick={() => {
              setShowList(false);
              setSearch("");
            }}
            className="fs-12 fs-md-13 fs-lg-14 fs-xl-16 fs-xxl-17 f2 text-white border-0 btncontadd bg-primar rounded rounded-2">
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default BranchScreen;
