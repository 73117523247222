import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import { useDispatch } from "react-redux";
import { useForgotPasswordMutation } from "../../redux/api/api";
import { saveLoader } from "../../redux/slice/loaderNoti";
import { toast } from "react-hot-toast";

const ForgotPassword = ({ onCLickSuccessHandler, setEmailID }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState(false);
  const [btn, setBtn] = useState(false);

  const validEmail = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g);

  // RTK QUERY
  const [forgotPasswordApi] = useForgotPasswordMutation();

  const submitHandler = () => {
    if (email?.length == 0) {
      setEmailErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("type", 2);
      formdata.append("email", email);

      dispatch(saveLoader(true));
      setBtn(true);
      forgotPasswordApi(formdata)
        .unwrap()
        .then((res) => {
          setBtn(false);
          dispatch(saveLoader(false));
          toast.success(
            "We Have Sent a Link To Your Account! Please Check Your Email"
          );
          onCLickSuccessHandler();
        })
        .catch((err) => {
          dispatch(saveLoader(false));
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const handleChange = (event) => {
    if (event?.length > 0) {
      if (/^[6-9][0-9]{0,9}$/.test(event)) {
        setEmail(event);
      } else if (validEmail.test(event)) {
        setEmail(event);
      }
    } else {
      setEmail("");
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event);
    setEmailID(event);
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandler();
    }
  };

  return (
    <div className="rit-auth forgot-auth-rt">
      <div className="form-box">
        <h5>Forgot Password</h5>
        <div className="box">
          <p>Email</p>
          <input
            className=""
            onChange={(e) => handleEmailChange(e.target.value)}
            value={email}
            onKeyDown={keydownHandler}
          />
          {validEmail.test(email) == false && emailErr && (
            <div className="d-flex gap-1 mt-2">
              {" "}
              <ErrorIcon className="svg_log" />
              <p className="err-input_log mt-1">Enter Valid Email</p>
            </div>
          )}
        </div>
        {/* <p className="f2 w-95 primary1 fs-14 fs-lg-15 fs-xl-17">
          SMS can only be used if a mobile phone number has been configured.
        </p> */}
        {/* <button
          onClick={onCLickSuccessHandler}
          className="text-white get-start-btn w-100 d-flex mb-3 border-0 mb-0 opacity-50"
          disabled
        >
          Reset Via SMS
        </button> */}
        <button
          onClick={submitHandler}
          disabled={btn}
          className={`text-white get-start-btn w-100 d-flex mb-2  mt-2 border-0 ${
            btn && "opacity-50"
          }`}
        >
          Reset Via Email
        </button>

        <p className="parag f2 text-center d-flex flex-wrap ac-jc">
          Already have an account?{"  "}
          <button
            onClick={() => navigate("/login")}
            className="f3 primary ms-1 cust-btn"
          >
            Sign in
          </button>
        </p>
      </div>
    </div>
  );
};

export default ForgotPassword;
