import React from "react";
import ServicesHeadComp from "../component/ServicesComp/ServicesHeadComp";
import ServicesComp from "../component/ServicesComp/ServicesComp";
import ServicesComp2 from "../component/ServicesComp/ServicesComp2";
import ServicesComp3 from "../component/ServicesComp/ServicesComp3";
import ServicesComp4 from "../component/ServicesComp/ServicesComp4";
import ServicesComp5 from "../component/ServicesComp/ServicesComp5";
import ServicesComp6 from "../component/ServicesComp/ServicesComp6";
import LetStartComp from "../component/ServicesComp/LetStartComp";
import ServingFor from "../component/Home/ServingFor";
import ContactUs from "../component/ServicesComp/ContactUs";
import Footer from "../navbar/Footer";

const ServicesScreen = () => {
  return (
    <div className="mb-3 w-100">
      <div
        className="w-100 h-100 d-flex ac-jc py-4"
        // style={{ background: "#dde5fc" }}
      >
        <ServicesHeadComp />
      </div>
      <div className="w-100 mt-5">
        <ServingFor />
      </div>
      <div className="w-100 h-100 d-flex ac-jc">
        <ServicesComp />
      </div>
      <div className="w-100 h-100 d-flex ac-jc">
        <ServicesComp2 />
      </div>
      {/* <div className='w-100 h-100 d-flex ac-jc'>
                <ServicesComp3 />
            </div> */}
      <div className="w-100 h-100 d-flex ac-jc">
        <ServicesComp5 />
      </div>{" "}
      <div className="w-100 h-100 d-flex ac-jc">
        <ServicesComp4 />
      </div>
      <div className="w-100 h-100 d-flex ac-jc">
        <ServicesComp6 />
      </div>
      <div className="w-100 h-100 d-flex ac-jc py-5 ">
        <LetStartComp />
      </div>
      {/* <div className="mt-5 w-100 py-5 d-flex ac-jc">
        <ContactUs />
      </div> */}
      <Footer />
    </div>
  );
};

export default ServicesScreen;
