import React from "react";
import {
  banner,
  closeIcon,
  commentIcon,
  discountIcon,
  happyEmoj,
  product,
} from "../../assets/img";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { productDetailImg } from "../../redux/api/constants";
import { useNavigate } from "react-router-dom";

const ShipmentPopup = ({ showClickHandler, showUpdate }) => {
  const navigate = useNavigate();
  return (
    <div className="tl-shipment-qty">
      <div
        className="back-update-qty"
        style={{ display: showUpdate ? "block" : "none", zIndex: 1 }}></div>
      <div
        className="update-qty"
        style={{ right: showUpdate ? "0%" : "-100%" }}>
        <div className="d-flex ac-je mt-2 mt-lg-4">
          <button
            onClick={showClickHandler}
            className="cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2">
            <img src={closeIcon} alt="" />
          </button>
        </div>
        <div className="d-flex ac-jb mt-2 mt-lg-4">
          <h6 className="mb-0 mt-3 f4 fs-18 fs-sm-19 fs-lg-24 fs-xl-25">
            Your Shipment Qty: 0
          </h6>
          <button
            onClick={() => navigate("/select-product")}
            className="get-start-btn border-0 rounded mt-4 mb-0">
            Create
          </button>
        </div>
        <div className="btm-scroll pt-3 d-flex flex-md-row ac-jb">
          <div className="prod-pack">
            <div className="ac-jb mt-2 mt-lg-4">
              <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0">
                Total Recipients
              </p>
              <h4 className="mt-2 mb-4 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark">
                0
              </h4>
            </div>
          </div>
          <div className="ms-3">
            <div className="px-2">
              <p className="f2 fs-13 fs-sm-14 fs-md-15 fs-lg-16 primary1 mt-0 mb-0 text-end">
                Shipments Cost
              </p>
              <h4 className="mt-2 f4 fs-14 fs-md-15 fs-lg-16 fs-xl-17 fs-xxl-18 text-dark text-end">
                $100.00
              </h4>
            </div>

            {/* Btn */}
          </div>
        </div>
        <div className="ac-jc d-grid addrecipient">
          <img src={banner} className="bannerImge mt-3" />
        </div>
        <h4 class=" f4 mt-3 addrecipient">No existing shipments.</h4>
        <p
          style={{ textAlign: "center" }}
          className=" fs-xs-11 fs-xl-18 fs-lg-17 fs-md-16 fs-sm-15 primary1 addrecipient">
          Already know where you want to ship your swag? Create shipments by
          clicking the Create button.
        </p>
      </div>
    </div>
  );
};

export default ShipmentPopup;
