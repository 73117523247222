import React from "react";
import { useNavigate } from "react-router-dom";

const DashboardOrder = () => {
  const navigate = useNavigate();
  return (
    <div className="w-100 w-md-50 px-2 order-box mb-4">
      <div className="box d-flex flex-column ac-jc p-3 p-lg-4 p-xl-5">
        <h5 className="f4 fs-18 fs-md-21 fs-lg-25 fs-xl-28 w-100 text-dark">
          Orders
        </h5>
        <div className="w-100 d-flex flex-column ac-jc mt-4 mt-lg-5">
          <h6 className="f3 fs-18 fs-md-21 fs-lg-25 fs-xl-27 text-dark">
            No Recent Orders
          </h6>
          <p className="parag text-center mt-2 mb-4">
            You've placed no swag orders recently
          </p>
        </div>
        <button
          onClick={() => navigate("/lets-start")}
          className="f4 get-start-btn bg-transparent  primary my-0 mx-2 border-primar text-nowrap">
          Start new order
        </button>
      </div>
    </div>
  );
};

export default DashboardOrder;
