import React from "react";
import { gb_banner, gb_foot, our_gb1, our_gb2, our_gb3 } from "../assets/img";
import Footer from "../navbar/Footer";

const GiveBack = ({ title, paragraph }) => {
  return (
    <div className="d-flex flex-column ac-jc ">
      <div className="w-100  w-lg-75 w-xl-65 w-xxl-60 text-center mx-auto">
        <img src={gb_banner} className="w-100 "   style={{objectFit:"cover",objectPosition:"center",height:"50%" }}/>
      </div>
      <h2 className="sect-title text-center dark-blue ">Our Givebacks</h2>
      <div className="w-90 m-auto mb-4">
        <div className="d-flex ac-jc text-center flex-wrap">
          <div className="col-md-6">
            <img src={our_gb1} className="w-40" />
            <p className="f2 fs-16 text-justify w-90 m-auto mt-3" style={{ color: "#839dd1" }}>
              Congratulations! With the purchase of every SOIL product you have
              contributed towards helping farmers, and aiding agroforestry
              efforts in India to make the planet a greener and better place.
            </p>
          </div>
          <div className="col-md-6 mt-2">
            <img src={our_gb2} className="w-40" />
            <p className="f2 fs-16 text-justify w-90 m-auto mt-3" style={{ color: "#839dd1" }}>
              We passionately believe in giving back, which is why we commit 1%
              of all our sales proceeds to ensure that the basic human right to
              clean drinking water is met for those in need. It's not just a
              transaction; it's a transformation.{" "}
            </p>
          </div>
        </div>
        <div className="text-center">
        <h2 className="sect-title text-center dark-blue mt-5">2 Little Angels</h2>
        <img src={our_gb3} className="w-40" />
        <p className="f2 fs-16 text-justify w-90 m-auto mt-3" style={{ color: "#839dd1" }}>We thank you for believing and supporting the 2 Little Angels program. Your order has helped us and Asan give 2 young girls access to a basic essential of a menstrual cup. You have not only aided in improving her health and hygiene which we take so much for granted each month, but you have also helped her stay in school, regain her self esteem and put a smile back on her face! <br/> <br/> Words can't express how grateful we are for your kindness. Together, we're weaving a brighter future for these girls. Our goal this year is to reach 5,000 young angels, and every helping hand, every shared story, every contribution brings us closer.<br/> <br/> Thank you for being an Angel to 2 Angels!</p>
        </div>
      </div>
      <div className="w-100 mt-4">
          <img src={gb_foot} className="w-100 " style={{objectFit:"cover",objectPosition:"center",height:"50%"}}/>
          <Footer />
      </div>
    
    </div>
  );
};

export default GiveBack;
