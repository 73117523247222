// @ts-nocheck
import React from "react";
import { closeIcon, link_icon, logist, product1, redeem_animated } from "../../assets/img";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import InsertLinkIcon from '@mui/icons-material/InsertLink';

const RedeemRequestListComp = () => {
    return (
        <div className="w-100 h-100 d-flex ac-jc mt-5 flex-wrap">
            <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2">
                <div className="prod-list-box ">
                    <div className="">
                        <div className="d-flex justify-content-between align-self-center mb-3">
                            <p className="f2 fs-13 fs-sm-14 fs-lg-15 ms-2 text-primary1 parag mb-0">Autoship</p>
                            <button className='bg-transparent f2 fs-13 fs-sm-14 fs-lg-15 ms-2 text-primary border-0'>
                                Visit
                                <InsertLinkIcon className="bg-transparent f2 fs-10 fs-sm-11 fs-lg-12 ms-1 text-primary" style={{ transform: 'rotate(130deg)' }} />
                            </button>
                        </div>

                    </div>
                    <div className="cust-btn d-flex ac-jc">
                        <img src={redeem_animated} alt="" className="custom-w-h rounded-3" />
                    </div>
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-primary1 text-dark mt-3 mb-2">Credit Test</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">Status : Inactive</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">Date Created : 11/12/2023</p>
                    <div className="d-flex justify-content-center ">
                        <button
                            // onClick={showPopupHander}
                            // onClick={showClickHandler}
                            className="get-start-btn border-0 mt-3 mb-3"
                        >
                            View Request
                        </button>
                    </div>
                </div>
            </div>
            <div className="p-2 p-md-2 my-2 w-xs-100 my-lg-3 px-xxl-3 prod-list-box-tl2">
                <div className="prod-list-box">
                    <div className="">
                        <div className="d-flex justify-content-between align-self-center mb-3">
                            <p className="f2 fs-13 fs-sm-14 fs-lg-15 ms-2 text-primary1 parag mb-0">Autoship</p>
                            <button className='bg-transparent f2 fs-13 fs-sm-14 fs-lg-15 ms-2 text-primary border-0'>
                                Visit
                                <InsertLinkIcon className="bg-transparent f2 fs-10 fs-sm-11 fs-lg-12 ms-1 text-primary" style={{ transform: 'rotate(130deg)' }} />
                            </button>
                        </div>

                    </div>
                    <div className="cust-btn d-flex ac-jc">
                        <img src={redeem_animated} alt="" className="custom-w-h rounded-3" />
                    </div>
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-primary1 text-dark mt-3 mb-2">Credit Test</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">Status : Inactive</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">Date Created : 11/12/2023</p>
                    <div className="d-flex justify-content-center ">
                        <button
                            // onClick={showPopupHander}
                            // onClick={showClickHandler}
                            className="get-start-btn border-0 mt-3 mb-3"
                        >
                            View Request
                        </button>
                    </div>
                </div>
            </div>
            <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2">
                <div className="prod-list-box">
                    <div className="">
                        <div className="d-flex justify-content-between align-self-center mb-3">
                            <p className="f2 fs-10 fs-sm-11 fs-lg-12 ms-2 text-primary1 parag mb-0">Autoship</p>
                            <button className='bg-transparent f2 fs-13 fs-sm-14 fs-lg-15 ms-2 text-primary border-0'>
                                Visit
                                <InsertLinkIcon className="bg-transparent f2 fs-10 fs-sm-11 fs-lg-12 ms-1 text-primary" style={{ transform: 'rotate(130deg)' }} />
                            </button>
                        </div>

                    </div>
                    <div className="cust-btn d-flex ac-jc">
                        <img src={redeem_animated} alt="" className="custom-w-h rounded-3" />
                    </div>
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-primary1 text-dark mt-3 mb-2">Credit Test</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">Status : Inactive</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">Date Created : 11/12/2023</p>
                    <div className="d-flex justify-content-center ">
                        <button
                            // onClick={showPopupHander}
                            // onClick={showClickHandler}
                            className="get-start-btn border-0 mt-3 mb-3"
                        >
                            View Request
                        </button>
                    </div>
                </div>
            </div>
            <div className="p-2 p-md-2 w-xs-100 my-2 my-lg-3 px-xxl-3 prod-list-box-tl2">
                <div className="prod-list-box">
                    <div className="">
                        <div className="d-flex justify-content-between align-self-center mb-3">
                            <p className="f2 fs-13 fs-sm-14 fs-lg-15 ms-2 text-primary1 parag mb-0">Autoship</p>
                            <button className='bg-transparent f2 fs-13 fs-sm-14 fs-lg-15 ms-2 text-primary border-0'>
                                Visit
                                <InsertLinkIcon className="bg-transparent f2 fs-10 fs-sm-11 fs-lg-12 ms-1 text-primary" style={{ transform: 'rotate(130deg)' }} />
                            </button>
                        </div>

                    </div>
                    <div className="cust-btn d-flex ac-jc">
                        <img src={redeem_animated} alt="" className="custom-w-h rounded-3" />
                    </div>
                    <p className="f4 fs-13 fs-sm-14 text-custom-res fs-lg-15 ms-2 text-primary1 text-dark mt-3 mb-2">Credit Test</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-2">Status : Inactive</p>
                    <p className="f2 fs-11 fs-sm-12 text-custom-res fs-lg-13 ms-2 text-primary1 parag mb-0">Date Created : 11/12/2023</p>
                    <div className="d-flex justify-content-center ">
                        <button
                            // onClick={showPopupHander}
                            // onClick={showClickHandler}
                            className="get-start-btn border-0 mt-3 mb-3"
                        >
                            View Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RedeemRequestListComp