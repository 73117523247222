import React from 'react'
import { closeIcon, product } from '../../../assets/img'
import DoneIcon from "@mui/icons-material/Done";
import SelectProductHeader from './SelectProductHeader';
// import ProductSelectComp from './ProductSelectComp';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { useState } from 'react';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";


const ProductPopup = ({ PopupHanler, ProductHanler }) => {
    const [check, setCheck] = useState(false)
    const checkBox = () => {
        setCheck(!check)
    }
    return (
        <div className="modal-popup" >
            <div className="back-close" />
            <div className="center-content pb-5">
                <button
                    className="modal-cls-btn fs-33 f5 primary cust-btn px-2 mb-0 mx-2"
                    onClick={PopupHanler}
                >
                    <img src={closeIcon} alt="" />
                </button>
                <div className="d-flex flex-column pt-5 px-4 w-100 justify-content-between mt-md-5 flex-md-row">
                    <div className="">
                        <h4 className="f4 text-dark fs-xl-23 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 box-text-form text-sx-center">Select your products (Linit to 4)</h4>
                    </div>
                    <div className="d-flex align-center-custom">
                        <button className="get-start-btn border-0 my-0 mx-2" onClick={ProductHanler}>Pack</button>
                    </div>
                </div>
                <div>
                    <SelectProductHeader />
                </div>
                <div className='px-5 mt-4 d-flex flex-column'>
                    <div className='d-flex ms-1 px-5 ' style={{ overflow: 'scroll', width: '100%' }}>
                        <table class="rwd-table float-start w-100 mt-3">
                            <tbody>
                                <tr className='d-flex align-items-center justify-content-evenly'>
                                    <th className='text-dark f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Product</th>
                                    <th className='text-dark f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11  text-start'>Type</th>
                                    <th className='text-dark f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '>Shipping Options</th>
                                    <th className='text-dark f2 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '>Size Availability</th>
                                </tr>
                                <tr className='d-flex mt-3 borer-r rounded-4  select-custom-boxes py-4'>
                                    <td data-th="Supplier Code" className='d-flex align-items-center gap-3' style={{ width: '310px' }}>
                                        {check && <CheckBoxIcon onClick={checkBox} className='f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' />}
                                        {!check && <CheckBoxOutlineBlankIcon onClick={checkBox} className='f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' />}
                                        <div className='d-flex w-100'>
                                            <img src={product} className=' img-box-tee2 object-fit-contain' />
                                            <div className='d-flex right-said-box
                                    w-xs-50
                                    w-sm-60
                                 w-90'>
                                                <div className=''>
                                                    <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0  ">The Triblend Unisex Tee</p>
                                                    <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Quantity : 100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-th="Supplier Name parag text-center" style={{ width: '140px', }}>
                                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Product</p>
                                    </td>
                                    <td data-th="Supplier Name parag text-center" style={{ width: '140px', }}>
                                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Global</p>
                                    </td>
                                    <td data-th="Invoice Number" className='parag f2 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 ' style={{ width: '300px' }}>
                                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 mb-2 primary1 mt-0 mb-0">Inventory Sizes</p>
                                        <div className='d-flex text-start flex-wrap w-40' style={{ width: '300px' }}>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">XS: 98</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">S:197</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">M:288</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">L:0</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">XL: 416</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">2XL: 597</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">3XL: 689</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">4XL: 0</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">XXS: 0</p>
                                        </div>
                                    </td>
                                </tr>
                                <tr className='d-flex mt-3 borer-r rounded-4  select-custom-boxes py-4'>
                                    <td data-th="Supplier Code" className='d-flex align-items-center gap-3' style={{ width: '310px' }}>
                                        {check && <CheckBoxIcon onClick={checkBox} className='f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' />}
                                        {!check && <CheckBoxOutlineBlankIcon onClick={checkBox} className='f4 primary1 ms-2 fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22' />}
                                        <div className='d-flex w-100'>
                                            <img src={product} className=' img-box-tee2 object-fit-contain' />
                                            <div className='d-flex right-said-box
                                    w-xs-50
                                    w-sm-60
                                 w-90'>
                                                <div className=''>
                                                    <p class="f3 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 text-dark mt-0 mb-0  ">The Triblend Unisex Tee</p>
                                                    <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Quantity : 100</p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td data-th="Supplier Name parag text-center" style={{ width: '140px', }}>
                                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Product</p>
                                    </td>
                                    <td data-th="Supplier Name parag text-center" style={{ width: '140px', }}>
                                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0">Global</p>
                                    </td>
                                    <td data-th="Invoice Number" className='parag f2 s-xl-13 fs-lg-12 fs-md-11 fs-sm-10 fs-xs-9 ' style={{ width: '300px' }}>
                                        <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 mb-2 primary1 mt-0 mb-0">Inventory Sizes</p>
                                        <div className='d-flex text-start flex-wrap w-40' style={{ width: '300px' }}>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">XS: 98</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">S:197</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">M:288</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">L:0</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">XL: 416</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">2XL: 597</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">3XL: 689</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">4XL: 0</p>
                                            <p class="f2 fs-9 fs-sm-10 fs-xs-9 fs-md-11 fs-lg-12 primary1 mt-0 mb-0 text-start pe-2 py-1">XXS: 0</p>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className='d-flex ms-5 align-items-center'>
                    <button class="get-start-btn bg-lt-blue primary  my-0 mx-2 f4 s-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11" style={{ borderColor: 'primary' }}>Send merch</button>
                    <DeleteRoundedIcon className='bg-lt-blue primary' />
                </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPopup