import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';


const FormRightComp2 = () => {
    return (
        <div className='w-100 ac-jc d-flex'
            style={{
                height: '100vh',
                background: '#E2ECFF',
            }}>
            <div className="form_cont2 ps-4 py-5 pe-4 ac-jb w-80">
                <div className='mb-4'>
                    <p className='f5 text-dark fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 my-0 box-text-form text-center'>What product do you prefer?</p>
                    <p class="f2 primary1 fs-xl-13 fs-xs-9 fs-md-10 fs-sm-8 fs-lg-13 my-0 box-text-form text-center mt-3">Select one</p>
                </div>
                <div className='d-flex ac-jb gap-3'>
                    {/* <img src={file} className='custom-img-form2 rounded-4' style={{ background: '#E2ECFF' }} /> */}
                    <button className='border-0 custom-img-form2 rounded-4 d-flex ac-jc' style={{ background: '#E2ECFF' }} >
                        <PhotoAlbumIcon className='f5 primary1 fs-xl-30 fs-lg-30 fs-md-30 fs-sm-30 fs-xs-30 my-0 box-text-form text-center' />
                    </button>
                    <button className='border-0 custom-img-form2 rounded-4 d-flex ac-jc' style={{ background: '#E2ECFF' }} >
                        <PhotoAlbumIcon className='f5 primary1 fs-xl-30 fs-lg-30 fs-md-30 fs-sm-30 fs-xs-30 my-0 box-text-form text-center' />
                    </button>
                    {/* <img src={file} className='custom-img-form2 rounded-4' /> */}
                </div>
            </div></div>
    )
}

export default FormRightComp2