import React from "react";
import { dashboard } from "../assets/img";
import RedeemDetail from "../component/Services/Redeem/RedeemDetail";
import ServiceBanner from "../component/Services/ServiceBanner";
import Footer from "../navbar/Footer";

const banner = {
  title: "OUR SUSTAINABILITY ETHOS",
};
const redeem = [
  {
    title: "Sustainablity Title 1",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit Commodi voluptatibus accusamus sequi nam quasi distinctio accusantium temporibus inventore eaque modi voluptatem incidunt ipsam magnam blanditiis quia iusto veritatis ad a",
    img: dashboard,
  },
  {
    title: "Sustainablity Title 2",
    desc: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi voluptatibus, accusamus, sequi nam quasi distinctio accusantium temporibus inventore eaque modi voluptatem incidunt ipsam magnam blanditiis quia iusto veritatis ad a!",
    img: dashboard,
  },
];
const SustainablityScreen = () => {
  return (
    <div className="tl-redeem">
      <ServiceBanner title={banner?.title} paragraph={banner?.parag} />
      <RedeemDetail redeem={redeem} />
      <Footer />
    </div>
  );
};

export default SustainablityScreen;
