import React, { useState } from 'react'
import { file, noteIcon, redeem_animated } from '../../../assets/img'
import { Col } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

const FormThree = ({ F2, F4 }) => {
    const navigation = useNavigate()
    const [imgfile, setImgFile] = useState('')

    const handleChange = (e) => {
        console.log(e.target.files);
        setImgFile(URL.createObjectURL(e.target.files[0]));
    }
    return (
        <div className=' d-flex align-custom1 flex-column mt-3'
            style={{
                // height: '140%',
                alignItems: 'end'
            }
            }>
            <div className="box1 w-85">
                <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Heading Text*
                </p>
                <input placeholder="Welcome!" value='Welcome!' className="f2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
            </div>
            <div className="box w-85 mt-3">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Company Name
                </p>
                <textarea
                    placeholder="We want to send product your way! Click below and enter your Shopping details "
                    value="We want to send product your way! Click below and enter your Shopping details"
                    className="f2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120 txt-wrap"
                // cols="40"
                // rows="5"
                ></textarea>
            </div>
            <div className="box1 w-85 mt-3">
                <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    CTA Text*
                </p>
                <input placeholder="Start Here" value='Start Here' className="f2 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 w-xs-120" />
            </div>
            <div className="w-85 mt-3">
                <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                    Display Image
                </p>
                <div className='w-100 align-items-center ps-4 d-flex mb-4 rounded-3 py-3'
                    style={{
                        background: '#E2ECFF',
                    }}>
                    <p className='my-0 text-start f1 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>{imgfile.length === 0 ? 'roket.png' : imgfile}</p>
                </div>
                <div className='d-flex flex-column flex-md-row w-100 h-50 rounded-5 form-box-shadow py-5 d-flex align-items-center justify-content-around'>
                    <img src={imgfile.length === 0 ? file : imgfile} className='file-up-img mb-res-5' />
                    <div className=''>
                        <p className='text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-nowrap'>Drag and drop to uplode or
                            <span>
                                <label class="label">
                                    <input type="file" className=''
                                        // value={imgfile}
                                        // onChange={(e) => setImgFile(e.target.value)}
                                        onChange={handleChange}
                                    />
                                    <span className='text-center mb-0 f3 text-primary fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 text-nowrap ps-1'>Choose file</span>
                                </label>
                            </span>
                        </p>
                        <p className='text-center mb-0 f2 primary1 fs-xl-10 fs-lg-7 fs-md-9 fs-sm-9 fs-xs-10'>Accepted File Types: .PNG .JPEG .JPG .SVG </p>
                        <p className='text-center mb-0 f2 primary1 fs-xl-10 fs-lg-7 fs-md-9 fs-sm-9 fs-xs-10'>(Max size 5MB)</p>
                    </div>
                </div>
            </div>
            <div className='d-flex ac-jb w-85 mt-5'>
                <button className='cust-btn text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '
                    onClick={F2}>
                    <ArrowBackIcon
                        className='text-center mb-0 f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-14 fs-xs-15' /> Previous Step</button>
                <button className='get-start-btn border-0 mt-0'
                    onClick={F4}>Continue</button>
            </div>

            {/* <div className='w-100 ac-jc d-flex'
                style={{ height: '175vh', background: '#E2ECFF' }}>
                <div className="d-flex flex-column flex-md-row form_cont ps-4 py-4 pe-4 ac-jb w-70 form-box-cont">
                    <div className='margin-c'>
                        <p className='f5 text-dark fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 my-0 box-text-form text-sx-center'>Welcome</p>
                        <p class=" primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 my-0 box-text-form text-sx-center">Lorem ipsum of the test used in website design </p>
                    </div>
                    <img src={redeem_animated} className='custom-img-form rounded-4' />
                </div>
            </div> */}
        </div>
    )
}

export default FormThree