import React from 'react'
import ProjectComp from '../Components/ProjectComp/ProjectComp'
import OurProjectHeader from '../Components/ProjectComp/OurProjectHeader'

const OurProjectScreen = () => {
    return (
        <div className="h-100 w-100">
            <div className=''>
                <OurProjectHeader />
            </div>
            <div className='w-100 d-flex ac-jc' >
                <ProjectComp />
            </div>
        </div>

    )
}

export default OurProjectScreen