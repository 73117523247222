import React from "react";
import { joinTeam } from "../../../assets/img";
import { useNavigate } from "react-router-dom";

const JoinTeam = () => {
  const navigate = useNavigate();
  return (
    <div className="join-abt py-2 py-lg-5">
      <div className="d-flex flex-column flex-md-row mt-4 w-90 w-md-80 w-lg-75 mx-auto ac-jc">
        <div className="w-90 w-lg-80 w-xl-75 px-3">
          <h4 className="sect-title f5 mb-2 mb-lg-3 text-primar text-center text-md-start">
            Join the Merch Revolution
          </h4>
          <p className="parag fuls  text-justify w-70">
            We’re looking for ambitious individuals who strive in complex
            environments, and love swag, culture, branding, & proactively
            solving problems!
          </p>
          {/* <button
            onClick={() => navigate("/book-consult")}
            className="cust-btn bg-primar get-start-btn mb-3 mx-auto ms-md-0"
          >
            Join Our Team
          </button> */}
          <button className="get-start-btn mt-0 border-0 mb-4 mt-4">
            Join Our Team
          </button>
        </div>
        <div className="rit-img">
          <img src={joinTeam} alt="" />
        </div>
      </div>
    </div>
  );
};

export default JoinTeam;
