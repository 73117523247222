// @ts-nocheck
import React from "react";
import { login, loginban } from "../../assets/img";

const LeftAuth = () => {
  return (
    <div className="left-auth w-100">
      <div className="mt-lg-5 mt-sm-0">
        <div className=" mt-lg-5 mt-sm-0">
          <h4 className="sect-title mb-1 text-dark fs-1 ms-3">Welcome!</h4>
          <p className="parag ms-3">
            Experience merchandise like never before.
            {/* <br className="d-none d-lg-block" />
          eveniet molestiae possimus, voluptatum neque eaque */}
          </p>
        </div>
        <img src={loginban} alt="" className="w-80" />
      </div>
    </div>
  );
};

export default LeftAuth;
