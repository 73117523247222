import React from 'react'
import { cogwheel, light_mode, night_mode, noteIcon, redeem_animated } from '../../../assets/img'
import { Col } from 'react-bootstrap'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { DateRangeOutlined, Done } from '@mui/icons-material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useState } from 'react';
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
const FormTwo = ({ F1, F3 }) => {
    const [lighttheem, setLightTheem] = useState(true)
    const [darktheem, setDarkTheem] = useState(false)
    const [customtheem, setCustomTheem] = useState(false)

    const [color1, setColor1] = useState('#FFFFFF')
    const [color2, setColor2] = useState('#131415')
    const [color3, setColor3] = useState('#9846DE')

    const [color1edit, setColor1Edit] = useState('#FFFFFF')
    const [color2edit, setColor2Edit] = useState('#131415')
    const [color3edit, setColor3Edit] = useState('#9846DE')


    const darkMode = () => {
        setDarkTheem(true)
        setLightTheem(false)
        setCustomTheem(false)
    }
    const lightMode = () => {
        setDarkTheem(false)
        setLightTheem(true)
        setCustomTheem(false)
    }
    const custoMode = () => {
        setDarkTheem(false)
        setLightTheem(false)
        setCustomTheem(true)
    }
    const navigation = useNavigate()
    return (
        <div className=' align-custom1 d-flex flex-column mt-3'
            style={{
                // height: '160%',
                alignItems: 'end'
            }
            }
        >
            <div className="w-85">
                {/* <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 my-0 ">
                    Step 2/6
                </p> */}
                {/* <p
                        className="f4 fs-xs-18 fs-sm-19 fs-md-20 fs-lg-21 fs-xl-23 mt-3 mb-4" >
                        Choose your theme
                    </p> */}

                <button onClick={lightMode} className={lighttheem ? 'w-100 ps-3 align-items-center justify-content-between border-0 bg-transparent rounded-5 py-4 select_shadow d-flex  form-box-shadow ' : 'ps-3 w-100 align-items-center justify-content-between border-0 bg-transparent rounded-5 py-4 select_shadow d-flex '}>
                    <div className='d-flex ac-jc'>
                        <div className='theem_select rounded-3 p-2'
                            style={{ background: '#E2ECFF' }}>
                            <img src={light_mode} className='w-100 object-fit-contain h-100' />
                        </div>
                        <div className='ms-4'>
                            <p className=' text-start mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Light Theme </p>
                            <p className='text-start mt-3 mb-0 f2 primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12'>Let's got to the beach and enjoy the sun.</p>
                        </div>
                    </div>
                    <CheckCircleSharpIcon className={lighttheem ? 'me-4 visible bg-lght rounded-5 text-Blue mb-0 f4 fs-20' : ' invisible'} />
                </button>
                {/* select 2 */}
                <button onClick={darkMode} className={darktheem ? 'ps-3 w-100 align-items-center justify-content-between border-0 bg-transparent rounded-5 py-4 select_shadow d-flex  form-box-shadow ' : ' ps-3 w-100 align-items-center justify-content-between border-0 bg-transparent rounded-5 py-4 select_shadow d-flex '}>
                    <div className='d-flex ac-jc'>
                        <div className='theem_select rounded-3 p-2 '
                            style={{ background: '#E2ECFF' }}>
                            <img src={night_mode} className='w-100 object-fit-contain h-100' />
                        </div>
                        <div className='ms-4'>
                            <p className=' text-start mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Dark Theme</p>
                            <p className='text-start mt-3 mb-0  primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2'>Let's head to space and go to the moon!</p>
                        </div>
                    </div>
                    <CheckCircleSharpIcon className={darktheem ? 'me-4 visible bg-lght rounded-5 text-Blue mb-0 f4 fs-20' : ' invisible'} />
                </button>

                {/* select 3 */}
                {/* <button onClick={custoMode} className={customtheem ? 'ps-3 w-100 align-items-center justify-content-between border-0 bg-transparent rounded-5 py-4 select_shadow d-flex  form-box-shadow ' : ' ps-3 w-100 align-items-center justify-content-between border-0 bg-transparent rounded-5 py-4 select_shadow d-flex '}>
                    <div className='d-flex ac-jc'>
                        <div className='theem_select rounded-3 p-2 '
                            style={{ background: '#E2ECFF' }}>
                            <img src={cogwheel} className='w-100 object-fit-contain h-100' />
                        </div>
                        <div className='ms-4'>
                            <p className=' text-start mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11'>Custom Theme</p>
                            <p className='text-start mt-3 mb-0  primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2'>Feeling Adventureous? Customize the look of your redeem page</p>
                        </div>
                    </div>
                    <CheckCircleSharpIcon className={customtheem ? 'me-4 visible bg-lght rounded-5 text-Blue mb-0 f4 fs-20' : ' invisible'} />
                </button> */}

                {/* <div className="Integration-screen2 d-flex flex-column w-100 ac-jb mb-4 "> */}
                <div className="Integration-screen2 mt-5">
                    <p className="f2 primary1 fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                        Font Family
                    </p>
                    <div className='box d-flex flex-row'>
                        <select className="cust-btn f2 fs-13 fs-md-14 fs-lg-16 fs-xl-17 fs-xxl-18 primary1 mb-0">
                            <option value="all">All</option>
                            <option value="Pack">Pack</option>
                            <option value="Products">Products</option>
                        </select>
                    </div>
                </div>
                {/* <div className='d-flex w-100 align-items-center justify-content-around mt-4 d-flex flex-column flex-lg-row '>
                    <div className="w-30 w-xs-100 input-custom ">
                        <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                            Background Color
                        </p>
                        <div className='d-flex w-100 ac-jc'>
                            <div className="d-flex ac-jb color_choos d-flex h-100 w-100 px-2 py-2 rounded-2">
                                <input type='color'
                                    value={color1}
                                    onChange={(e) => setColor1(e.target.value)}
                                    className='color-pic' />
                                <div className='box-none border-0'>
                                    <input className='my-0' value={color1} onChange={(e) => setColor1(e.target.value)} disabled={color1edit ? true : false} />
                                </div>
                                <button className='border-0 bg-transparent p-0' onClick={() => setColor1Edit(!color1edit)}>
                                    <CreateOutlinedIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-30 w-xs-100 input-custom">
                        <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                            Font Color
                        </p>
                        <div className='d-flex w-100 ac-jc'>
                            <div className="d-flex ac-jb color_choos d-flex h-100 w-100 px-2 py-2 rounded-2">
                                <input type='color'
                                    value={color2}
                                    onChange={(e) => setColor2(e.target.value)}
                                    className='color-pic' />
                                <div className='box-none border-0'>
                                    <input className='my-0' value={color2} onChange={(e) => setColor2(e.target.value)} disabled={color2edit ? true : false} />
                                </div>
                                <button className='border-0 bg-transparent p-0' onClick={() => setColor2Edit(!color2edit)}>
                                    <CreateOutlinedIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="w-30 w-xs-100 input-custom">
                        <p className="f2 primary1  fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11">
                            Accent Color
                        </p>
                        <div className='d-flex w-100 ac-jc'>
                            <div className="d-flex ac-jb color_choos d-flex h-100 w-100 px-2 py-2 rounded-2">
                                <input type='color' value={color3}
                                    onChange={(e) => setColor3(e.target.value)}
                                    className='color-pic' />
                                <div className='box-none border-0'>
                                    <input className='my-0' value={color3} onChange={(e) => setColor3(e.target.value)} disabled={color3edit ? true : false} />
                                </div>
                                <button className='border-0 bg-transparent p-0' onClick={() => setColor3Edit(!color3edit)}>
                                    <CreateOutlinedIcon />
                                </button>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='d-flex align-items-end w-100 mt-5'
                >
                    <div className='d-flex ac-jb w-100'>
                        <button className='cust-btn text-center mb-0 f3 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-12 fs-xs-11 '
                            onClick={F1}
                        >
                            <ArrowBackIcon
                                className='text-center mb-0 f4 text-dark fs-xl-15 fs-lg-14 fs-md-13 fs-sm-14 fs-xs-15' /> Previous Step</button>
                        <button className='get-start-btn border-0 mt-0' onClick={F3}>Continue</button>
                    </div>
                </div>
            </div>
            {/* <div className='w-100 ac-jc d-flex'
                style={{
                    height: '150vh',
                    background: '#E2ECFF',
                }}>
                <div className="d-flex flex-column flex-md-row form_cont ps-4 py-4 pe-4 ac-jb w-70 form-box-cont">
                    <div className='margin-c'>
                        <p className='f5 text-dark fs-xl-18 fs-lg-19 fs-md-20 fs-sm-21 fs-xs-22 my-0 box-text-form text-sx-center'>Welcome</p>
                        <p class=" primary1 fs-xl-12 fs-xs-10 fs-md-10 fs-sm-8 fs-lg-12 f2 my-0 box-text-form text-sx-center">Lorem ipsum of the test used in website design </p>
                    </div>
                    <img src={redeem_animated} className='custom-img-form rounded-4' />
                </div>
            </div> */}
        </div>
    )
}

export default FormTwo