import { useState } from "react";
import EmtyShipment from "../component/Shipments/EmtyShipment";
import ShipmentsContact from "../component/Shipments/ShipmentsContact";
import ShipmentHeader from "../component/Shipments/ShipmentHeader";

const ShipmentHomeScreen = () => {
  const [emtyshipment, setEmtyShipment] = useState(true)
  const [contactshipment, setContactShipment] = useState(false)
  const Ship = () => {
    setEmtyShipment(true)
    setContactShipment(false)
  }
  const ContShip = () => {
    setEmtyShipment(false)
    setContactShipment(true)
  }
  return (
    <div className="order-screen">
      <div className="mx-auto wi-100 w-sm-90 w-lg-95 w-xl-85 px-2 px-lg-2">
        <div className="d-flex ac-jb w-100">
          <h2 className="sect-title mb-0">Shipments</h2>
          <button
            // onClick={() => navigate("/lets-start")}
            className="get-start-btn border-0  f4 s-xl-16 fs-lg-15 fs-md-14 fs-sm-13 fs-xs-12">
            Send Merch
          </button>
        </div>
        <div className="d-flex">
          <div className="d-flex px-3 flex-column align-items-center position-relative">
            <button onClick={Ship} className={emtyshipment ? "cust-btn py-2 mb-0 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20" : "cust-btn py-2 mb-0 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20 primary1"}>
              Shipments
            </button>
            {emtyshipment && <div className="active"></div>}
          </div>
          <div className="d-flex px-3 flex-column align-items-center position-relative">

            <button onClick={ContShip} className={contactshipment ? "cust-btn py-2 mb-0 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20" : "cust-btn py-2 mb-0 f4 fs-xs-15 fs-sm-17 fs-lg-18 fs-xl-20 primary1"}>
              Contacts
            </button>
            {contactshipment && <div className="active"></div>}
          </div>
        </div>
        {emtyshipment && <EmtyShipment />}
        {/* {contactshipment && <ShipmentHeader />} */}
        {contactshipment && <ShipmentsContact />}
      </div>
    </div>
  );
};

export default ShipmentHomeScreen;
