import { contact } from "../../assets/img";

const BookConsultLeft = () => {
  return (
    <div className="consultBanner rounded rounded-end-5 col-md-6">
      {/* <p className="primary1 w-70 mb-0 f3 fs-18">
        "Basically I had this massive backlog of merchHQ I had to send out, and
        I don't think I realized just how long I had to spend at the post office
        to do this.[It was] just not enjoyable."
      </p> */}
      <img src={contact} alt="" className="w-70"/>
    </div>
  );
};

export default BookConsultLeft;
