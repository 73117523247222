import BookConsultLeft from "../component/BookConsult/BookConsultLeft";
import BookConsultRight from "../component/BookConsult/BookConsultRight";
const BookConsult = () => {
  return (
    <div className="w-100 d-flex flex-column flex-md-row w-md-100">
      <BookConsultLeft />
      <BookConsultRight />
    </div>
  );
};

export default BookConsult;
